import React from "react";
import PPPMessage from "./../../../../../assets/images/PPP-message.svg";
import { Link } from "react-router-dom";


const PppMessages = () => {
  return (
    <div className="bg-white rounded-2xl p-4 mt-3">
      <h3 className="uppercase font-semibold font-18 text-darkblue">
        PPP MESSAGES
      </h3>

      <div className="mt-4">
        <img src={PPPMessage} alt="PPPMessage" className="w-full m-auto" />
      </div>
      <div className="text-center mt-4">
        <Link href="#" className="font-medium font-18 text-darkblue">
          Click on Illustrator to see the messages.
        </Link>
      </div>
    </div>
  );
};

export default PppMessages;
