import { createAsyncThunk } from "@reduxjs/toolkit";
import { openToast } from "../../../slices/common/toast/index.js";
import { closeAdminModal } from "../../../slices/common/adminModal";
import {
  addDocType,
  deleteDocType,
  getAllDocTypes,
  updateDocType,
} from "../../../services/admin/documentType/documentType.js";
import { getDocumentTypeForWeb } from "../../../services/web/common/creatorRequest.service.js";

export const GetAllDocumentTypeAction = createAsyncThunk(
  "docType/getAllType",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getAllDocTypes(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const GetAllDocumentTypeForWebAction = createAsyncThunk(
  "docType/getAllTypeForWeb",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getDocumentTypeForWeb();
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const EditDocumentTypeAction = createAsyncThunk(
  "docType/editType",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await updateDocType(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const AddDocumentTypeAction = createAsyncThunk(
  "docType/addType",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await addDocType(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(closeAdminModal());
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(closeAdminModal());
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const RemoveDocumentTypeAction = createAsyncThunk(
  "docType/removeType",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await deleteDocType(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue({ ...response, id: payload.id });
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);
