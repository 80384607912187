import { Button, MenuItem, Select, Box } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  CountryCode,
  Phone,
} from "../../../../../../utils/validation/ChangePhoneNumberForm";
import { UpdateAccountAction } from "../../../../../../store/middlewares/web/profile/myProfile/account";
import { Input } from "../../../../../atoms";
import { closeModal } from "../../../../../../store/slices/common/modal";

const ModalChangePhoneNumber = () => {
  const { me } = useSelector((state) => state?.me);
  const { countryCode: countryCodes } = useSelector(
    (state) => state?.countryCode
  );
  const [intialVal, setInitialVal] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (me) {
      let tempObj = {
        phone: me?.phone ? me?.phone?.split("-")[1] : "", //set country code number of the current logged in user after updating
        countryCode: me?.phone ? me?.phone?.split("-")[0] : "", //set phone number of the current logged in user after updating
      };
      setInitialVal(tempObj);
    }
  }, [me]);

  const schema = Yup.object({
    phone: Phone,
    countryCode: CountryCode,
  });

  const handleClose = () => {
    formik.resetForm(); // reset form on Close
    dispatch(closeModal());
  };

  const handleSubmit = (values) => {
    let tempNum = `${values.countryCode}-${values.phone}`;
    let tempObj = {
      ...values,
      phone: tempNum,
      coverUrl: me?.coverUrl,
      profileUrl: me?.profileUrl,
    };
    delete tempObj["countryCode"];
    dispatch(UpdateAccountAction(tempObj)); // API Call to update Phone Number
    handleClose();
  };

  // Initialize Form Object and destructure
  const formik = useFormik({
    initialValues: intialVal,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: handleSubmit,
  });

  const countryCodeSelection = () => {
    // select country code
    return (
      <Select
        value={formik.values ? formik.values.countryCode : countryCodes[0]?.code ? countryCodes[0]?.code : ""}
        fullWidth
        name={"countryCode"}
        placeholder="Code"
        onChange={(e) => {
          formik.handleChange(e);
          e.preventDefault();
        }}
        displayEmpty
        SelectDisplayProps={{ outline: "none", padding: 0 }}
        sx={{
          border: "none ",
          padding: "0 !important",
          flexBasis: "23%",
          borderRight: "2px solid #EAEAEA",
          borderTopRightRadius: "0px !important",
          borderBottomRightRadius: "0px !important",
        }}
      >
        {countryCodes?.map((x) => {
          return (
            <MenuItem key={x.id} value={`${x.code}`.trim()}>
              {x.code}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          display: "flex !important",
          flexDirection: "row",
          border: "1px solid #EAEAEA",
          borderRadius: "16px",
        }}
      >
        {countryCodeSelection()}
        {/* change phone number */}
        <Input
          name="phone"
          value={formik.values?.phone}
          onChange={(e) => {
            let pattern = /[^0-9]/g;
            if (!pattern.test(e.target.value)) {
              formik.handleChange(e);
            }
          }}
          error={formik.errors.phone && formik.touched.phone}
          fullWidth
          placeholder="Enter Phone"
          onBlur={formik.handleBlur}
          id="phone"
          sx={{
            border: "0px solid !important",
            borderRadius: "none !important",
          }}
        />
      </Box>
      {formik.errors.phone && formik.touched.phone && (
        <div className="text-red-500 text-sm mt-1">{formik.errors.phone}</div>
      )}
      <Box className="flex justify-end mt-6 gap-3">
        <Button
          variant="contained"
          className="m-auto white-btn"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button type="submit" variant="contained" className="m-auto px-12">
          Save
        </Button>
      </Box>
    </form>
  );
};

export default ModalChangePhoneNumber;
