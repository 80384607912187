import { createSlice } from "@reduxjs/toolkit";
import { resetPassword } from "./../../../middlewares/auth/reset/reset";

export const webresetSlice = createSlice({
  name: 'webreset',
  initialState: {
    webresetLoading: false,
    webresetMessage: "",
    webresetError: false,
    webresetSuccess: false,
    webresetUser: null,
  },
  reducers: {
    resetwebreset: (state) => {
      state.webresetLoading = false;
      state.webresetMessage = "";
      state.webresetError = false;
      state.webresetSuccess = false;
      state.webresetUser = null;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(resetPassword.pending, (state) => {
        state.webresetLoading = true;
        state.webresetMessage = "";
        state.webresetError = false;
        state.webresetSuccess = false;
        state.webresetUser = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        const { response, message } = action.payload
        state.webresetLoading = false;
        state.webresetMessage = message;
        state.webresetError = false;
        state.webresetSuccess = true;
        state.webresetUser = response;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        const { message } = action.payload
        state.webresetLoading = false;
        state.webresetMessage = message;
        state.webresetError = true;
        state.webresetSuccess = false;
        state.webresetUser = null;
      })
  }
});

export const {
  resetwebreset
} = webresetSlice.actions;

export default webresetSlice.reducer;