import React from 'react'
import Gallery from "./../../../../../assets/images/gallery.svg";
import Microphone from "./../../../../../assets/images/microphone-2.svg";
import Verified from "./../../../../../assets/images/verified.svg";
import DollarIcon from "./../../../../../assets/images/dollar-square.svg";
import ArchiveTick from "./../../../../../assets/images/archive-tick.svg";
import HeartOutlineIcon from "./../../../../../assets/images/heart-outline.svg";
import LockIcon from "./../../../../../assets/images/lock.svg";
import GalleryBlueIcon from "./../../../../../assets/images/gallery-blue.svg";

import NoArticleFilterMenu from '../../home/NoArticleFilterMenu';
import { Box, Button, IconButton, InputAdornment } from '@mui/material';
import { Input } from '../../../../atoms';
import { openModal } from '../../../../../store/slices/common/modal';
import { useDispatch } from 'react-redux';
import ModalSendTip from '../../userProfile/ModalSendTip';

const NoArticlePosts = ({ noArticlePosts }) => {
    const dispatch = useDispatch()
    return (
        <>
            {noArticlePosts?.map((post, index) => {
                const { userProfileSrc, name, postImgSrc, username, day, desctription, showGallery, isLocked } = post
                return (
                    <div className="bg-white rounded-2xl p-4 mt-4 creator-home-module" key={index}>
                        <div className="flex justify-between items-center">
                            <div className="flex gap-5 varified">
                                <div className="relative">
                                    <img src={userProfileSrc} alt="ColeUser" />
                                    <div className="verified-bg">
                                        <img src={Verified} alt="Verified" />
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center">
                                    <h3>{name}</h3>
                                    {username && <p>@{username}</p>}
                                </div>
                            </div>
                            <div className="flex flex-wrap items-center gap-3">
                                <p className="font-medium font-14 text-black">{day}</p>
                                <NoArticleFilterMenu />
                            </div>
                        </div>
                        <p className="font-16 text-darkblue mt-4">{desctription}</p>
                        <div className="relative mt-5">
                            <img src={postImgSrc} alt="ProfilePostImg1" />
                            {isLocked &&
                                <>
                                    <div className="profile-lock-icon text-center">
                                        <Button
                                            variant="contained"
                                            className="white-btn rounded-full"
                                        >
                                            {" "}
                                            <img src={LockIcon} alt="LockIcon" height={40} width={40} />
                                        </Button>
                                        <h5 className="font-16 font-medium text-darkblue my-2 lg:my-4">
                                            Unlock this Post
                                        </h5>
                                        <Button variant="contained" className="unlock-post">
                                            Pay $5 To Unlock This Post
                                        </Button>
                                    </div>
                                    <div className="profile-gallery-btn">
                                        <Button className="whitebtn flex items-center gap-2">
                                            <img src={GalleryBlueIcon} alt="GalleryBlueIcon" />{" "}
                                            <span>01</span>
                                        </Button>
                                    </div>
                                </>}
                            {showGallery && <div className="profile-gallery-btn">
                                <Button className="whitebtn flex items-center gap-2">
                                    <img src={GalleryBlueIcon} alt="GalleryBlueIcon" />{" "}
                                    <span>01</span>
                                </Button>
                            </div>}
                        </div>

                        <div className="flex justify-between mt-3">
                            <div className="flex gap-3">
                                <div>
                                    <IconButton aria-label="delete">
                                        <img src={HeartOutlineIcon} alt="HeartOutlineIcon" />
                                    </IconButton>
                                </div>

                                <Box className="flex items-center">
                                    <IconButton aria-label="delete">
                                        <img src={DollarIcon} alt="DollarIcon" />
                                    </IconButton>
                                    <Box
                                        className="font-medium font-16 cursor-pointer text-current	"
                                        onClick={() =>
                                            dispatch(openModal({
                                                title: "SEND TIP",
                                                content: <ModalSendTip />,
                                            }))
                                        }
                                    >
                                        Send Tip
                                    </Box>
                                </Box>
                            </div>

                            <IconButton aria-label="delete">
                                <img src={ArchiveTick} alt="ArchiveTick" />
                            </IconButton>
                        </div>

                        <div className="mt-5">
                            <Box
                                className="w-full write-comment"
                            >
                                <Input
                                    placeholder="Write a Comment"
                                    id="outlined-adornment-weight"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <img src={userProfileSrc} alt="User1" width={44} />{" "}
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <img src={Microphone} alt="microphone" width={24} />
                                            <img
                                                src={Gallery}
                                                alt="Gallery"
                                                width={24}
                                                className="ml-4"
                                            />
                                        </InputAdornment>
                                    }
                                />
                            </Box>
                        </div>
                    </div >
                )
            })}
        </>
    )
}

export default NoArticlePosts
