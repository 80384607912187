import React from 'react'

const HomeStories = ({ bgImageSrc, userImageSrc, username }) => {
    //User Stories In Home Page 
    return (
        <div className='user-img relative'>
            <img src={bgImageSrc} alt='User' />
            <img src={userImageSrc} alt='User1' className='user-position' />
            <p className='mb-0 user-text'>{username}</p>
        </div>
    )
}

export default HomeStories
