import { Button } from '@mui/material'
import React from 'react'
import ProfilePostBg1 from "./../../../assets/images/post-profile-bg.png";
import GalleryBlueIcon from "./../../../assets/images/gallery-blue.svg";
import LockIcon from "./../../../assets/images/lock.svg";

const LockedPost = ({postMedia, amonut = 5}) => {
  return (
    <div className="relative mt-5 profile-bg-white">
    <img
      src={ProfilePostBg1}
      alt="ProfilePostBg1"
      className="w-full min-h-215px"
    />
    <div className="profile-lock-icon text-center">
      <Button variant="contained" className="white-btn rounded-full">
        <img
          src={LockIcon}
          alt="LockIcon"
          height={40}
          width={40}
          className="img-dark-filter"
        />
      </Button>
      <h5 className="font-16 font-medium text-darkblue my-2 lg:my-4">
        Unlock this Post
      </h5>
      <Button variant="contained" className="unlock-post">
        Pay ${amonut} To Unlock This Post
      </Button>
    </div>
    <div className="profile-gallery-btn">
      <div className="whitebtn flex items-center gap-2">
        <img
          src={GalleryBlueIcon}
          alt="GalleryBlueIcon"
          className="img-dark-filter"
        />
        <span>
          {postMedia?.length > 10
            ? `${postMedia?.length}`
            : postMedia?.length > 0
            ? `0${postMedia?.length}`
            : "00"}
        </span>
      </div>
    </div>
  </div>
  )
}

export default LockedPost
