import { Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { closeAdminModal } from "../../../../store/slices/common/adminModal";

const DiscardModalBtn = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(closeAdminModal());
  };

  return (
    <Button variant="outlined" className="btn" onClick={handleClick}>
      Discard
    </Button>
  );
};

export default DiscardModalBtn;
