import React from "react";
import ReactApexChart from "react-apexcharts";
import useChartColors from "../../../../hooks/useChartColors";

const SimplePie = ({ chartId, dataSeries = [] }) => {
  const chartColors = useChartColors(chartId);

  const series = dataSeries.length > 0 ? dataSeries : [44, 55, 13];
  var options = {
    chart: {
      height: 500,
      type: "pie",
    },
    labels: ["Active Users", "Banned Users", "Deleted Users"],
    colors: chartColors,
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "14px",
      markers: {
        size: 6,
        shape: "line", // circle, square, line, plus, cross
        strokeWidth: 10,
        radius: 2,
      },
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series || []}
        data-chart-colors='["bg-green-500", "bg-orange-500", "bg-red-500"]'
        id={chartId}
        className="apex-charts"
        height={options.chart.height}
        type="pie"
      />
    </React.Fragment>
  );
};

export default SimplePie;
