import React from "react";
import User1 from "../../../../assets/images/blank.png";
import {  IconButton, Tooltip, Typography } from "@mui/material";
import {
  AlternateEmail,
  ArticleOutlined,
  Block,
  Bookmark,
  CalendarToday,
  Delete,
  LocationOn,
  MailOutline,
  NotificationsNone,
  Phone,
  RestoreFromTrashOutlined,
  TaskAlt,
} from "@mui/icons-material";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import {
  banUserAction,
  deleteUserAction,
} from "../../../../store/middlewares/admin/users/user";
import CustomLoader from "../../../atoms/admin/CustomLoader";
import { useNavigate } from "react-router-dom";

const UserDetailCard = ({ user, getUserLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      {getUserLoading ? (
        <CustomLoader />
      ) : (
        <div className="card">
          <div className="card-body">
            <div className="relative">
              <div>
                <img
                  src={
                    user?.coverImg
                      ? // eslint-disable-next-line no-undef
                        `${process.env.REACT_APP_AWS_URL}${user?.coverImg}`
                      : User1
                  }
                  alt={user?.name}
                  className="w-full lg:h-[300px] h-[150px] object-cover pointer-events-none"
                />
              </div>
              <div className="flex lg:flex-nowrap flex-wrap">
                <div className="relative lg:top-[-64px] top-[-30px] lg:left-[32px] left-[10px]">
                  <div className="w-16 lg:w-32 h-16 lg:h-32 rounded-full border-4 border-white object-cover">
                    <img
                      src={
                        user?.profileImage
                          ? // eslint-disable-next-line no-undef
                            `${process.env.REACT_APP_AWS_URL}${user?.profileImage}`
                          : User1
                      }
                      alt={user?.name}
                      className="rounded-full w-full h-full object-cover pointer-events-none"
                    />
                  </div>
                </div>
                <div className="flex w-full flex-wrap gap-y-0 gap-x-4 lg:ml-[40px] ml-[0px]">
                  <div className="flex md:justify-between lg:justify-between justify-end lg:flex-nowrap flex-wrap-reverse items-center basis-full">
                    <div className="flex lg:flex-nowrap flex-wrap items-center lg:gap-2 gap-0">
                      <Typography variant="h3" className="user-name">
                        {user?.name}
                      </Typography>
                      <div
                        className={`flex items-center px-2.5 py-0.5 text-xl font-medium rounded border bg-white  ${
                          user?.deletedAt
                            ? "border-red-400 text-red-500"
                            : user?.bannedAt
                            ? " border-orange-400 text-orange-500"
                            : " border-green-400 text-green-500"
                        }`}
                      >
                        <div
                          className={`size-1.5 mr-1 rounded-full inline-block ${
                            user?.deletedAt
                              ? "bg-red-500"
                              : user?.bannedAt
                              ? " bg-orange-500"
                              : " bg-green-500"
                          } `}
                        ></div>{" "}
                        {user?.deletedAt
                          ? "DELETED"
                          : user?.bannedAt
                          ? "BANNED"
                          : "ACTIVE"}
                      </div>
                    </div>
                    <div className="flex justify-center gap-x-4">
                      <Tooltip title={"Show Posts"} placement="top">
                        <IconButton
                          color="info"
                          disableRipple
                          onClick={() => {
                            navigate("posts");
                          }}
                        >
                          <ArticleOutlined />
                        </IconButton>
                      </Tooltip>
                      {!user?.deletedAt && (
                        <Tooltip
                          title={"Notification Settings"}
                          placement="top"
                        >
                          <IconButton color="info" disableRipple>
                            <NotificationsNone />
                          </IconButton>
                        </Tooltip>
                      )}
                      {!user?.deletedAt && !user?.bannedAt && (
                        <Tooltip title={"Ban User"} placement="top">
                          <IconButton
                            color="warning"
                            disableRipple
                            onClick={() => {
                              Swal.fire({
                                title:
                                  "Are you sure you want to block this user?",
                                icon: "question",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes",
                                customClass: {
                                  title: "Swal-title-class",
                                },
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  dispatch(
                                    banUserAction({ id: user?.id, type: 1 })
                                  );
                                  // navigate("/admin/active-users");
                                }
                              });
                            }}
                          >
                            <Block />
                          </IconButton>
                        </Tooltip>
                      )}
                      {!user?.deletedAt && user?.bannedAt && (
                        <IconButton
                          color="success"
                          disableRipple
                          onClick={() => {
                            Swal.fire({
                              title:
                                "Are you sure you want to unblock this user?",
                              icon: "question",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: "Yes",
                              customClass: {
                                title: "Swal-title-class",
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                dispatch(
                                  banUserAction({ id: user?.id, type: 2 })
                                );
                              }
                            });
                          }}
                        >
                          <TaskAlt />
                        </IconButton>
                      )}
                      {!user?.deletedAt && (
                        <Tooltip title={"Delete User"} placement="top">
                          <IconButton
                            disableRipple
                            onClick={() => {
                              Swal.fire({
                                title:
                                  "Are you sure you want to delete this user?",
                                icon: "question",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes",
                                customClass: {
                                  title: "Swal-title-class",
                                },
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  dispatch(
                                    deleteUserAction({
                                      id: user?.id,
                                      action: "delete",
                                    })
                                  );
                                  // navigate("/admin/active-users");
                                }
                              });
                            }}
                          >
                            <Delete color="error" className="text-lg" />
                          </IconButton>
                        </Tooltip>
                      )}
                      {user?.deletedAt && (
                        <Tooltip title={"Restore User"} placement="top">
                          <IconButton
                            disableRipple
                            onClick={() => {
                              Swal.fire({
                                title:
                                  "Are you sure you want to restore this user?",
                                icon: "question",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes",
                                customClass: {
                                  title: "Swal-title-class",
                                },
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  dispatch(
                                    deleteUserAction({
                                      id: user?.id,
                                      action: "restore",
                                    })
                                  );
                                }
                              });
                            }}
                          >
                            <RestoreFromTrashOutlined
                              color="error"
                              className="text-lg"
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                  <div className="flex lg:flex-row flex-col items-start lg:gap-y-0 lg:gap-x-4  gap-x-0 gap-y-4 lg:my-0 my-3">
                    {user?.username && (
                      <div className="flex justify-center">
                        <AlternateEmail className={"mr-1"} fontSize="small" />
                        <p>{user?.username}</p>
                      </div>
                    )}
                    {user?.email && (
                      <div className="flex justify-center">
                        <MailOutline className={"mr-1"} fontSize="small" />
                        <p>{user?.email}</p>
                      </div>
                    )}
                    {user?.phone && (
                      <div className="flex justify-center">
                        <Phone className={"mr-1"} fontSize="small" />
                        <p>{user?.phone}</p>
                      </div>
                    )}
                    {user?.city && (
                      <div className="flex justify-center">
                        <LocationOn className={"mr-1"} fontSize="small" />
                        <p>{user?.city}</p>
                      </div>
                    )}
                    {user?.userType && (
                      <div className="flex justify-center">
                        <Bookmark className={"mr-1"} fontSize="small" />
                        <p style={{textTransform: 'capitalize'}}>{user?.userType}</p>
                      </div>
                    )}
                    {user?.createdAt && (
                      <div className="flex justify-center">
                        <CalendarToday className={"mr-1"} fontSize="small" />
                        <p>{dayjs(user?.createdAt).format("DD/MM/YYYY")}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {user?.bio && (
              <div className="flex flex-col gap-2">
                <div className="w-full border-b">
                  <Typography variant="h5">BIO</Typography>
                </div>
                <Typography variant="body1" className="my-2">
                  {user?.bio}
                </Typography>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default UserDetailCard;
