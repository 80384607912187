import React from "react";
import SaveforlaterImage from "./../../../../../assets/images/saveforlater.png";


const NoMessages = () => {
  return (
    <div className=" h-530px flex items-center justify-center flex-col my-5">
      <img src={SaveforlaterImage} alt="SaveforlaterImage" width={246} />
      <p className="font-20 mt-3">Nothing found</p>
    </div>
  );
};

export default NoMessages;
