import ColeUser from "./../assets/images/cole-user.png";
import ProfilePostImg1 from "./../assets/images/post-profile-img1.png";
import ProfilePostBg1 from "./../assets/images/post-profile-bg.png";

const noArticlePosts = [
    {
        "userProfileSrc": ColeUser,
        "name": "Cole",
        "username": "colejanson",
        "day": "Yesterday",
        "description": "Happy tuesday",
        "postImgSrc": ProfilePostImg1,
        showGallery: true,
        isLocked: false,
    },
    {
        "userProfileSrc": ColeUser,
        "name": "Cole",
        "username": "colejanson",
        "day": "Yesterday",
        "description": "Happy tuesday",
        "postImgSrc": ProfilePostBg1,
        showGallery: true,
        isLocked: true,
    }
]

export default noArticlePosts