import { createSlice } from "@reduxjs/toolkit";
import { getDashboardAction } from "../../../middlewares/admin/dashboard/dashboard";

const initialState = {
  dashboardLoading: false,
  dashboard: null,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getDashboardAction.pending, (state) => {
        state.dashboardLoading = true;
        state.dashboard = null;
        state.getUserErrorMessage = "";
      })
      .addCase(getDashboardAction.fulfilled, (state, action) => {
        state.dashboardLoading = false;
        state.dashboard = action.payload.response.data;
      })
      .addCase(getDashboardAction.rejected, (state) => {
        state.dashboardLoading = false;
        state.dashboard = null;
      });
  },
});

export default dashboardSlice.reducer;
