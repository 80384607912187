import { createSlice } from "@reduxjs/toolkit";
import {
  blockUserProfileAction,
  getUserProfileAction,
} from "../../../middlewares/web/profile/myProfile/me";
import {
  banUserAction,
  deleteUserAction,
  GetUserAction,
  NotificationUserAction,
} from "../../../middlewares/admin/users/user";
import { subscribeToPlanAction } from "../../../middlewares/web/subscriptions/subscriptions";

const initialState = {
  getUserLoading: false,
  user: {},
  getUserSuccess: false,
  getUserErrorMessage: "",
  notificationLoading: false,
  notificationsSuccess: false,
  notificationsError: "",
  notification: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    RestrictUserAction: (state) => {
      let { user } = state;
      state.user = { ...user, restricted: !user?.restricted };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(banUserAction.fulfilled, (state) => {
        let user = state.user;
        state.user = {
          ...user,
          bannedAt: user?.bannedAt ? null : new Date().toISOString(),
        };
      })
      .addCase(subscribeToPlanAction.fulfilled, (state, action) => {
        let user = state.user;
        state.user = {
          ...user,
          planId: !user?.isSubscribed ? action.payload.plans.subscriptionPlanId : null,
          isSubscribed: !user?.isSubscribed,
        };
      })
      .addCase(deleteUserAction.fulfilled, (state) => {
        let user = state.user;
        state.user = {
          ...user,
          deletedAt: user?.deletedAt ? null : new Date(),
        };
      })
      .addCase(getUserProfileAction.pending, (state) => {
        state.getUserLoading = true;
        state.getUserSuccess = "";
        state.user = null;
        state.getUserErrorMessage = "";
      })
      .addCase(getUserProfileAction.fulfilled, (state, action) => {
        state.getUserLoading = false;
        state.getUserSuccess = true;
        state.user = action.payload.response;
        state.getUserErrorMessage = action.payload.message;
      })
      .addCase(getUserProfileAction.rejected, (state, action) => {
        state.getUserLoading = false;
        state.getUserSuccess = false;
        state.user = null;
        state.getUserErrorMessage = action.payload.message;
      })
      .addCase(GetUserAction.pending, (state) => {
        state.getUserLoading = true;
        state.getUserSuccess = "";
        state.user = null;
        state.getUserErrorMessage = "";
      })
      .addCase(GetUserAction.fulfilled, (state, action) => {
        state.getUserLoading = false;
        state.getUserSuccess = true;
        state.user = action.payload.response;
        state.getUserErrorMessage = action.payload.message;
      })
      .addCase(GetUserAction.rejected, (state, action) => {
        state.getUserLoading = false;
        state.getUserSuccess = false;
        state.user = null;
        state.getUserErrorMessage = action.payload.message;
      })
      .addCase(blockUserProfileAction.fulfilled, (state) => {
        let { user } = state;
        state.user = { ...user, blocked: !user?.blocked };
      })
      .addCase(NotificationUserAction.pending, (state) => {
        state.notificationLoading = true;
        state.notificationsSuccess = "";
        state.notificationsError = "";
        state.notification = null;
      })
      .addCase(NotificationUserAction.fulfilled, (state, action) => {
        state.notificationLoading = false;
        state.notificationsSuccess = true;
        state.notification = action.payload.response;
        state.notificationsError = "";
      })
      .addCase(NotificationUserAction.rejected, (state, action) => {
        state.notificationLoading = false;
        state.notificationsSuccess = true;
        state.notification = null;
        state.notificationsError = action.payload.message;
      });
    // .addCase(RestrictUserAction.fulfilled, (state) => {
    //   let { user } = state
    //   state.user = { ...user, restricted: !user?.restricted };
    // });
  },
});

export const { RestrictUserAction } = userSlice.actions;

export default userSlice.reducer;
