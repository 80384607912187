import { Box, FormControl, OutlinedInput } from "@mui/material";
import React from "react";
import * as Yup from "yup";
import { Code, Name } from "../../../../utils/validation/AddCountryCodeForm";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { AddCountryCodeAction } from "../../../../store/middlewares/admin/countryCode/countryCode";
import { DiscardModalBtn, SubmitModalBtn } from "../../../atoms";

const ModalAddCountryCode = () => {
  const dispatch = useDispatch();

  // Define Schema For Validation
  const AddCountryCodeSchema = Yup.object({
    name: Name,
    code: Code,
  });

  const handleSubmit = (values) => {
    //On Submit Form
    const { name, code } = values;
    let tempObj = { name, code: `+${code}` };
    dispatch(AddCountryCodeAction(tempObj));
  };

  // Form Management
  const formik = useFormik({
    initialValues: {
      name: "",
      code: "",
    },
    validationSchema: AddCountryCodeSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
      <Box>
        <FormControl className="w-full" variant="outlined">
          <label htmlFor="name" style={{ color: "rgba(0, 0, 0, 0.6)" }}>
            Country Name
          </label>
          <OutlinedInput
            placeholder="Country Name"
            type="name"
            label="Country Name"
            name="name"
            id="name"
            size="small"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            className={
              formik.errors.name && formik.touched.name ? "error-border" : ""
            }
          />
          {formik.errors.name && formik.touched.name && (
            <Box className="text-red-500 text-sm mt-1">
              {formik.errors.name}
            </Box>
          )}
        </FormControl>
      </Box>
      <Box>
        <FormControl className="w-full" variant="outlined">
          <label htmlFor="code" style={{ color: "rgba(0, 0, 0, 0.6)" }}>
            Country Code
          </label>
          <OutlinedInput
            placeholder="Enter Code"
            type="number"
            name="code"
            size="small"
            id="code"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.code}
            className={
              formik.errors.code && formik.touched.code ? "error-border" : ""
            }
          />

          {formik.errors.code && formik.touched.code && (
            <Box className="text-red-500 text-sm mt-1">
              {formik.errors.code}
            </Box>
          )}
        </FormControl>
      </Box>
      <Box className="flex items-end justify-end gap-4 text-end">
        <SubmitModalBtn />
        <DiscardModalBtn />
      </Box>
    </form>
  );
};

export default ModalAddCountryCode;
