import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UserName } from '../../../../../../utils/validation/ChangeUserNameForm'
import { useFormik } from 'formik'
import * as Yup from "yup";
import {  UpdateAccountAction } from "../../../../../../store/middlewares/web/profile/myProfile/account"
import { Input } from '../../../../../atoms'
import { closeModal } from '../../../../../../store/slices/common/modal'

const ModalChangeUsername = () => {
    const { me } = useSelector((state) => state?.me)

    const dispatch = useDispatch();

    const [username, setUsername] = useState(me?.username ? me?.usermname : '')  // set intial username

    useEffect(() => {
        setUsername(me?.username ? me?.username : '') // set username after updating
    }, [me])

    const schema = Yup.object({
        username: UserName // validdation
    })

    const handleClose = () => {
        formik.resetForm() // reset form on Modal Clos
        dispatch(closeModal())
    }

    const handleSubmit = (values) => {
        values.profileUrl = me?.profileUrl
        values.coverUrl = me?.coverUrl
        dispatch(UpdateAccountAction(values)) // API Call to update username
    }

    //initialize the form object
    const formik = useFormik({
        initialValues: { username: username },
        enableReinitialize: true,
        validationSchema: schema,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: handleSubmit,
    })

    return (
        //title - CHANGE USERNAME
        <form onSubmit={formik.handleSubmit}>
            <Input
                name='username'
                value={formik.values.username}
                onChange={formik.handleChange}
                placeholder='Enter Username'
                onBlur={formik.handleBlur}
                id="username"
                error={formik.errors.username && formik.touched.username}
                errorMsg={formik.errors.username}
            />

            <div className='flex justify-end mt-6 gap-3'>
                <Button variant="contained" className='m-auto white-btn' onClick={handleClose}>Cancel</Button>
                <Button type="submit" variant="contained" className='m-auto px-12' >Save</Button>
            </div>
        </form>
    )
}

export default ModalChangeUsername