import React from 'react'
import NotifyUser3 from './../../../../../assets/images/notification/notifyuser3.png';
import NotifyUser2 from './../../../../../assets/images/notification/notifyuser2.png';
import { CommentNotificationItem } from '../../../../atoms';

const CommentNotifications = () => {
        //Notification in Comments Tab
    return (
        <>
            <CommentNotificationItem userProfileImage={NotifyUser2} name={"Leo"} isComment={true} comment={"Jetzt kann ich gut schlafen"} time={"2 min ago"} />
            <CommentNotificationItem userProfileImage={NotifyUser3} name={"Leo"} isLike={true} time={"2 min ago"} />
        </>
    )
}

export default CommentNotifications
