import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../../atoms";
import { closeModal } from "../../../../../../store/slices/common/modal";
import { setSocialLinks } from "../../../../../../store/services/web/profile/sociallinks.services";

const ModalChangeTikTok = () => {
  const { me } = useSelector((state) => state?.me);
  const dispatch = useDispatch();

  const [tiktok, setTiktok] = useState(
    me?.socialLinks.find((link) => link.type === "tiktok")?.url || ""
  );

  useEffect(() => {
    setTiktok(
      me?.socialLinks.find((link) => link.type === "tiktok")?.url || ""
    ); // Set tiktok handle of the current logged-in user after updating
  }, [me]);

  const schema = Yup.object({
    tiktok: Yup.string()
      .url("Invalid URL")
      .required("tiktok handle is required"),
  });

  const handleClose = () => {
    formik.resetForm(); // Reset form on Modal Close
    dispatch(closeModal());
  };

  const handleSubmit = (values) => {
    // Handle Form Submit
    console.log(values);

    dispatch(setSocialLinks({ url: values.tiktok, type: "tiktok" })); // API Call to update tiktok handle
    handleClose();
  };

  // Form initialization and set default value
  const formik = useFormik({
    initialValues: { tiktok },
    enableReinitialize: true,
    validationSchema: schema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        name="tiktok"
        value={formik.values.tiktok}
        onChange={formik.handleChange}
        placeholder="Enter tiktok Handle"
        onBlur={formik.handleBlur}
        id="tiktok"
        error={formik.errors.tiktok && formik.touched.tiktok}
        errorMsg={formik.errors.tiktok}
      />

      <div className="flex justify-end mt-6 gap-3">
        <Button
          variant="contained"
          className="m-auto white-btn"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button type="submit" variant="contained" className="m-auto px-12">
          Save
        </Button>
      </div>
    </form>
  );
};
export default ModalChangeTikTok;
