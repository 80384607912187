import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPost } from "../../../services/web/post/post.service";
import { closeModal } from "../../../slices/common/modal";
import { openToast } from "../../../slices/common/toast"
import { banPostAdmin, deletePostAdmin } from "../../../services/web/post/posts.services";

export const getPostAction = createAsyncThunk(
  "post/getPost",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getPost(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


//Delete Post For Admin
export const DeletePostAdminFromDetail = createAsyncThunk(
  "posts/deletePostAdminFromDetail",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await deletePostAdmin(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        window.location = "/admin/active-posts"
        dispatch(closeModal());
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue({ ...response, postId: payload.postId });
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

//Ban Post For Admin
export const BanPostAdminFromDetail = createAsyncThunk(
  "posts/banPostAdminFromDetail",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await banPostAdmin(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        window.location = "/admin/active-posts"
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);