import axiosClient from '../../../../utils/api';

export const reset = async (payload) => {
    try {
        const response = await axiosClient().request({
            url: "/auth/newPassword",
            method: "post",
            data: payload
        })
        return response.data
    } catch (err) {
        let tempObj = { message: err?.response?.data?.message }
        return tempObj
    }
};

