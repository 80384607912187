import React from "react";
import userProfile from "../../../../assets/images/img2.jpg";
import { Dropdown } from "./Dropdown";
import Swal from "sweetalert2";
import { NavLink } from "react-router-dom";
import { LockOutlined, Logout, PersonOutline } from "@mui/icons-material";

const ProfileMenu = () => {
  const logutmodel = () => {
    Swal.fire({
      title: "Are you sure you want to logout ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      customClass: {
        title: "Swal-title-class",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("user-details-admin");
        window.location.reload();
      }
    });
  };
  return (
    <Dropdown className="relative flex items-center h-header">
      <Dropdown.Trigger
        type="button"
        className="inline-block p-0 transition-all duration-200 ease-linear bg-topbar rounded-full text-topbar-item dropdown-toggle btn hover:bg-topbar-item-bg-hover hover:text-topbar-item-hover"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
      >
        <div className="bg-pink-100 rounded-full">
          <img
            src={userProfile}
            alt=""
            className="size-[37.5px] rounded-full"
          />
        </div>
      </Dropdown.Trigger>
      <Dropdown.Content
        placement="right-end"
        className="absolute z-50 p-4 text-left bg-white rounded-md shadow-md !top-4 dropdown-menu min-w-[14rem]"
        aria-labelledby="dropdownMenuButton"
      >
        <a href="#!" className="flex gap-3 mb-3">
          <div className="relative inline-block shrink-0">
            <div className="rounded bg-slate-100">
              <img src={userProfile} alt="" className="size-12 rounded" />
            </div>
          </div>
          <div>
            <p className="otp-color ">Admin</p>
          </div>
        </a>
        <ul>
          <li>
            <a
              className="block otp-color pr-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500"
              href="#"
            >
              <PersonOutline className="mr-1" fontSize="small" />
              Profile
            </a>
          </li>
          <li>
            <NavLink
              className="block otp-color pr-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500"
              to="/admin/change-password"
            >
              <LockOutlined className="mr-1" fontSize="small" />
              Change Password
            </NavLink>
          </li>
          <li className="pt-2 mt-2 border-t border-slate-200">
            <a
              className="block otp-color cursor-pointer pr-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500"
              onClick={logutmodel}
            >
              <Logout className="mr-1" fontSize="small" /> Sign Out
            </a>
          </li>
        </ul>
      </Dropdown.Content>
    </Dropdown>
  );
};

export default ProfileMenu;
