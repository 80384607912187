import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { useState } from "react";
import { CustomLoader, NotificationSetting } from "../../../atoms";

const UserNotificationCard = ({ notificationLoading, notification }) => {
  //--------- notifications ----------------
  const [value, setValue] = useState("push");
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      {notificationLoading ? (
        <CustomLoader />
      ) : (
        <div className="mt-5 card">
          <div className="card-body">
            <div className="flex flex-wrap post-tab w-full">
              <TabContext value={value}>
                <Box className="w-full border rounded-lg">
                  <TabList
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    className="flex-wrap notification-menu"
                  >
                    <Tab
                      label="Push Notification"
                      className="h-12 bg-white rounded-[10px]"
                      value="push"
                    />
                    <Tab
                      label="Email Notification"
                      className="h-12 bg-white rounded-[10px]"
                      value="email"
                    />
                    <Tab
                      label="Site Notification"
                      className="h-12 bg-white rounded-[10px]"
                      value="site"
                    />
                    <Tab
                      label="Toast Notification"
                      className="h-12 bg-white rounded-[10px]"
                      value="toast"
                    />
                  </TabList>
                </Box>

                <TabPanel
                  value="push"
                  className="w-full"
                  sx={{ padding: "0 !important" }}
                >
                  <NotificationSetting type={value} notification={notification}/>
                </TabPanel>

                <TabPanel
                  value="email"
                  className="w-full"
                  sx={{ padding: "0 !important" }}
                >
                  <NotificationSetting type={value} notification={notification} />
                </TabPanel>

                <TabPanel
                  value="site"
                  className="w-full"
                  sx={{ padding: "0 !important" }}
                >
                  <NotificationSetting type={value} notification={notification} />
                </TabPanel>

                <TabPanel
                  value="toast"
                  className="w-full"
                  sx={{ padding: "0 !important" }}
                >
                  <NotificationSetting type={value} notification={notification} />
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserNotificationCard;
