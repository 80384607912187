import { closeAdminModal } from "../../../slices/common/adminModal";
import { openToast } from "../../../slices/common/toast/index.js";
import {
  getActiveCreator,
  getBannedCreator,
  getPendingCreator,
  updateCreatorRequestStatus,
} from "./../../../services/admin/users/creators.js";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const GetActiveCreatorsAction = createAsyncThunk(
  "users/getCreators",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getActiveCreator();
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const GetPendingCreatorsAction = createAsyncThunk(
  "users/getPendingCreators",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getPendingCreator();
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const UpdatePendingCreatorStatusAction = createAsyncThunk(
  "users/updatePendingCreatorStatus",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await updateCreatorRequestStatus(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue({...payload });
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const GetBannedCreatorAction = createAsyncThunk(
  "users/getBannedCreators",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getBannedCreator();
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
