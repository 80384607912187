import { Button, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ShoppingCart from "./../../../../../assets/images/shopping-cart.svg";
import EyeIcon from "./../../../../../assets/images/eye-white.svg";
import HeartOutlineIcon from "./../../../../../assets/images/heart-outline.svg";
import { useNavigate } from 'react-router-dom';

const CreatorShopItem = ({ productImg, discount, isNewArrival, productName, oldPrice, newPrice }) => {

    const navigate = useNavigate();
    const [triggerNav, setTriggerNav] = useState(false)
    useEffect(() => {
        if (triggerNav) {
            navigate("/user/product-detail");
        }
    }, [navigate, triggerNav]);

    const handleDetailPage = () => {
        setTriggerNav(true);
    }
    return (
        <Grid item xs={6} md={4} lg={4} key={productName}>
            <div className="lighrgraybg">
                <div className="relative strams-img">
                    <img src={productImg} alt="Streams1" />
                    {isNewArrival && <div className="profile-stream-tag profile-stream-tag-primary">
                        <p className="mb-0">New Arrivals</p>
                    </div>}
                    {discount !== 0 && <div className="profile-stream-tag">
                        <p className="mb-0">{discount}%</p>
                    </div>}
                    <div className="card-hover-icon">
                        <Button
                            variant="outlined"
                            className="light-blue-icon-btn"
                            sx={{
                                background: "#BEAFA1 !important",
                                backdropFilter: "blur(5px)",
                                border: "none!important"
                            }}
                        >
                            <img
                                src={ShoppingCart}
                                alt="ShoppingCart"
                                width={24}
                            />
                        </Button>
                        <Button
                            variant="outlined"
                            className="light-blue-icon-btn"
                            sx={{
                                background: "#BEAFA1 !important",
                                backdropFilter: "blur(5px)",
                                border: "none!important"
                            }}
                        >
                            <img
                                src={HeartOutlineIcon}
                                alt="HeartIcon"
                                width={24}
                            />
                        </Button>
                        <Button
                            variant="outlined"
                            className="light-blue-icon-btn"
                            sx={{
                                background: "#BEAFA1 !important",
                                backdropFilter: "blur(5px)",
                                border: "none!important"
                            }}
                            onClick={handleDetailPage}
                        >
                            <img src={EyeIcon} alt="EyeIcon" width={24} />
                        </Button>
                    </div>
                </div>
                <div className="mt-3">
                    <p className="text-darkblue font-18 font-medium mb-2">
                        {productName}
                    </p>
                    <p className="font-medium">
                        <span className="text-primary">${newPrice}</span>{" "}
                        {oldPrice && <del>${oldPrice}</del>}
                    </p>
                </div>
            </div>
        </Grid >
    )
}

export default CreatorShopItem
