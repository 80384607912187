import axiosClient from "../../../../utils/api";

export const getAllSettings = async () => {
  const URL = "admin/post/getPostSettings";
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

export const getGeneralSettings = async () => {
  try {
    const response = await axiosClient().request({
      url: "admin/pagination/getGeneralPagination",
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during General Setiings:", error);
    throw error;
  }
};

export const updateSettings = async (payload) => {
  const URL = `/admin/post/updatePostSettings`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

export const updateGeneralSettings = async (payload) => {
  const URL = `/admin/pagination/setGeneralPagination`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};
