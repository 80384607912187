import React from 'react'
import NoBookmarkImg from './../../../../../assets/images/no-bookmark-img.png';

const NoPostsForBookmark = () => {
    return (
        <div className="flex flex-col justify-center items-center p-4 h-calc-200">
            <img
                src={NoBookmarkImg}
                alt="NoBookmarkImg"
                width={246}
            />
            <p className="font-20 mt-3">No bookmarks yet</p>
        </div>
    )
}

export default NoPostsForBookmark
