import { Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import Logo from "../../../../../assets/images/logo.svg";
import { closeModal } from "../../../../../store/slices/common/modal";
import { DeletePost } from "../../../../../store/middlewares/web/post/posts";

const ModalConfirmDeletePost = ({ postId }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleDelete = () => {
    //Unblock User API Call
    dispatch(DeletePost({ postId }));
  };

  return (
    <>
      <div className="flex justify-center items-center pb-3 pt-5">
        <div>
          <div className="flex justify-center items-center pb-7">
            <img src={Logo} alt={"Logo"} className="w-60 " />
          </div>
          <h3 className="text-lg text-center text-[#1E405C] font-semibold">
            Are you sure want to Delete this Post?
          </h3>
        </div>
      </div>
      <div className="flex justify-end mt-4 gap-3">
        <Button
          variant="contained"
          className="m-auto white-btn"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          className="m-auto px-12 delete-button"
          onClick={handleDelete}
        >
          Delete
        </Button>
      </div>
    </>
  );
};

export default ModalConfirmDeletePost;
