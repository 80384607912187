import { createSlice } from "@reduxjs/toolkit";
import { getAllLanguagesForWebAction } from "../../../middlewares/web/profile/myProfile/profiledisplay";
import {
  AddLanguagesAction,
  EditLanguagesAction,
  GetAllLanguagesAction,
  RemoveLanguageAction,
} from "../../../middlewares/web/profile/myProfile/languages";

export const languagesSlice = createSlice({
  name: "languages",
  initialState: {
    itemsLoading: false,
    itemsMessage: "",
    itemsError: false,
    items: [],
    actionSuccess: false,
    actionError: false,
    actionMessage: "",
  },
  extraReducers(builder) {
    builder
      .addCase(getAllLanguagesForWebAction.pending, (state) => {
        state.itemsLoading = true;
        state.itemsError = false;
        state.itemsMessage = "";
        state.actionSuccess = false;
        state.actionError = false;
        state.actionMessage = "";
      })
      .addCase(getAllLanguagesForWebAction.fulfilled, (state, action) => {
        state.itemsLoading = false;
        state.itemsError = false;
        state.items = action.payload.response;
        state.itemsMessage = action.payload.message;
      })
      .addCase(getAllLanguagesForWebAction.rejected, (state, action) => {
        state.itemsLoading = false;
        state.itemsError = true;
        state.itemsMessage = action.payload.message;
        state.items = null;
      })
      .addCase(GetAllLanguagesAction.pending, (state) => {
        state.itemsLoading = true;
        state.itemsError = false;
        state.itemsMessage = "";
        state.actionSuccess = false;
        state.actionError = false;
        state.actionMessage = "";
      })
      .addCase(GetAllLanguagesAction.fulfilled, (state, action) => {
        state.itemsLoading = false;
        state.itemsError = false;
        state.items = action.payload.response;
        state.itemsMessage = action.payload.message;
      })
      .addCase(GetAllLanguagesAction.rejected, (state, action) => {
        state.itemsLoading = false;
        state.itemsError = true;
        state.itemsMessage = action.payload.message;
        state.items = null;
      })
      .addCase(EditLanguagesAction.pending, (state) => {
        state.actionSuccess = false;
        state.actionError = false;
        state.actionMessage = "";
      })
      .addCase(EditLanguagesAction.fulfilled, (state, action) => {
        let { items } = state;
        let tempUsers = items.map((code) => {
          if (code.id === action.payload.response.id) {
            return {
              ...code,
              ...action.payload.response,
              languageUrl: action.payload.response.languageUrl
                ? action.payload.response.languageUrl
                : code.languageUrl,
            };
          }
          return {
            ...code,
            languageUrl : code.languageUrl,
          };
        });
        state.actionSuccess = true;
        state.actionError = false;
        state.items = tempUsers;
        state.actionMessage = action.payload.response.message;
      })
      .addCase(EditLanguagesAction.rejected, (state, action) => {
        state.actionSuccess = false;
        state.actionError = true;
        state.actionMessage = action.payload.response.message;
      })
      .addCase(AddLanguagesAction.pending, (state) => {
        state.actionSuccess = false;
        state.actionError = false;
        state.actionMessage = "";
      })
      .addCase(AddLanguagesAction.fulfilled, (state, action) => {
        let { response, message } = action.payload;
        let tempArray = state.items;
        tempArray.push(response);
        state.actionSuccess = true;
        state.actionError = false;
        state.items = tempArray;
        state.actionMessage = message;
      })
      .addCase(AddLanguagesAction.rejected, (state, action) => {
        state.actionSuccess = false;
        state.actionError = true;
        state.actionMessage = action.payload.response.message;
      })
      .addCase(RemoveLanguageAction.pending, (state) => {
        state.actionSuccess = false;
        state.actionError = false;
        state.actionMessage = "";
      })
      .addCase(RemoveLanguageAction.fulfilled, (state, action) => {
        let tempUsers = state.items.filter(
          (code) => code.id !== action.payload.id
        );
        state.actionSuccess = true;
        state.actionError = false;
        state.items = tempUsers;
        state.actionMessage = action.payload.message;
      })
      .addCase(RemoveLanguageAction.rejected, (state, action) => {
        state.actionSuccess = false;
        state.actionError = true;
        state.actionMessage = action.payload.response.message;
      });
  },
});

export default languagesSlice.reducer;
