import { FormControl, OutlinedInput } from '@mui/material'
import React from 'react'

const Input = ({ label = "", name = "", error = false, type = "text", placeholder, onChange = () => { }, onBlur = () => { }, value = "", errorMsg = "", startAdornment, endAdornment, ...rest }) => {
    return (
        <FormControl className="w-full" variant="outlined">
            {label && <label htmlFor={name} className='text-black'>{label}</label >}
            <OutlinedInput
                placeholder={placeholder}
                className={`${error ? "error-border" : ""} focus:border-none focus:outline-none border-transparent`}
                type={type}
                name={name}
                id={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                startAdornment={startAdornment}
                endAdornment={endAdornment}
                {...rest}
            />
            {error && (
                <div className="text-red-500 text-sm mt-1">
                    {errorMsg}
                </div>
            )}
        </FormControl>
    )
}

export default Input
