import CommentsIcon from "./../assets/images/messagesIcon.svg";
import MentionsIcon from "./../assets/images/mentions-blue.svg";
import SubscriptionsBlueIcon from "./../assets/images/subscriptionblue.svg";
import ConnectivityIcon from './../assets/images/connec-blue.svg';
import starIcon from './../assets/images/star.svg';
import TagBlueIcon from './../assets/images/tag-blue.svg';

const reArrangeCategoryArray = [
    { "iconSrc": TagBlueIcon, "name": "Tags" },
    { "iconSrc": CommentsIcon, "name": "Comments" },
    { "iconSrc": MentionsIcon, "name": "Mentions" },
    { "iconSrc": SubscriptionsBlueIcon, "name": "Subscriptions" },
    { "iconSrc": ConnectivityIcon, "name": "Livestream" },
    { "iconSrc": starIcon, "name": "Favorite" },
]

export default reArrangeCategoryArray