import {
  Button,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { closeModal } from "../../../../../store/slices/common/modal";
import { useDispatch, useSelector } from "react-redux";
import { FolderName } from "../../../../../utils/validation/AddFolderForm";
import { useFormik } from "formik";
import { Input } from "../../../../atoms";
import * as Yup from "yup";
import {
  addFolderAction,
  getFolderAction,
} from "../../../../../store/middlewares/web/folders/folders";
import { addToBookMarks } from "../../../../../store/middlewares/web/post/posts";

const ModalAddBookMark = ({ postId = 0, onlyAdd = false }) => {
  const { folders } = useSelector((state) => state?.folders);
  const [checked, setChecked] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!onlyAdd) {
      dispatch(getFolderAction());
    }
  }, [onlyAdd, dispatch]);

  const schema = Yup.object({
    folderName: FolderName, // validation
  });

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleSubmit = (values) => {
    if (values) {
      formik.resetForm();
      dispatch(addFolderAction({ onlyAdd, ...values })); // API Call to Add username
    }
  };

  const formik = useFormik({
    initialValues: { folderName: "" },
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: handleSubmit,
  });

  const handleAddToBookMark = () => {
    if (postId !== 0) {
      const reqObj = {
        postId: postId,
        folderId: checked,
      };
      dispatch(addToBookMarks(reqObj));
    } else {
      let folderName = formik.values.folderName;
      dispatch(addFolderAction({ onlyAdd, folderName }));
      formik.resetForm();
    }
  };

  const handleChange = (e) => {
    setChecked(e.target.value);
  };

  return (
    <div>
      {folders?.length > 0 && postId !== 0 && (
        <div className="mb-3">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormControl component="fieldset" className="w-full ">
              {/* Select Language */}
              <RadioGroup
                aria-label="options"
                name="options"
                onChange={(e) => handleChange(e)}
                checked={checked}
              >
                {folders?.map((folder) => {
                  return (
                    <div
                      className="site-notification flex items-center justify-center pl-2 pr-4 py-2 border-radius-10 gap-3 w-full"
                      key={folder.id}
                    >
                      <FormControlLabel
                        className="w-full"
                        value={folder.id}
                        control={
                          <Radio
                            sx={{ paddingTop: "0 !important" }}
                            value={folder.id}
                            checked={folder.id === checked}
                          />
                        } //Radio Button For Each Language
                        label={<span>{folder.folderName}</span>}
                      />
                    </div>
                  );
                })}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      )}
      <form onSubmit={formik.handleSubmit}>
        <Input
          placeholder="Enter Bookmark name"
          value={formik.values.folderName}
          onChange={formik.handleChange}
          name="folderName"
          onBlur={formik.handleBlur}
          id="folderName"
          inputProps={{ maxLength: 64 }}
          // onKeyDown={(e) => handleKeyDown(e)}
          error={formik.errors.folderName && formik.touched.folderName}
          errorMsg={formik.errors.folderName}
          endAdornment={
            <InputAdornment position="end" className="text-black">
              {formik.values.folderName.length}/64
            </InputAdornment>
          }
        />
      </form>
      <div className="flex justify-end mt-6 gap-3">
        <Button
          variant="contained"
          className="m-auto white-btn"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          onClick={handleAddToBookMark}
          disabled={onlyAdd ? false : folders?.length > 0 ? !checked : false}
          variant="contained"
          className="m-auto px-12"
        >
          {!onlyAdd ? "Save" : "Add"}
        </Button>
      </div>
    </div>
  );
};

export default ModalAddBookMark;
