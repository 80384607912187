import { Box, FormControl, FormControlLabel,  InputAdornment, OutlinedInput, Radio, RadioGroup } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchIcon from './../../../../../../assets/images/search-normal.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updateLanguageForUser } from '../../../../../../store/middlewares/web/profile/myProfile/profiledisplay';

const ModalLanguageSelection = () => {
    const dispatch = useDispatch()
    const { me } = useSelector((state) => state?.me)
    const [checked, setChecked] = useState()
    const [languagesToDisplay, setLanguagesToDisplay] = useState()
    const [searchText, setSearchText] = useState("")

    useEffect(() => {
        setChecked(me?.languageId) // set languageId
    }, [me])

    const handleChange = (e) => {
        setChecked(e.target.value)
        dispatch(updateLanguageForUser({ id: e.target.value }))
    }

    const { items: languages } = useSelector((state) => state.languages)

    useEffect(() => {
        // handle search
        if (searchText) {
            setLanguagesToDisplay(languages.filter((lang) => lang.language.toLowerCase().includes(searchText.toLowerCase())))
        } else {
            setLanguagesToDisplay(languages)
        }
    }, [languages, searchText])


    return (
        // title - LANGUAGE
        <>
            <FormControl variant="outlined" className='w-full light-blue-search'>
                <OutlinedInput
                    placeholder="Enter Language"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    id="enter-code"
                    startAdornment={<InputAdornment position="end"><img src={SearchIcon} alt='SearchIcon' className='img-dark-filter'
                    /></InputAdornment>}
                />
            </FormControl>
            <div className='mt-3'>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                className='bg-white'
                >
                    <FormControl component="fieldset" className='w-full'>
                        {/* Select Language */}
                        <RadioGroup
                            aria-label="options"
                            name="options"
                            onChange={(e) => handleChange(e)}
                            checked={checked}
                        >
                            {languagesToDisplay?.map((lang) => {
                                return (
                                    <div key={lang.id} className='site-notification pl-2 pr-4 py-1 border-radius-10 gap-3 w-full'>
                                        <FormControlLabel className='w-full'
                                            value={lang.language}
                                            control={<Radio value={lang.id} checked={me?.languageId === lang.id} />} //Radio Button For Each Language
                                            label={
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                                    <span>{lang.language}</span>
                                                    <Box className="language-img" sx={{ width: "20px", maxHeight: "20px" }}>
                                                        <img src={lang.languageUrl} alt={lang.language} width={20} style={{ objectFit: "cover", borderRadius: "50%" }} />
                                                    </Box>
                                                </Box>
                                            }
                                        />
                                    </div>
                                )
                            })}
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
        </>

    )
}

export default ModalLanguageSelection
