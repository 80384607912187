import { Box, FormControl, OutlinedInput } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Language, File } from "../../../../utils/validation/EditLanguageForm";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import User1 from "../../../../assets/images/blank.png";
import { EditLanguagesAction } from "../../../../store/middlewares/web/profile/myProfile/languages";
import { DiscardModalBtn, SubmitModalBtn } from "../../../atoms";

const ModalEditLanguage = ({ data }) => {
  const [imageSrc, setImageSrc] = useState(User1);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setImageSrc(data?.languageUrl ? data?.languageUrl : User1);
  }, [data]);

  // Define Schema For Validation
  const AddCountryCodeSchema = Yup.object({
    language: Language,
    file: File,
  });

  const handleSubmit = (values) => {
    dispatch(EditLanguagesAction({ ...values, id: data?.id }));
  };

  // Form Management
  const formik = useFormik({
    initialValues: {
      //Set Initial Values In Form
      language: data ? data?.language : "",
      file: data ? data?.languageUrl : "",
    },
    enableReinitialize: true,
    validationSchema: AddCountryCodeSchema, // Validation
    onSubmit: handleSubmit,
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileSizeLimit = 3 * 1024 * 1024; // 3MB limit
    const reader = new FileReader();

    if (file) {
      if (file.size <= fileSizeLimit) {
        reader.onload = () => {
          setImageSrc(reader.result);
          formik.setFieldValue("file", e.target.files[0]);
          setErrorMessage(""); // Reset error message when a valid image is loaded
        };
        reader.readAsDataURL(file);
      } else {
        setErrorMessage(
          "Please select a valid image file with size up to 3MB."
        );
        formik.setFieldValue("file", "");
      }
    }
  };

  return (
    <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
      <Box>
        <div>
          <label className="font-medium">Country Flag </label>
          <br />
          <div className="flex flex-col mt-2 justify-center items-start gap-x-2.5">
            <div className="flex flex-col items-center mt-2">
              <input
                type="file"
                name="file"
                accept="image/png, image/jpeg, image/png, image/webp, image/bmp"
                id="editimg"
                onChange={(e) => {
                  handleFileChange(e); // Call your custom function
                }}
                hidden
              />
              <label htmlFor="editimg">
                {/* <p className='bg-[#009ef7] cursor-pointer button-shadow text-white px-3 rounded-md font-medium py-1'>Add Image</p> */}
                <div className="w-24 h-24 overflow-hidden">
                  {imageSrc && (
                    <img
                      src={imageSrc}
                      alt="flag"
                      className="w-full h-full object-cover"
                    />
                  )}
                </div>
              </label>
            </div>
          </div>
          {errorMessage && (
            <div className="text-red-500 text-sm mt-1">{errorMessage}</div>
          )}
          {formik.errors.file && formik.touched.file && (
            <Box className="text-red-500 text-sm mt-1">
              {formik.errors.file}
            </Box>
          )}
        </div>
      </Box>
      <Box>
        <FormControl className="w-full" variant="outlined">
          <label htmlFor="name" style={{ color: "rgba(0, 0, 0, 0.6)" }}>
            Language Name
          </label>
          <OutlinedInput
            placeholder="Enter Language Name"
            label="language Name"
            name="language"
            id="language"
            size="small"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.language}
            className={
              formik.errors.language && formik.touched.language
                ? "error-border"
                : ""
            }
          />
          {formik.errors.language && formik.touched.language && (
            <Box className="text-red-500 text-sm mt-1">
              {formik.errors.language}
            </Box>
          )}
        </FormControl>
      </Box>
      <Box className="flex items-end justify-end gap-4 text-end">
        <SubmitModalBtn />
        <DiscardModalBtn />
      </Box>
    </form>
  );
};

export default ModalEditLanguage;
