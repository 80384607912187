import { createSlice } from "@reduxjs/toolkit";
import { GetSubscriptionsAction } from "../../../middlewares/admin/subscriptions/subscriptions";

export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState: {
    subscriptionsLoading: false,
    subscriptions: [],
    actionSuccess: false,
    actionError: false,
    actionMessage: "",
  },
  extraReducers(builder) {
    builder
      .addCase(GetSubscriptionsAction.pending, (state) => {
        state.subscriptionsLoading = true;
      })
      .addCase(GetSubscriptionsAction.fulfilled, (state, action) => {
        state.subscriptionsLoading = false;
        state.subscriptions = action.payload.response;
      })
      .addCase(GetSubscriptionsAction.rejected, (state) => {
        state.subscriptionsLoading = false;
        state.subscriptions = [];
      });
  },
});

export default subscriptionsSlice.reducer;
