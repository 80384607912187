import {
  addReason,
  addReport,
  deleteReason,
  editReason,
  getAllReasons,
  getAllReportedUsers,
} from "../../../services/admin/cms/reportReasons.js";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeModal } from "../../../slices/common/modal/index.js";
import { openToast } from "../../../slices/common/toast/index.js";
import { closeAdminModal } from "../../../slices/common/adminModal";

export const GetReportReasonsAction = createAsyncThunk(
  "reportReasons/getReportReasons",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getAllReasons(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const EditReportReasonsAction = createAsyncThunk(
  "reportReasons/editReportReasons",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await editReason(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue({ ...response, response: payload });
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const AddReportReasonsAction = createAsyncThunk(
  "reportReasons/addReportReasons",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await addReason(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const RemoveReportReasonsAction = createAsyncThunk(
  "reportReasons/removeReportReasons",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await deleteReason(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue({ ...response, id: payload.id });
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const AddReportAction = createAsyncThunk(
  "report/reportUser",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await addReport(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeModal());
        if (payload.type === "post") {
          location.reload();
        } else {
          window.location = "/user/home";
        }
        return fulfillWithValue({ ...response, id: payload.id });
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const GetAllReportedUsersAction = createAsyncThunk(
  "report/getAllReportedUsers",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getAllReportedUsers({ type: "user" });
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const GetAllReportedPostsAction = createAsyncThunk(
  "report/getAllReportedPosts",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getAllReportedUsers({ type: "post" });
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
