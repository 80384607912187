import React from 'react'
import { NotificationItem } from '../../../../atoms';

const AllNotifications = ({allNotificationsArray}) => {
    //Notification in All Tab
    return (
        <>
            {allNotificationsArray?.map((item, index) => {
                return (
                    <NotificationItem key={`${item?.username}-${index}`} userProfileImage={item?.userProfileImage} iconSrc={item?.iconSrc} name={item?.name} isVerified={item?.isVerified} username={item?.username} notificationText={item?.notificationText} addToFavorite={item?.addToFavorite} runPromotion={item?.runPromotion} date={item?.date} favoriteBtnText={item?.favoriteBtnText} />
                )
            })}
        </>
    )
}

export default AllNotifications

