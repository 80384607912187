import React from 'react'
import CloseSquare from './../../../../../assets/images/close-square.svg';
import UpArrow from './../../../../../assets/images/UpArrow.svg';
import OfferTag from './../../../../../assets/images/offer-tag.png';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';

const SubscriptionPromotion = () => {
    return (

        <div className='bg-white rounded-2xl p-4'>
            <div className='flex justify-between'>
                <h3 className='uppercase font-semibold font-18 text-darkblue'>Subscription</h3>
                <Link href="#"><img src={CloseSquare} alt='CloseSquare' className="img-dark-filter" /></Link>
            </div>

            <div className='light-blue-bg mt-4'>
                <div className='flex justify-between w-full'>
                    <div>
                        <h5 className='font-16 font-medium text-darkblue'>Subscription Price and Promotions</h5>
                        <p className='font-14 mt-1'>$10 per month, 2 subscription bundles</p>
                    </div>
                </div>
                <IconButton color="primary"><img src={UpArrow} alt='arrow' className="img-dark-filter" /></IconButton>
            </div>

            <div className='light-blue-bg mt-4'>
                <div className='flex justify-between  w-full'>
                    <div className='flex items-start'>
                        <img src={OfferTag} alt='OfferTag' className='mr-3' />
                        <div>
                            <h5 className='font-16 font-medium text-darkblue'>Limited Offer 70% off for 31 days!</h5>
                            <p className='font-14 mt-1'>For expired and new subscribers ends Mar 23</p>
                        </div>
                    </div>
                </div>
                <IconButton color="primary"><img src={UpArrow} alt='arrow' className="img-dark-filter" /></IconButton>
            </div>
        </div>
    )
}

export default SubscriptionPromotion
