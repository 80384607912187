import React, { useCallback, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import { Box, InputAdornment } from "@mui/material";
import { useFormik } from "formik";
import { Input } from "../../../../atoms";
import DollarSquare from "./../../../../../assets/images/dollar-square.svg";
import { Price } from "../../../../../utils/validation/AddFreePlan";
import { openModal } from "../../../../../store/slices/common/modal";
import ModalAddSubscriptionBundle from "../ModalAddSubscriptionBundle";
import { useDispatch, useSelector } from "react-redux";
import {
  addSubscriptionsPlanAction,
  getSubscriptionsPlanAction,
} from "../../../../../store/middlewares/web/subscriptions/subscriptions";
import getMonthCount from "../../../../../utils/getMonthCount";

export default function ProfileSubscription() {
  const dispatch = useDispatch();
  const [subscriptionBundleArray, setSubscriptionBundleArray] = useState([]);
  const [basePlan, setBasePlan] = useState(null);
  const { me } = useSelector((state) => state.me);

  const { plans } = useSelector((state) => state.plans);

  useEffect(() => {
    if(plans.length > 1 ) {
      setSubscriptionBundleArray(
        plans
        ?.filter((item) => item?.planType !== "free")
        ?.filter((item) => item?.duration !== "oneMonth")
      );
    } else {
      setSubscriptionBundleArray([])
    }
    let tempObj = plans?.find((item) => item?.duration === "oneMonth");

    setBasePlan(
      tempObj ? tempObj : plans?.find((item) => item?.planType === "free")
    );
  }, [plans]);

  const getSubscriptionPlans = useCallback(() => {
    dispatch(getSubscriptionsPlanAction({ creatorId: me?.id }));
  }, [dispatch, me]);

  useEffect(() => {
    getSubscriptionPlans();
  }, [getSubscriptionPlans]);

  const handleSubmit = (values) => {
    let tempObj = null;
    if (values.price === 0) {
      tempObj = { planType: "free" };
    } else {
      tempObj = {
        planType: "paid",
        price: Number(Number(values.price).toFixed(2)),
        duration: "oneMonth",
        discount: 0,
      };
    }
    let payload = {
      plans: [tempObj],
    };
    dispatch(addSubscriptionsPlanAction(payload));
  };

  const FreeSubscriptionSchema = Yup.object({
    price: Price,
  });

  const formik = useFormik({
    initialValues: { price: basePlan?.price ? basePlan?.price : 0 },
    enableReinitialize: true,
    validationSchema: FreeSubscriptionSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Box className="fans-profile bg-white h-calc-200 p-5 heading rounded-2xl">
      <h3 className="font-semibold mb-3 font-24 text-darkblue ">
        Subscription
      </h3>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} md={12} lg={6}>
          <div className="lightgray-400 rounded-2xl py-3 px-5 w-full mt-3">
            <form onSubmit={formik.handleSubmit}>
              <p className="font-20  mb-3 text-darkblue">
                Minimum $0 USD or Free
              </p>
              <Input
                placeholder="Price Per Month (Free)"
                type="number"
                name="price"
                id="price"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.price}
                errorMsg={formik.errors.price}
                error={formik.errors.price && formik.touched.price}
                className={
                  formik.errors.price && formik.touched.price
                    ? "error-border"
                    : ""
                }
                inputProps={{ min: 0 }}
                startAdornment={
                  <InputAdornment position="start">
                    <img
                      src={DollarSquare}
                      alt="DollarSquare"
                      className="cursor-pointer img-dark-filter"
                    />{" "}
                  </InputAdornment>
                }
              />
              <Box className="flex justify-end mt-6 gap-3">
                <Button
                  variant="contained"
                  className="m-auto white-btn"
                  onClick={() => console.log("call")}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained">
                  Submit
                </Button>
              </Box>
            </form>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <div className="lightgray-400 rounded-2xl py-3 px-5 w-full fans-profile mt-3">
            <p className="font-20  mb-3 text-darkblue">
              Profile Promotion Campaign
            </p>
            <p className="mb-4">
              Offer a free trial or a discounted subscription on your profile
              for a limited number of new or already expired subscribers.
            </p>
            <div className="heading">
              <Button variant="contained" className="w-full">
                Start Promotion Campaign
              </Button>
            </div>
          </div>
        </Grid>
        {subscriptionBundleArray?.length === 0 && (
          <Grid item xs={12} md={12} lg={6}>
            <div className="lightgray-400 rounded-2xl py-3 px-5 w-full fans-profile mt-3">
              <p className="font-20  mb-3 text-darkblue heading">
                Subscription Bundles
              </p>
              <p className="mb-4">
                Offer several months of subscription as a discounted bundle.
              </p>
              <div className="heading">
                <Button
                  disabled={!basePlan?.price}
                  variant="contained"
                  className="w-full"
                  onClick={() =>
                    dispatch(
                      openModal({
                        title: "ADD SUBSCRIPTION BUNDLE",
                        content: (
                          <ModalAddSubscriptionBundle
                            baseValue={formik.values.price}
                          />
                        ),
                        hasCloseBtn: false,
                      })
                    )
                  }
                >
                  Add Bundle
                </Button>
              </div>
            </div>
          </Grid>
        )}
        {subscriptionBundleArray?.length > 0 && (
          <Grid item xs={12} md={12} lg={6}>
            <div className="lightgray-400 rounded-2xl py-3 px-5 w-full fans-profile mt-3">
              <p className="font-20  mb-3 text-darkblue heading">
                Subscription Bundles
              </p>
              {subscriptionBundleArray?.map((plan, index) => {
                let cost = Number(
                  plan.price * (1 - plan.discount / 100)
                ).toFixed(2);
                return (
                  <Button
                    variant="outlined"
                    key={plan.duration}
                    className={`w-full cursor-default pointer-events-none ${
                      index !== subscriptionBundleArray?.length - 1
                        ? "mb-2"
                        : ""
                    }`}
                    onClick={() => console.log("call")}
                  >
                    {`$${cost} for ${getMonthCount(plan.duration)} months`}
                  </Button>
                );
              })}
            </div>
          </Grid>
        )}
        <Grid item xs={12} md={12} lg={6}>
          <div className="lightgray-400 rounded-2xl py-3 px-5 w-full fans-profile mt-3">
            <p className="font-20  mb-3 text-darkblue heading">Trial Links</p>
            <p className="mb-4">
              Create and share separate links with free trial subscription.
            </p>
            <div className="heading">
              <Button variant="contained" className="w-full">
                Create New Free Trail Link
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

// export default Profile;
