import { IconButton } from "@mui/material";
import React from "react";
import SquareCloseIcon from "./../../../../../assets/images/close-square.svg";
import ListmenublackIcon from "./../../../../../assets/images/listmenublack.svg";

const title = () => {
  return (
    <div className="flex justify-between items-center borderBottom pb-3">
      <h3>PINNED LISTS</h3>
      <div>
        <IconButton color="primary">
          <img
            src={ListmenublackIcon}
            alt="ListmenublackIcon"
            height={24}
            width={24}
          />
        </IconButton>

        <IconButton color="primary">
          <img
            src={SquareCloseIcon}
            alt="SquareCloseIcon"
            height={24}
            width={24}
          />
        </IconButton>
      </div>
    </div>
  );
};

export default title;
