import React from "react";
import DisplayPost from "../../common/DisplayPost";
import { NoPostsForBookmark } from "../../../../molecules";
import { Box, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const DisplayPostsInBookmark = () => {
  const { posts, postsLoading } = useSelector((state) => state.posts);

  return (
    <div
      className={`light-gray-bg lg:mr-4 creator-home-module post-no-padding mb-4 ${
        postsLoading ? "flex items-center justify-center" : ""
      }`}
    >
      {!postsLoading && (
        <>
          {posts
            ?.filter((post) => post?.bookmark_post?.length > 0)
            ?.filter((post) => post?.bookmark_post[0]?.isActive === true)
            ?.map((post) => {
              return <DisplayPost key={post.id} post={post} />;
            })}
          {(posts?.filter((post) => post?.bookmark_post[0]?.isActive === true)
            ?.length === 0 ||
            posts?.length === 0) && <NoPostsForBookmark />}
        </>
      )}
      {postsLoading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            jutsifyContent: "center",
          }}
          className="py-72"
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default DisplayPostsInBookmark;
