import { Box, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import UserBlank from "./../../../assets/images/blank.png";
import { useDispatch, useSelector } from "react-redux";
import { addComentsToPost } from "../../../store/middlewares/web/post/posts";
import { Send } from "@mui/icons-material";

const AddCommentTextArea = ({ postId }) => {
  const [focus, setFocus] = useState(false);
  const [hasValue, setHasValue] = useState(false);
  const textNewRef = useRef(); // ref the textArea

  const { me } = useSelector((state) => state.me);
  const { updatePostsSuccess } = useSelector((state) => state.posts);
  const dispatch = useDispatch();

  // const [isEdit, SetIsEdit] = useState(null)

  const onChangeHandler = function (e) {
    //on change handler to increase height as the user types
    if (e.target.value) {
      setHasValue(true);
    } else {
      setHasValue(false);
    }
    const target = e.target;
    textNewRef.current.style.height = "16px";
    textNewRef.current.style.height = `${target.scrollHeight}px`;
  };

  const handleAddComment = () => {
    let html = textNewRef?.current?.value
      .split("\n")
      .map((line) => `<p>${line}</p>`)
      .join("");
    let reqObj = {
      postId,
      comment: html,
    };
    dispatch(addComentsToPost(reqObj)); // add comment
  };

  useEffect(() => {
    //reset after adding comment
    if (textNewRef.current && updatePostsSuccess) {
      textNewRef.current.blur();
      textNewRef.current.value = "";
      textNewRef.current.style.height = "24px";
      setFocus(false);
      setHasValue(false);
      // SetIsEdit(null)
    }
  }, [updatePostsSuccess, textNewRef, dispatch]);

  return (
    <div className={`flex write-comment gap-3 ${hasValue ? "items-start" : "items-center"}`}>
      {/* changing style to align items */}
      <Box className="min-h-[44px] h-[44px] min-w-[44px] w-[44px] mr-2">
        <img
          src={me?.profileUrl ? me?.profileUrl : UserBlank}
          alt={me?.name}
          className="w-full h-full rounded-[50%] object-cover"
        />
      </Box>
      <textarea
        ref={textNewRef}
        onChange={onChangeHandler}
        placeholder="Write a Comment..."
        className={`text-area bg-white ${
          focus ? "focused" : ""
        } focus:border-none focus:outline-none`} //changing style on focusing textarea
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          if (!textNewRef?.current?.value) {
            setFocus(false); //reset styling
          }
        }}
        style={{ height: "24px", resize: "none", flexGrow: "1" }}
      />
      <IconButton
        disabled={!hasValue}
        onClick={() => {
          handleAddComment(); //add comment on icon click
        }}
        sx={{ color: "#1E405C" }}
      >
        <Send size={28} />
      </IconButton>
    </div>
  );
};

export default AddCommentTextArea;
