import axiosClient from "../../../../utils/api";

export const getNotifications = async () => {
  try {
    const response = await axiosClient().request({
      url: "/notification/getNotification",
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.log("Error at Get Notifications Service", error);
    throw error;
  }
};
export const updateNotifications = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: "/notification/updateNotification",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Update Notifications Service", error);
    throw error;
  }
};
