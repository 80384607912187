import { Grid, Typography, Box } from "@mui/material";
import React from "react";

const UserTaxInfo = ({ user }) => {
  let tempObj = {
    ...user,
    residenceCountry: "USA",
    birthCity: "Chicago",
    issuingCountry: "USA",
    taxIdNumber: "123-45-6789",
    vatNumber: "US123456789",
    documentType: "Passport",
    photoId: "photoId/b7cd9780-649d-4402-acec-3c135a60a51e",
    holdingPhotoId: "holdingPhotoId/8c513fca-3511-4528-a0b5-79d101ade082",
    releaseFormUrl: "releaseFormUrl/fb19c9b9-4718-468a-875e-fcb55a6a9515",
  };
  return (
    <div className="mt-5 card">
      <div className="card-body">
        <div className="flex flex-col gap-2">
          <div className="w-full border-b">
            <Typography variant="h5">TAX INFORMATION</Typography>
          </div>
          <Grid container className="py-2">
            <Grid item xs={6} className="mb-2">
              <Typography variant="body1">Document Type</Typography>
              <Typography component={"span"} variant="h6" className="my-2">
                <strong>{tempObj?.documentType}</strong>
              </Typography>
            </Grid>
            <Grid item xs={6} className="mb-2">
              <Typography variant="body1">TAX ID</Typography>
              <Typography component={"span"} variant="h6" className="my-2">
                <strong>{tempObj?.taxIdNumber}</strong>
              </Typography>
            </Grid>
            <Grid item xs={6} className="mb-2">
              <Typography variant="body1">VAT ID</Typography>
              <Typography component={"span"} variant="h6" className="my-2">
                <strong>{tempObj?.vatNumber}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} className="mb-2">
              <Typography variant="body1">Identification</Typography>
              <Box className="flex items-center gap-2">
                <div className="w-[150px] h-[150px] border rounded-lg">
                  <img
                    // eslint-disable-next-line no-undef
                    src={`${process.env.REACT_APP_AWS_URL}${tempObj?.holdingPhotoId}`}
                    className="w-full h-full rounded-lg "
                  />
                </div>
                <div className="w-[150px] h-[150px] border rounded-lg">
                  <img
                    // eslint-disable-next-line no-undef
                    src={`${process.env.REACT_APP_AWS_URL}${tempObj?.holdingPhotoId}`}
                    className="w-full h-full rounded-lg"
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default UserTaxInfo;
