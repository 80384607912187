import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { menuData } from "../../../../utils/LayoutMenuData";
import { Network } from "lucide-react";
import { Dropdown, withRouter } from "../../../../newComponents/atoms";

const HorizontalLayout = (props) => {
  const path = props.router.location.pathname;

  const [menuItems, setMenuItems] = useState(menuData);

  useEffect(() => {
    const handleResize = () => {
      const navbarHeader = document.querySelector(".navbar-header");
      const navbarNav = document.getElementById("navbar-nav");
      const fullWidthOfMenu = navbarHeader.clientWidth - 150;

      const menuWidth = fullWidthOfMenu || 0;
      let totalItemsWidth = 0;
      let visibleItems = [];
      let hiddenItems = [];

      for (let i = 0; i < menuData.length; i++) {
        const itemWidth = navbarNav?.children[i]?.offsetWidth;
        totalItemsWidth += itemWidth;
        if (totalItemsWidth <= menuWidth - 50 || window.innerWidth < 768) {
          visibleItems.push(menuData[i]);
        } else {
          hiddenItems.push(menuData[i]);
        }

        // If hiddenItems length > 0, show the "More" menu
        if (hiddenItems.length > 0) {
          const moreMenuItem = {
            id: "more",
            label: "More",
            link: "/#",
            icon: <Network />,
            subItems: hiddenItems,
          };
          const updatedMenuItems = [...visibleItems, moreMenuItem];
          setMenuItems(updatedMenuItems);
        } else {
          setMenuItems(visibleItems);
        }
      }
    };
    setTimeout(handleResize, 200);
    const delayedResize = () => {
      setTimeout(handleResize, 200);
    };

    window.addEventListener("resize", delayedResize);
    return () => {
      window.removeEventListener("resize", delayedResize);
    };
  }, []);

  return (
    <React.Fragment>
      {(menuItems || []).map((item, key) => {
        return (
          <React.Fragment key={key}>
            {item["isTitle"] ? (
              <li className="px-4 py-1 text-vertical-menu-item uppercase font-medium text-[11px] cursor-default tracking-wider group-data-[sidebar-size=sm]:hidden inline-block group-data-[sidebar-size=md]:block group-data-[sidebar-size=md]:underline group-data-[sidebar-size=md]:text-center">
                <span data-key="t-menu">{item.label}</span>
              </li>
            ) : item.subItems ? (
              <Dropdown className="relative group/sm" id={item.id}>
                <Dropdown.Trigger
                  type="a"
                  className="relative dropdown-button flex items-center pl-3 pr-5 mx-3 my-1 group/menu-link text-vertical-menu-item-font-size font-normal transition-all duration-75 ease-linear rounded-md py-2.5 text-vertical-menu-item hover:text-vertical-menu-item-hover 
                  hover:bg-vertical-menu-item-bg-hover [&.active]:text-vertical-menu-item-active [&.active]:bg-vertical-menu-item-bg-active 
                  group-data-[sidebar-size=md]:block 
                  group-data-[sidebar-size=md]:text-center 
                  group-data-[sidebar-size=sm]:group-hover/sm:w-[calc(theme('spacing.vertical-menu-sm')_*_3.63)] 
                  group-data-[sidebar-size=sm]:group-hover/sm:bg-vertical-menu 
                  group-data-[sidebar-size=sm]:my-0 
                  group-data-[sidebar-size=sm]:rounded-b-none 
                  [&.dropdown-button]:before:absolute 
                  [&.dropdown-button]:[&.show]:before:content-['\ea4e'] 
                  [&.dropdown-button]:before:content-['\ea6e'] 
                  [&.dropdown-button]:before:font-remix 
                  [&.dropdown-button]:before:right-2 
                  [&.dropdown-button]:before:text-16 
                  group-data-[sidebar-size=sm]:[&.dropdown-button]:before:hidden 
                  group-data-[sidebar-size=md]:[&.dropdown-button]:before:hidden 
                  "
                  href="/#"
                >
                  <span
                    className="min-w-[1.75rem] 
                  group-data-[sidebar-size=sm]:h-[1.75rem] inline-block text-start text-[16px] group-data-[sidebar-size=md]:block"
                  >
                    {item.icon &&
                      React.cloneElement(item.icon, {
                        className:
                          "h-4 group-data-[sidebar-size=sm]:h-5 group-data-[sidebar-size=sm]:w-5 transition group-hover/menu-link:animate-icons fill-slate-100 group-hover/menu-link:fill-blue-200  group-data-[sidebar-size=md]:block group-data-[sidebar-size=md]:mx-auto group-data-[sidebar-size=md]:mb-2",
                      })}
                  </span>
                  <span
                    className="group-data-[sidebar-size=sm]:pl-10 align-middle group-data-[sidebar-size=sm]:group-hover/sm:block group-data-[sidebar-size=sm]:hidden"
                    data-key="t-dashboards"
                  >
                    {item.label}
                  </span>
                </Dropdown.Trigger>
                <Dropdown.Content
                  currentPath={path}
                  className="hidden dropdown-content 
                  group-data-[sidebar-size=sm]:left-vertical-menu-sm 
                  group-data-[sidebar-size=sm]:w-[calc(theme('spacing.vertical-menu-sm')_*_2.8)] 
                  group-data-[sidebar-size=sm]:absolute 
                  group-data-[sidebar-size=sm]:rounded-b-sm bg-vertical-menu 
                  group-data-[sidebar-size=sm]:group-hover/sm:block 
                  group-data-[sidebar-size=sm]:rounded-br-md 
                  group-data-[sidebar-size=sm]:shadow-lg
                  group-data-[sidebar-size=sm]:shadow-slate-700/10 
                  group-data-[sidebar-size=sm]:group-hover/sm:block 
                  group-data-[sidebar-size=sm]:rounded-br-md 120"
                >
                  <ul
                    className="pl-[1.75rem] 
                  group-data-[sidebar-size=md]:pl-0 
                  group-data-[sidebar-size=sm]:pl-0
                  group-data-[sidebar-size=sm]:py-2 
                  "
                  >
                    {item.subItems &&
                      (item.subItems || [])?.map(
                        (subitem, key) =>
                          !subitem.isTitle && (
                            <React.Fragment key={key}>
                              {!subitem.subItems ? (
                                <li key={key}>
                                  <Link
                                    to={subitem.link}
                                    className="
                                    relative flex items-center px-6 py-2 text-vertical-menu-item-font-size transition-all 
                                    duration-75 ease-linear text-vertical-menu-sub-item hover:text-vertical-menu-sub-item-hover 
                                    [&.active]:text-vertical-menu-sub-item-active before:absolute before:left-1.5 before:top-4 before:w-1 
                                    before:h-1 before:rounded before:transition-all before:duration-75 before:ease-linear 
                                    before:bg-vertical-menu-sub-item hover:before:bg-vertical-menu-sub-item-hover [&.active]:before:bg-vertical-menu-sub-item-active 
                                    group-data-[sidebar-size=md]:before:hidden 
                                    group-data-[sidebar-size=md]:text-center 
                                    group-data-[sidebar-size=md]:block 
                                    group-data-[sidebar-size=sm]:before:hidden 
                                    "
                                    data-key="t-analytics"
                                  >
                                    {subitem.label}
                                  </Link>
                                </li>
                              ) : (
                                <Dropdown
                                  subitemId={subitem.id}
                                  className="group/sub"
                                >
                                  <Dropdown.Trigger
                                    type="a"
                                    className="dropdown-button relative flex items-center px-6 py-2 text-vertical-menu-item-font-size transition-all duration-75 ease-linear text-vertical-menu-sub-item hover:text-vertical-menu-sub-item-hover 
                                  [&.active]:text-vertical-menu-sub-item-active before:absolute before:left-1.5  before:top-4 before:w-1 before:h-1 before:rounded before:transition-all before:duration-75 before:ease-linear 
                                  before:bg-vertical-menu-sub-item hover:before:bg-vertical-menu-sub-item-hover [&.active]:before:bg-vertical-menu-sub-item-active 
                                  group-data-[sidebar-size=md]:before:hidden 
                                  group-data-[sidebar-size=md]:text-center 
                                  group-data-[sidebar-size=md]:block 
                                  group-data-[sidebar-size=sm]:before:hidden 
                                  [&.dropdown-button]:after:absolute 
                                  [&.dropdown-button]:[&.show]:after:content-['\ea4e'] 
                                  [&.dropdown-button]:after:content-['\ea6e'] 
                                  [&.dropdown-button]:after:font-remix [&.dropdown-button]:after:right-5  
                                  [&.dropdown-button]:after:text-16 
                                  group-data-[sidebar-size=sm]:[&.dropdown-button]:after:hidden 
                                  group-data-[sidebar-size=md]:[&.dropdown-button]:after:hidden 
                                  show"
                                    href="/#"
                                  >
                                    <span
                                      className="align-middle"
                                      data-key="t-products"
                                    >
                                      {subitem.label}
                                    </span>
                                  </Dropdown.Trigger>
                                  <Dropdown.Content
                                    className="dropdown-content 
                                  group-data-[sidebar-size=sm]:w-[calc(theme('spacing.vertical-menu-sm')_*_2.8)] 
                                  group-data-[sidebar-size=sm]:absolute group-data-[sidebar-size=sm]:rounded-b-sm 
                                  bg-vertical-menu 
                                  group-data-[sidebar-size=sm]:hidden 
                                  group-data-[sidebar-size=sm]:group-hover/sub:block 
                                  group-data-[sidebar-size=sm]:left-full 
                                  group-data-[sidebar-size=sm]:top-0 
                                  group-data-[sidebar-size=sm]:shadow-lg
                                  group-data-[sidebar-size=sm]:shadow-slate-700/5 
                                  "
                                  >
                                    <ul className="pl-[1.75rem]  group-data-[sidebar-size=md]:pl-0 group-data-[sidebar-size=sm]:pl-0 group-data-[sidebar-size=sm]:py-2">
                                      {(subitem.subItems || [])?.map(
                                        (item, key) => {
                                          return (
                                            <React.Fragment key={key}>
                                              {item.subItems ? (
                                                <Dropdown className="group/sub relative">
                                                  <Dropdown.Trigger
                                                    type="a"
                                                    href="/#"
                                                    className="
                                                    dropdown-button relative flex items-center px-6 py-2 text-vertical-menu-item-font-size transition-all 
                                                    duration-75 ease-linear text-vertical-menu-sub-item hover:text-vertical-menu-sub-item-hover 
                                                    [&.active]:text-vertical-menu-sub-item-active before:absolute before:left-1.5
                                                    before:top-4 before:w-1 before:h-1 before:rounded before:transition-all before:duration-75 before:ease-linear 
                                                    before:bg-vertical-menu-sub-item hover:before:bg-vertical-menu-sub-item-hover [&.active]:before:bg-vertical-menu-sub-item-active group-data-[sidebar-size=md]:before:hidden group-data-[sidebar-size=md]:text-center group-data-[sidebar-size=md]:block group-data-[sidebar-size=sm]:before:hidden [&.dropdown-button]:after:absolute [&.dropdown-button]:[&.show]:after:content-['\ea4e'] [&.dropdown-button]:after:content-['\ea6e'] [&.dropdown-button]:after:font-remix [&.dropdown-button]:after:right-5 [&.dropdown-button]:after:text-16 group-data-[sidebar-size=sm]:[&.dropdown-button]:after:hidden group-data-[sidebar-size=md]:[&.dropdown-button]:after:hidden"
                                                    data-key="t-analytics"
                                                  >
                                                    <span
                                                      className="align-middle"
                                                      data-key="t-product"
                                                    >
                                                      {item.label}
                                                    </span>
                                                  </Dropdown.Trigger>
                                                  <Dropdown.Content className="hidden dropdown-content group-data-[sidebar-size=sm]:w-[calc(theme('spacing.vertical-menu-sm')_*_2.8)] group-data-[sidebar-size=sm]:absolute group-data-[sidebar-size=sm]:rounded-b-sm bg-vertical-menu group-data-[sidebar-size=sm]:hidden group-data-[sidebar-size=sm]:group-hover/sub:block group-data-[sidebar-size=sm]:left-full group-data-[sidebar-size=sm]:top-0 group-data-[sidebar-size=sm]:shadow-lg group-data-[sidebar-size=sm]:shadow-slate-700/5">
                                                    <ul className="pl-[1.75rem] group-data-[sidebar-size=md]:pl-0 group-data-[sidebar-size=sm]:pl-0 group-data-[sidebar-size=sm]:py-2">
                                                      {(
                                                        item.subItems || []
                                                      )?.map((data, index) => (
                                                        <li key={index}>
                                                          <Link
                                                            to={data.link}
                                                            className="relative flex items-center px-6 py-2 text-vertical-menu-item-font-size transition-all duration-75 ease-linear text-vertical-menu-sub-item 
                                                            hover:text-vertical-menu-sub-item-hover 
                                                            [&.active]:text-vertical-menu-sub-item-active 
                                                            before:absolute before:left-1.5 before:top-4 before:w-1 before:h-1 before:rounded before:transition-all before:duration-75 before:ease-linear before:bg-vertical-menu-sub-item hover:before:bg-vertical-menu-sub-item-hover [&.active]:before:bg-vertical-menu-sub-item-active group-data-[sidebar-size=md]:before:hidden group-data-[sidebar-size=md]:text-center group-data-[sidebar-size=md]:block group-data-[sidebar-size=sm]:before:hidden"
                                                          >
                                                            {data.label}
                                                          </Link>
                                                        </li>
                                                      ))}
                                                    </ul>
                                                  </Dropdown.Content>
                                                </Dropdown>
                                              ) : (
                                                <li>
                                                  <Link
                                                    to={item.link}
                                                    className="relative flex items-center px-6 py-2 text-vertical-menu-item-font-size transition-all duration-75 ease-linear text-vertical-menu-sub-item hover:text-vertical-menu-sub-item-hover 
                                                    [&.active]:text-vertical-menu-sub-item-active before:absolute before:left-1.5 before:top-4 before:w-1 before:h-1 before:rounded before:transition-all before:duration-75 before:ease-linear 
                                                    before:bg-vertical-menu-sub-item hover:before:bg-vertical-menu-sub-item-hover [&.active]:before:bg-vertical-menu-sub-item-active  group-data-[sidebar-size=md]:before:hidden group-data-[sidebar-size=md]:text-center group-data-[sidebar-size=md]:block group-data-[sidebar-size=sm]:before:hidden"
                                                    data-key="t-analytics"
                                                  >
                                                    {item.label}
                                                  </Link>
                                                </li>
                                              )}
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </Dropdown.Content>
                                </Dropdown>
                              )}
                            </React.Fragment>
                          )
                      )}
                  </ul>
                </Dropdown.Content>
              </Dropdown>
            ) : (
              <li className="relative group/sm">
                <Link
                  className="relative flex items-center pl-3 pr-5 mx-3 my-1 group/menu-link 
                  text-vertical-menu-item-font-size font-normal transition-all duration-75 ease-linear rounded-md 
                  py-2.5 text-vertical-menu-item hover:text-vertical-menu-item-hover hover:bg-vertical-menu-item-bg-hover 
                  [&.active]:text-vertical-menu-item-active [&.active]:bg-vertical-menu-item-bg-active 
                  group-data-[sidebar-size=md]:block group-data-[sidebar-size=md]:text-center 
                  group-data-[sidebar-size=sm]:group-hover/sm:w-[calc(theme('spacing.vertical-menu-sm')_*_3.63)] 
                  group-data-[sidebar-size=sm]:group-hover/sm:bg-vertical-menu 
                  group-data-[sidebar-size=sm]:my-0 group-data-[sidebar-size=sm]:rounded-b-none 
                  [&.dropdown-button]:before:absolute 
                  [&.dropdown-button]:[&.show]:before:content-['\ea4e'] 
                  [&.dropdown-button]:before:content-['\ea6e'] 
                  [&.dropdown-button]:before:font-remix 
                  [&.dropdown-button]:before:right-2 
                  [&.dropdown-button]:before:text-16 
                  group-data-[sidebar-size=sm]:[&.dropdown-button]:before:hidden 
                  group-data-[sidebar-size=md]:[&.dropdown-button]:before:hidden 
                  "
                  to={item.link}
                >
                  <span className="min-w-[1.75rem] group-data-[sidebar-size=sm]:h-[1.75rem] inline-block text-start text-[16px] group-data-[sidebar-size=md]:block">
                    {item.icon &&
                      React.cloneElement(item.icon, {
                        className:
                          "h-4 group-data-[sidebar-size=sm]:h-5 group-data-[sidebar-size=sm]:w-5 transition group-hover/menu-link:animate-icons fill-slate-100 group-hover/menu-link:fill-blue-200 group-data-[sidebar-size=md]:block group-data-[sidebar-size=md]:mx-auto group-data-[sidebar-size=md]:mb-2",
                      })}
                  </span>
                  <span
                    className="group-data-[sidebar-size=sm]:pl-10 align-middle group-data-[sidebar-size=sm]:group-hover/sm:block group-data-[sidebar-size=sm]:hidden"
                    data-key="t-chat"
                  >
                    {item.label}
                  </span>
                </Link>
              </li>
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default withRouter(HorizontalLayout);
