import { createSlice } from "@reduxjs/toolkit";
import {
  addComentsToPost,
  addPost,
  addToBookMarks,
  AnswerPollAction,
  AnswerQuizAction,
  BanPostAdmin,
  DeletePost,
  DeletePostAdmin,
  GetAllPostAdminAction,
  getAllPostsAction,
  GetDeletedPostAdminAction,
  getFolderWisePostsAction,
  getPostsAction,
  getPostsByUserIdAdmin,
  getPostsByUserIdWebsiteAction,
  getPostsCollectionForUserListAction,
  getPostWebsiteAction,
  hidePostsOfAUserAction,
  likeDislikePost,
  removeCommentsAction,
  RestrictUserFromPostAction,
} from "../../../middlewares/web/post/posts";
import { GetAllReportedPostsAction } from "../../../middlewares/admin/cms/reportReasons";
export const postsSlice = createSlice({
  name: "posts",
  initialState: {
    postsLoading: false,
    postsMessage: "",
    postsError: false,
    postsSuccess: false,
    posts: [],
    addPostsMessage: "",
    addPostsError: false,
    addPostsSuccess: false,
    updatePostsMessage: "",
    updatePostsError: false,
    updatePostsSuccess: false,
  },
  reducers: {
    userAddToUserList: (state, action) => {
      const { id } = action.payload;
      let tempArray = state.posts?.map((post) => {
        if (post.userId === id) {
          return { ...post, isAddedInUserList: !post.isAddedInUserList };
        }
        return { ...post };
      });
      state.posts = tempArray;
    },
    blockUserFromPost: (state, action) => {
      const { id } = action.payload;
      let tempArray = state.posts?.map((post) => {
        if (post.userId === id) {
          return { ...post, blocked: !post.blocked };
        }
        return { ...post };
      });
      state.posts = tempArray;
    },
    subscribeToUser: (state, action) => {
      const { creatorId } = action.payload;
      let tempArray = state.posts?.map((post) => {
        if (post.users?.id === creatorId) {
          return { ...post, isSubscribed: !post.isSubscribed };
        }
        return { ...post };
      });
      state.posts = tempArray;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPostsAction.pending, (state) => {
        state.postsLoading = true;
        state.postsError = false;
        state.postsSuccess = false;
        state.postsMessage = "";
        state.posts = [];
      })
      .addCase(getPostsAction.fulfilled, (state, action) => {
        state.postsLoading = false;
        state.postsError = false;
        state.postsSuccess = true;
        state.posts = action.payload.response;
        state.postsMessage = action.payload.message;
      })
      .addCase(getPostsAction.rejected, (state, action) => {
        state.postsLoading = false;
        state.postsError = true;
        state.postsSuccess = false;
        state.posts = [];
        state.postsMessage = action.payload.message;
      })
      //get Post By Id In Website
      .addCase(getPostWebsiteAction.pending, (state) => {
        state.postsLoading = true;
        state.postsError = false;
        state.postsSuccess = false;
        state.postsMessage = "";
        state.posts = [];
      })
      .addCase(getPostWebsiteAction.fulfilled, (state, action) => {
        state.postsLoading = false;
        state.postsError = false;
        state.postsSuccess = true;
        state.posts = [action.payload.response];
        state.postsMessage = action.payload.message;
      })
      .addCase(getPostWebsiteAction.rejected, (state, action) => {
        state.postsLoading = false;
        state.postsError = true;
        state.postsSuccess = false;
        state.posts = [];
        state.postsMessage = action.payload.message;
      })
      //get posts By user Id In Website
      .addCase(getPostsByUserIdWebsiteAction.pending, (state) => {
        state.postsLoading = true;
        state.postsError = false;
        state.postsSuccess = false;
        state.postsMessage = "";
        state.posts = [];
      })
      .addCase(getPostsByUserIdWebsiteAction.fulfilled, (state, action) => {
        state.postsLoading = false;
        state.postsError = false;
        state.postsSuccess = true;
        state.posts = action.payload.response;
        state.postsMessage = action.payload.message;
      })
      .addCase(getPostsByUserIdWebsiteAction.rejected, (state, action) => {
        state.postsLoading = false;
        state.postsError = true;
        state.postsSuccess = false;
        state.posts = [];
        state.postsMessage = action.payload.message;
      })
      //get posts By user Id In Website
      .addCase(getPostsByUserIdAdmin.pending, (state) => {
        state.postsLoading = true;
        state.postsError = false;
        state.postsSuccess = false;
        state.postsMessage = "";
        state.posts = [];
      })
      .addCase(getPostsByUserIdAdmin.fulfilled, (state, action) => {
        state.postsLoading = false;
        state.postsError = false;
        state.postsSuccess = true;
        state.posts = action.payload.response;
        state.postsMessage = action.payload.message;
      })
      .addCase(getPostsByUserIdAdmin.rejected, (state, action) => {
        state.postsLoading = false;
        state.postsError = true;
        state.postsSuccess = false;
        state.posts = [];
        state.postsMessage = action.payload.message;
      })
      //Get Reported Posts
      .addCase(GetAllReportedPostsAction.pending, (state) => {
        state.postsLoading = true;
        state.postsError = false;
        state.postsSuccess = false;
        state.postsMessage = "";
        state.posts = [];
      })
      .addCase(GetAllReportedPostsAction.fulfilled, (state, action) => {
        state.postsLoading = false;
        state.postsError = false;
        state.postsSuccess = true;
        state.posts = action.payload.response;
        state.postsMessage = action.payload.message;
      })
      .addCase(GetAllReportedPostsAction.rejected, (state, action) => {
        state.postsLoading = false;
        state.postsError = true;
        state.postsSuccess = false;
        state.posts = [];
        state.postsMessage = action.payload.message;
      })
      //get All Posts For Home Page
      .addCase(getAllPostsAction.pending, (state) => {
        state.postsLoading = true;
        state.postsError = false;
        state.postsSuccess = false;
        state.postsMessage = "";
        state.posts = [];
      })
      .addCase(getAllPostsAction.fulfilled, (state, action) => {
        state.postsLoading = false;
        state.postsError = false;
        state.postsSuccess = true;
        state.posts = action.payload.response.reverse();
        state.postsMessage = action.payload.message;
      })
      .addCase(getAllPostsAction.rejected, (state, action) => {
        state.postsLoading = false;
        state.postsError = true;
        state.postsSuccess = false;
        state.posts = [];
        state.postsMessage = action.payload.message;
      })
      //get Folder Wise Posts For Bookmarks Page
      .addCase(getFolderWisePostsAction.pending, (state) => {
        state.postsLoading = true;
        state.postsError = false;
        state.postsSuccess = false;
        state.postsMessage = "";
        state.posts = [];
      })
      .addCase(getFolderWisePostsAction.fulfilled, (state, action) => {
        state.postsLoading = false;
        state.postsError = false;
        state.postsSuccess = true;
        state.posts = action.payload?.response;
        state.postsMessage = action.payload.message;
      })
      .addCase(getFolderWisePostsAction.rejected, (state, action) => {
        state.postsLoading = false;
        state.postsError = true;
        state.postsSuccess = false;
        state.posts = [];
        state.postsMessage = action.payload.message;
      })
      //Get All Posts Admin Side
      .addCase(GetAllPostAdminAction.pending, (state) => {
        state.postsLoading = true;
        state.postsError = false;
        state.postsSuccess = false;
        state.postsMessage = "";
        state.posts = [];
      })
      .addCase(GetAllPostAdminAction.fulfilled, (state, action) => {
        state.postsLoading = false;
        state.postsError = false;
        state.postsSuccess = true;
        state.posts = action.payload?.response;
        state.postsMessage = action.payload.message;
      })
      .addCase(GetAllPostAdminAction.rejected, (state, action) => {
        state.postsLoading = false;
        state.postsError = true;
        state.postsSuccess = false;
        state.posts = [];
        state.postsMessage = action.payload.message;
      })
      //Get Deleted Posts Admin Side
      .addCase(GetDeletedPostAdminAction.pending, (state) => {
        state.postsLoading = true;
        state.postsError = false;
        state.postsSuccess = false;
        state.postsMessage = "";
        state.posts = [];
      })
      .addCase(GetDeletedPostAdminAction.fulfilled, (state, action) => {
        state.postsLoading = false;
        state.postsError = false;
        state.postsSuccess = true;
        state.posts = action.payload?.response;
        state.postsMessage = action.payload.message;
      })
      .addCase(GetDeletedPostAdminAction.rejected, (state, action) => {
        state.postsLoading = false;
        state.postsError = true;
        state.postsSuccess = false;
        state.posts = [];
        state.postsMessage = action.payload.message;
      })
      //Get User List Collection Posts Admin Side
      .addCase(getPostsCollectionForUserListAction.pending, (state) => {
        state.postsLoading = true;
        state.postsError = false;
        state.postsSuccess = false;
        state.postsMessage = "";
        state.posts = [];
      })
      .addCase(
        getPostsCollectionForUserListAction.fulfilled,
        (state, action) => {
          state.postsLoading = false;
          state.postsError = false;
          state.postsSuccess = true;
          state.posts = action.payload?.response;
          state.postsMessage = action.payload.message;
        }
      )
      .addCase(
        getPostsCollectionForUserListAction.rejected,
        (state, action) => {
          state.postsLoading = false;
          state.postsError = true;
          state.postsSuccess = false;
          state.posts = [];
          state.postsMessage = action.payload.message;
        }
      )
      //Delete Posts Admin Side
      .addCase(DeletePostAdmin.pending, (state) => {
        state.updatePostsError = false;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = "";
      })
      .addCase(DeletePostAdmin.fulfilled, (state, action) => {
        let tempArray = state.posts.filter(
          (post) => post.id !== action.payload.postId
        );
        state.updatePostsError = false;
        state.updatePostsSuccess = true;
        state.posts = tempArray;
        state.updatePostsMessage = action.payload.message;
      })
      .addCase(DeletePostAdmin.rejected, (state, action) => {
        state.updatePostsError = true;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = action.payload.message;
      })
      //Delete Post In Admin
      .addCase(DeletePost.fulfilled, (state, action) => {
        let tempArray = state.posts.filter(
          (post) => post.id !== action.payload.postId
        );
        state.posts = tempArray;
      })
      //Restrict Posts
      .addCase(RestrictUserFromPostAction.pending, (state) => {
        state.updatePostsError = false;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = "";
      })
      .addCase(RestrictUserFromPostAction.fulfilled, (state, action) => {
        let tempArray = state?.posts?.map((post) => {
          if (post.users.id === action.payload.id) {
            return { ...post, restricted: !post.restricted };
          }
          return { ...post };
        });
        state.updatePostsError = false;
        state.updatePostsSuccess = true;
        state.posts = tempArray;
        state.updatePostsMessage = action.payload.message;
      })
      .addCase(RestrictUserFromPostAction.rejected, (state, action) => {
        state.updatePostsError = true;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = action.payload.message;
      })
      //Delete Posts Admin Side
      .addCase(hidePostsOfAUserAction.pending, (state) => {
        state.updatePostsError = false;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = "";
      })
      .addCase(hidePostsOfAUserAction.fulfilled, (state, action) => {
        if (action.payload.type !== "unhide") {
          let tempArray = state.posts.filter(
            (post) => post.users.id !== action.payload.id
          );
          state.updatePostsError = false;
          state.updatePostsSuccess = true;
          state.posts = tempArray;
          state.updatePostsMessage = action.payload.message;
        }
      })
      .addCase(hidePostsOfAUserAction.rejected, (state, action) => {
        state.updatePostsError = true;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = action.payload.message;
      })
      //Ban Posts Admin Side
      .addCase(BanPostAdmin.pending, (state) => {
        state.updatePostsError = false;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = "";
      })
      .addCase(BanPostAdmin.fulfilled, (state, action) => {
        let tempArray = state.posts.map((post) => {
          if (post.id !== action.payload.postId) {
            return { ...post, bannedAt: new Date().toISOString() };
          }
          return post;
        });
        state.updatePostsError = false;
        state.updatePostsSuccess = true;
        state.posts = tempArray;
        state.updatePostsMessage = action.payload.message;
      })
      .addCase(BanPostAdmin.rejected, (state, action) => {
        state.updatePostsError = true;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = action.payload.message;
      })
      //Add Posts
      .addCase(addPost.pending, (state) => {
        state.addPostsError = false;
        state.addPostsSuccess = false;
        state.addPostsMessage = "";
      })
      .addCase(addPost.fulfilled, (state, action) => {
        let { posts } = state;
        let {
          payload: { response, message },
        } = action;
        let tempArray = [response, ...posts];
        state.addPostsError = false;
        state.addPostsSuccess = true;
        state.posts = tempArray;
        state.addPostsMessage = message;
      })
      .addCase(addPost.rejected, (state, action) => {
        state.addPostsError = true;
        state.addPostsSuccess = false;
        state.addPostsMessage = action.payload.message;
      })

      //Like Dislike Posts
      .addCase(likeDislikePost.pending, (state) => {
        state.updatePostsError = false;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = "";
      })
      .addCase(likeDislikePost.fulfilled, (state, action) => {
        let { posts } = state;
        let {
          payload: { post_like, message, postId },
        } = action;
        let tempArray = posts.map((post) => {
          if (post.id === postId) {
            return { ...post, post_like };
          }
          return post;
        });
        state.updatePostsError = false;
        state.updatePostsSuccess = true;
        state.posts = tempArray;
        state.updatePostsMessage = message;
      })
      .addCase(likeDislikePost.rejected, (state, action) => {
        state.updatePostsError = true;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = action.payload.message;
      })

      //Add Comments
      .addCase(addComentsToPost.pending, (state) => {
        state.updatePostsError = false;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = "";
      })
      .addCase(addComentsToPost.fulfilled, (state, action) => {
        let { posts } = state;
        let {
          payload: { response, postId, message },
        } = action;
        let tempArray = posts.map((post) => {
          if (post.id === postId) {
            let tempArray = post?.post_comment;
            tempArray.push({
              ...response,
              name: response?.user?.name,
              profileImage: response?.user?.profileImage,
            });
            return { ...post, post_comment: tempArray };
          }
          return post;
        });
        state.updatePostsError = false;
        state.updatePostsSuccess = true;
        state.posts = tempArray;
        state.updatePostsMessage = message;
      })
      .addCase(addComentsToPost.rejected, (state, action) => {
        state.updatePostsError = true;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = action.payload.message;
      })

      //Remove Comments
      .addCase(removeCommentsAction.pending, (state) => {
        state.updatePostsError = false;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = "";
      })
      .addCase(removeCommentsAction.fulfilled, (state, action) => {
        let { posts } = state;
        let {
          payload: { postId, message, commentId },
        } = action;
        let tempArray = posts.map((post) => {
          if (post.id === postId) {
            let tempArray = post?.post_comment?.filter(
              (comment) => comment?.id !== commentId
            );
            return { ...post, post_comment: tempArray };
          }
          return post;
        });
        state.updatePostsError = false;
        state.updatePostsSuccess = true;
        state.posts = tempArray;
        state.updatePostsMessage = message;
      })
      .addCase(removeCommentsAction.rejected, (state, action) => {
        state.updatePostsError = true;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = action.payload.message;
      })
      .addCase(addToBookMarks.pending, (state) => {
        state.updatePostsError = false;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = "";
      })
      .addCase(addToBookMarks.fulfilled, (state, action) => {
        let { posts } = state;
        let {
          payload: {
            response: { postId, ...rest },
            message,
          },
        } = action;
        let tempArray = posts.map((post) => {
          if (post.id === postId) {
            return { ...post, bookmark_post: [rest] };
          }
          return post;
        });
        state.updatePostsError = false;
        state.updatePostsSuccess = true;
        state.posts = tempArray;
        state.updatePostsMessage = message;
      })
      .addCase(addToBookMarks.rejected, (state, action) => {
        state.updatePostsError = true;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = action.payload.message;
      })
      .addCase(AnswerPollAction.fulfilled, (state, action) => {
        let { posts } = state;
        let {
          payload: {
            response: { postId, pollOptionId, ...rest },
            message,
          },
        } = action;
        let tempArray = posts.map((post) => {
          if (post.id === postId) {
            let pollOptions = post.pollOptions?.map((option) => {
              if (option.id === pollOptionId) {
                return {
                  ...option,
                  answers: [
                    ...option.answers,
                    { postId, pollOptionId, ...rest },
                  ],
                };
              }
              return { ...option };
            });
            return { ...post, pollOptions };
          }
          return post;
        });
        state.updatePostsError = false;
        state.updatePostsSuccess = true;
        state.posts = tempArray;
        state.updatePostsMessage = message;
      })
      .addCase(AnswerPollAction.rejected, (state, action) => {
        state.updatePostsError = true;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = action.payload.message;
      })
      .addCase(AnswerQuizAction.fulfilled, (state, action) => {
        let { posts } = state;
        let {
          payload: {
            response: { postId, quizOptionId, ...rest },
            message,
          },
        } = action;
        let tempArray = posts.map((post) => {
          if (post.id === postId) {
            let quizOptions = post.quizOptions?.map((option) => {
              if (option.id === quizOptionId) {
                return {
                  ...option,
                  answers: [
                    ...option.answers,
                    { postId, quizOptionId, ...rest },
                  ],
                };
              }
              return { ...option };
            });
            return { ...post, quizOptions };
          }
          return post;
        });
        state.updatePostsError = false;
        state.updatePostsSuccess = true;
        state.posts = tempArray;
        state.updatePostsMessage = message;
      })
      .addCase(AnswerQuizAction.rejected, (state, action) => {
        state.updatePostsError = true;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = action.payload.message;
      });
  },
});

export const { userAddToUserList, blockUserFromPost, subscribeToUser } =
  postsSlice.actions;

export default postsSlice.reducer;
