import { Button } from '@mui/material'
import React from 'react'
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import reArrangeCategoryArray from '../../../../../utils/reArrangeCategoryArray';
import { closeModal } from '../../../../../store/slices/common/modal';
import { useDispatch } from 'react-redux';

function ModalReArrangeCategories() {

    const dispatch = useDispatch()
    //Rearrange Tab in Notifications Page
    const onClose = () => {
        dispatch(closeModal())
    }

    return (
        <>
            {reArrangeCategoryArray?.map((cat) => {
                return (
                    <div className="flex justify-between drag-content" key={cat.name}>
                        <div className="flex gap-2">
                            <img src={cat?.iconSrc} alt={cat.name} x />
                            <span className="text-darkblue">{cat.name}</span>
                        </div>
                        {/* Drag the items to change the order of tabs in Notifications Page */}
                        <div>
                            <DragIndicatorIcon className="-rotate-90 transform" />
                        </div>
                    </div>
                )
            })
            }
            <div className="flex justify-end mt-6 gap-3">
                <Button
                    variant="contained"
                    className="m-auto white-btn"
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button variant="contained" className="m-auto px-12">
                    Save
                </Button>
            </div>
        </>
    )
}

export default ModalReArrangeCategories