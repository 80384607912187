import * as Yup from "yup";
import { emailRegex, spaceRegExp } from "../regex";

export const email = Yup.string()
    .email("Email is Invalid.")
    .required("Email is Required.")
    .matches(emailRegex, 'Email is Invalid');

export const Password = Yup.string()
    .min(6, "Password should be at least 6 characters long").matches(spaceRegExp, "Password cannot contain spaces")
    .max(50, "Password should not contain more than 50 characters")
    .required("Password is Required");