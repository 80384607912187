import React from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../store/slices/common/modal";
import SquareCloseIcon from "./../../../../../assets/images/close-square.svg";
import { IconButton } from "@mui/material";

const title = () => {
  const dispatch = useDispatch();
  return (
    <div className="flex justify-between items-center yrsaclick-dialog">
      <h3>Leo</h3>
      <div>
        <IconButton
          aria-label="SquareCloseIcon"
          size="small"
          onClick={() => {
            dispatch(closeModal());
          }}
        >
          <img src={SquareCloseIcon} alt="SquareCloseIcon" />
        </IconButton>
      </div>
    </div>
  );
};

export default title;
