import React from "react";
import { HomeStories } from "../../../../atoms";
import PlusIcon from "./../../../../../assets/images/plus.svg";
import DiscountIcon from "./../../../../../assets/images/discount-shape.svg";
import homeStoriesArray from "../../../../../utils/homeStoriesArray";

const HomeStoriesSection = ({ isCreator = false }) => {
  return (
    <div className="flex overflow-x-auto  gap-4 profile-images hidden-scrollbar">
      {isCreator && (
        <>
          <div className="blue-bg">
            <img src={PlusIcon} alt="Plus Icon" />
          </div>
          <div className="light-blue-bg">
            <img
              src={DiscountIcon}
              alt="Discount Icon"
              className="discount-bg"
            />
          </div>
        </>
      )}
      {homeStoriesArray?.map((story, index) => {
        return (
          <HomeStories
            bgImageSrc={story?.bgImageSrc}
            userImageSrc={story?.userImageSrc}
            username={story.username}
            key={index}
          />
        );
      })}
    </div>
  );
};

export default HomeStoriesSection;
