import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetRestrictedUsersAction } from "../../../store/middlewares/admin/users/users";
import { Box, Typography } from "@mui/material";
// import BlockedUsersActionBar from "../../../component/admin/TableActionBar/users/BlockedUsers";
import User1 from "../../../assets/images/blank.png";
import {
  BreadCrumb,
  CustomLoader,
  TableContainer,
  UserAvatar,
} from "../../../newComponents/atoms";
import dayjs from "dayjs";

function RestrictedUsers() {
  const [usersToDisplay, setUsersToDisplay] = useState([]);
  const { users, getUsersLoading } = useSelector((state) => state.users);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetRestrictedUsersAction());
  }, [dispatch]);

  useEffect(() => {
    setUsersToDisplay(users);
  }, [users]);

  const columns = React.useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "user.name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <UserAvatar
            type={"users"}
            id={row.original?.otherUser?.id}
            name={row.original.otherUser?.name}
            username={row.original.otherUser?.username}
            profileImageSrc={
              row.original.otherUser?.profileImage
                ? // eslint-disable-next-line no-undef
                  `${process.env.REACT_APP_AWS_URL}${row.original.otherUser?.profileImage}`
                : User1
            }
          />
        ),
      },
      {
        header: "Email",
        accessorKey: "otherUser.email",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Restricted By",
        accessorKey: "user.name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <UserAvatar
            type={"users"}
            id={row.original?.user?.id}
            name={row.original.user?.name}
            username={row.original.user?.username}
            profileImageSrc={
              row.original.user?.profileImage
                ? // eslint-disable-next-line no-undef
                  `${process.env.REACT_APP_AWS_URL}${row.original.user?.profileImage}`
                : User1
            }
          />
        ),
      },
      {
        header: "Restricted On",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => {
          const date = dayjs(row.original?.createdAt).format("DD/MM/YYYY");
          return (
            <Box className="flex items-center">
              <Typography variant="p">{date}</Typography>
            </Box>
          );
        },
      },
      // {
      //   id: "action",
      //   header: <p className="text-right">Action</p>,
      //   enableColumnFilter: false,
      //   cell: ({ row }) => <BlockedUsersActionBar row={row.original} />,
      // },
    ],
    []
  );

  return (
    <Box className="user-page">
      <BreadCrumb title="Blocked Users" pageTitle={"Dashboard"} />
      {!getUsersLoading ? (
        <div className="card  mt-5">
          <div className="card-body p-3">
            <TableContainer
              isPagination={true}
              isSelect={true}
              isGlobalFilter={true}
              columns={columns}
              data={usersToDisplay}
              customPageSize={10}
              divclassName="my-2 col-span-12 overflow-x-auto lg:col-span-12"
              tableclassName="hover group dataTable w-full text-sm align-middle whitespace-nowrap no-footer"
              theadclassName="border-b border-slate-200 "
              trclassName="transition-all duration-150 ease-linear group-[.hover]:hover:bg-slate-50 [&.selected]:bg-custom-500 [&.selected]:text-custom-50 "
              thclassName="p-3 sorting px-3 py-4 text-slate-900 bg-slate-200/50 font-semibold text-left"
              tdclassName="max-w-[200px] overflow-hidden"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
            />
          </div>
        </div>
      ) : (
        <CustomLoader />
      )}
    </Box>
  );
}

export default RestrictedUsers;
