import { Divider, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useState } from "react";
import User1 from "./../../../../../assets/images/blank.png";
import Verified from "./../../../../../assets/images/verified.svg";
import MoreSquare from "./../../../../../assets/images/more-square.svg";
import InfoCircle from "./../../../../../assets/images/info-circle.svg";
import {
  BlockTitle,
  ModalBlock,
  ModalConfirmUnblock,
  ModalReport,
  ModalSaveToList,
  SaveToListTitle,
} from "../../../../molecules";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../../store/slices/common/modal";
import {
  hidePostsOfAUserAction,
  RestrictUserFromPostAction,
} from "../../../../../store/middlewares/web/post/posts";
import { upsertUserToUserListAction } from "../../../../../store/middlewares/admin/users/users";

const ProfileDropdownMenu = ({ user, folderId = "" }) => {
  const dispatch = useDispatch();
  const [anchorElSmall, setAnchorElSmall] = useState(null);

  const openSmall = Boolean(anchorElSmall);

  const onClose = () => {
    setAnchorElSmall(null); // remove Anchor For Menu
  };

  const handleClick = (e) => {
    setAnchorElSmall(e.currentTarget); // set Anchor For Menu
  };

  return (
    <div>
      <div className="">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={openSmall ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openSmall ? "true" : undefined}
        >
          <img
            src={MoreSquare}
            alt="MoreSquare"
            width={24}
            className="img-dark-filter"
          />
        </IconButton>

        <Menu
          anchorEl={anchorElSmall}
          id="account-menu"
          open={openSmall}
          onClose={onClose}
          onClick={onClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.08))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={onClose}>
            <div className="relative varified mr-3">
              <div className="h-[60px] w-[60px]">
                <img
                  src={user?.profileImage ? user?.profileImage : User1}
                  alt="ColeUser"
                  className="h-full w-full rounded-[50%] object-cover"
                />
              </div>
              {user?.isVerified && (
                <div className="verified-bg">
                  <img src={Verified} alt="Verified" width={16} height={16} />
                </div>
              )}
            </div>
            <div className="">
              <p className="w-[150px] overflow-hidden whitespace-nowrap text-ellipsis">
                {user?.name}
              </p>
              {user?.username && <p>@{user?.username}</p>}
            </div>
          </MenuItem>
          <div className="px-4">
            <Divider />
          </div>
          {user?.isAddedInUserList ? (
            <MenuItem
              onClick={() =>
                dispatch(upsertUserToUserListAction({ userId: user?.id }))
              }
            >
              Remove from Lists
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() =>
                dispatch(
                  openModal({
                    title: <SaveToListTitle />,
                    content: <ModalSaveToList userId={user?.id} />,
                    hasCloseBtn: true,
                  })
                )
              }
            >
              Add to List
            </MenuItem>
          )}
          {user?.restricted ? (
            <MenuItem
              onClick={() => {
                dispatch(
                  RestrictUserFromPostAction({
                    id: user?.id,
                    folderId: folderId,
                    restrict: false,
                  })
                );
              }}
            >
              Unrestrict
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => {
                dispatch(
                  RestrictUserFromPostAction({
                    id: user?.id,
                    folderId: folderId,
                    restrict: true,
                  })
                );
              }}
            >
              <div className="flex items-center justify-between w-full">
                Restrict
                <Tooltip
                  title={
                    "You will not see messages or comments from this user."
                  }
                  placement="top"
                >
                  <img
                    src={InfoCircle}
                    alt="InfoCircle"
                    className="img-dark-filter"
                  />
                </Tooltip>
              </div>
            </MenuItem>
          )}

          {user?.blocked ? (
            <MenuItem
              onClick={() => {
                dispatch(
                  openModal({
                    title: "UNBLOCK USER",
                    content: (
                      <ModalConfirmUnblock
                        userId={user?.id}
                        folderId={folderId}
                      />
                    ),
                  })
                );
              }}
            >
              Unblock
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() =>
                dispatch(
                  openModal({
                    title: <BlockTitle username={user?.username} />,
                    content: <ModalBlock id={user?.id} folderId={folderId} />,
                    hasCloseBtn: true,
                  })
                )
              }
            >
              Block
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              dispatch(
                openModal({
                  title: "REPORT",
                  hasCloseBtn: false,
                  content: <ModalReport userId={user?.id} type={"user"} />,
                })
              );
            }}
          >
            Report
          </MenuItem>
          {user?.postsHiddenFromTimeline && (
            <MenuItem
              onClick={() =>
                dispatch(
                  hidePostsOfAUserAction({ id: user?.id, type: "unhide" })
                )
              }
            >
              {"Show User's Post In Timeline"}
            </MenuItem>
          )}
          <MenuItem onClick={onClose} className="text-red">
            Remove
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default ProfileDropdownMenu;
