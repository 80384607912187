import React, { useState } from "react";
import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  // Tooltip
} from "@mui/material";
import MoreSquareWhite from "./../../../../../assets/images/more-square-white.svg";
import Verified from "./../../../../../assets/images/verified.svg";
// import InfoCircle from "./../../../../../assets/images/info-circle.svg";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../../store/slices/common/modal";
import User1 from "./../../../../../assets/images/blank.png";
import {
  BlockTitle,
  ModalBlock,
  ModalConfirmUnblock,
  ModalReport,
  ModalSaveToList,
  SaveToListTitle,
} from "../../../../molecules";
import { useNavigate } from "react-router-dom";
import { upsertUserToUserListAction } from "../../../../../store/middlewares/admin/users/users";
// import { RestrictUserFromPostAction } from "../../../../../store/middlewares/web/post/posts";

const HomeSingleSuggestionMenu = ({ isVerified = false, suggestion }) => {
  const {
    profileImage: thumbnailSrc,
    name,
    username,
    blocked,
    isAddedInUserList,
    id,
  } = suggestion;
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const onClose = () => {
    setAnchorEl(null); // remove Anchor For Menu
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget); // set Anchor For Menu
  };

  return (
    <div>
      <div className="MoreSquareWhite">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{
            ml: 2,
            padding: "0 !important",
            marginLeft: "0 !important",
          }}
          aria-controls={open ? "video-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <img
            src={MoreSquareWhite}
            alt="MoreSquareWhite"
            className="img-dark-filter"
          />
        </IconButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        id="video-menu"
        open={open}
        onClose={onClose}
        onClick={onClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.08))",
            mt: 1.5,
            // "& .MuiAvatar-root": { width: 32, height: 32, ml: -0.5, mr: 1 },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            navigate(`/user/profile/${id}`);
          }}
        >
          <div className="relative varified mr-3">
            <div className="h-[60px] w-[60px]">
              <img
                src={
                  thumbnailSrc
                    ? // eslint-disable-next-line no-undef
                      `${process.env.REACT_APP_AWS_URL}${thumbnailSrc}`
                    : User1
                }
                alt="ColeUser"
                className="h-full w-full rounded-[50%] object-cover"
              />
            </div>
            {isVerified && (
              <div className="verified-bg">
                <img src={Verified} alt="Verified" width={16} height={16} />
              </div>
            )}
          </div>
          <div className="">
            <p className="w-[150px] overflow-hidden whitespace-nowrap text-ellipsis">
              {name}
            </p>
            {username && <p>@{username}</p>}
          </div>
        </MenuItem>
        <div className="px-4">
          <Divider />
        </div>
        <MenuItem onClick={onClose}>Copy Link to Profile</MenuItem>
        {isAddedInUserList ? (
          <MenuItem
            onClick={() => {
              dispatch(upsertUserToUserListAction({ userId: id }));
            }}
          >
            Remove from lists
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              dispatch(
                openModal({
                  title: <SaveToListTitle />,
                  hasCloseBtn: false,
                  content: <ModalSaveToList userId={id} />,
                })
              );
            }}
          >
            Add to lists
          </MenuItem>
        )}
        {/* {!isRestricted && (
          <MenuItem
            onClick={() => {
              dispatch(RestrictUserFromPostAction({ id }));
            }}
          >
            <div className="flex items-center justify-between w-full">
              Restrict
              <Tooltip
                title={"You will not see messages or comments from this user."}
                placement="top"
              >
                <img src={InfoCircle} alt="InfoCircle" />
              </Tooltip>
            </div>
          </MenuItem>
        )}
        {isRestricted && (
          <MenuItem
            onClick={() => {
              dispatch(RestrictUserFromPostAction({ id }));
            }}
          >
            Unrestrict
          </MenuItem>
        )} */}
        {blocked ? (
          <MenuItem
            onClick={() => {
              dispatch(
                openModal({
                  title: "UNBLOCK USER",
                  content: (
                    <ModalConfirmUnblock userId={id} fromSuggestion={true} />
                  ),
                })
              );
            }}
          >
            Unblock
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              dispatch(
                openModal({
                  title: <BlockTitle username={username} />,
                  content: <ModalBlock id={id} fromSugggestion={true} />,
                })
              );
            }}
          >
            Block
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            dispatch(
              openModal({
                title: "REPORT",
                hasCloseBtn: false,
                content: <ModalReport userId={id} type={"user"} />,
              })
            );
          }}
        >
          Report
        </MenuItem>
      </Menu>
    </div>
  );
};

export default HomeSingleSuggestionMenu;
