import { createSlice } from "@reduxjs/toolkit";

export const adminModalSlice = createSlice({
    name: 'adminModal',
    initialState: {
        isOpen: false,
        actions: null,
        hasCloseBtn: false,
        content: null,
        title: null,
    },
    reducers: {
        openAdminModal: (state, { payload }) => {
            state.isOpen = true;
            state.actions = payload?.actions;
            state.hasCloseBtn = payload?.hasCloseBtn;
            state.content = payload?.content;
            state.title = payload?.title;
        },
        closeAdminModal: (state) => {
            state.isOpen = false;
            state.actions = null;
            state.hasCloseBtn = false;
            state.content = null;
            state.title = null;
        },
    },
});

export const {
    openAdminModal, closeAdminModal
} = adminModalSlice.actions;

export default adminModalSlice.reducer;