import axiosClient from "../../../../utils/api";

//Add Creator Request
export const addCreatorRequest = async (payload) => {
  let headers = {
    "Content-Type": "multipart/form-data",
  };
  try {
    const response = await axiosClient().request({
      url: "/creatorRequest/add",
      method: "post",
      data: payload,
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Add Creator Request Service", error);
    throw error;
  }
};
//Delete Post By Admin
export const getDocumentTypeForWeb = async () => {
  let headers = {
    "Content-Type": "multipart/form-data",
  };
  try {
    const response = await axiosClient().request({
      url: "/creatorRequest/getDocumentType",
      method: "get",
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Add Creator Request Service", error);
    throw error;
  }
};
