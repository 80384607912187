import { createSlice } from "@reduxjs/toolkit";
import { GetCountryCodeForWebAction } from "../../../middlewares/web/profile/myProfile/me";
import {
  AddCountryCodeAction,
  EditCountryCodeAction,
  GetAllCountryCodeAction,
  RemoveCountryCodeAction,
} from "../../../middlewares/admin/countryCode/countryCode";

export const countryCodeSlice = createSlice({
  name: "countryCode",
  initialState: {
    countryCodeLoading: false,
    countryCodeMessage: "",
    countryCodeError: false,
    countryCode: [],
    actionSuccess: false,
    actionError: false,
    actionMessage: "",
  },
  extraReducers(builder) {
    builder
      .addCase(GetCountryCodeForWebAction.pending, (state) => {
        state.countryCodeLoading = true;
        state.countryCodeError = false;
        state.countryCodeMessage = "";
      })
      .addCase(GetCountryCodeForWebAction.fulfilled, (state, action) => {
        state.countryCodeLoading = false;
        state.countryCodeError = false;
        state.countryCode = action.payload.response.data;
        state.countryCodeMessage = action.payload.message;
      })
      .addCase(GetCountryCodeForWebAction.rejected, (state, action) => {
        state.countryCodeLoading = false;
        state.countryCodeError = true;
        state.countryCode = [];
        state.countryCodeMessage = action.payload.message;
      })
      .addCase(GetAllCountryCodeAction.pending, (state) => {
        state.countryCodeLoading = true;
        state.countryCodeError = false;
        state.countryCodeMessage = "";
      })
      .addCase(GetAllCountryCodeAction.fulfilled, (state, action) => {
        state.countryCodeLoading = false;
        state.countryCodeError = false;
        state.countryCode = action.payload.response.data;
        state.countryCodeMessage = action.payload.message;
      })
      .addCase(GetAllCountryCodeAction.rejected, (state, action) => {
        state.countryCodeLoading = false;
        state.countryCodeError = true;
        state.countryCode = [];
        state.countryCodeMessage = action.payload.message;
      })
      .addCase(EditCountryCodeAction.pending, (state) => {
        state.actionSuccess = false;
        state.actionError = false;
        state.actionMessage = "";
      })
      .addCase(EditCountryCodeAction.fulfilled, (state, action) => {
        let tempUsers = state.countryCode.map((code) => {
          if (code.id === action.payload.response.data.id) {
            return { ...code, ...action.payload.response.data };
          }
          return { ...code };
        });
        state.actionSuccess = true;
        state.actionError = false;
        state.countryCode = tempUsers;
        state.actionMessage = action.payload.message;
      })
      .addCase(EditCountryCodeAction.rejected, (state, action) => {
        state.actionSuccess = false;
        state.actionError = true;
        state.actionMessage = action.payload.message;
      })
      .addCase(AddCountryCodeAction.pending, (state) => {
        state.actionSuccess = false;
        state.actionError = false;
        state.actionMessage = "";
      })
      .addCase(AddCountryCodeAction.fulfilled, (state, action) => {
        let {
          response: { newcode },
        } = action.payload;
        let tempArray = state.countryCode;
        tempArray.push(newcode);
        state.actionSuccess = true;
        state.actionError = false;
        state.countryCode = tempArray;
        state.actionMessage = action.payload.message;
      })
      .addCase(AddCountryCodeAction.rejected, (state, action) => {
        state.actionSuccess = false;
        state.actionError = true;
        state.actionMessage = action.payload.message;
      })
      .addCase(RemoveCountryCodeAction.pending, (state) => {
        state.actionSuccess = false;
        state.actionError = false;
        state.actionMessage = "";
      })
      .addCase(RemoveCountryCodeAction.fulfilled, (state, action) => {
        let tempUsers = state.countryCode.filter(
          (code) => code.id !== action.payload.id
        );
        state.actionSuccess = true;
        state.actionError = false;
        state.countryCode = tempUsers;
        state.actionMessage = action.payload.message;
      })
      .addCase(RemoveCountryCodeAction.rejected, (state, action) => {
        state.actionSuccess = false;
        state.actionError = true;
        state.actionMessage = action.payload.message;
      });
  },
});

export default countryCodeSlice.reducer;