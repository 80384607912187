import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationsAction, updateNotificationsAction } from "../../../../../../store/middlewares/web/profile/myProfile/notifications";

const ModalSiteNotifications = () => {
  const schema = Yup.object({});

  const dispatch = useDispatch();

  const { items } = useSelector((state) => state.notifications);

  const getNotification = useCallback(() => {
    dispatch(getNotificationsAction());
  }, [dispatch]);

  useEffect(() => {
    getNotification();
  }, [getNotification]);

  const handleSubmit = (values) => {
    //Handle Change And Update Choice
    let { id, userId, ...rest } = items;
    console.log(userId);
    let tempUserData = { ...rest, ...values };
    let reqObj = { id, UserData: tempUserData };
    dispatch(updateNotificationsAction(reqObj));
  };

  //initialize Form Object
  const { values, handleChange, setFieldValue } = useFormik({
    initialValues: {
      siteNewComment: items?.siteNewComment ? items?.siteNewComment : false,
      siteNewLikes: items?.siteNewLikes ? items?.siteNewLikes : false,
      siteNewSubscriber: items?.siteNewSubscriber
        ? items?.siteNewSubscriber
        : false,
      siteNewSubscriptionFromTrial: items?.siteNewSubscriptionFromTrial
        ? items?.siteNewSubscriptionFromTrial
        : false,
      siteDiscountFromUsersIFollowed: items?.siteDiscountFromUsersIFollowed
        ? items?.siteDiscountFromUsersIFollowed
        : false,
      siteNewStream: items?.siteNewStream ? items?.siteNewStream : false,
      siteUpcomingStreamReminders: items?.siteUpcomingStreamReminders
        ? items?.siteUpcomingStreamReminders
        : false,
    },
    enableReinitialize: true,
    validationSchema: schema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: handleSubmit,
  });

  return (
    // title - SITE NOTIFICATIONS
    <>
      <div className="gray-border border-radius-10 p-3">
        <p className="font-20 font-medium text-darkblue mb-3">
          Related to You and Your Posts
        </p>
        <div className="site-notification px-2 py-1 border-radius-10 gap-3">
          <FormControlLabel
            control={
              <Checkbox
                name="siteNewComment"
                checked={values.siteNewComment}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue("siteNewComment", !values.siteNewComment);
                  handleSubmit({
                    ...values,
                    siteNewComment: !values.siteNewComment,
                  });
                }}
                color="primary"
              />
            }
            label="New Comment"
            style={{
              color: values.siteNewComment ? "#2799F6" : "#65717B",
            }}
          />
        </div>
        <div className="site-notification px-2 py-1 border-radius-10 gap-3">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.siteNewLikes}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue("siteNewLikes", !values.siteNewLikes);
                  handleSubmit({
                    ...values,
                    siteNewLikes: !values.siteNewLikes,
                  });
                }}
                name="siteNewLikes"
                color="primary"
              />
            }
            label="New Likes"
            style={{
              color: values.siteNewLikes ? "#2799F6" : "#65717B",
            }}
          />
        </div>
        <div className="site-notification px-2 py-1 border-radius-10 gap-3">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.siteNewSubscriber}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue("siteNewSubscriber", !values.siteNewSubscriber);
                  handleSubmit({
                    ...values,
                    siteNewSubscriber: !values.siteNewSubscriber,
                  });
                }}
                name="siteNewSubscriber"
                color="primary"
              />
            }
            label="New Subscriber"
            style={{
              color: values.siteNewSubscriber ? "#2799F6" : "#65717B",
            }}
          />
        </div>
        <div className="site-notification px-2 py-1 border-radius-10 gap-3">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.siteNewSubscriptionFromTrial}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(
                    "siteNewSubscriptionFromTrial",
                    !values.siteNewSubscriptionFromTrial
                  );
                  handleSubmit({
                    ...values,
                    siteNewSubscriptionFromTrial:
                      !values.siteNewSubscriptionFromTrial,
                  });
                }}
                name="siteNewSubscriptionFromTrial"
                color="primary"
              />
            }
            label="New Subscription from Trial"
            style={{
              color: values.siteNewSubscriptionFromTrial
                ? "#2799F6"
                : "#65717B",
            }}
          />
        </div>
      </div>
      <div className="gray-border border-radius-10 p-3 mt-4">
        <p className="font-20 font-medium text-darkblue mb-3">
          Subscriptions and Following
        </p>
        <div className="site-notification px-2 py-1 border-radius-10 gap-3 ">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.siteDiscountFromUsersIFollowed}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(
                    "siteDiscountFromUsersIFollowed",
                    !values.siteDiscountFromUsersIFollowed
                  );
                  handleSubmit({
                    ...values,
                    siteDiscountFromUsersIFollowed:
                      !values.siteDiscountFromUsersIFollowed,
                  });
                }}
                name="siteDiscountFromUsersIFollowed"
                color="primary"
              />
            }
            label="Discounts from Users I Used to Follow"
            style={{
              color: values?.siteDiscountFromUsersIFollowed
                ? "#2799F6"
                : "#65717B",
            }}
          />
        </div>
        <div className="site-notification px-2 py-1 border-radius-10 gap-3">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.siteNewStream}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue("siteNewStream", !values.siteNewStream);
                  handleSubmit({
                    ...values,
                    siteNewStream: !values.siteNewStream,
                  });
                }}
                name="siteNewStream"
                color="primary"
              />
            }
            label="New Stream"
            style={{
              color: values?.siteNewStream ? "#2799F6" : "#65717B",
            }}
          />
        </div>
        <div className="site-notification px-2 py-1 border-radius-10 gap-3">
          <FormControlLabel
            control={
              <Checkbox
                value={"upcoming-streams"}
                checked={values.siteUpcomingStreamReminders}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(
                    "siteUpcomingStreamReminders",
                    !values.siteUpcomingStreamReminders
                  );
                  handleSubmit({
                    ...values,
                    siteUpcomingStreamReminders:
                      !values.siteUpcomingStreamReminders,
                  });
                }}
                name="siteUpcomingStreamReminders"
                color="primary"
              />
            }
            label="Upcoming Stream Reminders"
            style={{
              color: values?.siteUpcomingStreamReminders
                ? "#2799F6"
                : "#65717B",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ModalSiteNotifications;
