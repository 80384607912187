import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  banUser,
  deleteUser,
  getActiveUsers,
  getBannedUsers,
  getBlockedUsers,
  getRestrictedUsers,
  getUserListCollection,
  upsertUserToUserList,
} from "./../../../services/admin/users/users.js";
import { openToast } from "../../../slices/common/toast/index.js";
import { closeModal } from "../../../slices/common/modal/index.js";
import { userAddToUserList } from "../../../slices/common/posts/index.js";
import { getUserListFolderAction } from "../../web/folders/folders.js";

export const GetActiveUsersAction = createAsyncThunk(
  "users/getUsers",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getActiveUsers();
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const GetBlockedUsersAction = createAsyncThunk(
  "users/blockedUsers",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getBlockedUsers();
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const GetRestrictedUsersAction = createAsyncThunk(
  "users/restrictedUsers",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getRestrictedUsers();
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const GetBannedUsersAction = createAsyncThunk(
  "users/getBannedUsers",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getBannedUsers();
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const BanUserAction = createAsyncThunk(
  "users/BanUser",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await banUser(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue({ ...response, id: payload.id });
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const DeleteUserAction = createAsyncThunk(
  "users/DeleteUser",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await deleteUser(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue({ ...response, id: payload.id });
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const upsertUserToUserListAction = createAsyncThunk(
  "users/upsertToUserList",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { refresh, ...rest } = payload;
      const response = await upsertUserToUserList({ ...rest });
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        if (!payload?.folderId) {
          dispatch(getUserListFolderAction());
        }
        if (refresh) {
          location.reload();
        }
        dispatch(userAddToUserList({ id: rest.userId }));
        dispatch(closeModal());
        return fulfillWithValue({ ...response, id: rest.userId });
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUsersCollectionForUserListAction = createAsyncThunk(
  "users/getUsersForUserListCollection",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getUserListCollection({
        ...payload,
        dataType: "user",
      });
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        let users = [];
        response?.response[0].userList?.forEach((user) => {
          users?.push(user?.otherUser);
        });
        return fulfillWithValue({ ...response, users });
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
