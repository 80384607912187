import { Grid, IconButton } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowLeft from "./../../../../assets/images/arrow-left.svg";
import { Masonry } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { getSuggestionsAction } from "../../../../store/middlewares/web/suggestions/suggestions";
import { HomeSingleSuggestion } from "../../../../newComponents/organisms";

const AllSuggestions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { suggestions } = useSelector((state) => state.suggestions);

  const getAllSuggestion = useCallback(() => {
    // Only dispatch the action if suggestions have not been loaded yet
    dispatch(getSuggestionsAction({ pagination: false }));
  }, [dispatch]);

  useEffect(() => {
    getAllSuggestion();
  }, [getAllSuggestion]);

  return (
    <Grid container spacing={2} className="h-full creator-home-module">
      <Grid item xs={12} md={12} lg={12} className="subscription">
        <div className="bg-white rounded-2xl p-3 ">
          <div className="flex flex-wrap items-center justify-between heading">
            <div className="flex items-center gap-3">
              <IconButton
                color="primary"
                aria-label="add to shopping cart"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img src={ArrowLeft} alt="ArrowLeft" height={26} width={26} className="img-dark-filter" />
              </IconButton>
              <h3 className="font-18 font-semibold text-darkblue uppercase">
                All Suggestions
              </h3>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-2xl pt-4 h-queue mt-4">
          <div className="lg:ml-4">
            <Grid container spacing={1} className="h-full mb-4 fans-message">
              <Masonry columns={{ xs: 1, sm: 1, md: 2, lg: 2 }} spacing={2}>
                {suggestions.map((suggestion, index) => {
                  return (
                    <HomeSingleSuggestion suggestion={suggestion} key={index} />
                  );
                })}
              </Masonry>
            </Grid>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default AllSuggestions;
