import { getSpotifyUserDetails } from "./../../../../services/web/profile/spotify";
import { connectSpotify } from "./../../../../services/web/profile/account.services";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const GetSpotify = createAsyncThunk("profile/connectSpotify", async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
        const res = await getSpotifyUserDetails(payload);
        if (res.status === 1) {
            let reqObj = {
                accessToken: payload?.spotifyToken,
                country: res?.country,
                displayName: res?.display_name,
                spotifyUrl: res?.external_urls?.spotify,
                spotifyUserId: res?.id
            }
            const connectSpotifyRes = await connectSpotify({ userData: reqObj });
            // if any async operation need to be done on response data, do it here,
            // then full fill data
            if (connectSpotifyRes.status === 1) {
                const { response, message } = connectSpotifyRes
                return fulfillWithValue({ response, message });
            }
            return rejectWithValue({ message: connectSpotifyRes?.message });
        }
        return rejectWithValue({ message: res?.message });
    } catch (error) {
        return rejectWithValue(error);
    }
})
