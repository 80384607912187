import { Button } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Logo from '../../../../../assets/images/logo.svg';
import { useNavigate } from 'react-router-dom';
import { closeModal } from '../../../../../store/slices/common/modal';
import { deleteAccountAction } from '../../../../../store/middlewares/web/profile/myProfile/me';

const ModalDelete = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { meError } = useSelector((state) => state.me)

    const handleClose = () => {
        dispatch(closeModal())
    }

    const handleDelete = () => {
        //Delete Account API Call
        dispatch(deleteAccountAction())
        if (!meError) {
            navigate("/")
            handleClose()
        }
    }

    return (
        <>
            <div className='flex justify-center items-center pb-3 pt-5'>
                <div>
                    <div className='flex justify-center items-center pb-7'>
                        <img src={Logo} alt={"Logout"} className='w-60 ' />
                    </div>
                    <h3 className='text-lg text-center text-[#1E405C] font-semibold'>Are you sure want to delete your account?</h3>
                </div>
            </div>
            <div className='flex justify-end mt-6 gap-3'>
                <Button variant="contained" className='m-auto white-btn' onClick={handleClose}>Cancel</Button>
                <Button type="submit" variant="contained" className='m-auto px-12 delete-button' onClick={handleDelete} >Delete</Button>
            </div>
        </>
    )
}

export default ModalDelete