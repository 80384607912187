import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

const Timepicker = () => {
  const [selectedTime, setSelectedTime] = useState(dayjs());

  const handleTimeChange = (time) => {
    setSelectedTime(time); //on Change handler
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiTimePicker className='w-full'
        value={selectedTime}
        onChange={handleTimeChange}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};

export default Timepicker;