import { Button } from "@mui/material";
import React, { useState } from "react";
import { closeModal } from "../../../../../store/slices/common/modal";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { SelectDropdown } from "../../../../atoms";
import { addSubscriptionsPlanAction } from "../../../../../store/middlewares/web/subscriptions/subscriptions";
import getMonthCount from "../../../../../utils/getMonthCount";

const ModalAddSubscriptionBundle = ({ baseValue }) => {
  const dispatch = useDispatch();

  let discountArray = [
    { label: "0% Discount", value: 0 },
    { label: "5% Discount", value: 5 },
    { label: "10% Discount", value: 10 },
    { label: "15% Discount", value: 15 },
    { label: "20% Discount", value: 20 },
  ];

  const [subscriptionValues, setSubscriptionValues] = useState({
    threeMonths: baseValue * 3,
    sixMonths: baseValue * 6,
    twelveMonths: baseValue * 12,
  });

  const handleChange = (key, discount) => {
    let oldVal = baseValue * getMonthCount(key);
    let newVal = oldVal * (1 - discount / 100);
    setSubscriptionValues({ ...subscriptionValues, [key]: newVal });
  };

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleSubmit = (values) => {
    let payload = {
      plans: [
        {
          planType: "paid",
          price: Number(Number(baseValue * 3).toFixed(1)),
          duration: "threeMonths",
          discount: values.threeMonths,
        },
        {
          planType: "paid",
          price: Number(Number(baseValue * 6).toFixed(1)),
          duration: "sixMonths",
          discount: values.sixMonths,
        },
        {
          planType: "paid",
          price: Number(Number(baseValue * 12).toFixed(1)),
          duration: "twelveMonths",
          discount: values.twelveMonths,
        },
      ],
    };
    dispatch(addSubscriptionsPlanAction(payload));
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: { threeMonths: 0, sixMonths: 0, twelveMonths: 0 },
    enableReinitialize: true,
    validationSchema: null,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: handleSubmit,
  });

  return (
    <div>
      {Object.keys(subscriptionValues)?.map((key, index) => {
        return (
          <div
            key={key}
            className={`flex flex-col gap-2 ${
              index === Object.keys(subscriptionValues)?.length ? "" : "mb-4"
            }`}
          >
            <p>{`Subscription for ${getMonthCount(
              key
            )} months for $${subscriptionValues[key].toFixed(2)} total.`}</p>
            <SelectDropdown
              label="Enter Discount"
              placeholder="Enter Discount"
              value={formik.values[key]}
              onChange={(e) => {
                formik.handleChange(e);
                handleChange(key, e.target.value);
              }}
              name={key}
              onBlur={formik.handleBlur}
              id={key}
              error={formik.errors[key] && formik.touched[key]}
              errorMsg={formik.errors[key]}
              options={discountArray}
            />
          </div>
        );
      })}
      <form onSubmit={formik.handleSubmit}>
        <div className="flex justify-end mt-6 gap-3">
          <Button
            variant="contained"
            className="m-auto white-btn"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button variant="contained" type="submit" className="m-auto px-12">
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ModalAddSubscriptionBundle;
