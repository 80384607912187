import { createSlice } from "@reduxjs/toolkit";
import {
  AddReportReasonsAction,
  EditReportReasonsAction,
  GetReportReasonsAction,
  RemoveReportReasonsAction,
} from "../../../middlewares/admin/cms/reportReasons";

export const reportReasonsSlice = createSlice({
  name: "reportReasons",
  initialState: {
    reasonsLoading: false,
    reasons: [],
  },
  extraReducers(builder) {
    builder
      //Get Reasons
      .addCase(GetReportReasonsAction.pending, (state) => {
        state.reasonsLoading = true;
        state.reasons = [];
      })
      .addCase(GetReportReasonsAction.fulfilled, (state, action) => {
        state.reasonsLoading = false;
        state.reasons = action.payload.response;
      })
      .addCase(GetReportReasonsAction.rejected, (state) => {
        state.reasonsLoading = false;
        state.reasons = [];
      })
      //Edit Reasons
      .addCase(EditReportReasonsAction.pending, (state) => {
        state.reasonsLoading = false;
      })
      .addCase(EditReportReasonsAction.fulfilled, (state, action) => {
        let { reasons } = state;
        let temp = reasons.map((reason) => {
          if (reason.id === action.payload.response.id) {
            return { ...reason, reason: action.payload.response.reason };
          }
          return reason;
        });
        state.reasonsLoading = false;
        state.reasons = temp;
      })
      .addCase(EditReportReasonsAction.rejected, (state) => {
        state.reasonsLoading = false;
      })
      //Add Reasons
      .addCase(AddReportReasonsAction.pending, (state) => {
        state.reasonsLoading = false;
      })
      .addCase(AddReportReasonsAction.fulfilled, (state, action) => {
        state.reasonsLoading = false;
        let { reasons } = state;
        reasons.push(action.payload.response.data);
        state.reasons = reasons;
      })
      .addCase(AddReportReasonsAction.rejected, (state) => {
        state.reasonsLoading = false;
      })
      //Delete Reasons
      .addCase(RemoveReportReasonsAction.pending, (state) => {
        state.reasonsLoading = false;
      })
      .addCase(RemoveReportReasonsAction.fulfilled, (state, action) => {
        state.reasonsLoading = false;
        state.reasons = state.reasons.filter(
          (reason) => reason.id !== action.payload.id
        );
      })
      .addCase(RemoveReportReasonsAction.rejected, (state) => {
        state.reasonsLoading = false;
      });
  },
});

export default reportReasonsSlice.reducer;
