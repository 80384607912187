import { Box, FormControl, OutlinedInput } from "@mui/material";
import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  DiscardModalBtn,
  SelectDropdown,
  SubmitModalBtn,
} from "../../../atoms";
import { AddStateAction } from "../../../../store/middlewares/admin/state/state";
import { Country, StateName } from "../../../../utils/validation/AddStateForm";

const ModalAddState = () => {
  const dispatch = useDispatch();
  const { countryCode } = useSelector((state) => state.countryCode);

  // Define Schema For Validation
  const AddStateSchema = Yup.object({
    name: StateName,
    countryId: Country,
  });

  const handleSubmit = (values) => {
    //On Submit Form
    dispatch(AddStateAction({ ...values }));
  };

  // Form Management
  const formik = useFormik({
    initialValues: {
      name: "",
      countryId: "",
    },
    validationSchema: AddStateSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
      <Box>
        <FormControl className="w-full mb-4" variant="outlined">
          <label htmlFor="name" style={{ color: "rgba(0, 0, 0, 0.6)" }}>
            Reason
          </label>
          <OutlinedInput
            placeholder="Enter State"
            type="name"
            name="name"
            id="name"
            size="small"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.reason}
            className={
              formik.errors.name && formik.touched.name ? "error-border" : ""
            }
          />
          {formik.errors.name && formik.touched.name && (
            <Box className="text-red-500 text-sm mt-1">
              {formik.errors.name}
            </Box>
          )}
        </FormControl>
        <SelectDropdown
          placeholder="Country"
          onChange={formik.handleChange}
          label="Select Country"
          value={formik.values.countryId ? formik.values.countryId : ""}
          name="countryId"
          onBlur={formik.handleBlur}
          id="countryId"
          error={formik.errors.countryId && formik.touched.countryId}
          errorMsg={formik.errors.countryId}
          options={countryCode?.map((country) => {
            return {
              label: country?.name,
              value: country?.id,
            };
          })}
        />
      </Box>
      <Box className="flex items-end justify-end gap-4 text-end">
        <SubmitModalBtn />
        <DiscardModalBtn />
      </Box>
    </form>
  );
};

export default ModalAddState;
