/* eslint-disable no-undef */
import React, { useMemo } from "react";
import { PostCreatedDetails, PostParticulars } from "../../../molecules";
import { Box, Typography } from "@mui/material";
import User1 from "../../../../assets/images/blank.png";
import TableContainer from "../../../atoms/admin/tablecomponet/TableContainer";
import dayjs from "dayjs";

const PostDetailsCard = ({ post }) => {
  const columns = useMemo(
    () => [
      {
        header: "Reported By",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <Box className="flex items-center gap-2">
            <Box className="w-16 h-16">
              <img
                src={
                  row.original.profileImage
                    ? `${process.env.REACT_APP_AWS_URL}${row.original.profileImage}`
                    : User1
                }
                alt={row.original.name}
                className="w-full h-full rounded-full"
              />
            </Box>
            <Box className="flex flex-col">
              <Typography variant="p">{row.original.reporter.name}</Typography>
              {row.original.reporter.username && (
                <Typography variant="p">
                  @{row.original.reporter.username}
                </Typography>
              )}
            </Box>
          </Box>
        ),
      },
      {
        header: "Reason",
        accessorKey: "reason",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <Box className="flex items-center">
            <Typography variant="p">{row.original.reason}</Typography>
          </Box>
        ),
      },
      {
        header: "Reported On",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => {
          const date = dayjs(row.original.createdAt).format("DD/MM/YYYY");
          return (
            <Box className="flex items-center">
              <Typography variant="p">{date}</Typography>
            </Box>
          );
        },
      },
      // {
      //   id: "action",
      //   header: <p className="text-right">Action</p>,
      //   enableColumnFilter: false,
      //   cell: ({ row }) => (
      //     <ReportedPostsActionBar
      //       row={row.original}
      //       id={row.original.post.id}
      //     />
      //   ),
      // },
    ],
    []
  );
  return (
    <>
      <div className="card">
        <div className="card-body rounded-t-md border-b">
          <PostParticulars post={post} />
        </div>
        <div className="card-body">
          <PostCreatedDetails post={post} />
        </div>
      </div>
      {post?.Reports.length > 0 && (
        <div className="card">
          <div className="card-body">
            <Typography variant="body1">REPORTED BY</Typography>
            <TableContainer
              isPagination={false}
              columns={columns || []}
              data={post?.Reports || []}
              customPageSize={10}
              divclassName="-mx-5 overflow-x-auto"
              tableclassName="w-full whitespace-nowrap mt-4"
              theadclassName="text-left bg-slate-100 text-slate-500 "
              thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-y border-slate-200  w-10"
              tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 "
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PostDetailsCard;
