import React from 'react'
import CommentsIcon from "./../../../assets/images/messagesIcon.svg";

const SingleNotificationComment = ({userProfileImage, isComment, isLike,  name, comment, time}) => {
    //Single Notification Item For Notification in Comments Tab 
    return (
        <div className="light-gray-box flex justify-between gap-2 mb-3">
            <div className="flex flex-sm-wrap items-start gap-6">
                <div className="relative">
                    <img
                        src={userProfileImage}
                        alt="NotifyUser2"
                        className="notify-user"
                    />  
                    <img
                        src={CommentsIcon}
                        alt="CommentsIcon"
                        className="notify-user-status"
                    />
                </div>
                <div>
                    <div className="flex flex-wrap items-center gap-2 title-width">
                        <p className="">
                            <span className="font-semibold text-darkblue font-20">
                                {name}
                            </span>{" "}
                            {isComment && "mentioned you in her comment on posts."}
                            {isLike && "liked your post."}
                        </p>
                    </div>
                    {comment && <p className="text-darkblue font-medium mt-2">
                        {comment}
                    </p>}
                </div>
            </div>
            <p className="font-medium text-nowrap date-position	">
               {time}
            </p>
        </div>
    )
}

export default SingleNotificationComment
