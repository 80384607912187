import React from "react";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";

export default function ProfileSubscription() {
  return (
    <Box component="main" className="flex" sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} className="fans-profile">
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={2} className="h-calc-200 fans-profile mt-3">
            <div className="bg-white rounded-2xl py-3 px-5 w-full">
              <Grid item xs={12} md={12} lg={6}>
                <h3 className="font-semibold mb-3 font-24 text-darkblue">
                  Subscription
                </h3>
                <h5 className="font-20 font-medium mb-3 text-darkblue">
                  General
                </h5>
                <p className="mb-4">
                  Offer a free trial or a discounted subscription on your
                  profile for a limited number of new or already expired
                  subscribers.
                </p>
                <div className="heading">
                  <Button variant="contained" className="w-full">
                    Start Promotion Campaign
                  </Button>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

// export default Profile;
