import React, {  useState } from "react";
import Grid from "@mui/material/Grid";
import { Box, CircularProgress, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ScreenMirroringIcon from "./../../../../../assets/images/screenmirroring.svg";
import GalleryBlueIcon from "./../../../../../assets/images/gallery-blue.svg";
import VideoBlueIcon from "./../../../../../assets/images/video-blue.svg";
import AudioSquareBlueIcon from "./../../../../../assets/images/audio-square-blue.svg";
import LockBlueIcon from "./../../../../../assets/images/lock-blue.svg";
import SaveForLaterImg from "./../../../../../assets/images/saveforlater.png";
import { useSelector } from "react-redux";
import { DisplayPost } from "../../../../organisms";
import { NoItemsInCollection, UserListFolders } from "../../../../molecules";

const PostsTab = ({ active, setActive }) => {
  const [selectedTabPosts, setSelectedTabPosts] = useState("all");

  const { posts, postsLoading } = useSelector((state) => state.posts);
  const { folders } = useSelector((state) => state.folders);

  const handlePostTabChange = (event, newValue) => {
    setSelectedTabPosts(newValue);
  };

  return (
    <TabContext value={selectedTabPosts}>
      <Box className="mb-5">
        <TabList
          onChange={handlePostTabChange}
          aria-label="simple tabs example"
          variant="scrollable"
          scrollButtons='hidden'
        >
          <Tab
            icon={<img src={ScreenMirroringIcon} alt="ScreenMirroringIcon" />}
            label={`All (${active?.totalPosts < 10 ? `0${active?.totalPosts}` :  active?.totalPosts ? active?.totalPosts : 0 })`}
            value="all"
          />
          <Tab
            icon={<img src={GalleryBlueIcon} alt="GalleryBlueIcon" />}
            label={`Photo (${active?.totalImage < 10 ? `0${active?.totalImage}` :  active?.totalImage ? active?.totalImage : 0})`}
            value="image"
          />
          <Tab
            icon={<img src={VideoBlueIcon} alt="VideoBlueIcon" width={24} />}
            label={`Video (${active?.totalVideo < 10 ? `0${active?.totalVideo}` :  active?.totalVideo ? active?.totalVideo : 0})`}
            value="video"
          />
          <Tab
            icon={<img src={AudioSquareBlueIcon} alt="AudioSquareBlueIcon" />}
            label={`Audio (${active?.totalAudio < 10 ? `0${active?.totalAudio}` :  active?.totalAudio ? active?.totalAudio : 0})`}
            value="audio"
          />
          <Tab
            icon={<img src={ScreenMirroringIcon} alt="ScreenMirroringIcon" />}
            label={`Other (${active?.totalOther < 10 ? `0${active?.totalOther}` :  active?.totalOthers  ? active?.totalOthers : 0 })`}

            value="other"
          />
          <Tab
            icon={<img src={LockBlueIcon} alt="LockBlueIcon" />}
            label={`Locked (${active?.totalLocked < 10 ? `0${active?.totalLocked}` :  active?.totalLocked ? active?.totalLocked : 0})`}
            value="locked"
          />
        </TabList>
      </Box>
      <Grid container spacing={1} className="h-full mb-4 fans-message">
        <Grid item xs={12} md={12} lg={8}>
          <div className="light-gray-bg lg:mr-4 post-no-padding creator-home-module">
            <TabPanel value="all" className="p-0 w-full">
              {postsLoading ? (
                <div className="flex flex-col justify-center items-center p-4 h-calc-200">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {posts?.length === 0  || folders?.length === 0 && (
                    <div className="flex flex-col justify-center items-center p-4 h-calc-200">
                      <img
                        src={SaveForLaterImg}
                        alt="SaveForLaterImg"
                        width={246}
                      />
                      <p className="font-20 mt-3">Nothing found</p>
                    </div>
                  )}
                  {posts?.map((post) => {
                    return <DisplayPost key={post?.id} post={post} />;
                  })}
                </>
              )}
            </TabPanel>

            <TabPanel value="image" className="p-0 w-full">
              {postsLoading ? (
                <div className="flex flex-col justify-center items-center p-4 h-calc-200">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {(posts?.length === 0 || folders?.length === 0) && (
                    <NoItemsInCollection />
                  )}
                  {posts?.map((post) => {
                    return <DisplayPost key={post?.id} post={post} />;
                  })}
                </>
              )}
            </TabPanel>

            <TabPanel value="video" className="p-0 w-full">
              {postsLoading ? (
                <div className="flex flex-col justify-center items-center p-4 h-calc-200">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {(posts?.length === 0 || folders?.length === 0)  && (
                    <div className="flex flex-col justify-center items-center p-4 h-calc-200">
                      <img
                        src={SaveForLaterImg}
                        alt="SaveForLaterImg"
                        width={246}
                      />
                      <p className="font-20 mt-3">Nothing found</p>
                    </div>
                  )}
                  {posts?.map((post) => {
                    return <DisplayPost key={post?.id} post={post} />;
                  })}
                </>
              )}
            </TabPanel>

            <TabPanel value="audio" className="p-0 w-full">
              {postsLoading ? (
                <div className="flex flex-col justify-center items-center p-4 h-calc-200">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {(posts?.length === 0 || folders?.length === 0) && (
                    <NoItemsInCollection />
                  )}
                  {posts?.map((post) => {
                    return <DisplayPost key={post?.id} post={post} />;
                  })}
                </>
              )}
            </TabPanel>

            <TabPanel value="other" className="p-0 w-full">
              {postsLoading ? (
                <div className="flex flex-col justify-center items-center p-4 h-calc-200">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {(posts?.length === 0 || folders?.length === 0) && (
                    <NoItemsInCollection />
                  )}
                  {posts?.map((post) => {
                    return <DisplayPost key={post?.id} post={post} />;
                  })}
                </>
              )}
            </TabPanel>

            <TabPanel value="locked" className="p-0 w-full">
              {postsLoading ? (
                <div className="flex flex-col justify-center items-center p-4 h-calc-200">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {(posts?.length === 0 || folders?.length === 0) && (
                    <div className="flex flex-col justify-center items-center p-4 h-calc-200">
                      <img
                        src={SaveForLaterImg}
                        alt="SaveForLaterImg"
                        width={246}
                      />
                      <p className="font-20 mt-3">Nothing found</p>
                    </div>
                  )}
                  {posts?.map((post) => {
                    return <DisplayPost key={post?.id} post={post} />;
                  })}
                </>
              )}
            </TabPanel>
          </div>
        </Grid>

        <UserListFolders
          mediaType={selectedTabPosts}
          type={"posts"}
          active={active}
          setActive={setActive}
        />
      </Grid>
    </TabContext>
  );
};

export default PostsTab;
