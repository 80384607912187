import { getSubscriptions } from "../../../services/admin/subscriptions/subscriptions.js";
import { closeAdminModal } from "../../../slices/common/adminModal";
import { openToast } from "../../../slices/common/toast/index.js";
import {
  getActiveCreator,
  getBannedCreator,
  getPendingCreator,
  updateCreatorRequestStatus,
} from "./../../../services/admin/users/creators.js";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const GetSubscriptionsAction = createAsyncThunk(
  "users/getSubscriber",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getSubscriptions();
      if (response.status === 1) {
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
