import { useDispatch, } from 'react-redux';
import { Outlet } from "react-router-dom";
import React from "react";
import { useCallback, useEffect, } from 'react';
import { Box } from '@mui/material';
import { Header, Sidebar } from '../../../../molecules';
import { getMeAction } from '../../../../../store/middlewares/web/profile/myProfile/me';

const MainLayoutComponent = ({ type = "user" }) => {
  const drawerWidth = 330;
  const dispatch = useDispatch()

  const getMeDetails = useCallback(() => {
    dispatch(getMeAction())
  }, [dispatch])

  useEffect(() => {
    getMeDetails()
  }, [getMeDetails])

  return (
    <>
      <Header />
      <div className='flex md:gap-6 gap-3 container md:px-4 p-0 md:mt-8 mt-0'>
        <Sidebar type={type} />
        <Box
          component="main"
          className="flex"
          sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Outlet />
        </Box>
      </div>
    </>
  )
}

export default MainLayoutComponent