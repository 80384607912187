import { Box, FormControl, OutlinedInput } from "@mui/material";
import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { EditReportReasonsAction } from "../../../../store/middlewares/admin/cms/reportReasons";
import { Reason } from "../../../../utils/validation/AddReportReasonForm";
import { DiscardModalBtn, SubmitModalBtn } from "../../../atoms";

const ModalEditReportReason = ({ data }) => {
  const dispatch = useDispatch();

  // Define Schema For Validation
  const AddReportReasonSchema = Yup.object({
    reason: Reason,
  });

  const handleSubmit = (values) => {
    //On Submit Form
    const { reason } = values;
    dispatch(EditReportReasonsAction({ reason, id: data?.id }));
  };

  // Form Management
  const formik = useFormik({
    initialValues: {
      reason: data?.reason,
    },
    enableReinitialize: true,
    validationSchema: AddReportReasonSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
      <Box>
        <FormControl className="w-full" variant="outlined">
          <label htmlFor="name" style={{ color: "rgba(0, 0, 0, 0.6)" }}>
            Reason
          </label>
          <OutlinedInput
            placeholder="Enter Reason"
            type="text"
            label="Reason"
            name="reason"
            id="reason"
            size="small"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.reason}
            className={
              formik.errors.reason && formik.touched.reason
                ? "error-border"
                : ""
            }
          />
          {formik.errors.reason && formik.touched.reason && (
            <Box className="text-red-500 text-sm mt-1">
              {formik.errors.reason}
            </Box>
          )}
        </FormControl>
      </Box>
      <Box className="flex items-end justify-end gap-4 text-end">
        <SubmitModalBtn />
        <DiscardModalBtn />
      </Box>
    </form>
  );
};

export default ModalEditReportReason;
