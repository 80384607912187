/**
 * @fileOverview This file contains the axiosClient utility function and related constants for making HTTP requests.
 * @module axiosClient
 */

import axios from "axios";
import { getDecryptedToken } from "./CryptoJS";

axios.defaults.withCredentials = false;
let apiURL = "";
let accountURL = "";
let adminURL = "";

/**
 * Configuration options for the you only environment.
 * @type {Object}
 */
const youOnly = {
  name: "youonly",
  account: "",
  api: "https://api.youonly.io",
  live: "https://youonly.io/",
  admin: "https://youonly.io/admin",
};

/**
 * Configuration options for the localhost environment.
 * @type {Object}
 */
const localhost = {
  account: "http://localhost:3000/",
  admin: "http://localhost:3000/admin",
  api: "https://api.youonly.io",
};

const path = window.location.href;
const live = !!path.match(youOnly.live);

if (live) {
  accountURL = youOnly.account;
  apiURL = youOnly.api;
  adminURL = youOnly.admin;
} else {
  accountURL = localhost.account;
  apiURL = localhost.api;
  adminURL = localhost.admin;
}

export const API_URL = apiURL;
export const ACCOUNT_URL = accountURL;

/**
 * The axios client function for making HTTP requests to api.
 * @function axiosClient
 */
export function axiosClient() {
  // gets the email and refresh cookies
  const adminToken = JSON.parse(localStorage.getItem("user-details-admin"))?.token;
  const userToken = JSON.parse(localStorage.getItem("user-details"))?.token;
  
  let token = "";
  
  const path = window.location.href;
  const admin = path.includes(adminURL);
  if (admin) {
    token = adminToken;
  } else {
    token = userToken;
  }
  
  const defaultOptions = {
    baseURL: apiURL,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      accept: "application/json",
    },
  };
  const instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use((config) => {
    config.headers = {
      Authorization: `Bearer ${getDecryptedToken(token)}`,
    };
    return config;
  });
  instance.defaults.headers["Authorization"] = getDecryptedToken(token);

  return instance;
}

export default axiosClient;