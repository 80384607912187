import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowLeft from "./../../../../../assets/images/arrow-left.svg";
import ScreenMirrioringIcon from "./../../../../../assets/images/screenmirroring.svg";
import SaveforlaterImage from "./../../../../../assets/images/saveforlater.png";
import UnlockBlueIcon from "./../../../../../assets/images/unlock-blue.svg";
import PhotoBlueIcon from "./../../../../../assets/images/gallery-blue.svg";
import VideoBlueIcon from "./../../../../../assets/images/video-blue.svg";
import AudioBlueIcon from "./../../../../../assets/images/audio-square-blue.svg";
import Media1 from "./../../../../../assets/images/media1.png";
import MediaBg from "./../../../../../assets/images/media-bg.png";
import LockIcon from "./../../../../../assets/images/lock.svg";
import ThumbnailUser1 from "./../../../../../assets/images/thumbnail-user1.png";
import chatgallery1 from "./../../../../../assets/images/chatgallery/chatgallery1.png";
import chatgallery2 from "./../../../../../assets/images/chatgallery/chatgallery2.png";
import chatgallery3 from "./../../../../../assets/images/chatgallery/chatgallery3.png";
import chatgallery4 from "./../../../../../assets/images/chatgallery/chatgallery4.png";
import chatgallery5 from "./../../../../../assets/images/chatgallery/chatgallery5.png";

export default function ChatGalleryFans() {
  const [value, setValue] = useState("0");

  // const open = Boolean(anchorEl);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="profile-post">
      <div className="flex justify-between flex-wrap pr-3 py-3 pl-2">
        <div className="flex gap-2">
          <IconButton color="primary" aria-label="add to shopping cart">
            <img
              className="img-dark-filter"
              src={ArrowLeft}
              alt="ArrowLeft"
              height={26}
              width={26}
            />
          </IconButton>
          <div className="">
            <p className="font-20 font-semibold text-darkblue">Chat Gallery</p>
            <p className="font-medium">With Leo</p>
          </div>
        </div>
      </div>

      <div className="message-body h-calc-200 chat-gallery">
        <div className="post-tab mb-2 mt-3">
          <TabContext value={value}>
            <Box>
              <TabList onChange={handleChange} aria-label="simple tabs example">
                <Tab
                  label="All"
                  icon={
                    <img
                      src={ScreenMirrioringIcon}
                      alt="ScreenMirrioringIcon"
                    />
                  }
                  value="0"
                />
                <Tab
                  label="Unlock"
                  icon={<img src={UnlockBlueIcon} alt="UnlockBlueIcon" />}
                  value="1"
                />
                <Tab
                  label="Photo"
                  icon={<img src={PhotoBlueIcon} alt="PhotoBlueIcon" />}
                  value="2"
                />
                <Tab
                  label="Video"
                  icon={<img src={VideoBlueIcon} alt="VideoBlueIcon" />}
                  value="3"
                />
                <Tab
                  label="Audio"
                  icon={<img src={AudioBlueIcon} alt="AudioBlueIcon" />}
                  value="4"
                />
              </TabList>
            </Box>

            <TabPanel value="0" className="p-0 w-full text-center relative">
              <div className="bg-white rounded-2xl py-4 px-2 mt-4 profile-media creator-home-module chat-gallery">
                <Grid container spacing={2} className="h-full">
                  <Grid item xs={6} md={12} lg={6} xl={4}>
                    <div className="gray-border">
                      <div className="relative profile-bg-white">
                        <img
                          src={MediaBg}
                          alt="MediaBg"
                          className="w-full h-215px"
                        />
                        <div className="profile-lock-icon text-center">
                          <Button variant="contained" className="white-btn">
                            <img
                              src={LockIcon}
                              alt="LockIcon"
                              height={40}
                              width={40}
                              className="img-dark-filter"
                            />
                          </Button>
                          <h5 className="font-16 font-medium text-darkblue my-2 lg:my-1 xl:my-4">
                            Unlock this Post
                          </h5>
                          <Button variant="contained" className="unlock-post">
                            Unlock for $40.50
                          </Button>
                        </div>
                      </div>
                      <div className="flex items-center justify-between gap-3 items-center">
                        <div className="flex items-center gap-3 mt-3">
                          <img src={VideoBlueIcon} alt="VideoBlueIcon" />
                          <span className="text-black font-16 font-medium">
                            03
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6} md={12} lg={6} xl={4}>
                    <div className="gray-border">
                      <div className="relative">
                        <img
                          src={chatgallery1}
                          alt="chatgallery1"
                          className="w-full h-215px"
                        />
                        <img
                          src={ThumbnailUser1}
                          alt="ThumbnailUser1"
                          className="thumbnailuser1"
                        />
                      </div>
                      <div className="flex items-center justify-between gap-3 items-center">
                        <div className="flex items-center gap-3 mt-3">
                          <img src={VideoBlueIcon} alt="VideoBlueIcon" />
                          <span className="text-black font-16 font-medium">
                            03
                          </span>
                        </div>
                        <Button
                          variant="outlined"
                          className="mt-3 light-blue-icon-btn font-semibold text-primary"
                        >
                          A
                        </Button>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6} md={12} lg={6} xl={4}>
                    <div className="gray-border">
                      <div className="relative">
                        <img
                          src={chatgallery2}
                          alt="chatgallery2"
                          className="w-full h-215px"
                        />
                        <img
                          src={ThumbnailUser1}
                          alt="ThumbnailUser1"
                          className="thumbnailuser1"
                        />
                      </div>
                      <div className="flex items-center justify-between gap-3 items-center">
                        <div className="flex items-center gap-3 mt-3">
                          <img src={VideoBlueIcon} alt="VideoBlueIcon" />
                          <span className="text-black font-16 font-medium">
                            03
                          </span>
                        </div>
                        <Button
                          variant="outlined"
                          className="mt-3 light-blue-icon-btn font-semibold text-primary"
                        >
                          A
                        </Button>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6} md={12} lg={6} xl={4}>
                    <div className="gray-border">
                      <div className="relative profile-bg-white">
                        <img
                          src={MediaBg}
                          alt="MediaBg"
                          className="w-full h-215px"
                        />
                        <div className="profile-lock-icon text-center">
                          <Button variant="contained" className="white-btn">
                            <img
                              src={LockIcon}
                              alt="LockIcon"
                              height={40}
                              width={40}
                              className="img-dark-filter"
                            />
                          </Button>
                          <h5 className="font-16 font-medium text-darkblue my-2 md:my-4 lg:my-1 xl:my-4">
                            Unlock this Post
                          </h5>
                          <Button variant="contained" className="unlock-post">
                            Unlock for $40.50
                          </Button>
                        </div>
                      </div>
                      <div className="flex items-center justify-between gap-3 items-center">
                        <div className="flex items-center gap-3 mt-3">
                          <img src={VideoBlueIcon} alt="VideoBlueIcon" />
                          <span className="text-black font-16 font-medium">
                            03
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6} md={12} lg={6} xl={4}>
                    <div className="gray-border">
                      <div className="relative profile-bg-white">
                        <img
                          src={MediaBg}
                          alt="MediaBg"
                          className="w-full h-215px"
                        />
                        <div className="profile-lock-icon text-center">
                          <Button variant="contained" className="white-btn">
                            <img
                              src={LockIcon}
                              alt="LockIcon"
                              height={40}
                              width={40}
                              className="img-dark-filter"
                            />
                          </Button>
                          <h5 className="font-16 font-medium text-darkblue my-2 lg:my-1 xl:my-4">
                            Unlock this Post
                          </h5>
                          <Button variant="contained" className="unlock-post">
                            Unlock for $40.50
                          </Button>
                        </div>
                      </div>
                      <div className="flex items-center justify-between gap-3 items-center">
                        <div className="flex items-center gap-3 mt-3">
                          <img src={VideoBlueIcon} alt="VideoBlueIcon" />
                          <span className="text-black font-16 font-medium">
                            03
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6} md={12} lg={6} xl={4}>
                    <div className="gray-border">
                      <div className="relative">
                        <img
                          src={chatgallery3}
                          alt="chatgallery3"
                          className="w-full h-215px"
                        />
                        <img
                          src={ThumbnailUser1}
                          alt="ThumbnailUser1"
                          className="thumbnailuser1"
                        />
                      </div>
                      <div className="flex items-center justify-between gap-3 items-center">
                        <div className="flex items-center gap-3 mt-3">
                          <img src={VideoBlueIcon} alt="VideoBlueIcon" />
                          <span className="text-black font-16 font-medium">
                            03
                          </span>
                        </div>
                        <Button
                          variant="outlined"
                          className="mt-3 light-blue-icon-btn font-semibold text-primary"
                        >
                          A
                        </Button>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6} md={12} lg={6} xl={4}>
                    <div className="gray-border">
                      <div className="relative">
                        <img
                          src={chatgallery4}
                          alt="chatgallery4"
                          className="w-full h-215px"
                        />
                        <img
                          src={ThumbnailUser1}
                          alt="ThumbnailUser1"
                          className="thumbnailuser1"
                        />
                      </div>
                      <div className="flex items-center justify-between gap-3 items-center">
                        <div className="flex items-center gap-3 mt-3">
                          <img src={VideoBlueIcon} alt="VideoBlueIcon" />
                          <span className="text-black font-16 font-medium">
                            03
                          </span>
                        </div>
                        <Button
                          variant="outlined"
                          className="mt-3 light-blue-icon-btn font-semibold text-primary"
                        >
                          A
                        </Button>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6} md={12} lg={6} xl={4}>
                    <div className="gray-border">
                      <div className="relative">
                        <img
                          src={chatgallery5}
                          alt="chatgallery5"
                          className="w-full h-215px"
                        />
                        <img
                          src={ThumbnailUser1}
                          alt="ThumbnailUser1"
                          className="thumbnailuser1"
                        />
                      </div>
                      <div className="flex items-center justify-between gap-3 items-center">
                        <div className="flex items-center gap-3 mt-3">
                          <img src={VideoBlueIcon} alt="VideoBlueIcon" />
                          <span className="text-black font-16 font-medium">
                            03
                          </span>
                        </div>
                        <Button
                          variant="outlined"
                          className="mt-3 light-blue-icon-btn font-semibold text-primary"
                        >
                          A
                        </Button>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6} md={12} lg={6} xl={4}>
                    <div className="gray-border">
                      <div className="relative">
                        <img
                          src={Media1}
                          alt="Media1"
                          className="w-full h-215px"
                        />
                        <img
                          src={ThumbnailUser1}
                          alt="ThumbnailUser1"
                          className="thumbnailuser1"
                        />
                      </div>
                      <div className="flex items-center justify-between gap-3 items-center">
                        <div className="flex items-center gap-3 mt-3">
                          <img src={VideoBlueIcon} alt="VideoBlueIcon" />
                          <span className="text-black font-16 font-medium">
                            03
                          </span>
                        </div>
                        <Button
                          variant="outlined"
                          className="mt-3 light-blue-icon-btn font-semibold text-primary"
                        >
                          A
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>

            <TabPanel value="1" className="p-0 w-full text-center">
              <img
                src={SaveforlaterImage}
                alt="SaveforlaterImage"
                width={246}
                className="m-auto"
              />
              <p className="font-20 mt-3">Nothing found</p>
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </div>
  );
}

// export default ChatGalleryFans;
