export const notificationKeyFilterByType = (str, type) => {
  let regex;
  switch (type) {
    case "email":
      regex = /\bemail\S*/gi;
      break;
    case "push":
      regex = /\bpush\S*/gi;
      break;
    case "site":
      regex = /\bsite\S*/gi;
      break;
    default:
      regex = /\btoast\S*/gi;
      break;
  }
  // Find All keys starting with email
  return regex.test(str);
};
