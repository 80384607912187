import React, { useState } from "react";
import getMonthCount from "../../../../../utils/getMonthCount";
import { Button } from "@mui/material";
import { subscribeToPlanAction } from "../../../../../store/middlewares/web/subscriptions/subscriptions";
import { ExpandMore } from "@mui/icons-material";
import { SelectedPlanButton } from "../../../../atoms";
import { useDispatch } from "react-redux";

const UserProfileSubscription = ({
  user,
  basePlan,
  subscriptionBundleArray,
}) => {
  const [showDiscount, setShowDiscount] = useState(true);
  const dispatch = useDispatch()
  return (
    <>
      {!user?.isSubscribed && (
        <div
          className="flex flex-col gap-3 light-blue-bg px-0 mt-4 border-radius-10"
          style={{ height: "100%" }}
        >
          <div className="px-2 w-full">
            <Button
              variant="contained"
              onClick={() =>
                dispatch(
                  subscribeToPlanAction({
                    creatorId: user?.id,
                    planID: basePlan?.id,
                  })
                )
              }
              className="m-auto w-full min-h-40px w-full"
            >
              {basePlan?.planType === "free"
                ? "Subscribe For Free"
                : `Subscribe $${basePlan?.price} for 1 Months`}
            </Button>
          </div>
          {user?.hasExpired && (
            <>
              <div className="ticketRip w-full">
                <div className="circleLeft"></div>
                <div className="ripLine"></div>
                <div className="circleRight"></div>
              </div>

              <div className="flex justify-between gap-3 items-center p-0">
                <p
                  className="font-14 cursor-pointer"
                  // onClick={setOpenDialog2}
                >
                  Expired
                </p>
                <p className="font-14 font-semibold">Mar 11, 2024</p>
              </div>
            </>
          )}
        </div>
      )}

      {!user?.isSubscribed && (
        <>
          {subscriptionBundleArray?.length > 0 && (
            <div
              className="flex flex-col gap-3 light-blue-bg mt-4 border-radius-10 p-4 pb-2"
              style={{ height: "100%" }}
            >
              {showDiscount && (
                <>
                  {subscriptionBundleArray?.map((plan) => {
                    let cost =
                      plan.price * (1 - plan.discount / 100).toFixed(2);

                    return (
                      <Button
                        onClick={() =>
                          dispatch(
                            subscribeToPlanAction({
                              creatorId: user?.id,
                              planID: plan?.id,
                            })
                          )
                        }
                        variant="contained"
                        key={plan.id}
                        className="px-12 w-full min-h-40px mb-2"
                      >
                        {`Subscribe $${cost}  (${
                          plan.discount
                        }% Off) for ${getMonthCount(plan?.duration)} months`}
                      </Button>
                    );
                  })}
                </>
              )}
              <Button
                variant="text"
                onClick={() => setShowDiscount(!showDiscount)}
                className="p-0 mt-3 mb-2 text-center"
              >
                <p className="mb-0 font-14 font-semibold">
                  {showDiscount ? "Hide" : "Show"} Discounted Options{" "}
                  <ExpandMore className={showDiscount ? "rotate-180" : ""} />
                </p>
              </Button>
            </div>
          )}
        </>
      )}

      {user?.isSubscribed && (
        <SelectedPlanButton planId={user?.planId} userId={user?.id} />
      )}
    </>
  );
};

export default UserProfileSubscription;
