import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const { me } = useSelector((state) => state.me);
  const [darkMode, setDarkMode] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleToggle = () => {
    setDarkMode((prevMode) => !prevMode);
    setChecked((prevChecked) => !prevChecked);
    if (!darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  };

  useEffect(() => {
    setDarkMode(me?.isDarkMode);
    if (me?.isDarkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [me]);

  return (
    <ThemeContext.Provider
      value={{ darkMode, checked, setChecked, handleToggle }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
