import React, { lazy, useCallback, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { MainLayout } from "../newComponents/organisms";
import {
  createTheme,
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";
import { useTheme } from "../newComponents/atoms";
import AllSuggestions from "../pages/web/fanSide/AllSuggestions";
import CollectionsCreator from "../pages/web/creatorSide/UserList";
import { useDispatch, useSelector } from "react-redux";
import { validateTokenWeb } from "../store/middlewares/auth/session/auth";
import { getDecryptedToken } from "../utils/CryptoJS";

const NotFound = lazy(() => import("../pages/web/common/NotFound"));
const Login = lazy(() => import("../pages/auth/session/Login"));
const CreatorHomeScreen = lazy(() => import("../pages/web/creatorSide/Home"));
const CreatorProfileScreen = lazy(() =>
  import("../pages/web/creatorSide/Profile")
);
const CreatorSavedForLater = lazy(() =>
  import("../pages/web/creatorSide/SavedForLater")
);
const CreatorNoQueue = lazy(() => import("../pages/web/creatorSide/NoQueue"));
const CreatorVault = lazy(() => import("../pages/web/creatorSide/Vault"));
const CreatorPoll = lazy(() => import("../pages/web/creatorSide/Poll"));
const CreatorQuiz = lazy(() => import("../pages/web/creatorSide/Quiz"));
const CreatorFundraiser = lazy(() =>
  import("../pages/web/creatorSide/Fundraiser")
);
const CreatorTagOtherCreator = lazy(() =>
  import("../pages/web/creatorSide/TagOtherCreator")
);

const generatePath = (slug) => {
  return `/${slug}`;
};

const creatorRoutes = [
  {
    path: generatePath("home"),
    element: <CreatorHomeScreen />,
  },
  {
    path: generatePath("my-profile"),
    element: <CreatorProfileScreen />,
  },
  {
    path: generatePath("saved-for-later"),
    element: <CreatorSavedForLater />,
  },
  {
    path: generatePath("no-queue"),
    element: <CreatorNoQueue />,
  },
  {
    path: generatePath("vault"),
    element: <CreatorVault />,
  },
  {
    path: generatePath("poll"),
    element: <CreatorPoll />,
  },
  {
    path: generatePath("quiz"),
    element: <CreatorQuiz />,
  },
  {
    path: generatePath("fundraiser"),
    element: <CreatorFundraiser />,
  },
  {
    path: generatePath("tag-other-creator"),
    element: <CreatorTagOtherCreator />,
  },
  {
    path: generatePath("all-suggestions"),
    element: <AllSuggestions />,
  },
  {
    path: generatePath("user-lists"),
    element: <CollectionsCreator />,
  },
];

const CreatorNavigation = () => {
  useDocumentTitle("YouOnly");
  const { darkMode } = useTheme();
  const dispatch = useDispatch();
  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
    },
  });

  const { isTokenValid } = useSelector((state) => state.webauth);
  const checkToken = useCallback(() => {
    if (user) {
      dispatch(validateTokenWeb({token: getDecryptedToken(user?.token)}));
    }
  }, [dispatch, user]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  const user = JSON.parse(localStorage.getItem("user-details"));
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />

      <Routes>
        {/* if user is logged in redirect to dashboard */}
        <Route
          path=""
          element={!user || !isTokenValid ? <Login /> : <Navigate to="home" />}
        />
        {/* if user is not present show unauthorized ui */}
        <Route
          element={
            !user || !isTokenValid ? (
              <Navigate to="/" />
            ) : (
              <>
                {/* //Protection for when a User tries to access a Creator Page  */}
                {user?.userType === "user" ? (
                  <Navigate to="/user/home" />
                ) : (
                  <MainLayout type="creator" />
                )}
              </>
            )
          }
        >
          {creatorRoutes.map((route, index) => {
            return (
              <Route
                key={`${route.path}${index}`}
                path={route.path}
                element={route.element}
              />
            );
          })}
          {/* all undefined routes are handled here for admin */}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </MuiThemeProvider>
  );
};

export default CreatorNavigation;
