import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import TableContainer from "../../../atoms/admin/tablecomponet/TableContainer";
import AllPostsActionBar from "../../../../component/admin/TableActionBar/AllPosts";
import CustomLoader from "../../../atoms/admin/CustomLoader";
import { useSelector } from "react-redux";
import User1 from "../../../../assets/images/blank.png";

const UserPosts = () => {
  const { posts, postsLoading } = useSelector((state) => state.posts);
  const columns = React.useMemo(
    () => [
      {
        header: "Post Media",
        accessorKey: "postMedia",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => {
          return (
            <div className="flex flex-wrap gap-2 ">
              {row.original.postMedia
                .filter((media) => media.mediaType !== "text")
                .slice(0, 3)
                .map((media) => {
                  return (
                    <div key={media.fileURL} className="w-16 h-16">
                      <img
                        // eslint-disable-next-line no-undef
                        src={`${process.env.REACT_APP_AWS_URL}${media.fileURL}`}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  );
                })}
            </div>
          );
        },
      },
      {
        header: "Description",
        accessorKey: "description",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <Box className="flex items-center">
            <div
              dangerouslySetInnerHTML={{ __html: row.original.postType === "post" || row.original.postType === "quiz" ? `<p>${row.original.title}</p>`  :  row.original.description }}
            />
          </Box>
        ),
      },
      {
        header: "Created By",
        accessorKey: "users.name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <Box className="flex items-center gap-2">
            <Box className="w-16 h-16">
              <img
                src={
                  row.original.users.profileImage
                    ? // eslint-disable-next-line no-undef
                      `${process.env.REACT_APP_AWS_URL}${row.original.users.profileImage}`
                    : User1
                }
                className="w-full h-full object-cover rounded-full"
              />
            </Box>
            <Box className="flex flex-col gap-1">
              <Typography variant="body1">{row.original.users.name}</Typography>
              {row.original.users.username && (
                <Typography variant="p">
                  @{row.original.users.username}
                </Typography>
              )}
            </Box>
          </Box>
        ),
      },
      {
        header: "Created On",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => {
          const date = dayjs(row.original.createdAt).format("DD/MM/YYYY");
          return <Typography variant="p">{date}</Typography>;
        },
      },
      {
        header: "No. of Likes",
        accessorKey: "post_like",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => {
          return (
            <Typography variant="p">{row.original.post_like.length}</Typography>
          );
        },
      },
      {
        header: "No. of Comments",
        accessorKey: "post_like",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => {
          return (
            <Typography variant="p">
              {row.original.post_comment.length}
            </Typography>
          );
        },
      },
      {
        id: "action",
        header: <p className="text-right">Action</p>,
        enableColumnFilter: false,
        cell: ({ row }) => (
          <AllPostsActionBar row={row.original} id={row.original.id} />
        ),
      },
    ],
    []
  );
  return (
    <>
      {!postsLoading ? (
        <div className="card">
          <div className="card-body rounded-t-md border-b">
            <h6 className="text-lg">POSTS</h6>
          </div>
          <div className="card-body">
            {posts?.length > 0 ? (
              <TableContainer
                isPagination={true}
                isSelect={true}
                isGlobalFilter={true}
                columns={columns}
                data={posts}
                customPageSize={10}
                divclassName="my-2 col-span-12 overflow-x-auto lg:col-span-12"
                tableclassName="hover group dataTable w-full text-sm align-middle whitespace-nowrap no-footer"
                theadclassName="border-b border-slate-200"
                trclassName="transition-all duration-150 ease-linear group-[.hover]:hover:bg-slate-50 [&.selected]:bg-custom-500 [&.selected]:text-custom-50"
                thclassName="p-3 sorting px-3 py-4 text-slate-900 bg-slate-200/50 font-semibold text-left"
                tdclassName="p-3 w-[200px] min-w-[180px]"
                PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
              />
            ) : (
              <Typography variant={"body1"} align="center">
                No Posts To Display
              </Typography>
            )}
          </div>
        </div>
      ) : (
        <CustomLoader />
      )}
    </>
  );
};

export default UserPosts;
