import { Block, Delete, Visibility } from "@mui/icons-material";
import React from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  BanPostAdmin,
  DeletePostAdmin,
} from "../../../../store/middlewares/web/post/posts";
import { useDispatch } from "react-redux";

const AllPosts = ({ id, isDashboard = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Box className="flex h-full items-center justify-end gap-4">
      <Tooltip title="View" placement="top">
        <IconButton
          onClick={() => navigate(`/admin/posts/${id}`)}
        >
          <Visibility className="inline-block text-[#009ef7]" />
        </IconButton>
      </Tooltip>
      {!isDashboard && (
        <>
          <Tooltip title="Ban" placement="top">
            <IconButton
              onClick={() => {
                Swal.fire({
                  title: "Are you sure you want to Ban this Post?",
                  icon: "question",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes",
                  customClass: {
                    title: "Swal-title-class",
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    dispatch(BanPostAdmin({ postId: id }));
                  }
                });
              }}
            >
              <Block color="warning" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete" placement="top">
            <IconButton
              onClick={() => {
                Swal.fire({
                  title: "Are you sure you want to Delete this Post?",
                  icon: "question",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes",
                  customClass: {
                    title: "Swal-title-class",
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    dispatch(DeletePostAdmin({ postId: id, type: "delete" }));
                  }
                });
              }}
            >
              <Delete color="error" />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Box>
  );
};

export default AllPosts;
