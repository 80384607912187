import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

const HighlightsSlider = ({highlightsArray}) => {
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        navs: false,
        speed: 500,
        centerMode: false,
        slidesToShow: 5.9,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 300,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };


    return (
        <Slider className="creator-home-module py-4" {...settings}>
            {highlightsArray?.map((highlight, index) => {
                return (
                    <div key={index}>
                        <div className="relative">
                            <img
                                src={highlight?.userImgSrc}
                                alt="NoArticleUser1"
                                className="w-full"
                            />
                            <div className="thumbnail-text">
                                <div className="flex gap-2 thumbnail-block">
                                    {highlight?.thumbnailSrc && <img
                                        src={highlight?.thumbnailSrc}
                                        alt={`thumbnail-${index}`}
                                        className="thumbnailuser1"
                                    />}
                                    {highlight?.description && <div className="flex flex-col items-center justify-center text-white">
                                        <h3>{highlight?.description}</h3>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </Slider>
    )
}

export default HighlightsSlider
