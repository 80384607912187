import React from 'react'

const MyProfileIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M12.12 13.28C12.05 13.27 11.96 13.27 11.88 13.28C10.12 13.22 8.71997 11.78 8.71997 10.01C8.71997 8.19998 10.18 6.72998 12 6.72998C13.81 6.72998 15.28 8.19998 15.28 10.01C15.27 11.78 13.88 13.22 12.12 13.28Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
            <path opacity="0.4" d="M18.74 19.8801C16.96 21.5101 14.6 22.5001 12 22.5001C9.40001 22.5001 7.04001 21.5101 5.26001 19.8801C5.36001 18.9401 5.96001 18.0201 7.03001 17.3001C9.77001 15.4801 14.25 15.4801 16.97 17.3001C18.04 18.0201 18.64 18.9401 18.74 19.8801Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
        </svg>
    )
}

export default MyProfileIcon
