import { ArrowCircleLeftOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const BreadCrumb = ({ title, pageTitle, showBackButton = true }) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="flex md:flex-row  gap-2 py-4 md:flex-row md:items-center print:hidden">
        <div className={`${showBackButton ? "flex items-center" : ""} grow`}>
          {showBackButton && (
            <IconButton onClick={() => navigate(-1)}>
              <ArrowCircleLeftOutlined />
            </IconButton>
          )}
          <h5 className="text-16 font-bold " style={{ color: "black" }}>
            {title}
          </h5>
        </div>
        <ul className="flex items-center gap-2 text-sm font-normal shrink-0">
          <li className="relative before:content-['\ea54'] before:font-remix before:-right-1 before:absolute before:text-[18px] before:-top-[3px] pr-4 before:text-slate-400 ">
            <Link to="/admin/dashboard" className="text-slate-400 ">
              {pageTitle}
            </Link>
          </li>
          <li className="text-slate-700 ">{title}</li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default BreadCrumb;
