import { createSlice } from "@reduxjs/toolkit";
import { getMediaUserWiseAction } from "../../../middlewares/web/media/media";

export const mediaSlice = createSlice({
  name: "media",
  initialState: {
    mediaLoading: false,
    media: [],
  },
  extraReducers(builder) {
    builder
    //Get User Wise Media 
      .addCase(getMediaUserWiseAction.pending, (state) => {
        state.mediaLoading = true;
        state.media = [];
      })
      .addCase(getMediaUserWiseAction.fulfilled, (state, action) => {
        state.mediaLoading = false;
        state.media = action?.payload?.response;
      })
      .addCase(getMediaUserWiseAction.rejected, (state) => {
        state.mediaLoading = false;
        state.media = [];
      });
  },
});

export default mediaSlice.reducer;
