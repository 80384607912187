import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMediaUserWise } from "../../../services/web/media/media.service";

export const getMediaUserWiseAction = createAsyncThunk("media/getMediaUserWise", async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await getMediaUserWise(payload);
        if (response.status === 1) {
            // if any async operation need to be done on response data, do it here,
            // then full fill data
            return fulfillWithValue(response);
        }
        return rejectWithValue({ message: response?.message });
    } catch (error) {
        return rejectWithValue(error);
    }
})