import React from 'react'
import { AddCommentTextArea, SingleComment } from '../../../../atoms';

const CommentsSection = ({ post_comment, postId, userEmail, isRestricted }) => {
    //comment section of a post
    
    return (
        <div className='read-comment mt-2 bg-white flex flex-col divide-y'>
            <h3 className='font-18 comment-title bg-white text-darkblue'>Comments</h3>
            {post_comment?.map((comment) => {
                return (
                    <SingleComment comment={comment} handleEdit={() => {}} postId={postId} userEmail={userEmail} key={comment?.id}/>
                )
            })}
            {!isRestricted && <AddCommentTextArea postId={postId} />}
        </div>
    )
}

export default CommentsSection
