import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import BlueArrowIcon from "./../../../../../assets/images/blue-arrow.svg";
import { useDispatch } from "react-redux";
import CloseSquareWhite from "./../../../../../assets/images/close-square-white.svg";
import GreenVerifyIcon from "./../../../../../assets/images/green-verify.svg";
import User1 from "./../../../../../assets/images/blank.png";
import { subscribeToPlanAction } from "../../../../../store/middlewares/web/subscriptions/subscriptions";
import { closeModal } from "../../../../../store/slices/common/modal";
import getMonthCount from "../../../../../utils/getMonthCount";

const ModalSubscribeToPlan = ({ user, basePlan, subscriptionBundle }) => {
  const dispatch = useDispatch();
  return (
    <div className="subscription-plan">
      <div className="relative">
        <IconButton className="close-icon" color="primary">
          <img
            src={CloseSquareWhite}
            alt="CloseSquareWhite"
            height={26}
            width={26}
            onClick={() => dispatch(closeModal())}
          />
        </IconButton>
        <div className="h-[300px]">
          <img
            src={
              user?.coverImg
                ? // eslint-disable-next-line no-undef
                  `${process.env.REACT_APP_AWS_URL}${user?.coverImg}`
                : User1
            }
            alt={user?.name}
            className="rounded-2xl w-full h-full object-cover"
          />
        </div>
      </div>
      <div className="sub-plan-bg px-5 ">
        <div className="plan-height">
          <div className="bg-primary">
            <div className="flex flex-wrap items-center justify-between gap-3">
              <div className="flex items-center gap-3">
                <div
                  style={{
                    height: "55px",
                    width: "55px",
                  }}
                >
                  <img
                    src={user?.profileImage ? user?.profileImage : User1}
                    alt={user?.name}
                    className="w-full h-full max-h-[55px] plan-user"
                  />
                </div>
                <div>
                  <p className="font-22 font-semibold flex flex-wrap gap-2">
                    {user?.name}{" "}
                    {user?.isVerified && (
                      <img src={GreenVerifyIcon} alt="GreenVerifyIcon" />
                    )}
                  </p>
                  {user?.username && (
                    <p className="font-14 font-medium">@{user?.username}</p>
                  )}
                </div>
              </div>
              {!basePlan?.price && (
                <p className="font-18 mr-3">
                  <span className="font-40 font-bold">Free</span>
                </p>
              )}
              {basePlan?.price && (
                <p className="font-18 mr-3">
                  <span className="font-40 font-bold">${basePlan.price}</span>
                  /Per Month
                </p>
              )}
            </div>
          </div>

          {basePlan?.price && (
            <p className="font-20 my-4">
              This subscription renews at ${basePlan.price}.{" "}
              <Link href="#" className="font-semibold">
                Renewal Info
              </Link>
            </p>
          )}
          {!basePlan?.price && (
            <p className="font-20 my-4">
              This subscription renews at $0.{" "}
              <Link href="#" className="font-semibold">
                Renewal Info
              </Link>
            </p>
          )}

          <Button
            variant="contained"
            className="w-full m-auto px-12"
            onClick={() =>
              dispatch(
                subscribeToPlanAction({
                  creatorId: user?.id,
                  planID: basePlan?.id,
                })
              )
            }
          >
            Subscribe
          </Button>

          <p className="text-darkblue font-20 font-medium pb-2 border-b mt-8 mb-3">
            Subscribe and Get These Benefits.
          </p>

          <ul>
            <li className="text-primary font-medium flex gap-3 mb-3">
              <img src={BlueArrowIcon} alt="BlueArrowIcon" />
              <span>Full access to this user’s content</span>
            </li>
            <li className="text-primary font-medium flex gap-3 mb-3">
              <img src={BlueArrowIcon} alt="BlueArrowIcon" />
              <span>Direct message with this user</span>
            </li>
            <li className="text-primary font-medium flex gap-3 mb-3">
              <img src={BlueArrowIcon} alt="BlueArrowIcon" />
              <span>Cancel your subscription at any time</span>
            </li>
            <li className="text-primary font-medium flex gap-3 mb-3">
              <img src={BlueArrowIcon} alt="BlueArrowIcon" />
              <span>Livestream Chat</span>
            </li>
          </ul>

          <Accordion
            sx={{
              border: "none !important",
              boxShadow: "none",
              borderBottom: "1px solid #ECECEC",
            }}
          >
            <AccordionSummary
              className="mb-0 items-center"
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div>
                <p className="mb-0 font-14 font-semibold">
                  Show Discounted Options
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex flex-col gap-3 light-blue-bg mt-4 border-radius-10 p-4">
                {subscriptionBundle?.map((plan) => {
                  let cost = plan.price * (1 - plan.discount / 100).toFixed(2);
                  return (
                    <Button
                      key={plan?.id}
                      variant="contained"
                      onClick={() =>
                        dispatch(
                          subscribeToPlanAction({
                            creatorId: user?.id,
                            planID: plan?.id,
                          })
                        )
                      }
                      className="px-12 w-full min-h-40px mb-2"
                    >
                      {`${getMonthCount(plan?.duration)} months (${
                        plan.discount
                      }% Off) $${cost} Total`}
                    </Button>
                  );
                })}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default ModalSubscribeToPlan;
