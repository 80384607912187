import {
  addCountryCode,
  deleteCountryCode,
  getAllCountryCode,
  updateCountryCode,
} from "../../../services/admin/countryCode/countryCode.js";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { openToast } from "../../../slices/common/toast/index.js";
import { closeAdminModal } from "../../../slices/common/adminModal";

export const GetAllCountryCodeAction = createAsyncThunk(
  "countryCode/getAllCountryCode",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getAllCountryCode(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const EditCountryCodeAction = createAsyncThunk(
  "countryCode/editCountryCode",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await updateCountryCode(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const AddCountryCodeAction = createAsyncThunk(
  "countryCode/addCountryCode",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await addCountryCode(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal())
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const RemoveCountryCodeAction = createAsyncThunk(
  "countryCode/removeCountryCode",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await deleteCountryCode(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue({ ...response, id: payload.id });
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);
