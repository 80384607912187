import { openToast } from "../../../slices/common/toast/index.js";
import { editCms, getSingleCMS } from "./../../../services/admin/cms/cms.js";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const GetSingleCMSAction = createAsyncThunk(
  "cms/getSingleCMS",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getSingleCMS(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const EditCMSAction = createAsyncThunk(
  "cms/editCMS",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await editCms(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);
