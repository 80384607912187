import React from "react"
import AddCardsIcon from "../assets/SVG/SideBar/AddCardsIcon"
import BookMarksIcon from "../assets/SVG/SideBar/BookMarksIcon"
import ExplorePageIcon from "../assets/SVG/SideBar/ExplorePageIcon"
import HomeIcon from "../assets/SVG/SideBar/HomeIcon"
import LiveStreamIcon from "../assets/SVG/SideBar/LiveStreamIcon"
import MessagesIcon from "../assets/SVG/SideBar/MessagesIcon"
import MoreIcon from "../assets/SVG/SideBar/MoreIcon"
import MyOrderIcon from "../assets/SVG/SideBar/MyOrderIcon"
import MyProfileIcon from "../assets/SVG/SideBar/MyProfileIcon"
import NotificationIcon from "../assets/SVG/SideBar/NotificationIcon"
import SubscriptionsIcon from "../assets/SVG/SideBar/SubscriptionsIcon"
import UserListsIcon from "../assets/SVG/SideBar/UserListsIcon"
import WishRequestsIcon from "../assets/SVG/SideBar/WishRequestsIcon"

const sideBarItems = [
    {
        "icon": <HomeIcon />,
        "slug": "home",
        "hasPage": true,
        "label": "Home",
        "count": 0,
    },
    {
        "icon": <ExplorePageIcon />,
        "slug": "explore-page",
        "hasPage": true,
        "label": "Explore Page",
        "count": 0
    },
    {
        "icon": <NotificationIcon />,
        "slug": "notifications",
        "hasPage": true,
        "label": "Notification",
        "count": 3
    },
    {
        "icon": <WishRequestsIcon />,
        "slug": "wish-requests",
        "hasPage": false,
        "label": "Wish Requests",
        "count": 3
    },
    {
        "icon": <LiveStreamIcon />,
        "slug": "live-stream",
        "hasPage": true,
        "label": "Livestream",
        "count": 0
    },
    {
        "icon": <MyOrderIcon />,
        "slug": "my-order",
        "hasPage": true,
        "label": "My Order",
        "count": 2
    },
    {
        "icon": <MessagesIcon />,
        "slug": "messages",
        "hasPage": true,
        "label": "Messages",
        "count": 0
    },
    {
        "icon": <UserListsIcon />,
        "slug": "user-lists",
        "hasPage": true,
        "label": "User Lists",
        "count": 0
    },
    {
        "icon": <BookMarksIcon />,
        "slug": "bookmarks",
        "hasPage": true,
        "label": "Bookmarks",
        "count": 0
    },
    {
        "icon": <SubscriptionsIcon />,
        "slug": "subscriptions",
        "hasPage": true,
        "label": "Subscriptions",
        "count": 0
    },
    {
        "icon": <AddCardsIcon />,
        "slug": "payments",
        "hasPage": true,
        "label": "Add Card",
        "count": 0
    },
    {
        "icon": <MyProfileIcon />,
        "slug": "my-profile",
        "hasPage": true,
        "label": "My Profile",
        "count": 0
    },
    {
        "icon": <MoreIcon />,
        "slug": "more",
        "hasPage": false,
        "label": "More",
        "count": 0
    },
]

export default sideBarItems