/* eslint-disable no-undef */
import { Delete } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UserBlank from './../../../assets/images/blank.png';
import { removeCommentsAction } from '../../../store/middlewares/web/post/posts';
import { NavLink } from 'react-router-dom';

const SingleComment = ({ comment, postId, userEmail }) => {
    //Single Comment with Delete Feature
    const dispatch = useDispatch()

    const handleDelete = (id) => {
        //Delete API Call
        dispatch(removeCommentsAction({ commentId: id, postId }))
    }

    const { me } = useSelector((state) => state.me)

    const canDelete = (comment?.userId === me?.id) || (userEmail === me?.email)
    // const canEdit = (comment?.userId === me?.id)

    return (
        <div className={"flex gap-3 items-start py-2 bg-white"} key={comment?.id}>
            <Box className="min-h-[44px] h-[44px] min-w-[44px] w-[44px] mr-1">
                <img src={comment?.profileImage ? `${process.env.REACT_APP_AWS_URL}${comment?.profileImage}` : UserBlank} alt={comment?.name} className='w-full h-full rounded-[50%] object-cover' />
            </Box>
            <div className='flex flex-col grow'>
                <NavLink className='comment-username cursor-pointer' to={comment?.userId === me?.id ? "/user/my-profile" : `/user/profile/${comment?.userId}`}><strong>{comment?.name}</strong></NavLink>
                <div className='comment-text text-black' dangerouslySetInnerHTML={{ __html: comment?.comment }} />
            </div>
            {canDelete && <IconButton onClick={() => { handleDelete(comment?.id) }}><Delete color='error' /> </IconButton>}
        </div>
    )
}

export default SingleComment
