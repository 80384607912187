import UserImg5 from './../assets/images/user5.png';
import UserImg6 from './../assets/images/user6.png';
import UserImg7 from './../assets/images/user7.png';
import UserImg8 from './../assets/images/user8.png';
import UserImg9 from './../assets/images/user9.png';
import User1 from './../assets/images/user1.png';

const homeStoriesArray = [
    {
        "bgImageSrc": UserImg5,
        "userImageSrc": User1,
        "username": "Alison"
    },
    {
        "bgImageSrc": UserImg6,
        "userImageSrc": User1,
        "username": "Alison"
    },
    {
        "bgImageSrc": UserImg7,
        "userImageSrc": User1,
        "username": "Alison"
    },
    {
        "bgImageSrc": UserImg8,
        "userImageSrc": User1,
        "username": "Alison"
    },
    {
        "bgImageSrc": UserImg9,
        "userImageSrc": User1,
        "username": "Alison"
    },
    {
        "bgImageSrc": UserImg5,
        "userImageSrc": User1,
        "username": "Alison"
    },
    {
        "bgImageSrc": UserImg5,
        "userImageSrc": User1,
        "username": "Alison"
    },
]

export default homeStoriesArray