import React, { useEffect, useState } from "react";
import Sidebar from "./VerticalLayout/SidebarAdmin";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import {
  changeLayout,
  changeLeftsidebarSizeType,
} from "../../../store/slices/common/layout";

const Layout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const { layoutType, layoutSidebarSizeType } = useSelector(
    (state) => state.layout
  );

  const [show, setShow] = useState(false);
  const handleDrawer = () => setShow(!show);

  const handleToggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (layoutType || layoutSidebarSizeType) {
      window.dispatchEvent(new Event("resize"));
      dispatch(changeLayout(layoutType));
      dispatch(changeLeftsidebarSizeType(layoutSidebarSizeType));
    }
  }, [layoutType, layoutSidebarSizeType, dispatch]);

  useEffect(() => {
    document.documentElement.classList.add("scroll-smooth", "group");
    document.body.classList.add(
      "text-base",
      "bg-body-bg",
      "text-body",
      "font-public",
    );
    return () => {
      document.documentElement.classList.remove("scroll-smooth", "group");
      document.body.classList.remove(
        "text-base",
        "bg-body-bg",
        "text-body",
        "font-public",
      );
    };
  }, []);

  return (
    <div className="group-data-[sidebar-size=sm]:min-h-sm group-data-[sidebar-size=sm]:relative">
      <Sidebar
        layoutType={layoutType}
        layoutSidebarSizeType={layoutSidebarSizeType}
      />
      <Header
        handleToggleDrawer={handleToggleDrawer}
        handleDrawer={handleDrawer}
      />
      <div className="relative min-h-screen group-data-[sidebar-size=sm]:min-h-sm">
        <div
          className="relative group-data-[sidebar-size=lg]:md:ml-vertical-menu group-data-[sidebar-size=md]:ml-vertical-menu-md group-data-[sidebar-size=sm]:ml-vertical-menu-sm pt-[calc(theme('spacing.header')_*_1)] pb-[calc(theme('spacing.header')_*_0.8)] px-4 "
        >
          <div className="container-fluid mx-auto">
            <Outlet />
          </div>
          {/* TODO:  <ContainerLoadding /> */}
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Layout;
