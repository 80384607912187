import React from 'react'
import NotifyUser2 from './../../../../../assets/images/notification/notifyuser2.png';
import { MentionNotificationItem } from '../../../../atoms';

const MentionNotifications = () => {
    //Notification in Mention Tab
    return (
        <>
            <MentionNotificationItem userProfileImage={NotifyUser2} name={"Maria Lubin"} username={"bluntbarble"} replyToUsername={"Leo"} replyText={"Hahha"} date={"March 7"} isVerified={true} />
            <MentionNotificationItem userProfileImage={NotifyUser2} name={"Maria Lubin"} username={"bluntbarble"} replyToUsername={"Leo"} replyText={"Hahha"} date={"March 7"} isVerified={true} />
        </>
    )
}

export default MentionNotifications
