import * as Yup from "yup";
import { spaceRegExp } from "../regex";


export const Password = Yup.string()
    .min(6, "Password should be at least 6 characters long").matches(spaceRegExp, "Password cannot contain spaces")
    .max(50, "Password should not contain more than 50 characters")
    .required("Password is Required");

export const newPassword = Yup.string()
    .oneOf([Yup.ref('oldPassword')], 'Old Password And New Password do not match!').matches(spaceRegExp, "Password cannot contain spaces")
    .required("New Password is Required");