import React from "react";
import "./assets/styles/darkmode.scss";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import "@fontsource/poppins";
import "./assets/scss/themes.scss";
import ToastWrapper from "./newComponents/atoms/ToastWrapper";
import { AdminModal, Modal, ThemeProvider } from "./newComponents/atoms";
import UserNavigation from "./navigation/userNavigation";
import CreatorNavigation from "./navigation/creatorNavigation";
import WebNavigation from "./navigation/webNavigation";
import AdminNavigation from "./navigation/adminNavigation";

const App = () => {
  return (
    <>
      <Modal />
      <AdminModal />
      <ToastWrapper />
      <Routes>
        <Route
          path="/user/*"
          element={
            <ThemeProvider>
              <UserNavigation />
            </ThemeProvider>
          }
        />
        <Route
          path="/creator/*"
          element={
            <ThemeProvider>
              <CreatorNavigation />
            </ThemeProvider>
          }
        />
        <Route
          path="/*"
          element={
            <ThemeProvider>
              <WebNavigation />
            </ThemeProvider>
          }
        />
        <Route path="/admin/*" element={<AdminNavigation />} />
      </Routes>
    </>
  );
};

export default App;
