import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Drawer,
    FormControl,
    FormControlLabel,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Radio, RadioGroup,
    Select,
    Tab, Checkbox,
    TextField,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Masonry from "@mui/lab/Masonry";
import { Link } from "react-router-dom";
import AvatarGroup from "@mui/material/AvatarGroup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircleIcon from "@mui/icons-material/Circle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import ArrowLeft from "./../../../../../assets/images/arrow-left.svg";
import SearchIcon from "./../../../../../assets/images/search-normal.svg";
import PlusCircleIcon from "./../../../../../assets/images/add-circle.svg";
import ScreenMirroringIcon from "./../../../../../assets/images/screenmirroring.svg";
import ActiveUserIcon from "./../../../../../assets/images/active-user.svg";
import TimerIcon from "./../../../../../assets/images/timer.svg";
import BlockedUserIcon from "./../../../../../assets/images/blockuserIcon.svg";
import GridBlackIcon from './../../../../../assets/images/grid-black.svg';
import GridBlackIcon2 from './../../../../../assets/images/grid-4.svg';
import GalleryBlueIcon from "./../../../../../assets/images/gallery-blue.svg";
import Archive8Icon from "./../../../../../assets/images/archive8.png";
import Archive9Icon from "./../../../../../assets/images/archive9.png";
import Archive10Icon from "./../../../../../assets/images/archive10.png";
import Archive11Icon from "./../../../../../assets/images/archive11.png";
import Archive12Icon from "./../../../../../assets/images/archive12.png";
import VideoPlayIcon from "./../../../../../assets/images/video-play.svg";
import VideoBlueIcon from "./../../../../../assets/images/video-blue.svg";
import AudioSquareBlueIcon from "./../../../../../assets/images/audio-square-blue.svg";
import LockBlueIcon from "./../../../../../assets/images/lock-blue.svg";
import MoreSquare from "./../../../../../assets/images/more-square.svg";
import ProfilePostImg1 from "./../../../../../assets/images/post-profile-img1.png";
import HeartOutlineIcon from "./../../../../../assets/images/heart-outline.svg";
import ArchiveTick from "./../../../../../assets/images/archive-tick.svg";
import Gallery from "./../../../../../assets/images/gallery.svg";
import Microphone from "./../../../../../assets/images/microphone-2.svg";
import SaveForLaterImg from "./../../../../../assets/images/saveforlater.png";
import listmenublack from './../../../../../assets/images/listmenublack.svg';
import MessagesIcon from './../../../../../assets/images/sidebar/messages.svg';
import SettingsIcon from './../../../../../assets/images/setting-5.svg';
import CustomerImg1 from "./../../../../../assets/images/collections/customer1.png";
import CustomerImg2 from "./../../../../../assets/images/collections/customer2.png";
import CustomerImg3 from "./../../../../../assets/images/collections/customer3.png";
import starIcon from "./../../../../../assets/images/star.svg";
import ChatBlueIcon from "./../../../../../assets/images/chat-icon-blue.svg";
import ShareIcon from "./../../../../../assets/images/share-black.svg";
import dollarIcon from "./../../../../../assets/images/dollar-square-blue.svg";
import More from "./../../../../../assets/images/more-square.svg";
import GreenVerifyIcon from "./../../../../../assets/images/green-verify.svg";
import OfferTag from "./../../../../../assets/images/offer-tag.png";
import CollectionPostBg from "./../../../../../assets/images/collections/collectionimg4.png";
import CollectionPostBg2 from "./../../../../../assets/images/collections/collectionimg5.png";
import CollectionPostBg3 from "./../../../../../assets/images/collections/collectionimg6.png";
import CollectionPostBg4 from "./../../../../../assets/images/collections/collectionimg7.png";
import ColeUser from "./../../../../../assets/images/cole-user.png";
import Verified from "./../../../../../assets/images/verified.svg";
import BlueCircleBg from "./../../../../../assets/images/blue-round-bg.png";
import VerifyWhiteIcon from "./../../../../../assets/images/verify-white.svg";
import VisaIcon from "./../../../../../assets/images/visa.svg";
import ApplePayIcon from "./../../../../../assets/images/apple-pay.svg";
import GPayIcon from "./../../../../../assets/images/gpay.svg";
import User1 from "./../../../../../assets/images/user1.png";
import DollarIcon from "./../../../../../assets/images/dollar-square.svg";
import IndiaIcon from "./../../../../../assets/images/language/Hindi.svg";
import InfoCircleBlue from "./../../../../../assets/images/info-circle-blue.svg";
import MasterIcon from "./../../../../../assets/images/master.svg";
import MaestroIcon from "./../../../../../assets/images/maestro.svg";
import DinearsClubIcon from "./../../../../../assets/images/dinears-club.svg";
import JCBIcon from "./../../../../../assets/images/jcb.svg";
import CancelSubscription from './../../../../../assets/images/cancel-subscription.png';

const PostsTab = () => {
    const [value, setValue] = React.useState("0");
    const [age, setAge] = React.useState('');

    const [anchorEl, setAnchorEl] = React.useState(null);
    // const open = Boolean(anchorEl);

    const [selectedValue, setSelectedValue] = useState("all0");
    const [selectedTab, setSelectedTab] = useState('all0');
    const isOpen = Boolean(anchorEl);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setChecked(event.target.checked);
        setSelectedValue(newValue);
        setSelectedTab(newValue);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const [anchorEl1, setAnchorEl1] = useState(null);
    const isOpen1 = Boolean(anchorEl1);

    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setIsDrawerOpen(open);
    };

    // const getBackgroundColor = () => {
    //     switch (selectedValue) {
    //         case "option1":
    //             return "lightblue";
    //         case "option2":
    //             return "lightgreen";
    //         case "option3":
    //             return "lightcoral";
    //         default:
    //             return "white";
    //     }
    // };

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);

    const [checked, setChecked] = React.useState(true);

    const [openDialog1, setOpenDialog1] = useState(false);
    const [openDialog2, setOpenDialog2] = useState(false);

    const handleOpenDialog1 = () => {
        setOpenDialog1(true);
    };

    const handleCloseDialog1 = () => {
        setOpenDialog1(false);
    };
    const handleOpenDialog2 = () => {
        setOpenDialog2(true);
    };

    const handleCloseDialog2 = () => {
        setOpenDialog2(false);
    };

    // For grid view post
    const [gridview, setgridview] = useState(false)
    const handleGridviewPost = () => {
        setgridview(!gridview)
    }

    return (
        <div className="h-queue fans-profile">
            <div className="post-tab">
                <TabContext value={selectedTab}>
                    <div className="bg-white rounded-2xl p-3 mt-5 w-full mb-3">
                        <div className="mb-4 recent-txtbox">
                            <FormControl className="w-full" variant="outlined">
                                <OutlinedInput
                                    placeholder="Recent"
                                    id="outlined-adornment-weight"
                                    endAdornment={
                                        <InputAdornment position="end" className="flex gap-3">
                                            <img
                                                src={!gridview ? GridBlackIcon : GridBlackIcon2}
                                                alt="GridBlackIcon"
                                                className="img-dark-filter"
                                                onClick={handleGridviewPost}
                                            />

                                            <img
                                                src={listmenublack}
                                                alt="List Menu"
                                                className="pr-4 img-dark-filter"
                                                onClick={handleClick1}
                                            />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>

                            <Menu
                                anchorEl={anchorEl1}
                                id="video-menu"
                                open={isOpen1}
                                onClose={handleClose1}
                                onClick={handleClose1}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: "visible",
                                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.08))",
                                        mt: 1.5,
                                        "& .MuiAvatar-root": {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        "&::before": {
                                            content: '""',
                                            display: "block",
                                            position: "absolute",
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: "background.paper",
                                            transform: "translateY(-50%) rotate(45deg)",
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{
                                    horizontal: "right",
                                    vertical: "top",
                                }}
                                anchorOrigin={{
                                    horizontal: "right",
                                    vertical: "bottom",
                                }}
                            >
                                <MenuItem onClick={handleClose}>
                                    <FormControlLabel
                                        value="Recent"
                                        control={<Radio />}
                                        label="Recent"
                                    />
                                </MenuItem>
                                <MenuItem>
                                    <FormControlLabel
                                        value="Current Subscription"
                                        control={<Radio />}
                                        label="Current Subscription"
                                    />
                                </MenuItem>
                                <MenuItem>
                                    <FormControlLabel
                                        value="Started"
                                        control={<Radio />}
                                        label="Started"
                                    />
                                </MenuItem>
                                <div className="mx-2">
                                    <Divider className="w-full my-1" />
                                </div>
                                <MenuItem>
                                    <FormControlLabel
                                        value="Ascending"
                                        control={<Radio />}
                                        label="Ascending"
                                    />
                                </MenuItem>
                                <MenuItem>
                                    <FormControlLabel
                                        value="Descending"
                                        control={<Radio />}
                                        label="Descending"
                                    />
                                </MenuItem>
                            </Menu>
                        </div>

                        <Box className="mb-4">
                            <TabList
                                onChange={handleChange}
                                aria-label="simple tabs example"
                                className="flex-wrap"
                            >
                                <Tab
                                    icon={
                                        <img
                                            src={ScreenMirroringIcon}
                                            alt="ScreenMirroringIcon"
                                        />
                                    }
                                    label="All (535)"
                                    value="all0"
                                />
                                <Tab
                                    icon={
                                        <img src={GalleryBlueIcon} alt="GalleryBlueIcon" />
                                    }
                                    label="Photo (452)"
                                    value="photo1"
                                />
                                <Tab
                                    icon={
                                        <img
                                            src={VideoBlueIcon}
                                            alt="VideoBlueIcon"
                                            width={24}
                                        />
                                    }
                                    label="Video (83)"
                                    value="video2"
                                />
                                <Tab
                                    icon={
                                        <img
                                            src={AudioSquareBlueIcon}
                                            alt="AudioSquareBlueIcon"
                                        />
                                    }
                                    label="Audio (3)"
                                    value="audio3"
                                />
                                <Tab
                                    icon={
                                        <img
                                            src={ScreenMirroringIcon}
                                            alt="ScreenMirroringIcon"
                                        />
                                    }
                                    label="Other (54)"
                                    value="other4"
                                />
                                <Tab
                                    icon={<img src={LockBlueIcon} alt="LockBlueIcon" />}
                                    label="Locked (33)"
                                    value="locaked5"
                                />
                            </TabList>
                        </Box>

                        <TabPanel value="all0" className="p-0 w-full profile-post">
                            {!gridview ?
                                <>
                                    <Grid
                                        container
                                        spacing={1}
                                        className="h-full mb-4 fans-message"
                                    >
                                        <Grid item xs={12} md={12} lg={8}>
                                            <div className="light-gray-bg lg:mr-4">

                                                <div className="bg-white rounded-2xl p-4 mt-4 creator-home-module">
                                                    <div className="flex flex-wrap justify-between items-center">
                                                        <div className="flex gap-5 varified">
                                                            <div className="relative">
                                                                <img src={ColeUser} alt="ColeUser" className="hw-60px" />
                                                                <div className="verified-bg">
                                                                    <img src={Verified} alt="Verified" />
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-col justify-center">
                                                                <h3>Cole</h3>
                                                                <p>@colejanson</p>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-wrap items-center gap-3">
                                                            <p className="font-medium font-14 text-black">6 hours ago</p>

                                                            <IconButton
                                                                onClick={handleClick}
                                                                size="small"
                                                                aria-controls={open ? "more-detail" : undefined}
                                                                aria-haspopup="true"
                                                                aria-expanded={open ? "true" : undefined}
                                                            >
                                                                <img src={MoreSquare} alt="MoreSquare" width={24} className="img-dark-filter" />
                                                            </IconButton>

                                                            <Menu
                                                                anchorEl={anchorEl}
                                                                id="more-detail"
                                                                open={open}
                                                                className="radiobtn-menu"
                                                                onClose={handleClose}
                                                                onClick={handleClose}
                                                                PaperProps={{
                                                                    elevation: 0,
                                                                    sx: {
                                                                        overflow: "visible",
                                                                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.08))",
                                                                        mt: 1.5,
                                                                        "& .MuiAvatar-root": {
                                                                            width: 32,
                                                                            height: 32,
                                                                            ml: -0.5,
                                                                            mr: 1,
                                                                        },
                                                                        "&::before": {
                                                                            content: '""',
                                                                            display: "block",
                                                                            position: "absolute",
                                                                            top: 0,
                                                                            right: 14,
                                                                            width: 10,
                                                                            height: 10,
                                                                            bgcolor: "background.paper",
                                                                            transform: "translateY(-50%) rotate(45deg)",
                                                                            zIndex: 0,
                                                                        },
                                                                    },
                                                                }}
                                                                transformOrigin={{ horizontal: "right", vertical: "top" }}
                                                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                                                            >
                                                                <MenuItem onClick={handleClose}>
                                                                    <RadioGroup
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        defaultValue="female"
                                                                        name="radio-buttons-group"
                                                                    >
                                                                        <FormControlLabel
                                                                            value="female"
                                                                            control={<Radio />}
                                                                            label="All Time"
                                                                        />
                                                                        <FormControlLabel
                                                                            value="male"
                                                                            control={<Radio />}
                                                                            label="Go to Date"
                                                                        />
                                                                    </RadioGroup>
                                                                    <Divider className="w-full" />
                                                                    <RadioGroup
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        defaultValue="female"
                                                                        name="radio-buttons-group"
                                                                    >
                                                                        <FormControlLabel
                                                                            value="female"
                                                                            control={<Radio />}
                                                                            label="Latest Posts"
                                                                        />
                                                                        <FormControlLabel
                                                                            value="male"
                                                                            control={<Radio />}
                                                                            label="Most Liked"
                                                                        />
                                                                        <FormControlLabel
                                                                            value="male"
                                                                            control={<Radio />}
                                                                            label="Highest Tips"
                                                                        />
                                                                    </RadioGroup>
                                                                    <Divider className="w-full" />
                                                                    <RadioGroup
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        defaultValue="female"
                                                                        name="radio-buttons-group"
                                                                    >
                                                                        <FormControlLabel
                                                                            value="female"
                                                                            control={<Radio />}
                                                                            label="Ascending"
                                                                        />
                                                                        <FormControlLabel
                                                                            value="male"
                                                                            control={<Radio />}
                                                                            label="Descending"
                                                                        />
                                                                    </RadioGroup>
                                                                </MenuItem>
                                                            </Menu>
                                                        </div>
                                                    </div>
                                                    <p className="font-16 text-darkblue mt-4">Happy tuesday</p>
                                                    <div className="relative mt-3">
                                                        <img src={ProfilePostImg1} alt="ProfilePostImg1" className="h-250px w-full" />
                                                        <div className="profile-gallery-btn">
                                                            <Button className="whitebtn flex items-center gap-2">
                                                                <img src={GalleryBlueIcon} alt="GalleryBlueIcon" />
                                                                <span>01</span>
                                                            </Button>
                                                        </div>
                                                    </div>

                                                    <div className="flex justify-between mt-3">
                                                        <div className="flex gap-3">
                                                            <div>
                                                                <IconButton aria-label="delete">
                                                                    <img src={HeartOutlineIcon} alt="HeartOutlineIcon" className="img-dark-filter" />
                                                                </IconButton>
                                                            </div>

                                                            <div>
                                                                <IconButton aria-label="delete">
                                                                    <img src={DollarIcon} alt="DollarIcon" className="img-dark-filter" />
                                                                </IconButton>
                                                                <span className="font-medium font-16">Send Tip</span>
                                                            </div>
                                                        </div>

                                                        <IconButton aria-label="delete">
                                                            <img src={ArchiveTick} alt="ArchiveTick" className='img-dark-filter' />
                                                        </IconButton>
                                                    </div>

                                                    <div className="mt-3">
                                                        <FormControl
                                                            className="w-full write-comment"
                                                            variant="outlined"
                                                        >
                                                            <OutlinedInput
                                                                placeholder="Write a Comment"
                                                                id="outlined-adornment-weight"
                                                                startAdornment={
                                                                    <InputAdornment position="start">
                                                                        <img src={User1} alt="User1" width={44} className="hw-44px" />
                                                                    </InputAdornment>
                                                                }
                                                                endAdornment={
                                                                    <InputAdornment position="end" className="flex gap-3 pr-4">
                                                                        <img src={Microphone} alt="microphone" width={24} className="img-dark-filter" />
                                                                        <img
                                                                            src={Gallery}
                                                                            alt="Gallery"
                                                                            width={24}
                                                                            className="img-dark-filter"
                                                                        />
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div>

                                                <div className="bg-white rounded-2xl p-4 mt-4 creator-home-module">
                                                    <div className="flex flex-wrap justify-between items-center">
                                                        <div className="flex gap-5 varified">
                                                            <div className="relative">
                                                                <img src={ColeUser} alt="ColeUser" className="hw-60px" />
                                                                <div className="verified-bg">
                                                                    <img src={Verified} alt="Verified" />
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-col justify-center">
                                                                <h3>Cole</h3>
                                                                <p>@colejanson</p>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-wrap items-center gap-3">
                                                            <p className="font-medium font-14 text-black">6 hours ago</p>

                                                            <IconButton
                                                                onClick={handleClick}
                                                                size="small"
                                                                aria-controls={open ? "more-detail" : undefined}
                                                                aria-haspopup="true"
                                                                aria-expanded={open ? "true" : undefined}
                                                            >
                                                                <img src={MoreSquare} alt="MoreSquare" width={24} className="img-dark-filter" />
                                                            </IconButton>

                                                            <Menu
                                                                anchorEl={anchorEl}
                                                                id="more-detail"
                                                                open={open}
                                                                className="radiobtn-menu"
                                                                onClose={handleClose}
                                                                onClick={handleClose}
                                                                PaperProps={{
                                                                    elevation: 0,
                                                                    sx: {
                                                                        overflow: "visible",
                                                                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.08))",
                                                                        mt: 1.5,
                                                                        "& .MuiAvatar-root": {
                                                                            width: 32,
                                                                            height: 32,
                                                                            ml: -0.5,
                                                                            mr: 1,
                                                                        },
                                                                        "&::before": {
                                                                            content: '""',
                                                                            display: "block",
                                                                            position: "absolute",
                                                                            top: 0,
                                                                            right: 14,
                                                                            width: 10,
                                                                            height: 10,
                                                                            bgcolor: "background.paper",
                                                                            transform: "translateY(-50%) rotate(45deg)",
                                                                            zIndex: 0,
                                                                        },
                                                                    },
                                                                }}
                                                                transformOrigin={{ horizontal: "right", vertical: "top" }}
                                                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                                                            >
                                                                <MenuItem onClick={handleClose}>
                                                                    <RadioGroup
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        defaultValue="female"
                                                                        name="radio-buttons-group"
                                                                    >
                                                                        <FormControlLabel
                                                                            value="female"
                                                                            control={<Radio />}
                                                                            label="All Time"
                                                                        />
                                                                        <FormControlLabel
                                                                            value="male"
                                                                            control={<Radio />}
                                                                            label="Go to Date"
                                                                        />
                                                                    </RadioGroup>
                                                                    <Divider className="w-full" />
                                                                    <RadioGroup
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        defaultValue="female"
                                                                        name="radio-buttons-group"
                                                                    >
                                                                        <FormControlLabel
                                                                            value="female"
                                                                            control={<Radio />}
                                                                            label="Latest Posts"
                                                                        />
                                                                        <FormControlLabel
                                                                            value="male"
                                                                            control={<Radio />}
                                                                            label="Most Liked"
                                                                        />
                                                                        <FormControlLabel
                                                                            value="male"
                                                                            control={<Radio />}
                                                                            label="Highest Tips"
                                                                        />
                                                                    </RadioGroup>
                                                                    <Divider className="w-full" />
                                                                    <RadioGroup
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        defaultValue="female"
                                                                        name="radio-buttons-group"
                                                                    >
                                                                        <FormControlLabel
                                                                            value="female"
                                                                            control={<Radio />}
                                                                            label="Ascending"
                                                                        />
                                                                        <FormControlLabel
                                                                            value="male"
                                                                            control={<Radio />}
                                                                            label="Descending"
                                                                        />
                                                                    </RadioGroup>
                                                                </MenuItem>
                                                            </Menu>
                                                        </div>
                                                    </div>
                                                    <p className="font-16 text-darkblue mt-4">Happy tuesday</p>
                                                    <div className="relative mt-3">
                                                        <img src={ProfilePostImg1} alt="ProfilePostImg1" className="h-250px w-full" />
                                                        <div className="profile-gallery-btn">
                                                            <Button className="whitebtn flex items-center gap-2">
                                                                <img src={GalleryBlueIcon} alt="GalleryBlueIcon" />
                                                                <span>01</span>
                                                            </Button>
                                                        </div>
                                                    </div>

                                                    <div className="flex justify-between mt-3">
                                                        <div className="flex gap-3">
                                                            <div>
                                                                <IconButton aria-label="delete">
                                                                    <img src={HeartOutlineIcon} alt="HeartOutlineIcon" className="img-dark-filter" />
                                                                </IconButton>
                                                            </div>

                                                            <div>
                                                                <IconButton aria-label="delete">
                                                                    <img src={DollarIcon} alt="DollarIcon" className="img-dark-filter" />
                                                                </IconButton>
                                                                <span className="font-medium font-16">Send Tip</span>
                                                            </div>
                                                        </div>

                                                        <IconButton aria-label="delete">
                                                            <img src={ArchiveTick} alt="ArchiveTick" className='img-dark-filter' />
                                                        </IconButton>
                                                    </div>

                                                    <div className="mt-3">
                                                        <FormControl
                                                            className="w-full write-comment"
                                                            variant="outlined"
                                                        >
                                                            <OutlinedInput
                                                                placeholder="Write a Comment"
                                                                id="outlined-adornment-weight"
                                                                startAdornment={
                                                                    <InputAdornment position="start">
                                                                        <img src={User1} alt="User1" width={44} className="hw-44px" />
                                                                    </InputAdornment>
                                                                }
                                                                endAdornment={
                                                                    <InputAdornment position="end" className="flex gap-3 pr-4">
                                                                        <img src={Microphone} alt="microphone" width={24} className="img-dark-filter" />
                                                                        <img
                                                                            src={Gallery}
                                                                            alt="Gallery"
                                                                            width={24}
                                                                            className="img-dark-filter"
                                                                        />
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={4}>
                                            <div className="light-gray-bg p-4 subscription">
                                                <div className="flex justify-between flex-wrap gap-3 border-b pb-3">
                                                    <p className="font-18 font-medium">
                                                        Customer Order
                                                    </p>
                                                    <img
                                                        className="img-dark-filter cursor-pointer"
                                                        src={listmenublack}
                                                        alt="listmenublack"
                                                        width={20}
                                                    />
                                                </div>

                                                <div className="customer-box active mt-5">
                                                    <div className="flex justify-between gap-4 flex-wrap">
                                                        <div>
                                                            <p className="mb-3 text-darkblue font-medium">
                                                                Following
                                                            </p>
                                                            <AvatarGroup>
                                                                <Avatar
                                                                    alt="CustomerImg1"
                                                                    src={CustomerImg1}
                                                                    className="avatar-img"
                                                                />
                                                                <Avatar
                                                                    alt="CustomerImg2"
                                                                    src={CustomerImg2}
                                                                    className="avatar-img"
                                                                />
                                                                <Avatar
                                                                    alt="CustomerImg3"
                                                                    src={CustomerImg3}
                                                                    className="avatar-img"
                                                                />
                                                            </AvatarGroup>
                                                        </div>
                                                        <p className="font-14 font-medium mb-0">
                                                            5 Users <span className="mx-2">|</span> 2.1K
                                                            Posts
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="customer-box mt-5">
                                                    <div className="flex justify-between gap-4 flex-wrap">
                                                        <div>
                                                            <p className="mb-3 text-darkblue font-medium">
                                                                Fans
                                                            </p>
                                                            <AvatarGroup>
                                                                <Avatar
                                                                    alt="CustomerImg1"
                                                                    src={CustomerImg1}
                                                                    className="avatar-img"
                                                                />
                                                                <Avatar
                                                                    alt="CustomerImg2"
                                                                    src={CustomerImg2}
                                                                    className="avatar-img"
                                                                />
                                                                <Avatar
                                                                    alt="CustomerImg3"
                                                                    src={CustomerImg3}
                                                                    className="avatar-img"
                                                                />
                                                            </AvatarGroup>
                                                        </div>
                                                        <p className="font-14 font-medium mb-0">
                                                            23 Users <span className="mx-2">|</span> 232
                                                            Posts
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="customer-box mt-5">
                                                    <div className="flex justify-between gap-4 flex-wrap">
                                                        <div>
                                                            <p className="text-darkblue font-medium">
                                                                Muted
                                                            </p>
                                                        </div>
                                                        <p className="font-14 font-medium mb-0">Empty</p>
                                                    </div>
                                                </div>

                                                <div className="customer-box mt-5">
                                                    <div className="flex justify-between gap-4 flex-wrap">
                                                        <div>
                                                            <p className="mb-3 text-darkblue font-medium">
                                                                Renew Off
                                                            </p>
                                                            <AvatarGroup>
                                                                <Avatar
                                                                    alt="CustomerImg1"
                                                                    src={CustomerImg1}
                                                                    className="avatar-img"
                                                                />
                                                                <Avatar
                                                                    alt="CustomerImg2"
                                                                    src={CustomerImg2}
                                                                    className="avatar-img"
                                                                />
                                                                <Avatar
                                                                    alt="CustomerImg3"
                                                                    src={CustomerImg3}
                                                                    className="avatar-img"
                                                                />
                                                            </AvatarGroup>
                                                        </div>
                                                        <p className="font-14 font-medium mb-0">
                                                            5 Users <span className="mx-2">|</span> 2.1K
                                                            Posts
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="customer-box mt-5">
                                                    <div className="flex justify-between gap-4 flex-wrap">
                                                        <div>
                                                            <p className="mb-3 text-darkblue font-medium">
                                                                Renew On
                                                            </p>
                                                            <AvatarGroup>
                                                                <Avatar
                                                                    alt="CustomerImg1"
                                                                    src={CustomerImg1}
                                                                    className="avatar-img"
                                                                />
                                                                <Avatar
                                                                    alt="CustomerImg2"
                                                                    src={CustomerImg2}
                                                                    className="avatar-img"
                                                                />
                                                                <Avatar
                                                                    alt="CustomerImg3"
                                                                    src={CustomerImg3}
                                                                    className="avatar-img"
                                                                />
                                                            </AvatarGroup>
                                                        </div>
                                                        <p className="font-14 font-medium mb-0">
                                                            22 Users <span className="mx-2">|</span> 2 Posts
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="customer-box mt-5">
                                                    <div className="flex justify-between gap-4 flex-wrap">
                                                        <div>
                                                            <p className="text-darkblue font-medium">
                                                                Recent(Last 24 hours)
                                                            </p>
                                                        </div>
                                                        <p className="font-14 font-medium mb-0">Empty</p>
                                                    </div>
                                                </div>

                                                <div className="customer-box mt-5">
                                                    <div className="flex justify-between gap-4 flex-wrap">
                                                        <div>
                                                            <p className="text-darkblue font-medium">
                                                                Friends
                                                            </p>
                                                        </div>
                                                        <p className="font-14 font-medium mb-0">Empty</p>
                                                    </div>
                                                </div>

                                                <div className="customer-box mt-5">
                                                    <div className="flex justify-between gap-4 flex-wrap">
                                                        <div>
                                                            <p className="text-darkblue font-medium">
                                                                Tagged
                                                            </p>
                                                        </div>
                                                        <p className="font-14 font-medium mb-0">Empty</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </> :
                                <>
                                    <Grid
                                        container
                                        spacing={1}
                                        className="h-full mb-4 fans-message"
                                    >
                                        <Grid item xs={12} md={12} lg={8}>
                                            <div className="light-gray-bg lg:mr-4">
                                                <div className="profile-media">
                                                    <Grid container spacing={2} className="h-full">
                                                        <Grid item xs={6} md={6} lg={4} xl={4}>
                                                            <div className="gray-border bg-white">
                                                                <div className="relative">
                                                                    <img
                                                                        src={Archive8Icon}
                                                                        alt="Archive8Icon"
                                                                        className="w-full h-215px"
                                                                    />
                                                                    <div className="video-play-icon cursor-pointer">
                                                                        <img
                                                                            src={VideoPlayIcon}
                                                                            alt="VideoPlayIcon"
                                                                            height={52}
                                                                            width={52}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="flex items-center justify-between gap-3">
                                                                    <div className="flex items-center gap-3 mt-3">
                                                                        <img
                                                                            src={GalleryBlueIcon}
                                                                            alt="GalleryBlueIcon"
                                                                        />
                                                                        <span className="text-black font-16 font-medium">
                                                                            01
                                                                        </span>
                                                                    </div>
                                                                    <Button
                                                                        variant="outlined"
                                                                        className="mt-3 light-blue-icon-btn font-semibold text-primary"
                                                                    >
                                                                        A
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Grid>

                                                        <Grid item xs={6} md={6} lg={4} xl={4}>
                                                            <div className="gray-border bg-white">
                                                                <img
                                                                    src={Archive9Icon}
                                                                    alt="Archive9Icon"
                                                                    className="w-full h-215px"
                                                                />
                                                                <div className="flex items-center justify-between gap-3 ">
                                                                    <div className="flex items-center gap-3 mt-3">
                                                                        <img
                                                                            src={GalleryBlueIcon}
                                                                            alt="GalleryBlueIcon"
                                                                        />
                                                                        <span className="text-black font-16 font-medium">
                                                                            01
                                                                        </span>
                                                                    </div>
                                                                    <Button
                                                                        variant="outlined"
                                                                        className="mt-3 light-blue-icon-btn font-semibold text-primary"
                                                                    >
                                                                        A
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Grid>

                                                        <Grid item xs={6} md={6} lg={4} xl={4}>
                                                            <div className="gray-border bg-white">
                                                                <img
                                                                    src={Archive10Icon}
                                                                    alt="Archive10Icon"
                                                                    className="w-full h-215px"
                                                                />
                                                                <div className="flex items-center justify-between gap-3 ">
                                                                    <div className="flex items-center gap-3 mt-3">
                                                                        <img
                                                                            src={GalleryBlueIcon}
                                                                            alt="GalleryBlueIcon"
                                                                        />
                                                                        <span className="text-black font-16 font-medium">
                                                                            01
                                                                        </span>
                                                                    </div>
                                                                    <Button
                                                                        variant="outlined"
                                                                        className="mt-3 light-blue-icon-btn font-semibold text-primary"
                                                                    >
                                                                        A
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Grid>

                                                        <Grid item xs={6} md={6} lg={4} xl={4}>
                                                            <div className="gray-border bg-white">
                                                                <div className="relative">
                                                                    <img
                                                                        src={Archive11Icon}
                                                                        alt="Archive11Icon"
                                                                        className="w-full h-215px"
                                                                    />
                                                                </div>
                                                                <div className="flex items-center justify-between gap-3 ">
                                                                    <div className="flex items-center gap-3 mt-3">
                                                                        <img
                                                                            src={GalleryBlueIcon}
                                                                            alt="GalleryBlueIcon"
                                                                        />
                                                                        <span className="text-black font-16 font-medium">
                                                                            01
                                                                        </span>
                                                                    </div>
                                                                    <Button
                                                                        variant="outlined"
                                                                        className="mt-3 light-blue-icon-btn font-semibold text-primary"
                                                                    >
                                                                        A
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Grid>

                                                        <Grid item xs={6} md={6} lg={4} xl={4}>
                                                            <div className="gray-border bg-white">
                                                                <img
                                                                    src={Archive12Icon}
                                                                    alt="Archive12Icon"
                                                                    className="w-full h-215px"
                                                                />
                                                                <div className="flex items-center justify-between gap-3 ">
                                                                    <div className="flex items-center gap-3 mt-3">
                                                                        <img
                                                                            src={GalleryBlueIcon}
                                                                            alt="GalleryBlueIcon"
                                                                        />
                                                                        <span className="text-black font-16 font-medium">
                                                                            01
                                                                        </span>
                                                                    </div>
                                                                    <Button
                                                                        variant="outlined"
                                                                        className="mt-3 light-blue-icon-btn font-semibold text-primary"
                                                                    >
                                                                        A
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={4}>
                                            <div className="light-gray-bg p-4 subscription">
                                                <div className="flex justify-between flex-wrap gap-3 border-b pb-3">
                                                    <p className="font-18 font-medium">
                                                        Customer Order
                                                    </p>
                                                    <img
                                                        className="img-dark-filter cursor-pointer"
                                                        src={listmenublack}
                                                        alt="listmenublack"
                                                        width={20}
                                                    />
                                                </div>

                                                <div className="customer-box active mt-5">
                                                    <div className="flex justify-between gap-4 flex-wrap">
                                                        <div>
                                                            <p className="mb-3 text-darkblue font-medium">
                                                                Following
                                                            </p>
                                                            <AvatarGroup>
                                                                <Avatar
                                                                    alt="CustomerImg1"
                                                                    src={CustomerImg1}
                                                                    className="avatar-img"
                                                                />
                                                                <Avatar
                                                                    alt="CustomerImg2"
                                                                    src={CustomerImg2}
                                                                    className="avatar-img"
                                                                />
                                                                <Avatar
                                                                    alt="CustomerImg3"
                                                                    src={CustomerImg3}
                                                                    className="avatar-img"
                                                                />
                                                            </AvatarGroup>
                                                        </div>
                                                        <p className="font-14 font-medium mb-0">
                                                            5 Users <span className="mx-2">|</span> 2.1K
                                                            Posts
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="customer-box mt-5">
                                                    <div className="flex justify-between gap-4 flex-wrap">
                                                        <div>
                                                            <p className="mb-3 text-darkblue font-medium">
                                                                Fans
                                                            </p>
                                                            <AvatarGroup>
                                                                <Avatar
                                                                    alt="CustomerImg1"
                                                                    src={CustomerImg1}
                                                                    className="avatar-img"
                                                                />
                                                                <Avatar
                                                                    alt="CustomerImg2"
                                                                    src={CustomerImg2}
                                                                    className="avatar-img"
                                                                />
                                                                <Avatar
                                                                    alt="CustomerImg3"
                                                                    src={CustomerImg3}
                                                                    className="avatar-img"
                                                                />
                                                            </AvatarGroup>
                                                        </div>
                                                        <p className="font-14 font-medium mb-0">
                                                            23 Users <span className="mx-2">|</span> 232
                                                            Posts
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="customer-box mt-5">
                                                    <div className="flex justify-between gap-4 flex-wrap">
                                                        <div>
                                                            <p className="text-darkblue font-medium">
                                                                Muted
                                                            </p>
                                                        </div>
                                                        <p className="font-14 font-medium mb-0">Empty</p>
                                                    </div>
                                                </div>

                                                <div className="customer-box mt-5">
                                                    <div className="flex justify-between gap-4 flex-wrap">
                                                        <div>
                                                            <p className="mb-3 text-darkblue font-medium">
                                                                Renew Off
                                                            </p>
                                                            <AvatarGroup>
                                                                <Avatar
                                                                    alt="CustomerImg1"
                                                                    src={CustomerImg1}
                                                                    className="avatar-img"
                                                                />
                                                                <Avatar
                                                                    alt="CustomerImg2"
                                                                    src={CustomerImg2}
                                                                    className="avatar-img"
                                                                />
                                                                <Avatar
                                                                    alt="CustomerImg3"
                                                                    src={CustomerImg3}
                                                                    className="avatar-img"
                                                                />
                                                            </AvatarGroup>
                                                        </div>
                                                        <p className="font-14 font-medium mb-0">
                                                            5 Users <span className="mx-2">|</span> 2.1K
                                                            Posts
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="customer-box mt-5">
                                                    <div className="flex justify-between gap-4 flex-wrap">
                                                        <div>
                                                            <p className="mb-3 text-darkblue font-medium">
                                                                Renew On
                                                            </p>
                                                            <AvatarGroup>
                                                                <Avatar
                                                                    alt="CustomerImg1"
                                                                    src={CustomerImg1}
                                                                    className="avatar-img"
                                                                />
                                                                <Avatar
                                                                    alt="CustomerImg2"
                                                                    src={CustomerImg2}
                                                                    className="avatar-img"
                                                                />
                                                                <Avatar
                                                                    alt="CustomerImg3"
                                                                    src={CustomerImg3}
                                                                    className="avatar-img"
                                                                />
                                                            </AvatarGroup>
                                                        </div>
                                                        <p className="font-14 font-medium mb-0">
                                                            22 Users <span className="mx-2">|</span> 2 Posts
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="customer-box mt-5">
                                                    <div className="flex justify-between gap-4 flex-wrap">
                                                        <div>
                                                            <p className="text-darkblue font-medium">
                                                                Recent(Last 24 hours)
                                                            </p>
                                                        </div>
                                                        <p className="font-14 font-medium mb-0">Empty</p>
                                                    </div>
                                                </div>

                                                <div className="customer-box mt-5">
                                                    <div className="flex justify-between gap-4 flex-wrap">
                                                        <div>
                                                            <p className="text-darkblue font-medium">
                                                                Friends
                                                            </p>
                                                        </div>
                                                        <p className="font-14 font-medium mb-0">Empty</p>
                                                    </div>
                                                </div>

                                                <div className="customer-box mt-5">
                                                    <div className="flex justify-between gap-4 flex-wrap">
                                                        <div>
                                                            <p className="text-darkblue font-medium">
                                                                Tagged
                                                            </p>
                                                        </div>
                                                        <p className="font-14 font-medium mb-0">Empty</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </>}
                        </TabPanel>
                        <TabPanel value="photo1" className="p-0 w-full">
                            <Grid container spacing={2} className="fans-profile">
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="mb-4"
                                ></Grid>
                                <div className="flex flex-col justify-center items-center p-4 h-calc-200">
                                    <img
                                        src={SaveForLaterImg}
                                        alt="SaveForLaterImg"
                                        width={246}
                                    />
                                    <p className="font-20 mt-3">Nothing found</p>
                                </div>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="video2" className="p-0 w-full">
                            <Grid container spacing={2} className="fans-profile">
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="mb-4"
                                ></Grid>
                                <div className="flex flex-col justify-center items-center p-4 h-calc-200">
                                    <img
                                        src={SaveForLaterImg}
                                        alt="SaveForLaterImg"
                                        width={246}
                                    />
                                    <p className="font-20 mt-3">Nothing found</p>
                                </div>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="audio3" className="p-0 w-full">
                            <Grid container spacing={2} className="fans-profile">
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="mb-4"
                                ></Grid>
                                <div className="flex flex-col justify-center items-center p-4 h-calc-200">
                                    <img
                                        src={SaveForLaterImg}
                                        alt="SaveForLaterImg"
                                        width={246}
                                    />
                                    <p className="font-20 mt-3">Nothing found</p>
                                </div>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="other4" className="p-0 w-full">
                            <Grid container spacing={2} className="fans-profile">
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="mb-4"
                                ></Grid>
                                <div className="flex flex-col justify-center items-center p-4 h-calc-200">
                                    <img
                                        src={SaveForLaterImg}
                                        alt="SaveForLaterImg"
                                        width={246}
                                    />
                                    <p className="font-20 mt-3">Nothing found</p>
                                </div>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="locaked5" className="p-0 w-full">
                            <Grid container spacing={2} className="fans-profile">
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="mb-4"
                                ></Grid>
                                <div className="flex flex-col justify-center items-center p-4 h-calc-200">
                                    <img
                                        src={SaveForLaterImg}
                                        alt="SaveForLaterImg"
                                        width={246}
                                    />
                                    <p className="font-20 mt-3">Nothing found</p>
                                </div>
                            </Grid>
                        </TabPanel>
                    </div>
                </TabContext>
            </div>
        </div>
    )
}

export default PostsTab