import React from "react";
import LeoImg from './../../../../../assets/images/Leo.png';

const ModalUserImage = () => {
  return (
    <div className="relative">
      <img src={LeoImg} alt="UserYsraImg" className="w-full" />
    </div>
  );
};

export default ModalUserImage;
