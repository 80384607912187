import React from 'react'

const BankIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.63 2.15009L2.63001 5.75007C2.28001 5.89007 2 6.31006 2 6.68006L2 10.0001C2 10.5501 2.45 11.0001 3 11.0001L21 11.0001C21.55 11.0001 22 10.5501 22 10.0001L22 6.68006C22 6.31006 21.72 5.89006 21.37 5.75006L12.37 2.15009C12.17 2.07009 11.83 2.07009 11.63 2.15009Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M2 22L22 22L22 19C22 18.45 21.55 18 21 18L3 18C2.45 18 2 18.45 2 19L2 22Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M20 18L20 11" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
            <path opacity="0.4" d="M16 18L16 11" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path opacity="0.4" d="M12 18L12 11" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path opacity="0.4" d="M8 18L8 11" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path opacity="0.4" d="M4 18L4 11" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M23 22L1 22" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path opacity="0.4" d="M12 8.5C11.1716 8.5 10.5 7.82843 10.5 7C10.5 6.17157 11.1716 5.5 12 5.5C12.8284 5.5 13.5 6.17157 13.5 7C13.5 7.82843 12.8284 8.5 12 8.5Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default BankIcon
