import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addLanguage,
  deleteLanguage,
  getAllLanguages,
  getAllLanguagesWeb,
  updateLanguage,
} from "../../../../services/admin/language/languages.js";
import { openToast } from "../../../../slices/common/toast/index.js";
import { closeAdminModal } from "../../../../slices/common/adminModal";

export const GetAllLanguagesAction = createAsyncThunk(
  "language/getLanguage",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getAllLanguages(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const GetAllLanguagesWebAction = createAsyncThunk(
  "language/getLanguageWeb",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getAllLanguagesWeb(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const EditLanguagesAction = createAsyncThunk(
  "language/updateLanguage",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await updateLanguage(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal())
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const AddLanguagesAction = createAsyncThunk(
  "language/addLanguage",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await addLanguage(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal())
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const RemoveLanguageAction = createAsyncThunk(
  "language/removeLanguage",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await deleteLanguage({ id: payload });
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue({ ...response, id: payload });
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);
