import React from "react";
import Grid from "@mui/material/Grid";
import { CreatorShopItem } from '../../../../molecules';

export default function CreatorShopItems({ creatorShopItems }) {
  return (
    <div className="mr-0">
      <div className="bg-white rounded-2xl py-4 px-2 profile-media streams">
        <Grid container spacing={2} className="h-full">
          {creatorShopItems?.map((item) => {
            return (
              <CreatorShopItem key={item?.productName} productImg={item?.productImg} discount={item?.discount} productName={item?.productName} newPrice={item?.newPrice} oldPrice={item?.oldPrice} />
            )
          })}
        </Grid>
      </div>
    </div>
  );
}

// export default Streams;
