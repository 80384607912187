import React from "react";

const SubmitModalBtn = () => {
  return (
    <button
      type="submit"
      className="text-white  bg-[#1a1a27] hover:bg-[#1a1a27]-700 focus:none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
    >
      Submit
    </button>
  );
};

export default SubmitModalBtn;
