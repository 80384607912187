import React from 'react'
import SaveForLaterImg from "./../../../../../assets/images/saveforlater.png";

function ModelPintoHome() {
  // Pin To Home
  return (
    <div className="flex flex-col justify-center items-center light-gray700-bg p-4 rounded-2xl">
      <img src={SaveForLaterImg} alt="SaveForLaterImg" width={198} />
      <p className="font-20">No list yet</p>
    </div>

  )
}

export default ModelPintoHome