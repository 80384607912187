import React from "react";
import Button from "@mui/material/Button";
import GalleryBlueIcon from "./../../../../assets/images/gallery-blue.svg";
import VideoPlayIcon from "./../../../../assets/images/play-circle.svg"
import { Grid } from "@mui/material";

const ShowMedia = ({ postMedia, index }) => {
  const showCount = (count) => {
    return (
      <div className="flex items-center gap-3 mt-3">
        <img src={GalleryBlueIcon} alt="GalleryBlueIcon" />
        <span className="text-black font-16 font-medium">
          {count > 10 ? count : `0${count}`}
        </span>
      </div>
    );
  };

  return (
    <Grid item xs={6} md={4} lg={4} key={index}>
      <div className="gray-border">
        <div
          className={`w-[232px] ${
            postMedia[0]?.fileType === "Video" ? "relative" : ""
          } h-[212px]`}
        >
          <img
            // eslint-disable-next-line no-undef
            src={`${process.env.REACT_APP_AWS_URL}${postMedia[0]?.fileURL}`}
            alt={`post`}
            className="max-h-[212px] w-full object-cover rounded-[16px]"
          />
          {postMedia[0]?.fileType !== "Video" ? (
            <></>
          ) : (
            <div className="video-play-icon cursor-pointer">
              <img
                src={VideoPlayIcon}
                alt="VideoPlayIcon"
                height={32}
                width={32}
              />
            </div>
          )}
        </div>
        {postMedia[0]?.fileType === "Video" ? (
          <div className="flex items-center justify-between gap-3">
            {showCount(postMedia?.length)}
            <Button
              variant="outlined"
              className="mt-3 light-blue-icon-btn font-semibold text-primary"
            >
              A
            </Button>
          </div>
        ) : (
          showCount(postMedia?.length)
        )}
      </div>
    </Grid>
  );
};

export default ShowMedia;
