import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnswerQuizAction } from "../../../../../store/middlewares/web/post/posts";
import GrayDot from "./../../../../../assets/images/dot.svg";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Close, Done } from "@mui/icons-material";
import dayjs from "dayjs";

const DisplayQuizInPost = ({ options, postId, isLoggedInUser, expiry }) => {
  const [answersCount, setAnswersCount] = useState(0);
  const { me } = useSelector((state) => state.me);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const daysLeft = dayjs(expiry).diff(dayjs(), "days");

  useEffect(() => {
    let sum = 0;
    let answeredOption = {};
    options.forEach((option) => {selectedAnswer
      sum += option?.answers?.length;
      if (option?.answers?.length > 0) {
        if (
          option?.answers?.map((answer) => answer?.userId).includes(`${me?.id}`)
        ) {
          answeredOption = option;
        }
      }
    });
    setSelectedAnswer(answeredOption);
    setAnswersCount(sum);
  }, [options, me]);

  const dispatch = useDispatch();
  const handleQuizChange = (e, optionId) => {
    setSelectedAnswer(e.target.value);
    dispatch(AnswerQuizAction({ postId: postId, quizOptionId: optionId }));
  };
  return (
    <div className="mt-4 border rounded-lg">
      {isLoggedInUser || selectedAnswer || daysLeft <= 0 ? (
        <div className="flex flex-col">
          {options?.map((option) => {
            return (
              <div
                key={option.sequence}
                value={option.optionText}
                className={"border-b p-2"}
              >
                <div
                  className={`flex p-2 ${
                    selectedAnswer?.id === option.id
                      ? "light-gray-bg rounded-lg"
                      : ""
                  } ${
                    selectedAnswer || isLoggedInUser
                      ? "justify-between"
                      : "justify-start"
                  }`}
                >
                  <div className="flex gap-4">
                    {!option?.isCorrectAnswer ? (
                      <Close color="error" />
                    ) : (
                      <Done color="success" />
                    )}
                    <p>{option.optionText}</p>
                  </div>
                  {isLoggedInUser && <div>{option?.answers?.length}</div>}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={selectedAnswer}
          name="radio-buttons-group"
        >
          {options?.map((option) => {
            return (
              <FormControlLabel
                key={option.sequence}
                value={option.optionText}
                control={
                  <Radio
                    className="p-0"
                    value={option.optionText}
                    onChange={(e) => {
                      handleQuizChange(e, option.id);
                    }}
                    checked={option?.optionText === selectedAnswer}
                    disabled={selectedAnswer}
                  />
                }
                label={
                  <div className="flex justify-between px-2">
                    <p>{option.optionText}</p>
                  </div>
                }
                className={"border-b p-4"}
              />
            );
          })}
        </RadioGroup>
      )}

      <div className="py-2 pl-3 rounded-b-lg light-gray-bg flex items-center">
        <p>QUIZ</p>
        <img src={GrayDot} alt="GrayDot" className="mx-2 gray-dot-width" />
        <p>{answersCount} Answers</p>
        {expiry && (
          <>
            <img src={GrayDot} alt="GrayDot" className=" mx-2 gray-dot-width" />
            {daysLeft > 0 ? <p>{daysLeft} Days Left</p> : <p>Expired</p>}
          </> 
        )}
      </div>
    </div>
  );
};

export default DisplayQuizInPost;
