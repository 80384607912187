import React from 'react'
import starIcon from './../../../assets/images/star.svg';
import verifyIcon from './../../../assets/images/green-verify.svg';
import { Button } from '@mui/material';

const NotificationItem = ({ userProfileImage, iconSrc, name, isVerified, username, notificationText, addToFavorite, favoriteBtnText = 'Add to favorites', runPromotion, date }) => {
    //Single Notification Item For Notification in All Tab 
    return (
        <div className="light-gray-box flex justify-between gap-2 mb-3 subscription" >
            <div className="flex flex-sm-wrap items-start gap-6">
                <div className="relative">
                    <img
                        src={userProfileImage}
                        alt="NotifyUser1"
                        className="notify-user"
                    />
                    <img
                        src={iconSrc}
                        alt="ConnectivityIcon"
                        className="notify-user-status"
                    />
                </div>
                <div>
                    <div className="flex flex-wrap items-center gap-2 title-width">
                        <p className="text-darkblue font-20 font-semibold z">
                            {name}
                        </p>
                        {isVerified && <img src={verifyIcon} alt="verifyIcon" />}
                        {username &&
                            <>
                                <span className="line">|</span>
                                <p>@{username}</p>
                            </>}
                    </div>
                    {runPromotion ? <div className="mt-2">
                        <Button
                            variant="outlined"
                            className="light-blue-btn"
                        >
                            <img
                                src={starIcon}
                                alt="starIcon"
                                width={20}
                                className="mr-2"
                            />
                            {`${name} is currently running a promotion check it out`}
                        </Button>
                    </div> : null}
                    {notificationText && <p className="text-darkblue font-medium mt-3">
                        {notificationText}
                    </p>}

                    {addToFavorite ? <div className="mt-2">
                        <Button
                            variant="outlined"
                            className="light-blue-btn"
                        >
                            <img
                                src={starIcon}
                                alt="starIcon"
                                width={20}
                                className="mr-2"
                            />
                            {favoriteBtnText}
                        </Button>
                    </div> : null}
                </div>
            </div>
            {date && <p className="font-medium text-nowrap date-position">
                {date}
            </p>}
        </div>
    )
}

export default NotificationItem
