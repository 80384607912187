import { Button } from "@mui/material"
import React from "react";
import MoreSquareBlueIcon from './../../../../../assets/images/more-square-blue.svg';
import NoArticleUser12 from "./../../../../../assets/images/noarticleUser12.png";
import BlueHeartIcon from "./../../../../../assets/images/heart-blue.svg";
import dollarblueIcon from "./../../../../../assets/images/dollar-square-blue.svg";
import ChatBlueIcon from "./../../../../../assets/images/chat-icon-blue.svg";
import Slider from "react-slick"

const ModalProfileVideo = () => {
    // Show Profile Video

    const settings3 = {
        dots: true,
        infinite: false,
        autoplay: false,
        navs: false,
        speed: 500,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <>
            <div className="pt-3 send-tips  ">
                <Slider className="streams pt-2" {...settings3}>
                    <div>
                        <div className="relative">
                            <img
                                src={NoArticleUser12}
                                alt="NoArticleUser12"
                                className="w-full"
                            />
                        </div>
                    </div>
                    <div>
                        <div className="relative">
                            <img
                                src={NoArticleUser12}
                                alt="NoArticleUser12"
                                className="w-full"
                            />
                        </div>
                    </div>
                    <div>
                        <div className="relative">
                            <img
                                src={NoArticleUser12}
                                alt="NoArticleUser12"
                                className="w-full"
                            />
                        </div>
                    </div>
                </Slider>
            </div>
            <div className="flex flex-wrap justify-end mt-4 gap-3 buttons">
                <Button
                    variant="outlined"
                    className="light-blue-icon-btn"
                    sx={{
                        padding: "0 7px !important",
                        minWidth: "47px !important",
                        width: "auto",
                    }}
                >
                    <img
                        src={BlueHeartIcon}
                        alt="BlueHeartIcon"
                        width={24}
                    />
                </Button>
                <Button
                    variant="outlined"
                    className="light-blue-icon-btn"
                    sx={{
                        padding: "0 7px !important",
                        minWidth: "47px !important",
                        width: "auto",
                    }}
                >
                    <img
                        src={ChatBlueIcon}
                        alt="ChatBlueIcon"
                        width={24}
                    />
                </Button>
                <Button
                    variant="outlined"
                    className="light-blue-icon-btn"
                    sx={{
                        padding: "0 7px !important",
                        minWidth: "47px !important",
                        width: "auto",
                    }}
                >
                    <img
                        src={MoreSquareBlueIcon}
                        alt="MoreSquareBlueIcon"
                        width={24}
                    />
                </Button>
                <Button
                    variant="outlined"
                    sx={{
                        padding: "0 7px !important",
                        minWidth: "47px !important",
                        width: "auto",
                    }}
                    className="light-blue-icon-btn text-primary whitespace-no-wrap px-4"
                >
                    <img
                        src={dollarblueIcon}
                        alt="dollarblueIcon"
                        width={24} className="mr-1"
                    />
                    Send Tip
                </Button>
            </div>
        </>

    )
}

export default ModalProfileVideo