import { createSlice } from "@reduxjs/toolkit";
import { changeHTMLAttribute } from "../../../../utils/layout";

export const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    layoutType: "vertical",
    layoutSidebarSizeType: "lg",
  },
  reducers: {
    changeLayout: (state, { payload }) => {
      changeHTMLAttribute("data-layout", payload);
      state.layoutType = payload;
    },
    changeLeftsidebarSizeType: (state, { payload }) => {
      switch (payload) {
        case "lg":
          changeHTMLAttribute("data-sidebar-size", "lg");
          break;
        case "md":
          changeHTMLAttribute("data-sidebar-size", "md");
          break;
        case "sm":
          changeHTMLAttribute("data-sidebar-size", "sm");
          break;
        default:
          changeHTMLAttribute("data-sidebar-size", "lg");
      }
      state.layoutSidebarSizeType = payload;
    },
  },
});

export const { changeLeftsidebarSizeType, changeLayout } = layoutSlice.actions;

export default layoutSlice.reducer;
