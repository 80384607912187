import React from "react";
import { UserMedia } from "../../../../molecules";

function UserTabMedia({ tabValue }) {
  //User Profile Tab
  return (
    <>
      {tabValue === "all" && <UserMedia type={tabValue} />}
      {tabValue === "image" && <UserMedia type={tabValue} />}
      {tabValue === "video" && <UserMedia type={tabValue} />}
    </>
  );
}

export default UserTabMedia;
