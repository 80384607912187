import { createSlice } from "@reduxjs/toolkit";

export const toastSlice = createSlice({
    name: 'toast',
    initialState: {
        isOpen: false,
        type: "",
        message: ""
    },
    reducers: {
        openToast: (state, { payload }) => {
            let { type, message } = payload
            state.isOpen = true;
            state.type = type;
            state.message = message;
        },
        closeToast: (state) => {
            state.isOpen = false;
            state.type = "";
            state.message = "";
        },
    },
});

export const {
    openToast, closeToast
} = toastSlice.actions;

export default toastSlice.reducer;