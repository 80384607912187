import { useEffect, useState } from "react";
import getChartColorsArray from "./../utils/ChartDynamicColor";

const useChartColors = (chartId) => {
  const [chartColors, setChartColors] = useState([]);

  useEffect(() => {
    const colors = getChartColorsArray(chartId);
    setChartColors(colors);
  }, [chartId]);

  return chartColors;
};

export default useChartColors;
