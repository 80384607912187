import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnswerPollAction } from "../../../../../store/middlewares/web/post/posts";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import dayjs from "dayjs";

const DisplayPollInPost = ({ post }) => {
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const dispatch = useDispatch();
  const { me } = useSelector((state) => state.me);
  const daysLeft = dayjs(post?.expiry).diff(dayjs(), "days");

  useEffect(() => {
    if (post?.pollOptions?.length > 0) {
      let answeredOption = {};
      post?.pollOptions?.forEach((option) => {
        if (option?.answers?.length > 0) {
          if (
            option?.answers
              ?.map((answer) => answer?.userId)
              .includes(`${me?.id}`)
          ) {
            answeredOption = option;
          }
        }
      });
      setSelectedAnswer(answeredOption?.optionText);
    }
  }, [post, me]);

  const handleChange = (e, optionId) => {
    setSelectedAnswer(e.target.value);
    dispatch(AnswerPollAction({ postId: post?.id, pollOptionId: optionId }));
  };
  return (
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue={selectedAnswer}
      name="radio-buttons-group"
      className="mt-4"
    >
      {post?.pollOptions?.map((option, index) => {
        return (
          <FormControlLabel
            key={option.sequence}
            value={option.optionText}
            control={
              <Radio
                className="p-0"
                value={option.optionText}
                onChange={(e) => {
                  handleChange(e, option.id);
                }}
                checked={option?.optionText === selectedAnswer}
                disabled={selectedAnswer || daysLeft <= 0}
              />
            }
            label={
              <p className="pl-2">
                {option.optionText} ({option?.answers?.length}{" "}
                {option?.answers?.length > 1 ? "Votes" : "Vote"})
              </p>
            }
            className={`border ${
              selectedAnswer === option.optionText ? "border-[#2799F6]" : ""
            } p-2 rounded-lg ${index === 0 ? "mt-0" : "mt-2"} `}
          />
        );
      })}
    </RadioGroup>
  );
};

export default DisplayPollInPost;
