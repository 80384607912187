
import { createAsyncThunk } from "@reduxjs/toolkit";
import { verifyOTP } from "../../../services/auth/forgotPassword/otp.service";

export const verifyOtp = createAsyncThunk("auth/verifyOtp", async (payload, { rejectWithValue,  fulfillWithValue }) => {
    try {
        const response = await verifyOTP(payload);
        if (response.status === 1) {
            // if any async operation need to be done on response data, do it here,
            // then full fill data
            let {  response : { flag } } = response
            if (flag === 1) {
                return fulfillWithValue(response);
            }
            return rejectWithValue({ message: response?.message });
        }
        return rejectWithValue({ message: response?.message });
    } catch (error) {
        return rejectWithValue(error);
    }
})