import { Grid, Typography } from "@mui/material";
import React from "react";

const UserAddressCard = ({ user }) => {
  console.log(user);
  let tempObj = {
    Country: "USA",
    State: "NY",
    adress: "123 Main St",
    city: "New York",
    postalCode: "10001",
  };
  return (
    <div className="mt-5 card">
      <div className="card-body">
        <div className="flex flex-col gap-2">
          <div className="w-full border-b">
            <Typography variant="h5">ADDRESS</Typography>
          </div>
          <Grid container className="py-2">
            <Grid item xs={6} className="mb-2">
              <Typography variant="body1">Street</Typography>
              <Typography component={"span"} variant="h6" className="my-2">
                <strong>{tempObj?.adress}</strong>
              </Typography>
            </Grid>
            <Grid item xs={6} className="mb-2">
              <Typography variant="body1">City/State</Typography>
              <Typography component={"span"} variant="h6" className="my-2">
                <strong>
                  {tempObj?.city}, {tempObj?.State}
                </strong>
              </Typography>
            </Grid>
            <Grid item xs={6} className="mb-2">
              <Typography variant="body1">Postal Code</Typography>
              <Typography component={"span"} variant="h6" className="my-2">
                <strong>{tempObj?.postalCode}</strong>
              </Typography>
            </Grid>
            <Grid item xs={6} className="mb-2">
              <Typography variant="body1">Country</Typography>
              <Typography component={"span"} variant="h6" className="my-2">
                <strong>{tempObj?.Country}</strong>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default UserAddressCard;
