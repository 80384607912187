import axiosClient from "../../../../utils/api";

export const getSpotifyUserDetails = async (payload) => {
    try {
        let headers = {
            "Authorization": `${payload?.tokenType} ${payload?.spotifyToken}`,
        }
        const response = await axiosClient().request({
            url: "/me",
            method: "get",
            headers: headers
        })
        return response.data;
    } catch (error) {
        console.error("Error occurred during Spotify Connection:", error);
        throw error;
    }
}