import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        isOpen: false,
        actions: null,
        hasCloseBtn: false,
        content: null,
        title: null,
    },
    reducers: {
        openModal: (state, { payload }) => {
            state.isOpen = true;
            state.actions = payload?.actions;
            state.hasCloseBtn = payload?.hasCloseBtn;
            state.content = payload?.content;
            state.title = payload?.title;
        },
        closeModal: (state) => {
            state.isOpen = false;
            state.actions = null;
            state.hasCloseBtn = false;
            state.content = null;
            state.title = null;
        },
    },
});

export const {
    openModal, closeModal
} = modalSlice.actions;

export default modalSlice.reducer;