import { createSlice } from "@reduxjs/toolkit";
import {
  blockuserSuggestionAction,
  getSuggestionsAction,
} from "../../../middlewares/web/suggestions/suggestions";

export const suggestionsSlice = createSlice({
  name: "suggestion",
  initialState: {
    suggestionsLoading: false,
    suggestions: [],
  },
  reducers: {
    RestrictSuggestionAction: (state, action) => {
      const { id } = action.payload;
      let tempArray = state.suggestions?.map((suggestion) => {
        if (suggestion.id === id) {
          return { ...suggestion, restricted: !suggestion.restricted };
        }
        return { ...suggestion };
      });
      state.suggestions = tempArray;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSuggestionsAction.pending, (state) => {
        state.suggestionsLoading = true;
        state.suggestions = [];
      })
      .addCase(getSuggestionsAction.fulfilled, (state, action) => {
        const { response } = action.payload;
        state.suggestionsLoading = false;
        state.suggestions = response;
      })
      .addCase(getSuggestionsAction.rejected, (state) => {
        state.suggestionsLoading = false;
        state.suggestions = [];
      })
      .addCase(blockuserSuggestionAction.fulfilled, (state, action) => {
        const { userId } = action.payload;
        let tempArray = state.suggestions?.map((suggestion) => {
          if (suggestion.id === userId) {
            return { ...suggestion, blocked: !suggestion.blocked };
          }
          return { ...suggestion };
        });
        state.suggestions = tempArray;
      });
  },
});

export const { RestrictSuggestionAction } = suggestionsSlice.actions;

export default suggestionsSlice.reducer;
