import { Box, Button } from "@mui/material";
import React from "react";
import warningIcon from "./../../../../../../assets/images/warning.svg";
import { closeModal } from "../../../../../../store/slices/common/modal";
import { useDispatch } from "react-redux";

const ModalPasswordlessSignIn = () => {
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(closeModal());
  };
  return (
    <>
      <p className="text-center text-darkblue font-18 font-medium">
        If you have a compatible device, you can enable passwordless
        authentication (Face ID, Windows Hello or Biometric Authentication). To
        do this, you need to enable Passwordless Sign In on this device.
      </p>
      <Box className="primary-bg border-radius-10 p-4 pb-7 relative mt-16 text-center text-white">
        <img src={warningIcon} alt="warningIcon" className="warning-icon" />
        <p className="font-20 mt-10 mb-4">
          When you try to login to the site, you will need to fill in the
          username field.
        </p>
        <Box className="mb-3">
          <Box className="light-blue-bg-400 px-5 py-2 border-radius-10 w-max-content m-auto">
            <span className="font-20 font-medium break-words">
              Username: u405610317
            </span>
          </Box>
        </Box>
      </Box>
      <Box className="mt-6 gap-3">
        <Button variant="contained" className="m-auto px-12 w-full mb-3">
          Add a Device to Passwordless Sign In
        </Button>
        <Button
          variant="contained"
          className="m-auto white-btn w-full"
          onClick={onClose}
        >
          Cancel
        </Button>
      </Box>
    </>
  );
};

export default ModalPasswordlessSignIn;
