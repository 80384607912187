import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getSocialLinks,
  setSocialLinks,
} from "../../../../services/web/profile/sociallinks.services";
import { closeModal } from "../../../../slices/common/modal";

export const getSocialLinksAction = createAsyncThunk(
  "me/getSocialLinks",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getSocialLinks(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const setSocialLinksAction = createAsyncThunk(
  "me/setSocialLinks",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await setSocialLinks(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeModal());
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
