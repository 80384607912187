import React from "react";
// import AddCardsIcon from "../assets/SVG/SideBar/AddCardsIcon";
import BookMarksIcon from "../assets/SVG/SideBar/BookMarksIcon";
// import ExplorePageIcon from "../assets/SVG/SideBar/ExplorePageIcon";
import HomeIcon from "../assets/SVG/SideBar/HomeIcon";
// import LiveStreamIcon from "../assets/SVG/SideBar/LiveStreamIcon";
import MessagesIcon from "../assets/SVG/SideBar/MessagesIcon";
import MoreIcon from "../assets/SVG/SideBar/MoreIcon";
// import MyOrderIcon from "../assets/SVG/SideBar/MyOrderIcon";
import MyProfileIcon from "../assets/SVG/SideBar/MyProfileIcon";
import NotificationIcon from "../assets/SVG/SideBar/NotificationIcon";
import SubscriptionsIcon from "../assets/SVG/SideBar/SubscriptionsIcon";
import UserListsIcon from "../assets/SVG/SideBar/UserListsIcon";
import WishRequestsIcon from "../assets/SVG/SideBar/WishRequestsIcon";

const creatorSideBarItems = [
  {
    icon: <HomeIcon />,
    slug: "home",
    hasPage: true,
    label: "Home",
    count: 0,
  },
  {
    icon: <NotificationIcon />,
    slug: "notifications",
    hasPage: true,
    label: "Notification",
    count: 3,
  },
  {
    icon: <WishRequestsIcon />,
    slug: "wish-requests",
    hasPage: false,
    label: "Wish Requests",
    count: 3,
  },
  {
    icon: <WishRequestsIcon />,
    slug: "all-order",
    hasPage: false,
    label: "All Order",
    count: 3,
  },
  {
    icon: <WishRequestsIcon />,
    slug: "my-creator-shop",
    hasPage: false,
    label: "My Creator Shop",
    count: 2,
  },
  {
    icon: <MessagesIcon />,
    slug: "messages",
    hasPage: true,
    label: "Messages",
    count: 0,
  },
  {
    icon: <UserListsIcon />,
    slug: "user-lists",
    hasPage: true,
    label: "User Lists",
    count: 0,
  },
  {
    icon: <BookMarksIcon />,
    slug: "bookmarks",
    hasPage: true,
    label: "Bookmarks",
    count: 0,
  },
  {
    icon: <SubscriptionsIcon />,
    slug: "post-label",
    hasPage: true,
    label: "Post Labels",
    count: 0,
  },
  {
    icon: <SubscriptionsIcon />,
    slug: "vault",
    hasPage: true,
    label: "Vault",
    count: 0,
  },
  {
    icon: <SubscriptionsIcon />,
    slug: "queue",
    hasPage: true,
    label: "Queue",
    count: 0,
  },
  {
    icon: <SubscriptionsIcon />,
    slug: "statements",
    hasPage: true,
    label: "Statements",
    count: 0,
  },
  {
    icon: <SubscriptionsIcon />,
    slug: "statistics",
    hasPage: true,
    label: "Statistics",
    count: 0,
  },
  {
    icon: <MyProfileIcon />,
    slug: "my-profile",
    hasPage: true,
    label: "My Profile",
    count: 0,
  },
  {
    icon: <MoreIcon />,
    slug: "more",
    hasPage: false,
    label: "More",
    count: 0,
  },
];

export default creatorSideBarItems;
