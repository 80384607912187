import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import User1 from "../../../../assets/images/blank.png";
import dayjs from "dayjs";
import { Bookmark, Comment, Favorite, Report } from "@mui/icons-material";
import { UserAvatar } from "../../../atoms";

const PostCreatedDetails = ({ post }) => {
  const [totalAnswers, setTotalAnswers] = useState(0);
  const [quizAnswers, setQuizAnswers] = useState(0);

  useEffect(() => {
    if (post?.pollOptions) {
      let tempSum = 0;
      post?.pollOptions?.map((option) => {
        tempSum += option?.answers?.length;
      });
      setTotalAnswers(tempSum);
    }
  }, [post]);
  useEffect(() => {
    if (post?.quizOptions) {
      let tempSum = 0;
      post?.quizOptions?.map((option) => {
        tempSum += option?.answers?.length;
      });
      setQuizAnswers(tempSum);
    }
  }, [post]);

  return (
    <Grid container alignItems={"flex-start"}>
      {post?.description && (
        <Grid item xs={10}>
          <Typography variant="h6">Description: </Typography>
          <Typography variant="body1 py-2">
            <div dangerouslySetInnerHTML={{ __html: post?.description }} />
          </Typography>
        </Grid>
      )}
      {!post?.description && (
        <Grid item xs={10}>
          <Typography variant="h6">Title: </Typography>
          <Typography variant="body1 py-2">
            <p>{post?.title}</p>
          </Typography>
        </Grid>
      )}
      <Grid item xs={2} className="flex justify-end gap-8">
        <Tooltip title={"No. of Likes"} placement="top">
          <Box className="flex items-center gap-2">
            <Favorite color="error" />
            <p>
              <strong>{post?.post_like?.length}</strong>
            </p>
          </Box>
        </Tooltip>
        <Tooltip title={"No. of Comments"} placement="top">
          <Box className="flex items-center gap-2">
            <Comment color="info" />
            <p>
              <strong>{post?.post_comment?.length}</strong>
            </p>
          </Box>
        </Tooltip>
        <Tooltip title={"Bookmark"} placement="top">
          <Box className="flex items-center gap-2">
            <Bookmark color="warning" />
            <p>
              <strong>{post?.post_comment?.length}</strong>
            </p>
          </Box>
        </Tooltip>
        <Tooltip title={"Report"} placement="top">
          <Box className="flex items-center gap-2">
            <Report color="error" />
            <p>
              <strong>{post?.Reports?.length}</strong>
            </p>
          </Box>
        </Tooltip>
      </Grid>

      {post?.createdAt && (
        <Grid
          item
          xs={post?.description ? 10 : 12}
          sx={{ paddingTop: "16px !important" }}
        >
          <Typography variant="h6">Posted On: </Typography>
          <Typography variant="body1 py-2">
            <p>{dayjs(post?.createdAt).format("DD/MM/YYYY")}</p>
          </Typography>
        </Grid>
      )}
      {post?.users && (
        <Grid item xs={12} sx={{ paddingTop: "16px !important" }}>
          <Typography variant="h6" className="mb-2">Posted By: </Typography>
          <UserAvatar
            type={"users"}
            id={post.users?.id}
            name={post.users?.name}
            username={post.users?.username}
            profileImageSrc={
              post.users?.profileImage
                ? // eslint-disable-next-line no-undef
                  `${process.env.REACT_APP_AWS_URL}${post.users?.profileImage}`
                : User1
            }
          />
        </Grid>
      )}
      {post?.quizOptions.length > 0 && (
        <Grid item xs={12} sx={{ paddingTop: "16px !important" }}>
          <div className="flex flex-col gap-2">
            <Typography variant="h6">Options: </Typography>
            <div className="flex flex-col flex-wrap gap-1">
              {post?.quizOptions?.map((option) => {
                let percent = Number(
                  (option.answers?.length * 100) / quizAnswers
                ).toFixed(2);
                return (
                  <div key={option.optionText}>
                    <div className="flex items-center justify-between mb-1">
                      <Typography variant="body1">
                        {option.optionText}
                      {option?.isCorrectAnswer && (
                        <div className="inline items-center ml-2 px-2.5 py-0.5 text-xs  rounded border bg-white border-green-400 text-green-500">
                          CORRECT ANSWER
                        </div>
                      )}
                      </Typography>
                      <Typography variant="button">
                        No. Of Votes : {option.answers?.length} ({percent}%)
                      </Typography>
                    </div>
                    <div className="w-full bg-slate-200 rounded-full h-2.5 mb-4">
                      <div
                        className="bg-custom-500 h-2.5 rounded-full"
                        style={{
                          width: `${percent}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Grid>
      )}
      {post?.pollOptions.length > 0 && (
        <Grid item xs={12} sx={{ paddingTop: "16px !important" }}>
          <div className="flex flex-col gap-2">
            <Typography variant="h6">Options: </Typography>
            <div className="flex flex-col flex-wrap gap-1">
              {post?.pollOptions?.map((option) => {
                return (
                  <div key={option.optionText}>
                    <div className="flex items-center justify-between mb-1">
                      <Typography variant="body1">
                        {option.optionText}
                      </Typography>
                      <Typography variant="button">
                        No. Of Votes : {option.answers?.length} (
                        {(option.answers?.length * 100) / totalAnswers}%)
                      </Typography>
                    </div>
                    <div className="w-full bg-slate-200 rounded-full h-2.5 mb-4">
                      <div
                        className="bg-custom-500 h-2.5 rounded-full"
                        style={{
                          width: `${
                            (option.answers?.length * 100) / totalAnswers
                          }%`,
                        }}
                      ></div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Grid>
      )}
      {post?.postMedia.filter((media) => media.fileType !== "text")?.length >
        0 && (
        <Grid item xs={12} sx={{ paddingTop: "16px !important" }}>
          <div className="flex flex-col gap-2">
            <Typography variant="h6">Media: </Typography>
            <div className="flex flex-wrap gap-2">
              {post?.postMedia
                ?.filter((media) => media.fileType !== "text")
                ?.map((post) => {
                  return (
                    <div key={post.fileURL} className="w-32 h-32">
                      <img
                        // eslint-disable-next-line no-undef
                        src={`${process.env.REACT_APP_AWS_URL}${post.fileURL}`}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default PostCreatedDetails;
