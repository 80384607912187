import { createSlice } from "@reduxjs/toolkit";
import { registerUser } from "../../../middlewares/auth/register/register";


export const webregisterSlice = createSlice({
  name: 'webregister',
  initialState: {
    webregisterLoading: false,
    webregisterMessage: "",
    webregisterError: false,
    webregisterSuccess: false,
    webregisterUser: null,
  },
  reducers: {
    resetwebregister: (state) => {
      state.webregisterLoading = false;
      state.webregisterMessage = "";
      state.webregisterError = false;
      state.webregisterSuccess = false;
      state.webregisterUser = null;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(registerUser.pending, (state) => {
        state.webregisterLoading = true;
        state.webregisterMessage = "";
        state.webregisterError = false;
        state.webregisterSuccess = false;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        const { response, message } = action.payload
        state.webregisterLoading = false;
        state.webregisterMessage = message;
        state.webregisterError = false;
        state.webregisterSuccess = true;
        state.webregisterUser = response;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.webregisterLoading = false;
        state.webregisterMessage = action.payload.message;
        state.webregisterError = false;
        state.webregisterSuccess = false;
        state.webregisterUser = null;
      })
  }
});

export const {
  resetwebregister
} = webregisterSlice.actions;

export default webregisterSlice.reducer;