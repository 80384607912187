import { Box, Button, FormControl, FormControlLabel, IconButton, InputAdornment, Radio, RadioGroup } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react'
import BlueCircleBg from "./../../../../../assets/images/blue-round-bg.png";
import VerifyWhiteIcon from "./../../../../../assets/images/verify-white.svg";
import VisaIcon from "./../../../../../assets/images/visa.svg";
import ApplePayIcon from "./../../../../../assets/images/apple-pay.svg";
import GPayIcon from "./../../../../../assets/images/gpay.svg";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import User1 from "./../../../../../assets/images/user1.png";
import DollarIcon from "./../../../../../assets/images/dollar-square.svg";
import { closeModal } from '../../../../../store/slices/common/modal';
import { Input } from '../../../../atoms';
import { useDispatch } from 'react-redux';

const ModalSendTip = () => {
    //Send tip to a user
    const dispatch = useDispatch()
    const handleSubmit = (values) => {
        // ngn.submit(values)
        console.log(values)
    }

    const formik = useFormik({
        initialValues: {
            amount: "",
            description: ""
        },
        validationSchema: null,
        onSubmit: handleSubmit,
    });

    const handleClose = () => {
        formik.resetForm()
        dispatch(closeModal())
    }

    return (
        // className="send-tips"
        <>
            <div className="relative mb-4 send-tips">
                <img src={BlueCircleBg} alt="BlueCircleBg" />
                <div className="text-white w-full">
                    <div className="flex flex-wrap gap-3 BlueCirclePosition">
                        <img
                            src={User1}
                            alt="User1"
                            width={60}
                            className="uesr1 cursor-pointer"
                            // onClick={handleOpenDialog2}
                            onClick={handleClose}
                        />
                        <div>
                            <h4 className="font-semibold font-20 flex items-center gap-2">
                                LEO <img src={VerifyWhiteIcon} alt="VerifyWhiteIcon" />
                            </h4>
                            <p className="font-14">@bluntbarbie</p>
                        </div>
                    </div>
                </div>
            </div>
            <Box
                className="without-lable-txtbox"
                variant="outlined"
            >
                <Input
                    type="text"
                    name="amount"
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Tip amount"
                    startAdornment={
                        <InputAdornment position="start">
                            <IconButton edge="start">
                                <img src={DollarIcon} alt="DollarIcon" className='img-dark-filter' />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </Box>
            <p className="font-14 text-darkblue font-medium mt-1 mb-4">
                Minimum $1 USD
            </p>

            <FormControl className="w-full send-tips">
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    className="w-full"
                >
                    <div className="flex items-center justify-between w-full radio-btn-border">
                        <FormControlLabel
                            value="1234 4567 7897 1021"
                            control={<Radio sx={{ top: 0 }} />}
                            label="1234 4567 7897 1021"
                            sx={{
                                display: "flex",
                                alignItems: "center !important"
                            }}
                        />
                        <img src={VisaIcon} alt="VisaIcon" />
                    </div>
                    <div className="flex items-center justify-between w-full radio-btn-border">
                        <FormControlLabel
                            value="Apple Pay"
                            control={<Radio sx={{ top: 0 }} />}
                            label="Apple Pay"
                            sx={{
                                display: "flex",
                                alignItems: "center !important"
                            }}
                        />
                        <img src={ApplePayIcon} alt="ApplePayIcon" />
                    </div>
                    <div className="flex items-center justify-between w-full radio-btn-border">
                        <FormControlLabel
                            value="Google Pay"
                            control={<Radio sx={{ top: 0 }} />}
                            label="Google Pay"
                            sx={{
                                display: "flex",
                                alignItems: "center !important"
                            }}
                        />
                        <img src={GPayIcon} alt="GPayIcon" />
                    </div>
                </RadioGroup>
            </FormControl>

            <div className="text-center add-payment-option mt-2 mb-4">
                <Button variant="outlined" className="w-full text-primary" sx={{ border: "2px dashed #D9D9D9 !important" }}>
                    <AddCircleOutlineIcon className="mr-2" height="22" width="22" />{" "}
                    Add Payment Option
                </Button>
            </div>

            <Input
                placeholder="Your message (Optional)"
                id="your-message"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inputProps={{ maxLength: 200 }}
                endAdornment={
                    <InputAdornment position="end" className='text-black'>{formik.values.description.length}/200</InputAdornment>
                }
            />

            <div className="flex justify-end mt-6 gap-3">
                <Button
                    variant="contained"
                    className="m-auto white-btn"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button variant="contained" className="m-auto px-12">
                    Send Tip
                </Button>
            </div>
        </>

    )
}

export default ModalSendTip
