import React from 'react'

const YourCardsIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.4">
                <path d="M22 8.50488L2 8.50488" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
                <path d="M18 16.5049L16 16.5049" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
                <path d="M13.5 16.5049L9.5 16.5049" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
            </g>
            <path d="M17.56 3.50488L6.45 3.50488C2.89 3.50488 2 4.38488 2 7.89488L2 16.1049C2 19.6149 2.89 20.4949 6.44 20.4949L17.56 20.4949C21.11 20.5049 22 19.6249 22 16.1149L22 7.89488C22 4.38488 21.11 3.50488 17.56 3.50488Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default YourCardsIcon
