import axiosClient from "../../../../utils/api";
// eslint-disable-next-line no-undef
const URI = process.env.REACT_APP_BASE_URL + "/admin/reportReason";
// eslint-disable-next-line no-undef
const ReportURI = process.env.REACT_APP_BASE_URL + "/report";
// eslint-disable-next-line no-undef
const AdminReportURI = process.env.REACT_APP_BASE_URL + "/admin/report";

export const editReason = async (payload) => {
  const URL = `${URI}/edit`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Edit Reason Service:", error);
    throw error;
  }
};

export const getAllReasons = async (payload) => {
  const URL = `${URI}/getAll`;
  // let user = localStorage.getItem("user-details-admin");
  // let headers = {
  //   Authorization: `Bearer ${JSON.parse(user).token}`,
  // };
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
      // headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get All Reasons Service:", error);
    throw error;
  }
};

export const deleteReason = async (payload) => {
  const URL = `${URI}/delete`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Edit Reason Service:", error);
    throw error;
  }
};

export const addReason = async (payload) => {
  const URL = `${URI}/add`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get All Reasons Service:", error);
    throw error;
  }
};

export const addReport = async (payload) => {
  const URL = `${ReportURI}/add`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Add Report Service:", error);
    throw error;
  }
};
export const getAllReportedUsers = async (payload) => {
  const URL = `${AdminReportURI}/getAll`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Add Report Service:", error);
    throw error;
  }
};
