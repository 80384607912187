import { Box, FormControl, OutlinedInput } from "@mui/material";
import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { DiscardModalBtn, SubmitModalBtn } from "../../../atoms";
import { DocumentName } from "../../../../utils/validation/AddDocumentTypeForm";
import { AddDocumentTypeAction } from "../../../../store/middlewares/admin/documentType/documentType";

const ModalAddDocumentType = () => {
  const dispatch = useDispatch();

  // Define Schema For Validation
  const AddDocumentTypeSchema = Yup.object({
    documentName: DocumentName,
  });

  const handleSubmit = (values) => {
    //On Submit Form
    dispatch(AddDocumentTypeAction({ ...values}));
    formik.resetForm();
  };

  // Form Management
  const formik = useFormik({
    initialValues: {
      documentName: "",
    },
    validationSchema: AddDocumentTypeSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
      <Box>
        <FormControl className="w-full" variant="outlined">
          <label htmlFor="name" style={{ color: "rgba(0, 0, 0, 0.6)" }}>
            Document Name
          </label>
          <OutlinedInput
            placeholder="Enter Document Name"
            type="text"
            name="documentName"
            id="documentName"
            size="small"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.documentName}
            className={
              formik.errors.documentName && formik.touched.documentName
                ? "error-border"
                : ""
            }
          />
          {formik.errors.documentName && formik.touched.documentName && (
            <Box className="text-red-500 text-sm mt-1">
              {formik.errors.documentName}
            </Box>
          )}
        </FormControl>
      </Box>
      <Box className="flex items-end justify-end gap-4 text-end">
        <SubmitModalBtn />
        <DiscardModalBtn />
      </Box>
    </form>
  );
};

export default ModalAddDocumentType;
