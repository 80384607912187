import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import GoogleIcon from "../../../../assets/images/google.svg";
import AppleIcon from "../../../../assets/images/apple.svg";

import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { loginAction } from '../../../../store/middlewares/auth/session/auth';
import axiosClient from '../../../../utils/api';

function SocialMediaButtons({ip}) {

  const [user, setUser] = useState(null)
  const dispatch = useDispatch()

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse)
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(() => {
    if (user) {
      axiosClient().get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
        //get all data like name, email, etc. of the google email selected 
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          Accept: 'application/json'
        }
      })
        .then((res) => {
          // call to login the user
          dispatch(loginAction({token: user.access_token, type: "google", password: "", name: res.data.name, email: res.data.email, ipAddress: ip}));
        })
        .catch((err) => console.log(err));
    }
  }, [user, dispatch, ip])

  return (
    <div className="flex gap-4 mt-8">
      <Button variant="outlined" className="w-full" onClick={() => googleLogin()} >
        <img
          src={GoogleIcon}
          alt="google"
          width={27}
          className="mr-3"
        />{" "}
        Google
      </Button>
      <Button variant="outlined" className="w-full" >
        <img src={AppleIcon} alt="Apple" width={27} className="mr-3" />
        Apple ID
      </Button>
    </div>
  )
}

export default SocialMediaButtons
