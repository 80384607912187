import React from 'react'

const ExplorePageIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_579_5273)">
                <path opacity="0.4" d="M14.2031 15.4532C14.1046 15.4533 14.007 15.4339 13.916 15.3962C13.825 15.3585 13.7424 15.3032 13.6728 15.2335L9.26657 10.8272C8.97364 10.5343 8.97364 10.0594 9.26657 9.76657C9.55944 9.47364 10.0343 9.47364 10.3272 9.76657L14.7335 14.1728C15.0264 14.4657 15.0264 14.9406 14.7335 15.2335C14.6639 15.3032 14.5812 15.3585 14.4902 15.3962C14.3992 15.4339 14.3016 15.4533 14.2031 15.4532Z" fill="#292D32" />
                <path opacity="0.4" d="M6.75013 18.5C6.62755 18.5 6.50685 18.47 6.39856 18.4125C6.29028 18.3551 6.19773 18.272 6.129 18.1705C6.06028 18.069 6.01747 17.9522 6.00432 17.8303C5.99118 17.7085 6.0081 17.5852 6.05361 17.4714L9.05361 9.97141C9.09134 9.87711 9.14782 9.79145 9.21964 9.71963C9.29145 9.64782 9.37711 9.59133 9.47141 9.55361L16.9714 6.55361C17.1077 6.49912 17.257 6.48581 17.4007 6.51533C17.5445 6.54485 17.6765 6.6159 17.7802 6.71968C17.884 6.82347 17.9551 6.95542 17.9846 7.09919C18.0141 7.24296 18.0008 7.39223 17.9463 7.52852L14.9463 15.0285C14.9086 15.1228 14.8521 15.2085 14.7803 15.2803C14.7085 15.3521 14.6228 15.4086 14.5285 15.4463L7.02852 18.4463C6.93999 18.4818 6.8455 18.5 6.75013 18.5ZM10.327 10.827L8.09628 16.4037L13.673 14.173L15.9037 8.59628L10.327 10.827Z" fill="#292D32" />
                <path d="M12 24.5C8.79469 24.5 5.78123 23.2518 3.51469 20.9853C1.24814 18.7188 0 15.7053 0 12.5C0 9.29469 1.24819 6.28123 3.51469 4.01469C5.78119 1.74814 8.79469 0.5 12 0.5C15.2053 0.5 18.2188 1.74819 20.4853 4.01469C22.7519 6.28119 24 9.29469 24 12.5C24 15.7053 22.7518 18.7188 20.4853 20.9853C18.2188 23.2519 15.2053 24.5 12 24.5ZM12 2C6.21028 2 1.5 6.71028 1.5 12.5C1.5 18.2897 6.21028 23 12 23C17.7897 23 22.5 18.2897 22.5 12.5C22.5 6.71028 17.7897 2 12 2Z" fill="#292D32" />
            </g>
            <defs>
                <clipPath id="clip0_579_5273">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ExplorePageIcon
