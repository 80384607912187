import React, { useState } from 'react'
import {
    Box,
    Tab,
    IconButton,
    Button,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ArrowLeft from "./../../../../../assets/images/arrow-left.svg";
import ProfilePostBg from "./../../../../../assets/images/profile-post-cover.png";
import ProfilePostUser from "./../../../../../assets/images/profile-post-user.png";
import GreenVerifyIcon from "./../../../../../assets/images/green-verify.svg";
import starIcon from "./../../../../../assets/images/star.svg";
import ShareIcon from "./../../../../../assets/images/share.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import BlueHeartIcon from "./../../../../../assets/images/heart-blue.svg";
import GalleryBlueIcon from "./../../../../../assets/images/gallery-blue.svg";
import HeartIcon from "./../../../../../assets/images/heart.svg";
import videoGreenIcon from "./../../../../../assets/images/video-green.svg";
import TiktokIcon from "./../../../../../assets/images/tiktok.svg";
import InstagramIcon from "./../../../../../assets/images/instagram.svg";
import SearchBlueIcon from "./../../../../../assets/images/search-blue.svg";
import GridIcon from "./../../../../../assets/images/grid-blue.svg";
import ListMenuBlueIcon from "./../../../../../assets/images/liet-menu.svg";
import AllIcon from "./../../../../../assets/images/screenmirroring.svg";
import PhotoIcon from "./../../../../../assets/images/gallery-blue.svg";
import VideoIcon from "./../../../../../assets/images/video-blue.svg";
import dollarblueIcon from "./../../../../../assets/images/dollar-square-blue.svg";
import ChatBlueIcon from "./../../../../../assets/images/chat-icon-blue.svg";
import CircleIcon from "@mui/icons-material/Circle";


import { ModalProfileImage, HighlightsSlider, NoArticleSuggestionMenu, NoArticlePosts, ModalProfileVideo } from "../../../../../newComponents/molecules";
import noArticlePosts from "../../../../../utils/noArticlePosts";
import highlightsArray from "../../../../../utils/highlightsArray";
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../../store/slices/common/modal';

const UserProfileSection = ({ isExpired = false, showHighlights = true }) => {
    const dispatch = useDispatch()

    const [value, setValue] = useState("1")

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className="xl:mr-6 mr-0">
            <div className="bg-white rounded-2xl p-4">
                <div className="flex items-center justify-between mb-3">
                    <div className="flex items-center gap-3 ">
                        <IconButton
                            color="primary"
                            aria-label="add to shopping cart"
                            className="pt-0 pb-0"
                        >
                            <img
                                src={ArrowLeft}
                                alt="ArrowLeft"
                                height={26}
                                width={26}
                            />
                        </IconButton>
                    </div>
                    <NoArticleSuggestionMenu />
                </div>

                <div className="relative">
                    <img src={ProfilePostBg} alt="profile background" />
                    {isExpired &&
                        <>
                            <div className="profile-post-tag expire-profile-post-tag">
                                <p className="mb-0 font-16">Expire Plan On</p>
                                <p className="mb-0 font-18 font-semibold">March 11, 2024</p>
                            </div>
                            <div className="available-tag">
                                <p className="mb-0">
                                    <CircleIcon className="mr-1 font-14" />
                                    Available Now
                                </p>
                            </div>
                        </>}
                </div>
                <div className="relative flex flex-xs-nowrap gap-10">
                    <div className='profile-user rounded-full h-144px'>
                        <img
                            src={ProfilePostUser}
                            alt="ProfilePostUser"
                            className="h-full max-w-full w-full object-cover"
                            width={144}
                            height={144} onClick={() => dispatch(openModal({
                                title: "YRSA CLICKS",
                                content: <ModalProfileVideo />,
                                hasCloseBtn: true
                            }))}
                        />
                    </div>
                    <div className="flex XXl-flex-wrap justify-between mt-3 gap-5">
                        <div>
                            <p className="font-18 font-semibold text-darkblue flex items-center gap-3">
                                Yrsa Clicks{" "}
                                <img src={GreenVerifyIcon} alt="Green Verify Icon" />
                            </p>
                            <p>@yrsaclicks</p>
                            <p className="mt-1 flex flex-wrap">
                                Outdoor enthusiast{" "}
                                <span className="text-lightgray-300 mx-2">|</span> Foodie{" "}
                                <span className="text-lightgray-300 mx-2">|</span>{" "}
                                <sub>
                                    <MoreHorizIcon className="mx-2" />
                                </sub>{" "}
                                <a href="#top" className="text-primary">
                                    More Info
                                </a>
                            </p>
                        </div>

                        <div>
                            <div className="flex flex-wrap xl:flex-no-wrap gap-3 justify-end profile-post-btns">
                                <Button
                                    variant="outlined"
                                    className="light-blue-icon-btn"
                                >
                                    <img
                                        src={dollarblueIcon}
                                        alt="dollarblueIcon"
                                        width={24}
                                    />
                                </Button>
                                <Button
                                    variant="outlined"
                                    className="light-blue-icon-btn"
                                >
                                    <img src={starIcon} alt="starIcon" width={24} />
                                </Button>
                                <Button
                                    variant="outlined"
                                    className="light-blue-icon-btn"
                                >
                                    <img src={ChatBlueIcon} alt="ChatBlueIcon" width={24} />
                                </Button>
                                <Button
                                    variant="outlined"
                                    className="light-blue-icon-btn"
                                >
                                    <img src={ShareIcon} alt="ShareIcon" width={24} />
                                </Button>
                                <div className="flex flex-col gap-3">
                                    <Button
                                        variant="contained"
                                        className="m-auto px-12 min-w-170px min-h-40px"
                                    >
                                        Subscribe
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className="whitespace-no-wrap m-auto px-12 btn-outline-primary min-w-170px min-h-40px"
                                    >
                                        <img
                                            src={BlueHeartIcon}
                                            alt="BlueHeartIcon"
                                            width={24}
                                            className="mr-2"
                                        />{" "}
                                        Wish Request
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap justify-between gap-3 mt-3 md:mt-0">
                    <div className="flex gap-3">
                        <Button
                            variant="contained"
                            className="m-auto px-8 light-gray-btn"
                        >
                            <img
                                src={GalleryBlueIcon}
                                alt="GalleryBlueIcon"
                                className="mr-2"
                            />{" "}
                            150
                        </Button>
                        <Button
                            variant="contained"
                            className="m-auto px-8 light-gray-btn"
                        >
                            <img src={HeartIcon} alt="HeartIcon" className="mr-2" />{" "}
                            21.6k
                        </Button>
                        <Button
                            variant="contained"
                            className="m-auto px-8 light-gray-btn"
                        >
                            <img
                                src={videoGreenIcon}
                                alt="videoGreenIcon"
                                className="mr-2"
                            />{" "}
                            83
                        </Button>
                    </div>
                    <div className="flex gap-3">
                        <Button
                            variant="contained"
                            className="m-auto px-4 light-gray-btn"
                        >
                            <img src={TiktokIcon} alt="TiktokIcon" />
                        </Button>
                        <Button
                            variant="contained"
                            className="m-auto px-4 light-gray-btn"
                        >
                            <img src={InstagramIcon} alt="InstagramIcon" />
                        </Button>
                    </div>
                </div>

                {showHighlights && <HighlightsSlider highlightsArray={highlightsArray} />}

            </div>

            <div className="bg-white rounded-2xl p-4 pb-0 mt-4 profile-tabs">
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                        >
                            <Tab label="252 Posts" value="1" />
                            <Tab label="532 Media" value="2" />
                            <Tab label="Creator Shop" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1" className="px-0">
                        <div className="flex justify-between items-center">
                            <h5 className="font-18 text-darkblue font-semibold">
                                Recent
                            </h5>
                            <div className="flex gap-3">
                                <Button
                                    variant="outlined"
                                    className="light-blue-icon-btn"
                                >
                                    <img
                                        src={SearchBlueIcon}
                                        alt="SearchBlueIcon"
                                        width={24}
                                    />
                                </Button>
                                <Button
                                    variant="outlined"
                                    className="light-blue-icon-btn"
                                >
                                    <img src={GridIcon} alt="GridIcon" width={24} />
                                </Button>
                                <Button
                                    variant="outlined"
                                    className="light-blue-icon-btn"
                                >
                                    <img
                                        src={ListMenuBlueIcon}
                                        alt="ListMenuBlueIcon"
                                        width={24}
                                    />
                                </Button>
                            </div>
                        </div>

                        <div className="posttabbtn mt-6">
                            <TabContext value={value}>
                                <Box>
                                    <TabList
                                        onChange={handleChange}
                                        aria-label="simple tabs example"
                                    >
                                        <Tab
                                            className="flex items-center mr-2"
                                            icon={
                                                <img
                                                    src={AllIcon}
                                                    alt="All Icon"
                                                    className="mb-0"
                                                />
                                            }
                                            label="All (535)"
                                            value="All"
                                        />
                                        <Tab
                                            className="flex items-center mr-2"
                                            icon={
                                                <img
                                                    src={PhotoIcon}
                                                    alt="All Icon"
                                                    className="mb-0"
                                                />
                                            }
                                            label="Photo (452)"
                                            value="Photo"
                                        />
                                        <Tab
                                            className="flex items-center mr-2"
                                            icon={
                                                <img
                                                    src={VideoIcon}
                                                    alt="All Icon"
                                                    className="mb-0"
                                                />
                                            }
                                            label="Archive (83)"
                                            value="Video"
                                        />
                                    </TabList>
                                </Box>

                                <TabPanel value="All" className="px-0"></TabPanel>
                            </TabContext>
                        </div>
                    </TabPanel>
                    <TabPanel value="2">Item Two</TabPanel>
                    <TabPanel value="3">Item Three</TabPanel>
                </TabContext>
            </div>

            <NoArticlePosts noArticlePosts={noArticlePosts} />

        </div>
    )
}

export default UserProfileSection
