import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeAdminModal } from "../../../../store/slices/common/adminModal";
import { Close } from "@mui/icons-material";

const AdminModal = () => {
  const { isOpen, actions, content, title } = useSelector(
    (state) => state.adminModal
  );
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeAdminModal());
  };

  return (
    <Dialog open={isOpen} maxWidth={"md"} fullWidth onClose={onClose}>
      <DialogContent>
        <Box className="flex items-center justify-between my-5">
          <Typography variant={"h6"} sx={{ fontWeight: "600", color: "#000" }}>
            {title}
          </Typography>
          <Tooltip title="Close" placement="top">
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
        {content}
      </DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

export default AdminModal;
