import React from 'react'

const AddCardsIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 9H13.5" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
            <path opacity="0.4" d="M6 17H8" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
            <path opacity="0.4" d="M10.5 17H14.5" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M22 12.53V16.61C22 20.12 21.11 21 17.56 21H6.44C2.89 21 2 20.12 2 16.61V8.39C2 4.88 2.89 4 6.44 4H13.5" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
            <g opacity="0.4">
                <path d="M16.5 6.75H22" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
                <path d="M19.25 9.5V4" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
            </g>
        </svg>
    )
}

export default AddCardsIcon
