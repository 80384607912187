import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";

const SelectDropdown = ({
  label = "",
  name = "",
  error = false,
  type = "text",
  placeholder,
  onChange = () => {},
  onBlur = () => {},
  options = [],
  value = "",
  errorMsg = "",
  startAdornment,
  endAdornment,
  ...rest
}) => {
  return (
    <FormControl className="w-full" variant="outlined">
      {label && (
        <label htmlFor={name} className="text-black">
          {label}
        </label>
      )}
      <Select
        placeholder={placeholder}
        className={error ? "error-border" : ""}
        type={type}
        name={name}
        id={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        {...rest}
      >
        {options?.map((option) => {
          return (
            <MenuItem
              key={option.value}
              value={option.value}
              className="flex items-center gap-3"
            >
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
      {error && <div className="text-red-500 text-sm mt-1">{errorMsg}</div>}
    </FormControl>
  );
};

export default SelectDropdown;
