import {  createSlice } from "@reduxjs/toolkit";
import { verifyOtp } from "../../../middlewares/auth/forgotPassword/otp";

export const webotpSlice = createSlice({
  name: 'webotp',
  initialState: {
    webotpLoading: false,
    webotpMessage: "",
    webotpError: false,
    webotpSuccess: false,
    webotpUser: null,
  },
  reducers: {
    resetwebotp: (state) => {
      state.webotpLoading = false;
      state.webotpMessage = "";
      state.webotpError = false;
      state.webotpSuccess = false;
      state.webotpUser = null;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(verifyOtp.pending, (state) => {
        state.webotpLoading = true;
        state.webotpMessage = "";
        state.webotpError = false;
        state.webotpSuccess = false;
        state.webotpUser = null;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        const { message, response } = action.payload
        state.webotpLoading = false;
        state.webotpMessage = message;
        state.webotpError = false;
        state.webotpSuccess = true;
        state.webotpUser = response;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        const { message } = action.payload
        state.webotpLoading = false;
        state.webotpMessage = message;
        state.webotpError = true;
        state.webotpSuccess = false;
        state.webotpUser = null
      })
  }
});

export const { resetwebotp } = webotpSlice.actions;

export default webotpSlice.reducer;