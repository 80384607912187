import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { closeModal } from "../../../../../store/slices/common/modal";
import { useDispatch } from "react-redux";

const ModalPinnedLists = () => {
  const dispatch = useDispatch();
  return (
    <>
      <FormControl className="w-full">
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
        >
          <div className="light-blue-bg-500 pl-2 pr-4 pt-1 pb-2 border-radius-10 gap-3 w-full">
            <FormControlLabel
              className="w-full"
              value="option1"
              control={<Radio />}
              label={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <span>
                    Following <span className="text-black">(empty)</span>
                  </span>
                </Box>
              }
            />
          </div>
        </RadioGroup>
      </FormControl>

      <div className="flex justify-end mt-6 gap-3">
        <Button
          variant="contained"
          className="m-auto white-btn"
          onClick={() => dispatch(closeModal())}
        >
          Cancel
        </Button>
        <Button variant="contained" className="m-auto px-12">
          Save
        </Button>
      </div>
    </>
  );
};

export default ModalPinnedLists;
