import { Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import getMonthCount from "../../../utils/getMonthCount";
import { subscribeToPlanAction } from "../../../store/middlewares/web/subscriptions/subscriptions";

const SelectedPlanButton = ({ planId, userId }) => {
  const { plans } = useSelector((state) => state.plans);
  let selectedPlan = plans?.find((plan) => plan?.id === planId);
  let cost = selectedPlan?.discount
    ? selectedPlan?.price * (1 - selectedPlan?.price / 100).toFixed(2)
    : selectedPlan?.price;
  let label = selectedPlan?.price
    ? `Subscribed for $${cost} (${
        selectedPlan?.discount
      }% off) for ${getMonthCount(selectedPlan?.duration)} months`
    : "Subscribed for Free";
  const dispatch = useDispatch();
  return (
    <Button
      onClick={() =>
        dispatch(
          subscribeToPlanAction({
            creatorId: userId,
            planID: planId,
          })
        )
      }
      variant="outlined"
      className="px-12 w-full min-h-40px mb-2"
    >
      {label}
    </Button>
  );
};

export default SelectedPlanButton;
