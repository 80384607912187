import { Button, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import React from 'react'
import BlueCircleBg from "./../../../../../../assets/images/blue-round-bg.png";
import User1 from "./../../../../../../assets/images/user1.png";
import VerifyWhiteIcon from "./../../../../../../assets/images/verify-white.svg";
import DollarIcon from "./../../../../../../assets/images/dollar-square.svg";

const TipDialog = ({ openDialog1, handleCloseDialog1, toggleDrawer }) => {
    return (
        <>
            <Dialog
                open={openDialog1}
                onClose={handleCloseDialog1}
                className="send-tips send-tips-creator"
            >
                <DialogTitle>
                    <div className="flex justify-between items-center borderBottom pb-3">
                        <h3>Send Tip</h3>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="relative mb-4">
                        <img src={BlueCircleBg} alt="BlueCircleBg" />
                        <div className="text-white w-full">
                            <div className="flex flex-wrap gap-3 BlueCirclePosition">
                                <img
                                    src={User1}
                                    alt="User1"
                                    width={60}
                                    className="uesr1 cursor-pointer"
                                // onClick={handleOpenDialog2}
                                />
                                <div>
                                    <h4 className="font-semibold font-20 flex items-center gap-2">
                                        LEO <img src={VerifyWhiteIcon} alt="VerifyWhiteIcon" />
                                    </h4>
                                    <p className="font-14">@bluntbarbie</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FormControl
                        className="w-full"
                        variant="outlined"
                    >
                        <OutlinedInput
                            type="text"
                            placeholder="Tip amount"
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconButton edge="start">
                                        <img src={DollarIcon} alt="DollarIcon" className="img-dark-filter" />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <p className="font-14 text-darkblue font-medium mt-1 mb-4">
                        Please Add a Payment Card
                    </p>

                    <div className="flex justify-end mt-6 gap-3">
                        <Button
                            variant="contained"
                            className="m-auto white-btn"
                            onClick={handleCloseDialog1}
                        >
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={toggleDrawer(true)} className="m-auto px-12">
                            Add a Payment Card
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default TipDialog