import React, { useEffect, useState } from "react";
import ScreenMirroringIcon from "./../../../../../assets/images/screenmirroring.svg";
import ActiveUserIcon from "./../../../../../assets/images/active-user.svg";
import TimerIcon from "./../../../../../assets/images/timer.svg";
import BlockedUserIcon from "./../../../../../assets/images/blockuserIcon.svg";
import Masonry from "@mui/lab/Masonry";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, CircularProgress, Grid, Tab } from "@mui/material";
import UserListFolders from "../../../../molecules/web/userLists/UserListFolders";
import { useSelector } from "react-redux";
import DisplayUserInCollection from "../../../../molecules/web/userLists/DisplayUserInCollection";
import { NoItemsInCollection } from "../../../../molecules";

const UsersTab = ({ active, setActive }) => {
  const [selectedTab, setSelectedTab] = useState("all");

  const { users, getUsersLoading: usersLoading } = useSelector(
    (state) => state.users
  );

  const [usersToDisplay, setUsersToDisplay] = useState([]);

  useEffect(() => {
    if (users) {
      setUsersToDisplay(users?.filter((user) => user?.isAddedInUserList));
    }
  }, [users]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <TabContext value={selectedTab}>
      <Box className="mb-5">
        <TabList
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="scrollable"
          scrollButtons="hidden"
        >
          <Tab
            icon={
              <img
                src={ScreenMirroringIcon}
                alt="ScreenMirroringIcon"
              />
            }
            label={`All (${
              active?.userCount < 10
                ? `0${active?.userCount}`
                : active?.userCount 
                ? active?.userCount
                : 0
            })`}
            value="all"
          />
          <Tab
            icon={<img src={ActiveUserIcon} alt="ActiveUserIcon" />}
            label={`Active (${
              active?.totalActiveUser < 10
                ? `0${active?.totalActiveUser}`
                : active?.totalActiveUser
                ? active?.totalActiveUser
                : 0
            })`}
            value="active"
          />
          <Tab
            icon={<img src={TimerIcon} alt="TimerIcon" width={24} />}
            label={`Expired (${
              active?.totalExpiredUser < 10
                ? `0${active?.totalExpiredUser}`
                : active?.totalExpiredUser
                ? active?.totalExpiredUser
                : 0
            })`}
            value="expired"
          />
          <Tab
            icon={<img src={BlockedUserIcon} alt="BlockedUserIcon" />}
            label={`Restricted (${
              active?.totalRestrictedUser < 10
                ? `0${active?.totalRestrictedUser}`
                : active?.totalRestrictedUser
                ? active?.totalRestrictedUser
                : 0
            })`}
            value="restricted"
          />
          <Tab
            icon={<img src={BlockedUserIcon} alt="BlockedUserIcon" />}
            label={`Blocked (${
              active?.totalBlockedUser < 10
                ? `0${active?.totalBlockedUser}`
                : active?.totalBlockedUser
                ? active?.totalBlockedUser
                : 0
            })`}
            value="blocked"
          />
        </TabList>
      </Box>

      <Grid container spacing={1} className="h-full mb-4 fans-message">
        <Grid item xs={12} md={12} lg={8}>
          <div className="light-gray-bg lg:mr-4 post-no-padding  mb-4">
            <TabPanel value="all" className="p-0 w-full">
              <div className="h-full mb-4 fans-message">
                {usersLoading ? (
                  <div className="py-12 w-full rounded-2xl flex justify-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    {usersToDisplay?.length === 0 ? (
                      <NoItemsInCollection />
                    ) : (
                      <Masonry
                        columns={{ xs: 1, sm: 1, md: 1, lg: 2 }}
                        spacing={2}
                      >
                        {usersToDisplay?.map((user, index) => {
                          return (
                            <DisplayUserInCollection
                              key={index}
                              user={user}
                              index={index}
                              folderId={active?.id}
                            />
                          );
                        })}
                      </Masonry>
                    )}
                  </>
                )}
              </div>
            </TabPanel>

            <TabPanel value="blocked" className="p-0 w-full">
              {usersLoading ? (
                <div className="py-12 w-full rounded-2xl flex justify-center">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {usersToDisplay?.filter((user) => user?.blocked)?.length ===
                  0 ? (
                    <NoItemsInCollection />
                  ) : (
                    <Masonry
                      columns={{ xs: 1, sm: 1, md: 1, lg: 2 }}
                      spacing={2}
                    >
                      {usersToDisplay
                        ?.filter((user) => user?.blocked)
                        ?.map((user, index) => {
                          return (
                            <DisplayUserInCollection
                              key={index}
                              user={user}
                              index={index}
                              folderId={active?.id}
                            />
                          );
                        })}
                    </Masonry>
                  )}
                </>
              )}
            </TabPanel>

            <TabPanel value="expired" className="p-0 w-full">
              {usersLoading ? (
                <div className="py-12 w-full rounded-2xl flex justify-center">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {usersToDisplay?.length === 0 ? (
                    <NoItemsInCollection />
                  ) : (
                    <Masonry
                      columns={{ xs: 1, sm: 1, md: 1, lg: 2 }}
                      spacing={2}
                    >
                      {usersToDisplay?.map((user, index) => {
                        return (
                          <DisplayUserInCollection
                            key={index}
                            user={user}
                            index={index}
                            folderId={active?.id}
                          />
                        );
                      })}
                    </Masonry>
                  )}
                </>
              )}
            </TabPanel>

            <TabPanel value="restricted" className="p-0 w-full">
              {usersLoading ? (
                <div className="py-12 w-full rounded-2xl flex justify-center">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {usersToDisplay?.filter((user) => user?.restricted)
                    ?.length === 0 ? (
                    <NoItemsInCollection />
                  ) : (
                    <Masonry
                      columns={{ xs: 1, sm: 1, md: 1, lg: 2 }}
                      spacing={2}
                    >
                      {usersToDisplay
                        ?.filter((user) => user?.restricted)
                        ?.map((user, index) => {
                          return (
                            <DisplayUserInCollection
                              key={index}
                              user={user}
                              index={index}
                              folderId={active?.id}
                            />
                          );
                        })}
                    </Masonry>
                  )}
                </>
              )}
            </TabPanel>

            <TabPanel value="active" className="p-0 w-full">
              {usersLoading ? (
                <div className="py-12 w-full rounded-2xl flex justify-center">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {usersToDisplay?.filter((user) => {
                    let isNotActive = user?.restricted || user?.blocked;
                    return !isNotActive;
                  })?.length === 0 ? (
                    <NoItemsInCollection />
                  ) : (
                    <Masonry
                      columns={{ xs: 1, sm: 1, md: 1, lg: 2 }}
                      spacing={2}
                    >
                      {usersToDisplay
                        ?.filter((user) => {
                          let isNotActive = user?.restricted || user?.blocked;
                          return !isNotActive;
                        })
                        ?.map((user, index) => {
                          return (
                            <DisplayUserInCollection
                              key={index}
                              user={user}
                              index={index}
                              folderId={active?.id}
                            />
                          );
                        })}
                    </Masonry>
                  )}
                </>
              )}
            </TabPanel>
          </div>
        </Grid>

        <UserListFolders
          mediaType={selectedTab}
          type={"users"}
          active={active}
          setActive={setActive}
          usersToDisplay={usersToDisplay}
        />
      </Grid>
    </TabContext>
  );
};

export default UsersTab;
