import { Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import Logo from "../../../../../assets/images/logo.svg";
import { closeModal } from "../../../../../store/slices/common/modal";
import { logoutAction } from "../../../../../store/middlewares/auth/session/auth";

const ModalLogout = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleDelete = () => {
    //Logout user API Call
    dispatch(logoutAction());
  };

  return (
    <>
      <div className="flex justify-center items-center pb-3 pt-5">
        <div>
          <div className="flex justify-center items-center pb-7">
            <img src={Logo} alt={"Logout"} className="w-60 " />
          </div>
          <h3 className="text-lg text-center text-[#1E405C] font-semibold">
            Are you sure you want to logout?
          </h3>
        </div>
      </div>
      <div className="flex justify-end mt-6 gap-3">
        <Button
          variant="contained"
          className="m-auto white-btn"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          className="m-auto px-12"
          onClick={handleDelete}
        >
          Logout
        </Button>
      </div>
    </>
  );
};

export default ModalLogout;
