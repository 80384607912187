import { createSlice } from "@reduxjs/toolkit";
import {
  GetAllSettingsAction,
  GetAllSettingsForWebAction,
  GetGeneralSettingsAction,
  UpdateGeneralSettingsAction,
  UpdateSettingsAction,
} from "../../../middlewares/admin/settings/settings";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    settingsLoading: false,
    settingsMessage: "",
    settingsError: false,
    items: null,
    editSuccess: false,
    editError: false,
    editMessage: "",
  },
  extraReducers(builder) {
    builder
      // Get All Settings
      .addCase(GetAllSettingsAction.pending, (state) => {
        state.settingsLoading = true;
        state.settingsError = false;
        state.settingsMessage = "";
      })
      .addCase(GetAllSettingsAction.fulfilled, (state, action) => {
        state.settingsLoading = false;
        state.settingsError = false;
        state.items = action.payload.response;
        state.settingsMessage = action.payload.message;
      })
      .addCase(GetAllSettingsAction.rejected, (state, action) => {
        state.settingsLoading = false;
        state.settingsError = true;
        state.settingsMessage = action.payload.message;
        state.items = null;
      })
      // Get General Pagination Settings
      .addCase(GetGeneralSettingsAction.pending, (state) => {
        state.settingsLoading = true;
        state.settingsError = false;
        state.settingsMessage = "";
      })
      .addCase(GetGeneralSettingsAction.fulfilled, (state, action) => {
        state.settingsLoading = false;
        state.settingsError = false;
        state.items = action.payload.response;
        state.settingsMessage = action.payload.message;
      })
      .addCase(GetGeneralSettingsAction.rejected, (state, action) => {
        state.settingsLoading = false;
        state.settingsError = true;
        state.settingsMessage = action.payload.message;
        state.items = null;
      })
      // Get All Settings For Website
      .addCase(GetAllSettingsForWebAction.pending, (state) => {
        state.settingsLoading = true;
        state.settingsError = false;
        state.settingsMessage = "";
      })
      .addCase(GetAllSettingsForWebAction.fulfilled, (state, action) => {
        state.settingsLoading = false;
        state.settingsError = false;
        state.items = action.payload.response;
        state.settingsMessage = action.payload.message;
      })
      .addCase(GetAllSettingsForWebAction.rejected, (state, action) => {
        state.settingsLoading = false;
        state.settingsError = true;
        state.settingsMessage = action.payload.message;
        state.items = null;
      })
      // Update Settings 
      .addCase(UpdateSettingsAction.pending, (state) => {
        state.editSuccess = false;
        state.editError = false;
        state.editMessage = "";
      })
      .addCase(UpdateSettingsAction.fulfilled, (state, action) => {
        state.editSuccess = true;
        state.editError = false;
        state.items = action.payload.response;
        state.editMessage = action.payload.message;
      })
      .addCase(UpdateSettingsAction.rejected, (state, action) => {
        state.editSuccess = false;
        state.editError = true;
        state.editMessage = action.payload.message;
      })
      // Update General Pagination Settings 
      .addCase(UpdateGeneralSettingsAction.pending, (state) => {
        state.editSuccess = false;
        state.editError = false;
        state.editMessage = "";
      })
      .addCase(UpdateGeneralSettingsAction.fulfilled, (state, action) => {
        state.editSuccess = true;
        state.editError = false;
        state.items = action.payload.response;
        state.editMessage = action.payload.message;
      })
      .addCase(UpdateGeneralSettingsAction.rejected, (state, action) => {
        state.editSuccess = false;
        state.editError = true;
        state.editMessage = action.payload.message;
      });
  },
});

export const {
  settingsRequest,
  settingsSuccess,
  settingsError,
  editSettingsSuccess,
  editSettingsError,
  editSettingsRequest,
  resetEdit,
} = settingsSlice.actions;

export default settingsSlice.reducer;
