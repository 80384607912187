import {
  Button,
  Divider,
  // Divider,
  FormControlLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import ListMenuBlueIcon from "./../../../../../assets/images/liet-menu.svg";
import { useDispatch } from "react-redux";
import { getPostsByUserIdWebsiteAction } from "../../../../../store/middlewares/web/post/posts";

const PostsFilterMenu = ({ id }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState("all");
  const [value1, setValue1] = useState("latest");
  const [value2, setValue2] = useState("asc");

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const handleChange1 = (e) => {
    console.log("call");
    setValue1(e.target.value);
  };
  const handleChange2 = (e) => {
    setValue2(e.target.value);
  };

  const getPosts = useCallback(() => {
    dispatch(getPostsByUserIdWebsiteAction({ id, type: [value, value1, value2] }));
  }, [value, value1, value2]);

  useEffect(() => {
    getPosts();
  }, [getPosts]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        className="light-blue-icon-btn"
      >
        <img src={ListMenuBlueIcon} alt="ListMenuBlueIcon" width={24} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        // id="more-detail"
        open={open}
        // className="radiobtn-menu"
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          width: "200px",
          sx: {
            padding: "6px 16px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.08))",
            mt: 1.5,
            "& .MuiAvatar-root": { width: 32, height: 32, ml: -0.5, mr: 1 },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={value}
          name="radio-buttons-group-1"
          onChange={handleChange}
          sx={{ margin: "0px 16px" }}
        >
          <MenuItem
            onClick={handleClose}
            sx={{
              flexDirection: "column",
              alignItems: "flex-start",
              paddingX: "0 !important",
            }}
          >
            <FormControlLabel
              value="all"
              control={
                <Radio
                  sx={{ paddingY: "0 !important", paddingLeft: "0 !important" }}
                />
              }
              label="All Time"
            />
          </MenuItem>
          <MenuItem
            onClick={handleClose}
            sx={{
              flexDirection: "column",
              alignItems: "flex-start",
              paddingX: "0 !important",
            }}
          >
            <FormControlLabel
              value="all"
              control={
                <Radio
                  sx={{ paddingY: "0 !important", paddingLeft: "0 !important" }}
                />
              }
              label="Go To Date"
            />
          </MenuItem>
        </RadioGroup>
        <Divider className="w-full" />
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={value1}
          name="radio-buttons-group-2"
          onChange={handleChange1}
          sx={{ margin: "0px 16px" }}
        >
          <MenuItem
            onClick={handleClose}
            sx={{
              flexDirection: "column",
              alignItems: "flex-start",
              paddingX: "0 !important",
            }}
          >
            <FormControlLabel
              value="latest"
              control={
                <Radio
                  sx={{
                    paddingY: "0 !important",
                    paddingLeft: "0 !important",
                  }}
                />
              }
              label="Latest Posts"
            />
          </MenuItem>
          <MenuItem
            onClick={handleClose}
            sx={{
              flexDirection: "column",
              alignItems: "flex-start",
              paddingX: "0 !important",
            }}
          >
            <FormControlLabel
              value="mostLiked"
              control={
                <Radio
                  sx={{
                    paddingY: "0 !important",
                    paddingLeft: "0 !important",
                  }}
                />
              }
              label="Most Liked"
            />
          </MenuItem>
          <MenuItem
            onClick={handleClose}
            sx={{
              flexDirection: "column",
              alignItems: "flex-start",
              paddingX: "0 !important",
            }}
          >
            <FormControlLabel
              value="highestTips"
              control={
                <Radio
                  sx={{
                    paddingY: "0 !important",
                    paddingLeft: "0 !important",
                  }}
                />
              }
              label="Highest Tips"
            />
          </MenuItem>
        </RadioGroup>
        <Divider className="w-full" />
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          // defaultValue="female"
          value={value2}
          name="radio-buttons-group-3"
          onChange={handleChange2}
          sx={{ margin: "0px 16px" }}
        >
          <MenuItem
            onClick={handleClose}
            sx={{
              flexDirection: "column",
              alignItems: "flex-start",
              paddingX: "0 !important",
            }}
          >
            <FormControlLabel
              value="asc"
              control={
                <Radio
                  sx={{ paddingY: "0 !important", paddingLeft: "0 !important" }}
                />
              }
              label="Ascending"
            />
          </MenuItem>
          <MenuItem
            onClick={handleClose}
            sx={{
              flexDirection: "column",
              alignItems: "flex-start",
              paddingX: "0 !important",
            }}
          >
            <FormControlLabel
              value="desc"
              control={
                <Radio
                  sx={{ paddingY: "0 !important", paddingLeft: "0 !important" }}
                />
              }
              label="Descending"
            />
          </MenuItem>
        </RadioGroup>
      </Menu>
    </>
  );
};

export default PostsFilterMenu;
