import React from 'react'
import NotifyUser2 from './../../../../../assets/images/notification/notifyuser2.png';
import SubscriptionsBlueIcon from "./../../../../../assets/images/subscriptionblue.svg";
import verifyIcon from './../../../../../assets/images/green-verify.svg';
import starIcon from './../../../../../assets/images/star.svg';
import { Button } from '@mui/material';

const FavoritedNotifications = () => {
    //Notification in Favorite Tab
    return (
        <div className="light-gray-box flex justify-between gap-2">
            <div className="flex flex-sm-wrap items-start gap-6">
                <div className="relative">
                    <img
                        src={NotifyUser2}
                        alt="NotifyUser2"
                        className="notify-user"
                    />
                    <img
                        src={SubscriptionsBlueIcon}
                        alt="SubscriptionsBlueIcon"
                        className="notify-user-status"
                    />
                </div>
                <div>
                    <div className="flex flex-wrap items-center gap-2 title-width">
                        <p className="text-darkblue font-20 font-semibold">
                            Leo
                        </p>
                        <img src={verifyIcon} alt="verifyIcon" />
                        <span className="line">|</span>
                        <p>@bluntbarble</p>
                    </div>
                    <div className="mt-2 flex gap-3">
                        <Button
                            variant="outlined"
                            className="light-blue-btn"
                        >
                            <img
                                src={starIcon}
                                alt="starIcon"
                                width={20}
                                className="mr-2"
                            />
                            Add to favorites and other lists
                            subscription to your profile!
                        </Button>
                    </div>
                </div>
            </div>
            <p className="font-medium text-nowrap date-position	">
                March 7
            </p>
        </div>
    )
}

export default FavoritedNotifications
