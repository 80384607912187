import { TabContext, TabList } from "@mui/lab";
import { Box, Button, CircularProgress, Tab } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RepeatCircle from "./../../../../../assets/images/repeat-circle.svg";
import AddCircleIcon from "./../../../../../assets/images/add-circle.svg";
import { HomeSingleSuggestion } from "../../../../organisms";
import { useDispatch, useSelector } from "react-redux";
import { getSuggestionsAction } from "../../../../../store/middlewares/web/suggestions/suggestions";

const SuggestionFullMenu = ({
  hasTab = true,
  hasRepeat = true,
  hasAdd = true,
  title = "Suggestion",
}) => {
  const dispatch = useDispatch();
  const [type, setType] = useState("user");
  const { suggestions, suggestionsLoading } = useSelector(
    (state) => state.suggestions
  );

  const { pathname } = useLocation();

  useEffect(() => {
    setType(pathname.split("/")[1]);
  }, [pathname]);

  const navigate = useNavigate();

  const getSuggestion = useCallback(() => {
      // Only dispatch the action if suggestions have not been loaded yet
      dispatch(getSuggestionsAction({ pagination: true }));
  }, [dispatch]);

  useEffect(() => {
    getSuggestion();
  }, [getSuggestion]);

  const [value, setValue] = useState("0");

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleShuffle = () => {
    // Reset the loaded state to allow re-fetching of suggestions
    getSuggestion();
  };

  return (
    <div className="bg-white rounded-2xl p-4 mb-3">
      <div className="flex justify-between items-center mb-2 gap-2 ">
        <div className="flex flex-wrap items-center gap-2">
          <h3 className="uppercase font-semibold font-18 text-darkblue mb-2">
            {title}
          </h3>
          {hasTab && (
            <div className="post-tab mb-2">
              <TabContext value={value}>
                <Box>
                  <TabList
                    onChange={handleChangeTab}
                    aria-label="simple tabs example"
                  >
                    <Tab label="All" value="0" />
                    <Tab label="Free" value="1" />
                  </TabList>
                </Box>
              </TabContext>
            </div>
          )}
        </div>
        <div className="flex gap-2">
          {hasRepeat && (
            <Link href="#">
              <img
                src={RepeatCircle}
                alt="RepeatCircle"
                width={28}
                onClick={handleShuffle}
                className="img-dark-filter"
              />
            </Link>
          )}
          {hasAdd && (
            <Link href="#">
              <img
                src={AddCircleIcon}
                alt="AddCircle"
                width={28}
                className="img-dark-filter"
              />
            </Link>
          )}
        </div>
      </div>
      <div className="flex flex-col justify-center items-center h-auto">
        {" "}
        {/* Adjust height as needed */}
        {suggestionsLoading ? (
          <CircularProgress />
        ) : (
          suggestions.map((suggestion, index) => (
            <HomeSingleSuggestion suggestion={suggestion} key={index} />
          ))
        )}
      </div>
      <div className="mt-3">
        <Button
          variant="outlined"
          className="light-blue-btn w-full"
          onClick={() => navigate(`/${type}/all-suggestions`)}
        >
          See All
        </Button>
      </div>
    </div>
  );
};

export default SuggestionFullMenu;
