import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addSubscriptionsPlan,
  getSubscriptionsPlan,
  subscribePlan,
} from "../../../services/web/subscriptions/subscriptions";
import { closeModal } from "../../../slices/common/modal";
import { subscribeToUser } from "../../../slices/common/posts";

export const getSubscriptionsPlanAction = createAsyncThunk(
  "subscriptions/getSubscriptionsPlan",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getSubscriptionsPlan(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addSubscriptionsPlanAction = createAsyncThunk(
  "subscriptions/addSubscriptionsPlan",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await addSubscriptionsPlan(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        // location.reload();
        dispatch(closeModal())
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const subscribeToPlanAction = createAsyncThunk(
  "subscriptions/subscribeToPlan",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await subscribePlan(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeModal())
        dispatch(subscribeToUser(payload))
        return fulfillWithValue({plans: response?.response?.plans});
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
