import { Box, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

const UserAvatar = ({
  username,
  name,
  profileImageSrc,
  type = "users",
  id,
}) => {
  return (
    <Box> 
      <NavLink
        to={`/admin/${type}/${id}`}
        className="flex items-center admin-user-avatar"
      >
        <img
          className="h-14 w-14 mr-5 rounded-[50%] object-cover pointer-events-none"
          src={profileImageSrc}
          alt={name}
        />
        <Box className="flex flex-col gap-2">
          <Typography variant="body1">{name}</Typography>
          {username && <Typography variant="p">@{username}</Typography>}
        </Box>
      </NavLink>
    </Box>
  );
};

export default UserAvatar;
