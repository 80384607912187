import { createSlice } from "@reduxjs/toolkit";
import {
  EditCMSAction,
  GetSingleCMSAction,
} from "../../../middlewares/admin/cms/cms";
import { getAuthCmsAction } from "../../../middlewares/auth/cms/cms";

export const cmsSlice = createSlice({
  name: "cms",
  initialState: {
    cmsLoading: false,
    cmsMessage: "",
    cmsError: false,
    cms: null,
    editSuccess: false,
    editError: false,
    editMessage: "",
  },
  extraReducers(builder) {
    builder
      .addCase(getAuthCmsAction.pending, (state) => {
        state.cmsLoading = true;
        state.cmsMessage = "";
        state.cmsError = false;
        state.cms = null;
      })
      .addCase(getAuthCmsAction.fulfilled, (state, action) => {
        const {
          payload: {
            message,
            response: { cms },
          },
        } = action;
        state.cmsLoading = false;
        state.cmsMessage = message;
        state.cmsError = false;
        state.cms = cms;
      })
      .addCase(getAuthCmsAction.rejected, (state, action) => {
        state.cmsLoading = false;
        state.cmsMessage = action.payload.message;
        state.cmsError = true;
        state.cms = null;
      })
      .addCase(GetSingleCMSAction.pending, (state) => {
        state.cmsLoading = true;
        state.cmsError = false;
        state.cmsMessage = "";
      })
      .addCase(GetSingleCMSAction.fulfilled, (state, action) => {
        let {
          payload: {
            response: { cms },
            message,
          },
        } = action;
        state.cmsLoading = false;
        state.cmsError = false;
        state.cms = cms;
        state.cmsMessage = message;
      })
      .addCase(GetSingleCMSAction.rejected, (state, action) => {
        state.cmsLoading = false;
        state.cmsError = true;
        state.cmsMessage = action.payload.message;
        state.cms = null;
      })
      .addCase(EditCMSAction.pending, (state) => {
        state.cmsLoading = true;
        state.cmsError = false;
        state.cmsMessage = "";
      })
      .addCase(EditCMSAction.fulfilled, (state, action) => {
        let {
          payload: {
            response: { updatedCms },
            message,
          },
        } = action;
        state.cmsLoading = false;
        state.cmsError = false;
        state.cms = updatedCms;
        state.cmsMessage = message;
      })
      .addCase(EditCMSAction.rejected, (state, action) => {
        state.cmsLoading = false;
        state.cmsError = true;
        state.cmsMessage = action.payload.message;
        state.cms = null;
      });
  },
});

export default cmsSlice.reducer;
