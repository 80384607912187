import axiosClient from "../../../../utils/api";

export const getAllSettingsForWeb = async () => {
  try {
    const response = await axiosClient().request({
      url: "/setting/getSettings",
      method: "post",
    });
    return response.data;
  } catch (error) {
    console.log("Error at Get Settings Service", error);
    throw error;
  }
};
