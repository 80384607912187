import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createFolders,
  createUserListFolders,
  deleteFolders,
  deleteUserListFolders,
  getFolders,
  getUserListFolders,
} from "../../../services/web/folders/folders.service";
import { closeModal } from "../../../slices/common/modal";

//Bookmark Folders
//Get Folder
export const getFolderAction = createAsyncThunk(
  "folders/getFolders",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getFolders();
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Add Folder
export const addFolderAction = createAsyncThunk(
  "folders/createFolders",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    let { onlyAdd, ...rest } = payload;
    try {
      const response = await createFolders(rest);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        if (onlyAdd) {
          dispatch(closeModal());
        }
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Delete Folder
export const deleteFolderAction = createAsyncThunk(
  "folders/deleteFolder",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await deleteFolders(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        // location.reload();
        dispatch(closeModal());
        return fulfillWithValue({ folderId: payload.folderId, ...response });
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//User List Folders
//Get Folder
export const getUserListFolderAction = createAsyncThunk(
  "folders/getUserListFolders",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getUserListFolders();
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Add Folder
export const addUserListFolderAction = createAsyncThunk(
  "folders/createUserListFolders",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    let { fromAdd, ...rest } = payload;
    try {
      const response = await createUserListFolders(rest);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        if (fromAdd) {
          console.log("title");
        }
        dispatch(closeModal());
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Delete Folder
export const deleteUserListFolderAction = createAsyncThunk(
  "folders/deleteUserListFolders",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await deleteUserListFolders(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeModal());
        location.reload()
        return fulfillWithValue({ folderId: payload.folderId, ...response });
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
