import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import ShareBlackIcon from "./../../../../../assets/images/share-black.png";
import MoreSquare from "./../../../../../assets/images/more-square.svg";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../../store/slices/common/modal";
import ModalConfirmDeletePost from "../../home/ModalConfirmDeletePost";

const UserPostDropdown = ({ post }) => {
  let { id } = post;
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); //set Anchor for Menu
  };

  const handleClose = () => {
    setAnchorEl(null); //reset Anchor for Menu
  };

  const handleDeletPost = () => {
    dispatch(
      openModal({
        title: "DELETE POST",
        content: <ModalConfirmDeletePost postId={id} />,
      })
    );
  };

  return (
    <>
      <IconButton
        size="small"
        aria-controls={open ? "more-detail" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <img
          src={ShareBlackIcon}
          alt="ShareIcon"
          width={24}
          className="img-dark-filter"
        />
      </IconButton>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={open ? "more-detail" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <img
          src={MoreSquare}
          alt="MoreSquare"
          width={24}
          className="img-dark-filter"
        />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="more-detail"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.08))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClose}>Copy Link to Post</MenuItem>
        <MenuItem onClick={handleClose}>Pin To your Profile Page</MenuItem>
        <MenuItem onClick={handleClose}>Share Post To Story</MenuItem>
        <MenuItem onClick={handleClose}>Edit Post </MenuItem>
        <MenuItem onClick={handleClose}>Archive Post</MenuItem>
        <MenuItem onClick={handleClose}>Add to Private Archive</MenuItem>
        <MenuItem onClick={handleClose}>Add A Tag</MenuItem>
        <Divider />
        <MenuItem onClick={handleDeletPost}>Delete Post</MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>View Detailed Statistics</MenuItem>
      </Menu>
    </>
  );
};

export default UserPostDropdown;
