import { Button, IconButton } from "@mui/material";
import React from "react";
import SearchBlueIcon from "./../../../../../assets/images/search-blue.svg";
import GridIcon from "./../../../../../assets/images/grid-blue.svg";
import AllIcon from "./../../../../../assets/images/screenmirroring.svg";
import PhotoIcon from "./../../../../../assets/images/gallery-blue.svg";
import VideoIcon from "./../../../../../assets/images/video-blue.svg";
import ListIcon from "./../../../../../assets/images/list-icon.svg";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { useState } from "react";
import PostsFilterMenu from "../../userProfile/PostsFilterMenu";

function ProfileTabMenu({
  value,
  onTabChange,
  id,
  setIsList,
  isList,
  mediaCount,
}) {
  const [tabValue, setTabValue] = useState("All");
  const handleChange = (event, newValue) => {
    // switching tabs
    onTabChange(newValue);
    setTabValue(newValue);
  };

  const userProfileTabs = [
    {
      iconSrc: AllIcon,
      label: "All",
      value: "all",
    },
    {
      iconSrc: PhotoIcon,
      label: "Photo",
      value: "image",
    },
    {
      iconSrc: VideoIcon,
      label: "Video",
      value: "video",
    },
  ];

  return (
    <div className="flex flex-col py-[15px] w-full">
      <div className="flex justify-between items-center">
        <h5 className="font-18 text-darkblue font-semibold">Recent</h5>
        <div className="flex gap-3">
          <Button variant="outlined" className="light-blue-icon-btn">
            <img src={SearchBlueIcon} alt="SearchBlueIcon" width={24} />
          </Button>
          {mediaCount !== 0 && (
            <IconButton
              variant="outlined"
              className="light-blue-icon-btn"
              onClick={() => setIsList(!isList)}
            >
              {isList ? (
                <img src={GridIcon} alt="GridIcon" width={24} />
              ) : (
                <img src={ListIcon} alt="ListIcon" width={24} />
              )}
            </IconButton>
          )}
          <PostsFilterMenu id={id} />
        </div>
      </div>

      {value === "1" && (
        <div className="posttabbtn mt-6">
          <TabContext value={tabValue}>
            <Box>
              <TabList onChange={handleChange} aria-label="simple tabs example">
                {userProfileTabs?.map((tab) => {
                  return (
                    <Tab
                      key={tab.label}
                      className="flex items-center mr-2"
                      icon={
                        <img
                          src={tab?.iconSrc}
                          alt={tab?.label}
                          className="mb-0"
                        />
                      }
                      label={tab?.label}
                      value={tab?.value}
                    />
                  );
                })}
              </TabList>
            </Box>
          </TabContext>
        </div>
      )}
    </div>
  );
}

export default ProfileTabMenu;
