import { createAsyncThunk } from "@reduxjs/toolkit";
import { register } from "../../../services/auth/register/register.service";

export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await register(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
