import { createAsyncThunk } from "@reduxjs/toolkit";
import { reset } from "../../../services/auth/reset/reset.service";

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await reset(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
