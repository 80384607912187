import React from 'react';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import We3AvatarImg from './../../../../../assets/images/we3avatar.png';
import WalletIcon from './../../../../../assets/images/wallet-3.svg';

export default function Web3Avatar() {
  return (
    <Box
      component="main"
      className='flex'
      sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} className='fans-profile'>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={2} className='h-calc-200 fans-profile mt-3'>
            <div className='bg-white rounded-2xl py-3 px-5 w-full flex items-center justify-center flex-col'>
              <img src={We3AvatarImg} alt="We3AvatarImg" width={225} />
              <div className='w-400px text-center mt-3'>
                <h3 className='font-26 text-darkblue font-semibold mb-3'>Web3 Avatar</h3>
                <p className='font-14 sm:px-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce molestie, justo ac lobortis.</p>
                <div className='heading mt-3'>
                  <Button variant="contained" className='lightcoffee-btn w-full'><img src={WalletIcon} alt='WalletIcon' className='mr-3' /> Connect Your Metamask Wallet</Button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

// export default Profile;