import { configureStore } from "@reduxjs/toolkit";

//--------------------- admin --------------------
import { authSlice } from "./slices/admin/session/auth";
import { usersSlice } from "./slices/common/users";
import { userSlice } from "./slices/common/users/user";
import { cmsSlice } from "./slices/common/cms";
import { countryCodeSlice } from "./slices/common/countryCode";

// ------------------ web ----------------------------
import { webauthSlice } from "./slices/auth/session/auth.slice";
import { webforgotSlice } from "./slices/auth/forgotPassword/forgot.slice";
import { webregisterSlice } from "./slices/auth/register/register.slice";
import { webotpSlice } from "./slices/auth/forgotPassword/otp.slice";
import { webresetSlice } from "./slices/auth/reset/reset.slice";
import { meSlice } from "./slices/web/profile/myProfile/me.slice";
import { languagesSlice } from "./slices/common/language";
import { notificationsSlice } from "./slices/web/profile/myProfile/notifications.slice";
import { twoStepAuth } from "./slices/web/profile/myProfile/twoStepAuth.slice";
import { settingsSlice } from "./slices/admin/settings/settings.slice";
import { postsSlice } from "./slices/common/posts";
import { toastSlice } from "./slices/common/toast";
import { modalSlice } from "./slices/common/modal";
import { adminModalSlice } from "./slices/common/adminModal";
import { foldersSlice } from "./slices/web/folders/folders.slice";
import { layoutSlice } from "./slices/common/layout";
import { suggestionsSlice } from "./slices/web/suggestion/suggestion.slice";
import { mediaSlice } from "./slices/web/media/media.slice";
import { reportReasonsSlice } from "./slices/common/reportReasons";
import { postSlice } from "./slices/common/posts/post.slice";
import { dashboardSlice } from "./slices/admin/dashboard/dashboard.slices";
import { stateSlice } from "./slices/common/state";
import { documentTypeSlice } from "./slices/common/documentType";
import { plansSlice } from "./slices/web/plans";
import { subscriptionsSlice } from "./slices/common/subscriptions";

function configStore() {
  const store = configureStore({
    reducer: {
      auth: authSlice.reducer,
      users: usersSlice.reducer,
      user: userSlice.reducer,
      cms: cmsSlice.reducer,
      countryCode: countryCodeSlice.reducer,
      // web reducer
      webauth: webauthSlice.reducer,
      webforgot: webforgotSlice.reducer,
      webregister: webregisterSlice.reducer,
      webotp: webotpSlice.reducer,
      webreset: webresetSlice.reducer,
      me: meSlice.reducer,
      languages: languagesSlice.reducer,
      notifications: notificationsSlice.reducer,
      qr: twoStepAuth.reducer,
      posts: postsSlice.reducer,
      settings: settingsSlice.reducer,
      toast: toastSlice.reducer,
      modal: modalSlice.reducer,
      adminModal: adminModalSlice.reducer,
      layout: layoutSlice.reducer,
      folders: foldersSlice.reducer,
      suggestions: suggestionsSlice.reducer,
      media: mediaSlice.reducer,
      post: postSlice.reducer,
      dashboard: dashboardSlice.reducer,
      reportReasons: reportReasonsSlice.reducer,
      stateList: stateSlice.reducer,
      subscriptionsList: subscriptionsSlice.reducer,
      documentTypes: documentTypeSlice.reducer,
      plans: plansSlice.reducer,
      // Add other reducers if needed
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }),
  });
  return store;
}

export default configStore;
