import { Close } from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'

const DisplayImagesInPost = ({ filesSrc, removeAble = false, removeImage, showDisplayMore = true }) => {
    const [cutImages, setCutImages] = useState(showDisplayMore ? 3 : filesSrc?.length) // number of images in basic layout

    //update the number of images to display
    const handleClick = () => {
        setCutImages(cutImages + 2)
    }

    useEffect(() => {
        if(!showDisplayMore) {
            setCutImages(filesSrc?.length)
        }
    }, [filesSrc, showDisplayMore])

    return (
        <Grid container>
            {filesSrc?.slice(0, cutImages).map((src, index) => (
                <Grid item xs={index === 0 ? 12 : 6} className={`normal-padding relative ${index % 2 !== 0 ? "pad-right" : ""} ${index === 0 ? "max-h-[400px]" : "max-h-[200px]"}`} key={`image-${index}`}>
                    <img key={index} src={src} alt={`post-${index}`} className={`w-full object-cover h-full rounded-2xl`} />
                    {(index === cutImages - 1 && filesSrc.length - cutImages > 0 && showDisplayMore) &&
                        /* more button */
                        <div className='more-image cursor-pointer' onClick={handleClick}>
                            <p className='mb-0 '>{filesSrc.length - cutImages} more</p>
                        </div>}
                    {removeAble && <IconButton disableRipple className={`remove-icon border bg-black ${index % 2 !== 0 ? "extra-right" : ''} `} size='small' sx={{ color: "#fff" }} onClick={() => removeImage(index)}>
                        <Close />
                    </IconButton>}
                </Grid>
            ))
            }
        </Grid >
    )
}

export default DisplayImagesInPost
