import { createSlice } from "@reduxjs/toolkit";
import { getPostAction } from "../../../middlewares/web/post/post";

export const postSlice = createSlice({
    name: "post",
    initialState: {
        postLoading: false,
        post: null,
    },
    extraReducers(builder) {
        builder
            .addCase(getPostAction.pending, (state) => {
                state.postLoading = true;
                state.post = null
            })
            .addCase(getPostAction.fulfilled, (state, action) => {
                const { response } = action.payload;
                state.postLoading = false
                state.post = response
            })
            .addCase(getPostAction.rejected, (state) => {
                state.postLoading = false;
                state.post = null;
            })
    }
});

export default postSlice.reducer;
