import React, { useEffect, useRef } from 'react'
import { Box, CircularProgress, Divider, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import {  VerifyTOTPFirstTimeAction } from '../../../../../../store/middlewares/web/profile/myProfile/account';
import OtpFields from '../../../../auth/OtpFields';

const QrSrc = ({ qrSrc, handleClose }) => {
    const inputRefs = useRef([]);
    const dispatch = useDispatch()

    const { verificationLoading, verificationMessage, isVerified } = useSelector((state) => state.qr)


    const handleSubmit = (e) => {
        e.preventDefault();
        const otp = inputRefs.current.map(input => input.value).join('');
        const payload = {
            otp
        };
        dispatch(VerifyTOTPFirstTimeAction(payload))
    }

    useEffect(() => {
        if (!verificationLoading) {
            if (isVerified) {
                handleClose()
            }
        }
    }, [verificationLoading, isVerified, handleClose])

    return (
        <Grid container>
            {verificationLoading ?
                <Grid item xs={12}>
                    <Box className="flex justify-center items-center flex-col h-full p-4">
                        <CircularProgress />
                    </Box>
                </Grid> :
                <>
                    <Grid item xs={12} sx={{ ml: 4, mt: 2 }}>
                        <Typography variant={"button"}>Step 1: </Typography>
                        <Typography variant={"h5"} >SCAN QR </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {/*  Scan QR in Authenticator App*/}
                        <img src={qrSrc} alt='qr' className='w-full' />
                    </Grid>
                    <Grid item xs={12} sx={{ ml: 4, mb: 2, mr: 4 }}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} className="max-h-auto">
                        <Box className="flex justify-center flex-col h-full sm:p-8 p-8 lg:pl-8 md:pl-16 sm:pl-16 pl-12 pt-0">
                            <Box>
                                <Typography variant={"button"}>Step 2: </Typography>
                                <Typography variant={"h5"}>ENTER OTP </Typography>
                            </Box>
                            {/* Enter TOTP from Authenticator App  */}
                            <OtpFields handleSubmit={handleSubmit} inputRefs={inputRefs} errorMsg={verificationMessage} error={!isVerified && verificationMessage} />
                        </Box>
                    </Grid>
                </>
            }

        </Grid>
    )
}

export default QrSrc
