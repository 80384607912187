import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import BlueLine from './../../../../../assets/images/blue-line.svg';
import Button from '@mui/material/Button';
import MessagesBg from './../../../../../assets/images/messagesbg.svg';
import StreamBg from './../../../../../assets/images/streamsbg.png';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Datepicker, Timepicker } from '../../../../atoms';

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const AddQueueDialog = () => {

    const [tabIndex, setTabIndex] = useState(0);
    const [age, setAge] = useState('');

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    const handleChange = (event) => {
        setAge(event.target.value);
    }

    return (
        <div className='mt-3 px-0 pb-0'>
            <Tabs value={tabIndex} onChange={handleTabChange} aria-label="simple tabs example">
                <Tab label="Scheduled" id="tab-0" aria-controls="tabpanel-0" />
                <Tab label="Save For Later" id="tab-1" aria-controls="tabpanel-1" />
            </Tabs>
            <TabPanel value={tabIndex} index={0} className='tab-box'>
                <div className='light-gray-bg rounded-2xl p-3 flex items-center'>
                    <img src={BlueLine} alt='BlueLine' className='mr-3' />
                    <span className='mb-0 font-14'>Please choose a date and time for your action to be executed.</span>
                </div>

                <Grid container spacing={2} className='mt-3'>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='mb-4'>
                        <div className='pr-0 sm:pr-2'>
                            <Datepicker className='w-full' />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='mb-4'>
                        <div className='pl-0 sm:pl-2'>
                            <Timepicker className='w-full' />
                        </div>
                    </Grid>

                    <div className='flex justify-end w-full'>
                        <Button variant="contained" className='m-auto'>Post</Button>
                    </div>
                </Grid>

                <Grid container spacing={2} className='mt-3'>
                    <Grid item xs={12} md={6} lg={6} className='sm:pr-2 mb-4 '>
                        <Button className='w-full white-shadow-btn' variant="outlined" endIcon={<img src={MessagesBg} alt='MessagesBg' height={30} width={30} />}>
                            Mass Messages
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} className='sm:pl-2 mb-4'>
                        <Button className='w-full white-shadow-btn' variant="outlined" endIcon={<img src={StreamBg} alt='StreamBg' height={30} width={30} />}>
                            Stream
                        </Button>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={tabIndex} index={1} className='tab-box'>
                <div className='light-gray-bg rounded-2xl p-3 flex items-start'>
                    <img src={BlueLine} alt='BlueLine' className='mr-3' />
                    <span className='mb-0 font-14'>Once the post is created, it will go to the end of the Saved for Later queue, and be published automatically, if this feature is turned on.</span>
                </div>

                <FormControl fullWidth>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        onChange={handleChange}>
                        <MenuItem value={0}>6h since my last post</MenuItem>
                        <MenuItem value={1}>6h since my last post</MenuItem>
                        <MenuItem value={2}>12h since my last post</MenuItem>
                        <MenuItem value={3}>24h since my last post</MenuItem>
                        <MenuItem value={4}>48h since my last post</MenuItem>
                    </Select>
                </FormControl>

                <div className='flex justify-end w-full my-4'>
                    <div className='mr-3'>
                        <Button variant="outlined">Cancel</Button>
                    </div>
                    <Button variant="contained" className='m-auto'>Post</Button>
                </div>
            </TabPanel>
        </div>
    );
};

export default AddQueueDialog;
