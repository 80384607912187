import React from "react";
import SaveForLaterImg from "./../../../../../assets/images/saveforlater.png";

const NoItemsInCollection = () => {
  return (
    <div className="flex flex-col justify-center items-center p-4 h-calc-200">
      <img
        src={SaveForLaterImg}
        alt="SaveForLaterImg"
        width={246}
        // className="img-dark-filter"
      />
      <p className="font-20 mt-3">Nothing found</p>
    </div>
  );
};

export default NoItemsInCollection;
