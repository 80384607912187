import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../../atoms";
import { closeModal } from "../../../../../../store/slices/common/modal";
import { setSocialLinks } from "../../../../../../store/services/web/profile/sociallinks.services";

const ModalChangeYouTube = () => {
  const { me } = useSelector((state) => state?.me);
  const dispatch = useDispatch();

  const [youtube, setYoutube] = useState(
    me?.socialLinks.find((link) => link.type === "youtube")?.url || ""
  );

  useEffect(() => {
    setYoutube(
      me?.socialLinks.find((link) => link.type === "youtube")?.url || ""
    ); // Set youtube handle of the current logged-in user after updating
  }, [me]);

  const schema = Yup.object({
    youtube: Yup.string()
      .url("Invalid URL")
      .required("youtube handle is required"),
  });

  const handleClose = () => {
    formik.resetForm(); // Reset form on Modal Close
    dispatch(closeModal());
  };

  const handleSubmit = (values) => {
    // Handle Form Submit
    console.log(values);

    dispatch(setSocialLinks({ url: values.youtube, type: "youtube" })); // API Call to update youtube handle
    handleClose();
  };

  // Form initialization and set default value
  const formik = useFormik({
    initialValues: { youtube },
    enableReinitialize: true,
    validationSchema: schema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        name="youtube"
        value={formik.values.youtube}
        onChange={formik.handleChange}
        placeholder="Enter youtube Handle"
        onBlur={formik.handleBlur}
        id="youtube"
        error={formik.errors.youtube && formik.touched.youtube}
        errorMsg={formik.errors.youtube}
      />

      <div className="flex justify-end mt-6 gap-3">
        <Button
          variant="contained"
          className="m-auto white-btn"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button type="submit" variant="contained" className="m-auto px-12">
          Save
        </Button>
      </div>
    </form>
  );
};

export default ModalChangeYouTube;
