import { createSlice } from "@reduxjs/toolkit";
import { GetStateForWebAction, } from "../../../middlewares/web/profile/myProfile/me";
import {
  AddStateAction,
  EditStateAction,
  GetAllStateAction,
  RemoveStateAction,
} from "../../../middlewares/admin/state/state";

export const stateSlice = createSlice({
  name: "state",
  initialState: {
    stateLoading: false,
    states: [],
    actionSuccess: false,
    actionError: false,
    actionMessage: "",
  },
  extraReducers(builder) {
    builder
      .addCase(GetStateForWebAction.pending, (state) => {
        state.stateLoading = true;
      })
      .addCase(GetStateForWebAction.fulfilled, (state, action) => {
        state.stateLoading = false;
        state.states = action.payload.response;
      })
      .addCase(GetStateForWebAction.rejected, (state) => {
        state.stateLoading = false;
        state.states = [];
      })
      .addCase(GetAllStateAction.pending, (state) => {
        state.stateLoading = true;
      })
      .addCase(GetAllStateAction.fulfilled, (state, action) => {
        state.stateLoading = false;
        state.states = action.payload.response;
      })
      .addCase(GetAllStateAction.rejected, (state) => {
        state.stateLoading = false;
        state.states = [];
      })
      .addCase(EditStateAction.fulfilled, (state, action) => {
        const {
          payload: { response },
        } = action;
        let tempStates = state.states.map((state) => {
          if (state.id === response.id) {
            return { ...state, ...response };
          }
          return { ...state };
        });
        state.states = tempStates;
      })
      .addCase(AddStateAction.fulfilled, (state, action) => {
        let { response } = action.payload;
        let tempArray = state.states;
        tempArray.push({ ...response, stateName: response.name });
        state.states = tempArray;
      })
      .addCase(RemoveStateAction.fulfilled, (state, action) => {
        let tempUsers = state.states.filter(
          (code) => code.id !== action.payload.id
        );
        state.states = tempUsers;
      });
  },
});

export default stateSlice.reducer;
