import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import ArrowLeft from "./../../../../../assets/images/arrow-left.svg";
import GreenVerifyIcon from "./../../../../../assets/images/green-verify.svg";
import starIcon from "./../../../../../assets/images/star.svg";
import ShareIcon from "./../../../../../assets/images/share.png";
import CircleIcon from "@mui/icons-material/Circle";
import BlueHeartIcon from "./../../../../../assets/images/heart-blue.svg";
import GalleryBlueIcon from "./../../../../../assets/images/gallery-blue.svg";
import HeartIcon from "./../../../../../assets/images/heart.svg";
import videoGreenIcon from "./../../../../../assets/images/video-green.svg";
import TwitterIcon from "./../../../../../assets/images/twitter.svg";
import User1 from "./../../../../../assets/images/blank.png";
import YoutubeIcon from "./../../../../../assets/images/youtube.svg";
import TiktokIcon from "./../../../../../assets/images/tiktok.svg";
import InstagramIcon from "./../../../../../assets/images/instagram.svg";
import ProfileDropdownMenu from "../ProfileDropdownMenu";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { subscribeToPlanAction } from "../../../../../store/middlewares/web/subscriptions/subscriptions";
import { openModal } from "../../../../../store/slices/common/modal";
import {
  ModalSubscribeToPlan,
  UserProfileSubscription,
} from "../../../../molecules";

const UserProfileDetails = ({ user, postLikeCount }) => {
  const [lengthtoShow, setLengthToShow] = useState(30);
  const [basePlan, setBasePlan] = useState(null);
  const [subscriptionBundleArray, setSubscriptionBundleArray] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { plans } = useSelector((state) => state.plans);

  useEffect(() => {
    if (plans.length > 1) {
      setSubscriptionBundleArray(
        plans
          ?.filter((item) => item?.planType !== "free")
          ?.filter((item) => item?.duration !== "oneMonth")
      );
    } else {
      setSubscriptionBundleArray([]);
    }
    let tempObj = plans?.find((item) => item?.duration === "oneMonth");

    setBasePlan(
      tempObj
        ? tempObj
        : plans?.find((item) => item?.planType === "free")
        ? plans?.find((item) => item?.planType === "free")
        : null
    );
  }, [plans]);

  const handleIconClick = (value) => {
    if (user?.socialLinks[0]) {
      let url;
      user?.socialLinks?.forEach((socialLink) => {
        if (socialLink?.type == value) {
          url = socialLink.url;
        }
      });
      if (url) {
        window.open(url, "_blank");
      }
    }
  };

  return (
    <div className="bg-white rounded-2xl p-4 collectionsFans">
      <div className="flex items-center justify-between mb-3">
        <div className="flex items-center gap-3">
          <IconButton
            color="primary"
            aria-label="add to shopping cart"
            className="pt-0 pb-0"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img
              src={ArrowLeft}
              alt="ArrowLeft"
              height={26}
              width={26}
              className="img-dark-filter"
            />
          </IconButton>
        </div>
        <ProfileDropdownMenu user={user} />
      </div>

      <div className="relative">
        <div className="other-user-cover-img">
          <img
            src={
              user?.coverImg
                ? // eslint-disable-next-line no-undef
                  `${process.env.REACT_APP_AWS_URL}${user?.coverImg}`
                : User1
            }
            alt={user?.name}
            className="w-full lg:h-[300px] h-[150px] object-cover pointer-events-none"
          />
        </div>
        {basePlan && (
          <div className="profile-post-tag">
            {basePlan?.price ? (
              <p className="mb-0">${basePlan?.price.toFixed(2)}</p>
            ) : (
              <p>Free</p>
            )}
          </div>
        )}
        <div className="available-tag">
          <p className="mb-0">
            <CircleIcon className="mr-1 font-14" /> Available Now
          </p>
        </div>
        <div className="flex lg:flex-nowrap flex-wrap">
          <div className="relative lg:top-[-64px] top-[-30px] lg:left-[32px] left-[10px]">
            <div className="w-16 lg:w-32 h-16 lg:h-32 rounded-full border-4 white-border object-cover">
              <img
                src={user?.profileImage ? user?.profileImage : User1}
                alt={user?.name}
                className="rounded-full w-full h-full object-cover pointer-events-none"
              />
            </div>
          </div>
          <div className="flex w-full flex-wrap gap-y-0 gap-x-4 lg:ml-[40px] ml-[0px]">
            <div className="flex items-start justify-between lg:flex-nowrap flex-wrap md:mt-4 mt-0 basis-full">
              <div className="flex flex-col lg:flex-nowrap flex-wrap items-start gap-0">
                <p className="font-18 font-semibold text-darkblue flex items-center gap-3">
                  {user?.name}
                  {!user?.isVerified && (
                    <img src={GreenVerifyIcon} alt="Green Verify Icon" />
                  )}
                </p>
                {user?.username && <p>@{user?.username}</p>}
                {user?.bio?.length > lengthtoShow && (
                  <p className="mt-1 flex flex-wrap">
                    {user?.bio?.substring(0, lengthtoShow)}
                    {lengthtoShow !== user?.bio?.length && "..."}
                    {lengthtoShow !== user?.bio?.length && (
                      <span
                        className="text-primary cursor-pointer"
                        onClick={() => setLengthToShow(user?.bio?.length)}
                      >
                        More Info
                      </span>
                    )}
                  </p>
                )}
                {user?.bio?.length <= lengthtoShow && (
                  <p className="mt-1 flex flex-wrap">{user?.bio}</p>
                )}
              </div>
              <div className="flex justify-between gap-x-4 profile-post-btns">
                <div className="flex gap-2">
                  <Button variant="outlined" className="light-blue-icon-btn">
                    <img src={starIcon} alt="starIcon" width={24} />
                  </Button>
                  <Button variant="outlined" className="light-blue-icon-btn">
                    <img src={ShareIcon} alt="ShareIcon" width={24} />
                  </Button>
                </div>
                <div className="flex flex-col gap-2">
                  <Button
                    variant="contained"
                    className="m-auto px-12 min-w-170px min-h-40px"
                    onClick={() => {
                      if (user?.isSubscribed) {
                        dispatch(
                          subscribeToPlanAction({
                            creatorId: user?.id,
                            planID: user?.planId,
                          })
                        );
                      } else {
                        dispatch(
                          openModal({
                            title: null,
                            content: (
                              <ModalSubscribeToPlan
                                user={user}
                                basePlan={basePlan}
                                subscriptionBundle={subscriptionBundleArray}
                              />
                            ),
                          })
                        );
                      }
                    }}
                  >
                    {user?.isSubscribed ? "Unsubscribe" : "Subscribe"}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="m-auto px-12 btn-outline-primary min-w-160px min-h-40px"
                  >
                    <img
                      src={BlueHeartIcon}
                      alt="BlueHeartIcon"
                      width={24}
                      className="mr-2"
                    />{" "}
                    Wish Request
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex lg:flex-row flex-col items-start lg:gap-y-0 lg:gap-x-4  gap-x-0 gap-y-4 lg:my-0 my-3"></div>
          </div>
        </div>
      </div>

      {basePlan && (
        <UserProfileSubscription
          user={user}
          basePlan={basePlan}
          subscriptionBundleArray={subscriptionBundleArray}
        />
      )}

      <div className="flex flex-wrap justify-between lg:gap-3 gap-4 mt-4">
        <div className="flex w-full justify-start md:w-auto gap-3">
          <Button variant="contained" className="m-auto px-8 light-gray-btn">
            <img src={GalleryBlueIcon} alt="GalleryBlueIcon" className="mr-2" />{" "}
            {user?.imagePostCount}
          </Button>
          <Button variant="contained" className="m-auto px-8 light-gray-btn">
            <img src={HeartIcon} alt="HeartIcon" className="mr-2" />{" "}
            {postLikeCount}
          </Button>
          <Button variant="contained" className="m-auto px-8 light-gray-btn">
            <img src={videoGreenIcon} alt="HeartIcon" className="mr-2" />{" "}
            {user?.videoPostCount}
          </Button>
        </div>
        <div className="flex w-full justify-start md:w-auto gap-3">
          <Button
            variant="contained"
            className="m-auto px-4 light-gray-btn"
            onClick={() => {
              handleIconClick("twitter");
            }}
          >
            <img src={TwitterIcon} alt="TwitterIcon" />
          </Button>
          <Button
            variant="contained"
            className="m-auto px-4 light-gray-btn"
            onClick={() => handleIconClick("youtube")}
          >
            <img src={YoutubeIcon} alt="YoutubeIcon" />
          </Button>
          <Button
            variant="contained"
            className="m-auto px-4 light-gray-btn"
            onClick={() => handleIconClick("tiktok")}
          >
            <img src={TiktokIcon} alt="TiktokIcon" />
          </Button>
          <Button
            variant="contained"
            className="m-auto px-4 light-gray-btn"
            onClick={() => handleIconClick("instagram")}
          >
            <img src={InstagramIcon} alt="InstagramIcon" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserProfileDetails;
