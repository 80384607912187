import React from 'react';
import Grid from '@mui/material/Grid';
import { Box, Button } from '@mui/material';
import SpotifyAccountImg from './../../../../../assets/images/spotify-account.png';
import wifiIcon from './../../../../../assets/images/wifi.svg';

export default function SpotifyAccount() {
    //Connect Spotify Account
    const handleClick = () => {

        var state = 123;
        var scope = ['user-read-private, user-read-email']; 
        var url = 'https://accounts.spotify.com/authorize';
        url += '?response_type=token';
        // eslint-disable-next-line no-undef
        url += '&client_id=' + encodeURIComponent(process.env.REACT_APP_SPOTIFY_CLIENT_ID);
        url += '&scope=' + encodeURIComponent(scope.join(","));
        // eslint-disable-next-line no-undef
        url += '&redirect_uri=' + encodeURIComponent(process.env.REACT_APP_SPOTIFY_CALLBACK_URL);
        url += '&state=' + encodeURIComponent(state);
        //Visit the page to select a spotify account to link
        window.location = `${url}`

    }
    return (
        <Box
            component="main"
            className='flex'
            sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} className='fans-profile'>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid container spacing={2} className='h-calc-200 fans-profile mt-3'>
                        <div className='bg-white rounded-2xl py-3 px-5 w-full flex items-center justify-center flex-col'>
                            <img src={SpotifyAccountImg} alt="SpotifyAccountImg" width={300} />
                            <div className='w-400px text-center mt-3'>
                                <h3 className='font-26 text-darkblue font-semibold mb-3'>Spotify Account</h3>
                                <p className='font-14 sm:px-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce molestie, justo ac lobortis.</p>
                                <div className='heading mt-3'>
                                    <Button variant="contained" className='green-btn w-full' onClick={() => handleClick()}><img src={wifiIcon} alt='wifiIcon' className='mr-3' /> Sign In With Spotify</Button>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

// export default Profile;