import React from "react";

const BlockTitle = ({ username }) => {
  return (
    <div className="flex justify-between items-center borderBottom pb-3">
      <h3>BLOCK</h3>
      {username && (
        <div className="activenow rounded-t-full">
          <p className="my-0 font-14 text-darkblue"> @{username}</p>
        </div>
      )}
    </div>
  );
};

export default BlockTitle;
