import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import UserBlank from "./../../../../../assets/images/blank.png";
import HeartOutlineIcon from "./../../../../../assets/images/heart-outline.svg";
import HeartFilledIcon from "./../../../../../assets/images/heart-icon-filled.svg";
import DollarIcon from "./../../../../../assets/images/dollar-square.svg";
import ArchiveTick from "./../../../../../assets/images/archive-tick.svg";
import formatDateForPost from "../../../../../utils/formatDateForPost";
import { useDispatch, useSelector } from "react-redux";
import CommentsSection from "../../../../molecules/web/common/CommentsSection";
import {
  Postheader,
  ModalAddBookMark,
  ModalSendTip,
  DisplayQuizInPost,
  DisplayPollInPost,
  PostDropdown,
  UserPostDropdown,
} from "../../../../molecules";
import { DisplayImagesInPost, LockedPost } from "../../../../atoms";
import {
  addToBookMarks,
  likeDislikePost,
} from "../../../../../store/middlewares/web/post/posts";
import { openModal } from "../../../../../store/slices/common/modal";
import BookmarkBlueFill from "./../../../../../assets/images/bookmark-fill-blue.svg";
import { useNavigate } from "react-router-dom";

const DisplayPost = ({ post, rightToolbar = null, refresh = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [liked, setLiked] = useState(false);
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const [postMedia, setPostMedia] = useState([]);
  const { me } = useSelector((state) => state.me);

  useEffect(() => {
    if (
      post?.post_like
        ?.map((user) => {
          return user?.id;
        })
        ?.includes(me?.id)
    ) {
      setLiked(true);
    } else {
      setLiked(false);
    }
    if (me) {
      setIsLoggedInUser(me?.id === post.userId);
    }
    setPostMedia(post?.postMedia?.filter((post) => post?.mediaType !== "text"));
  }, [post, me]);

  const handleLike = () => {
    // handle Like/Dislike
    let reqObj = {
      postId: post?.id,
    };
    dispatch(likeDislikePost(reqObj));
  };

  return (
    <div
      className={`bg-white rounded-2xl p-4 mt-3 ${
        !post.isSubscribed && "creator-home-module profile-post"
      }`}
      key={post?.id}
    >
      <div className="flex flex-wrap justify-between items-center">
        <Postheader
          img={
            post?.users?.profileImage
              ? // eslint-disable-next-line no-undef
                `${process.env.REACT_APP_AWS_URL}${post?.users?.profileImage}`
              : UserBlank
          }
          name={post?.users?.name}
          uname={post?.users?.username}
          id={post?.users?.id}
          isVerified={false}
          isLoggedInUser={isLoggedInUser}
        />

        <div className="flex items-center gap-3">
          {post?.createdAt && (
            <p className="font-medium font-14 text-black">
              {formatDateForPost(post?.createdAt)}
            </p>
          )}
          {isLoggedInUser ? <UserPostDropdown post={post} /> : rightToolbar ? rightToolbar : <PostDropdown post={post} refresh={refresh} />}
        </div>
      </div>

      {post.isSubscribed || post.users?.userType?.toLowerCase() === "user" ? (
        <>
          <div
            className="mt-4 cursor-pointer"
            dangerouslySetInnerHTML={{
              __html:
                post?.postType?.toLowerCase() !== "post"
                  ? `<p>${post.title}</p>`
                  : post.description,
            }}
            onClick={() => navigate(`/user/post/${post.id}`)}
          />
          <DisplayImagesInPost
            filesSrc={postMedia?.map(
              (media) =>
                // eslint-disable-next-line no-undef
                `${process.env.REACT_APP_AWS_URL}${media.fileURL}`
            )}
          />
        </>
      ) : (
        <LockedPost postMedia={postMedia} />
      )}
      {(post.isSubscribed || post.users?.userType?.toLowerCase() === "user")  && (
        <>
          {post?.pollOptions?.length > 0 && <DisplayPollInPost post={post} />}
          {post?.quizOptions?.length > 0 && (
            <DisplayQuizInPost
              options={post?.quizOptions}
              isLoggedInUser={isLoggedInUser}
              postId={post?.id}
              expiry={post?.expiry}
            />
          )}

          <div className="flex justify-between mt-3">
            <div className="flex gap-3">
              <div className="flex items-center gap-[12px]">
                <IconButton
                  aria-label="delete"
                  disabled={post?.restricted}
                  sx={{ padding: "0px !important" }}
                  onClick={() => {
                    handleLike();
                  }}
                >
                  {!liked ? (
                    <img
                      src={HeartOutlineIcon}
                      alt="HeartOutlineIcon"
                      className="img-dark-filter"
                    />
                  ) : (
                    <img
                      src={HeartFilledIcon}
                      alt="HeartFilledIcon"
                      className="img-dark-filter"
                    />
                  )}
                </IconButton>
                <p className="font-medium font-16">{post?.post_like?.length}</p>
              </div>

              <div>
                <IconButton
                  aria-label="delete"
                  onClick={() =>
                    dispatch(
                      openModal({
                        title: "SEND TIP",
                        content: <ModalSendTip />,
                      })
                    )
                  }
                >
                  <img
                    src={DollarIcon}
                    alt="DollarIcon"
                    className="img-dark-filter"
                  />
                </IconButton>
                <span className="font-medium font-16">Send Tip</span>
              </div>
            </div>
            <>
              {post?.bookmark_post?.length > 0 ? (
                <>
                  {!post?.bookmark_post[0]?.isActive ? (
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        dispatch(
                          openModal({
                            title: "CREATE NEW BOOKMARK",
                            content: <ModalAddBookMark postId={post?.id} />,
                            hasCloseBtn: true,
                          })
                        );
                      }}
                    >
                      <img
                        src={ArchiveTick}
                        alt="ArchiveTick"
                        className="img-dark-filter"
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        let reqObj = {
                          bookmarkId: post?.bookmark_post[0]?.id,
                          flag: false,
                        };
                        dispatch(addToBookMarks(reqObj));
                      }}
                    >
                      <img
                        src={BookmarkBlueFill}
                        alt="BookmarkBlueFill"
                        className="img-dark-filter"
                      />
                    </IconButton>
                  )}
                </>
              ) : (
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    dispatch(
                      openModal({
                        title: "CREATE NEW BOOKMARK",
                        content: <ModalAddBookMark postId={post?.id} />,
                        hasCloseBtn: true,
                      })
                    );
                  }}
                >
                  <img
                    src={ArchiveTick}
                    alt="ArchiveTick"
                    className="img-dark-filter"
                  />
                </IconButton>
              )}
            </>
          </div>
          <CommentsSection
            post_comment={post?.post_comment}
            postId={post?.id}
            userEmail={post?.users?.email}
            isRestricted={post?.restricted}
          />
        </>
      )}
    </div>
  );
};

export default DisplayPost;
