import React from 'react'

const BookMarksIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.89 6.38H5.10999C3.39999 6.38 2 7.77999 2 9.48999V20.85C2 22.3 3.04 22.92 4.31 22.21L8.23999 20.02C8.65999 19.79 9.34 19.79 9.75 20.02L13.68 22.21C14.95 22.92 15.99 22.3 15.99 20.85V9.48999C16 7.77999 14.6 6.38 12.89 6.38Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 9.48999V20.85C16 22.3 14.96 22.91 13.69 22.21L9.76001 20.02C9.34001 19.79 8.65999 19.79 8.23999 20.02L4.31 22.21C3.04 22.91 2 22.3 2 20.85V9.48999C2 7.77999 3.39999 6.38 5.10999 6.38H12.89C14.6 6.38 16 7.77999 16 9.48999Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
            <path opacity="0.4" d="M22 5.60999V16.97C22 18.42 20.96 19.03 19.69 18.33L16 16.27V9.48999C16 7.77999 14.6 6.38 12.89 6.38H8V5.60999C8 3.89999 9.39999 2.5 11.11 2.5H18.89C20.6 2.5 22 3.89999 22 5.60999Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
        </svg>
    )
}

export default BookMarksIcon
