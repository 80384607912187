import { Box, Typography } from '@mui/material'
import React from 'react'

const ProfileSectionWrapper = ({ title, children }) => {
    //Custom Wrapper to Wrap Custom Buttons in My Profile Section
    return (
        <Box className='gray-border p-3 pb-0 border-radius-10 mt-4'>
            <Typography variant='h3' className='font-medium text-darkblue font-20 mb-3'>{title}</Typography>
            {children}
        </Box>
    )
}

export default ProfileSectionWrapper
