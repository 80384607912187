import React, { lazy, useCallback, useEffect } from "react";
import { MainLayout } from "../newComponents/organisms";
import Login from "../pages/auth/session/Login";
import { Navigate, Route, Routes } from "react-router-dom";
import useDocumentTitle from "../hooks/useDocumentTitle";
import {
  createTheme,
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";
import { useTheme } from "../newComponents/atoms";
import { useDispatch, useSelector } from "react-redux";
import { validateTokenWeb } from "../store/middlewares/auth/session/auth";
import { getDecryptedToken } from "../utils/CryptoJS";

const UserHomeScreen = lazy(() => import("../pages/web/fanSide/Home"));
const UserHomeProfilePost = lazy(() =>
  import("../pages/web/fanSide/UserHomeProfilePost")
);
const UserHomeProductDetails = lazy(() =>
  import("../pages/web/fanSide/UserHomeProductDetails")
);
const UserProfileScreen = lazy(() => import("../pages/web/fanSide/Profile"));
const UserProfileNoArticle = lazy(() =>
  import("../pages/web/fanSide/NoArticle")
);
const UserProfileOGTV = lazy(() => import("../pages/web/fanSide/OGTV"));
const UserProfileSearchProfile = lazy(() =>
  import("../pages/web/fanSide/SearchProfile")
);
const UserNotifications = lazy(() =>
  import("../pages/web/fanSide/NotificationFans")
);
const UserArchive = lazy(() => import("../pages/web/fanSide/Archive"));
const UserArchiveOne = lazy(() => import("../pages/web/fanSide/Archive1"));
const UserFriends = lazy(() => import("../pages/web/fanSide/Friends"));
const UserHomePost = lazy(() => import("../pages/web/fanSide/UserHomePost"));
const UserExpiredSubscription = lazy(() =>
  import("../pages/web/fanSide/ExpireSubscription")
);
const UserBookmarks = lazy(() => import("../pages/web/fanSide/Bookmarks"));
const UserCollection = lazy(() => import("../pages/web/fanSide/Collection"));
const UserMessages = lazy(() => import("../pages/web/fanSide/Messages"));
const UserSubscriptions = lazy(() =>
  import("../pages/web/fanSide/Subscription")
);
const UserPayments = lazy(() => import("../pages/web/fanSide/Payments"));
const UserCart = lazy(() => import("../pages/web/fanSide/Cart"));
const UserCartPayment = lazy(() => import("../pages/web/fanSide/CartPayment"));
const UserPaymentThanks = lazy(() =>
  import("../pages/web/fanSide/PaymentThanks")
);
const UserMyOrder = lazy(() => import("../pages/web/fanSide/MyOrder"));
const UserPhotoOrder = lazy(() => import("../pages/web/fanSide/PhotoOrder"));
const UserPhotoOrderRequest = lazy(() =>
  import("../pages/web/fanSide/PhotoOrder/RequestToCreatorFans")
);
const UserPhotoOrdeAcceptCall = lazy(() =>
  import("../pages/web/fanSide/PhotoOrder/PhotoGalleryAcceptclFans")
);
const UserPhotoOrdeRejctedCall = lazy(() =>
  import("../pages/web/fanSide/PhotoOrder/PhotoGalleryRejectedFans")
);
const UserPhotoOrderPayment = lazy(() =>
  import("../pages/web/fanSide/PhotoOrder/PhotoGalleryPaymentFans")
);
const UserPhotoOrderPaymentSuccessfull = lazy(() =>
  import("../pages/web/fanSide/PhotoOrder/PhotoGalleryPaymentSuccessfulFans")
);
const UserPhotoOrderAcceptCount = lazy(() =>
  import("../pages/web/fanSide/PhotoOrder/PhotoGalleryAcceptCountFans")
);
const NotFound = lazy(() => import("../pages/web/common/NotFound"));
const BecomeACreator = lazy(() =>
  import("../pages/web/fanSide/BecomeACreator")
);
const NewPost = lazy(() => import("../pages/web/fanSide/NewPost"));
const AllSuggestions = lazy(() =>
  import("../pages/web/fanSide/AllSuggestions")
);
const ExploreFans = lazy(() => import("../pages/web/fanSide/ExploreFans"));
const SubscribedStreams = lazy(() =>
  import("../pages/web/fanSide/SubscribedStreams")
);

const generatePath = (slug) => {
  return `/${slug}`;
};

const userRoutes = [
  {
    path: generatePath("home"),
    element: <UserHomeScreen />,
  },
  {
    path: generatePath("profile/:id"),
    element: <UserHomeProfilePost />,
  },
  {
    path: generatePath("product-detail"),
    element: <UserHomeProductDetails />,
  },
  {
    path: generatePath("my-profile"),
    element: <UserProfileScreen />,
  },
  {
    path: generatePath("no-article"),
    element: <UserProfileNoArticle />,
  },
  {
    path: generatePath("ogtv"),
    element: <UserProfileOGTV />,
  },
  {
    path: generatePath("search-profile"),
    element: <UserProfileSearchProfile />,
  },
  {
    path: generatePath("notifications"),
    element: <UserNotifications />,
  },
  {
    path: generatePath("archive"),
    element: <UserArchive />,
  },
  {
    path: generatePath("archive-one"),
    element: <UserArchiveOne />,
  },
  {
    path: generatePath("friends"),
    element: <UserFriends />,
  },
  {
    path: generatePath("/post/:id"),
    element: <UserHomePost />,
  },
  {
    path: generatePath("expired-subscription"),
    element: <UserExpiredSubscription />,
  },
  {
    path: generatePath("bookmarks"),
    element: <UserBookmarks />,
  },
  {
    path: generatePath("user-lists"),
    element: <UserCollection />,
  },
  {
    path: generatePath("messages"),
    element: <UserMessages />,
  },
  {
    path: generatePath("subscriptions"),
    element: <UserSubscriptions />,
  },
  {
    path: generatePath("payments"),
    element: <UserPayments />,
  },
  {
    path: generatePath("cart"),
    element: <UserCart />,
  },
  {
    path: generatePath("cart/payment"),
    element: <UserCartPayment />,
  },
  {
    path: generatePath("cart/payment/thanks"),
    element: <UserPaymentThanks />,
  },
  {
    path: generatePath("my-order"),
    element: <UserMyOrder />,
  },
  {
    path: generatePath("photo-order"),
    element: <UserPhotoOrder />,
  },
  {
    path: generatePath("photo-order/request"),
    element: <UserPhotoOrderRequest />,
  },
  {
    path: generatePath("photo-order/accept-call"),
    element: <UserPhotoOrdeAcceptCall />,
  },
  {
    path: generatePath("photo-order/reject-call"),
    element: <UserPhotoOrdeRejctedCall />,
  },
  {
    path: generatePath("photo-order/payment"),
    element: <UserPhotoOrderPayment />,
  },
  {
    path: generatePath("photo-order/payment-successfull"),
    element: <UserPhotoOrderPaymentSuccessfull />,
  },
  {
    path: generatePath("photo-order/accept-count"),
    element: <UserPhotoOrderAcceptCount />,
  },
  {
    path: generatePath("become-a-creator"),
    element: <BecomeACreator />,
  },
  {
    path: generatePath("post"),
    element: <NewPost />,
  },
  {
    path: generatePath("all-suggestions"),
    element: <AllSuggestions />,
  },
  {
    path: generatePath("explore-page"),
    element: <ExploreFans />,
  },
  {
    path: generatePath("live-stream"),
    element: <SubscribedStreams />,
  },
];

const UserNavigation = () => {
  useDocumentTitle("YouOnly");
  const { darkMode } = useTheme();

  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
    },
  });

  const user = JSON.parse(localStorage.getItem("user-details"));

  const dispatch = useDispatch();

  const { isTokenValid } = useSelector((state) => state.webauth);
  const checkToken = useCallback(() => {
    if (user) {
      dispatch(validateTokenWeb({token: getDecryptedToken(user?.token)}));
    }
  }, [dispatch, user]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {/* if user is logged in redirect to dashboard */}
        <Route
          path=""
          element={
            (!localStorage.getItem("user-details") || !isTokenValid) ? (
              <Login />
            ) : (
              <Navigate to="home" />
            )
          }
        />
        {/* if user is not present show unauthorized ui */}
        <Route
          element={
          (!localStorage.getItem("user-details") || !isTokenValid) ? (
              <Navigate to="/" />
            ) : (
              <>
                {/* //Protection for when a Creator tries to access a User Page  */}
                {user?.userType === "creator" ? (
                  <Navigate to="/creator/home" />
                ) : (
                  <MainLayout />
                )}
              </>
            )
          }
        >
          {userRoutes.map((route, index) => {
            return (
              <Route
                key={`${route.path}${index}`}
                path={route.path}
                element={route.element}
              />
            );
          })}
          {/* all undefined routes are handled here for admin */}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </MuiThemeProvider>
  );
};

export default UserNavigation;
