import { createSlice } from "@reduxjs/toolkit";
import { Activate2Step, VerifyTOTPFirstTimeAction } from "../../../../middlewares/web/profile/myProfile/account";

export const twoStepAuth = createSlice({
    name: 'qr',
    initialState: {
        qrImageLoading: false,
        qrImageMessage: "",
        qrImageError: false,
        qrImage: "",
        verificationLoading: false,
        verificationSuccess: false,
        verificationError: false,
        verificationMessage: "",
        isVerified: false,
    },
    reducers: {
        resetqrImage: (state) => {
            state.qrImageLoading = false;
            state.qrImageMessage = "";
            state.qrImageError = false;
            state.qrImage = "";
            state.verificationLoading = false;
            state.verificationSuccess = false;
            state.verificationError = false;
            state.verificationMessage = "";
            state.isVerified = false;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(Activate2Step.pending, (state) => {
                state.qrImageLoading = true;
                state.qrImageMessage = "";
                state.qrImageError = "";
                state.qrImage = "";
                state.verificationLoading = false;
                state.verificationSuccess = false;
                state.verificationError = false;
                state.isVerified = false;
                state.verificationMessage = "";
            })
            .addCase(Activate2Step.fulfilled, (state, action) => {
                state.qrImageLoading = false;
                state.qrImageError = false;
                state.qrImageMessage = action.payload.message;
                state.qrImage = action.payload.response;
            })
            .addCase(Activate2Step.rejected, (state, action) => {
                state.qrImageLoading = false;
                state.qrImageError = true;
                state.qrImageMessage = action.payload.message;
                state.qrImage = '';
            })
            .addCase(VerifyTOTPFirstTimeAction.pending, (state) => {
                state.verificationLoading = true;
                state.verificationSuccess = false;
                state.verificationError = false;
                state.verificationMessage = "";
                state.isVerified = false;
            })
            .addCase(VerifyTOTPFirstTimeAction.fulfilled, (state, action) => {
                state.verificationLoading = false;
                state.verificationSuccess = true;
                state.verificationError = false;
                state.verificationMessage = action.payload.message;
                state.isVerified = true;
            })
            .addCase(VerifyTOTPFirstTimeAction.rejected, (state, action) => {
                state.verificationLoading = false;
                state.verificationSuccess = false;
                state.verificationError = true;
                state.isVerified = false;
                state.verificationMessage = action.payload.message;
            })
    }
});

export const {
    qrRequest,
    qrError,
    qrSuccess,
    resetqrImage,
    otpVerificationRequest,
    otpVerificationSuccess,
    otpVerificationError
} = twoStepAuth.actions;

export default twoStepAuth.reducer;