import React from 'react'

const MyOrderIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.4">
                <path d="M3.16992 7.93994L11.9999 13.0499L20.7699 7.96991" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 22.11V13.04" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9.93011 2.98L4.59012 5.95003C3.38012 6.62003 2.39014 8.30001 2.39014 9.68001V15.33C2.39014 16.71 3.38012 18.39 4.59012 19.06L9.93011 22.03C11.0701 22.66 12.9401 22.66 14.0801 22.03L19.4201 19.06C20.6301 18.39 21.6201 16.71 21.6201 15.33V9.68001C21.6201 8.30001 20.6301 6.62003 19.4201 5.95003L14.0801 2.98C12.9301 2.34 11.0701 2.34 9.93011 2.98Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <path d="M17 13.74V10.08L7.51001 4.59998" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default MyOrderIcon
