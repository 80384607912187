import { IconButton } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { closeModal } from '../../../../../store/slices/common/modal'
import ListMenuBlackIcon from "./../../../../../assets/images/listmenublack.svg";
import closesquare from "./../../../../../assets/images/close-square.svg";

const ModalPintoHomeTitle = () => {
    const dispatch = useDispatch()
    const onClose = () => {
        dispatch(closeModal())
    } 
    return (
        <div className="flex justify-between items-center borderBottom pb-3">
            <h3>PIN TO HOME</h3>

            <div className="flex gap-2">
                <IconButton
                    color="primary"
                    aria-label="add to shopping cart"
                    className="pt-0 pb-0"
                >
                    <img
                        src={ListMenuBlackIcon}
                        alt="ListMenuBlackIcon"
                        height={20}
                        width={20}
                    />
                </IconButton>
                <IconButton
                    color="primary"
                    onClick={onClose}
                    aria-label="add to shopping cart"
                    className="pt-0 pb-0"
                >
                    <img
                        src={closesquare}
                        alt="closesquare"
                        height={24}
                        width={24}
                    />
                </IconButton>
            </div>
        </div>
    )
}

export default ModalPintoHomeTitle
