import React from 'react'

const DarkMode = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3775 22.5375C10.9033 22.5386 10.4296 22.506 9.96003 22.44C7.3978 22.0696 5.05535 20.7869 3.36329 18.8275C1.67123 16.8681 0.743282 14.3638 0.750036 11.775C0.789192 9.30333 1.68571 6.92205 3.2863 5.03827C4.8869 3.1545 7.09216 1.88522 9.52504 1.44746C9.80698 1.39471 10.0981 1.4239 10.364 1.53157C10.6299 1.63924 10.8593 1.82089 11.025 2.05497C11.1984 2.29022 11.2993 2.57098 11.3153 2.86278C11.3313 3.15457 11.2617 3.44468 11.115 3.69746C10.6065 4.61551 10.3163 5.63836 10.2669 6.68669C10.2175 7.73501 10.4102 8.78063 10.8302 9.74242C11.2502 10.7042 11.886 11.5563 12.6885 12.2327C13.491 12.909 14.4385 13.3914 15.4575 13.6425C16.7978 13.9729 18.2065 13.8918 19.5 13.41C19.7717 13.3018 20.0691 13.2754 20.3556 13.334C20.6421 13.3927 20.9052 13.5338 21.1125 13.74C21.3176 13.937 21.4625 14.1882 21.5302 14.4645C21.5979 14.7407 21.5857 15.0304 21.495 15.3C20.7759 17.412 19.4134 19.2457 17.5988 20.5438C15.7842 21.8418 13.6086 22.539 11.3775 22.5375ZM9.78754 2.99996C7.70467 3.36213 5.81296 4.43841 4.43745 6.04386C3.06193 7.64931 2.2885 9.68369 2.25004 11.7975C2.23159 13.9721 2.98892 16.0821 4.38608 17.7486C5.78323 19.4152 7.72869 20.5291 9.87318 20.8904C12.0177 21.2518 14.2207 20.8368 16.0868 19.7201C17.9529 18.6034 19.3599 16.8581 20.055 14.7975C18.4647 15.383 16.7357 15.4796 15.09 15.075C13.8551 14.766 12.7073 14.1784 11.7345 13.3573C10.7618 12.5361 9.98989 11.5032 9.47805 10.3376C8.96621 9.17204 8.72799 7.90476 8.78163 6.63289C8.83528 5.36101 9.17937 4.1183 9.78754 2.99996Z" fill="#292D32" />
        </svg>

    )
}

export default DarkMode
