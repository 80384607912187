import React from 'react'
import verifyIcon from './../../../assets/images/green-verify.svg';
import MentionsIcon from "./../../../assets/images/mentions-blue.svg";

const SingleNotificationMention = ({ userProfileImage, name, username, isVerified, replyToUsername, replyText, date }) => {
    //Single Notification Item For Notification in Mention Tab 
    return (
        <div className="light-gray-box flex justify-between gap-2 mb-3">
            <div className="flex flex-sm-wrap items-start gap-6">
                <div className="relative">
                    <img
                        src={userProfileImage}
                        alt="NotifyUser2"
                        className="notify-user"
                    />
                    <img
                        src={MentionsIcon}
                        alt="MentionsIcon"
                        className="notify-user-status"
                    />
                </div>
                <div>
                    <div className="flex flex-wrap items-center gap-2 title-width">
                        <p className="text-darkblue font-20 font-semibold">
                            {name}
                        </p>
                        {isVerified && <img src={verifyIcon} alt="verifyIcon" />}
                        {username &&
                            <>
                                <span className="line">|</span>
                                <p>@{username}</p>
                            </>}
                    </div>
                    <p className="mt-1">
                        Replaying to{" "}
                        {replyToUsername && <span className="text-primary">@{replyToUsername}</span>}
                    </p>
                    <p className="text-darkblue font-medium">
                        {replyText}
                    </p>
                </div>
            </div>
            <p className="font-medium text-nowrap date-position	">
                {date}
            </p>
        </div>
    )
}

export default SingleNotificationMention
