import React, { useState } from "react";
import { ProfileDropdownMenu } from "../../../../organisms";
import starIcon from "./../../../../../assets/images/star.svg";
import ChatBlueIcon from "./../../../../../assets/images/chat-icon-blue.svg";
import User1 from "./../../../../../assets/images/blank.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GreenVerifyIcon from "./../../../../../assets/images/green-verify.svg";
import OfferTag from "./../../../../../assets/images/offer-tag.png";
import { Button } from "@mui/material";

const DisplayUserInCollection = ({ user, index,  folderId = "" }) => {
  const [lengthtoShow, setLengthToShow] = useState(30);

  return (
    <div key={index} className="bg-white p-3 rounded-2xl">
      <div className="flex justify-end flex-wrap gap-3 mb-2">
        <ProfileDropdownMenu
          user={{
            ...user,
            profileImage:
              // eslint-disable-next-line no-undef
              `${process.env.REACT_APP_AWS_URL}${user?.profileImage}`,
          }}
          folderId={folderId}
        />
      </div>
      <div className="relative">
        <div className="h-[100px]">
          <img
            src={
              user?.coverImg
                ? // eslint-disable-next-line no-undef
                  `${process.env.REACT_APP_AWS_URL}${user?.coverImg}`
                : User1
            }
            alt="CollectionPostBg"
            className="h-full w-full object-cover"
          />
        </div>
        <div className="w-[80px] h-[80px] absolute border-4 rounded-full bottom-[-40px] left-[10px]">
          <img
            src={
              user?.profileImage
                ? // eslint-disable-next-line no-undef
                  `${process.env.REACT_APP_AWS_URL}${user?.profileImage}`
                : User1
            }
            alt={user?.name}
            className="rounded-full w-full h-full object-cover"
          />
        </div>
      </div>
      <div className="relative flex flex-xs-nowrap gap-10 pl-24">
        <div className="flex flex-md-wrap flex-wrap justify-between gap-2 w-full mt-2 ml-2">
          <div>
            <p className="font-18 font-semibold text-darkblue flex items-center gap-3">
              {user?.name}
              <img src={GreenVerifyIcon} alt="Green Verify Icon" />
            </p>
            {user?.username && <p>@{user?.username}</p>}
          </div>

          <div>
            <div className="flex gap-3 profile-post-btns">
              <Button variant="outlined" className="light-blue-icon-btn">
                <img
                  src={starIcon}
                  alt="starIcon"
                  width={24}
                  className="img-dark-filter"
                />
              </Button>
              <Button variant="outlined" className="light-blue-icon-btn">
                <img
                  src={ChatBlueIcon}
                  alt="ChatBlueIcon"
                  width={24}
                  className="img-dark-filter"
                />
              </Button>
            </div>
          </div>
        </div>
      </div>
      {user?.bio && (
        <p className="mt-1 flex flex-wrap">
          {user?.bio?.substring(0, lengthtoShow)}
          {lengthtoShow !== user?.bio?.length && "..."}
          {lengthtoShow !== user?.bio?.length && (
            <span
              className="text-primary cursor-pointer"
              onClick={() => setLengthToShow(user?.bio?.length)}
            >
              More Info
            </span>
          )}
        </p>
      )}
      {index % 2 === 0 && (
        <>
          <div className="subscription">
            <div className="light-blue-bg border-radius-10 mt-4">
              <div className="flex justify-between items-center w-full">
                <div className="flex items-start">
                  <img src={OfferTag} alt="OfferTag" className="mr-3" />
                  <div>
                    <h5 className="font-16 font-semibold text-darkblue">
                      Limited Offer 50% off for 31 days!
                    </h5>
                    <p className="font-14 mt-1">
                      For expired and new subscribers ends Apr 18
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-3 light-blue-bg mt-4 border-radius-10">
            <div className="p-4 pb-2">
              <Button
                variant="contained"
                className="m-auto px-12 w-full min-h-40px"
              >
                Subscribe $5 for 31 days
              </Button>
            </div>
            <div className="ticketRip">
              <div className="circleLeft"></div>
              <div className="ripLine"></div>
              <div className="circleRight"></div>
            </div>

            <div className="flex justify-between gap-3 items-center p-4 pt-1">
              <p className="font-14">Expired</p>
              <p className="font-14 font-semibold">Mar 11, 2024</p>
            </div>
          </div>

          <div className="p-4 text-center pb-1">
            <p className="mb-0 font-14 font-semibold">
              Show Discounted Options <ExpandMoreIcon className="ml-1" />
            </p>
          </div>
        </>
      )}
      {index % 2 !== 0 && (
        <div className="flex flex-col gap-3 light-blue-bg mt-4 border-radius-10">
          <div className="p-4">
            <Button
              variant="outlined"
              className="m-auto px-12 w-full min-h-40px light-blue-icon-outline"
            >
              Subscribe for Free
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DisplayUserInCollection;
