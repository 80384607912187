import { Divider, IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react'
import MoreSquare from "./../../../../../assets/images/more-square.svg";
import ColeUser from "./../../../../../assets/images/cole-user.png";
import Verified from "./../../../../../assets/images/verified.svg";

const NoArticleSuggestionMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget); //set Anchor for Menu
    };

    const handleClose = () => {
        setAnchorEl(null); //remove Anchor for Menu
    };

    return (
        <div>
            <div className="">
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                >
                    <img src={MoreSquare} alt="MoreSquare" width={24} />
                </IconButton>

                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.08))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            "&::before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                    <MenuItem onClick={handleClose}>
                        <div className="relative varified mr-3">
                            <img
                                src={ColeUser}
                                alt="ColeUser"
                                height={38}
                                width={38}
                            />
                            <div className="verified-bg">
                                <img
                                    src={Verified}
                                    alt="Verified"
                                    width={16}
                                    height={16}
                                />
                            </div>
                        </div>
                        @colejanson
                    </MenuItem>
                    <div className="px-4">
                        <Divider />
                    </div>
                    <MenuItem onClick={handleClose}>
                        Copy Link to Profile
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        Add to / Remove from lists
                    </MenuItem>
                    <MenuItem onClick={handleClose}>Block</MenuItem>
                    <MenuItem onClick={handleClose}>Report</MenuItem>
                    <MenuItem onClick={handleClose} className="text-red">
                        Remove
                    </MenuItem>
                </Menu>
            </div>
        </div>
    )
}

export default NoArticleSuggestionMenu
