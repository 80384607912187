import {
  Block,
  Delete,
  RestoreFromTrashOutlined,
  TaskAlt,
} from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import {
  BanPostAdminFromDetail,
  DeletePostAdminFromDetail,
} from "../../../../store/middlewares/web/post/post";
import Swal from "sweetalert2";

const PostParticulars = ({ post }) => {
  const dispatch = useDispatch();
  return (
    <Grid container>
      <Grid item md={6} sm={4} xs={2}>
        <Typography variant="body1">{post?.title}</Typography>
      </Grid>
      <Grid item md={6} sm={8} xs={10} className="flex justify-end gap-8">
        <div className="px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-white border-green-400 text-green-500">
          <Typography variant="body1" className="capitalize">
            {post?.postType}
          </Typography>
        </div>
        <Tooltip title={"Ban Post"} placement="top">
          <IconButton
            sx={{ padding: "0 !important" }}
            disableRipple
            onClick={() => {
              Swal.fire({
                title: `Are you sure you want to ${
                  !post?.bannedAt ? "Ban" : "Restore"
                } this Post?`,
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
                customClass: {
                  title: "Swal-title-class",
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  dispatch(BanPostAdminFromDetail({ postId: post.id }));
                }
              });
            }}
          >
            {!post?.bannedAt ? (
              <Block color="warning" />
            ) : (
              <TaskAlt color="success" />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title={"Delete Post"} placement="top">
          <IconButton
            sx={{ padding: "0 !important" }}
            disableRipple
            onClick={() => {
              Swal.fire({
                title: `Are you sure you want to ${
                  !post?.deletedAt ? "delete" : "restore"
                } this Post?`,
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
                customClass: {
                  title: "Swal-title-class",
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  dispatch(
                    DeletePostAdminFromDetail({
                      postId: post.id,
                      type: !post?.deletedAt ? "delete" : "restore",
                    })
                  );
                }
              });
            }}
          >
            {!post?.deletedAt ? (
              <Delete color="error" />
            ) : (
              <RestoreFromTrashOutlined color="error" />
            )}
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default PostParticulars;
