import { deleteAccount, getCountryCodeForWeb, updateMe, getMe, getUserProfile, blockUserProfile, getStateForWeb, updateMeCreator } from "./../../../../services/web/profile/me.services";
import { connectAccount, deleteSessions, getAllSessions, updateAccount } from "../../../../services/web/profile/account.services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeModal } from "../../../../slices/common/modal";
import { blockUserFromPost } from "../../../../slices/common/posts";
import { BlockedUsersAction } from "../../../../slices/common/users";
import { updateCountOnBlockedUser } from "../../../../slices/web/folders/folders.slice";

export const getMeAction = createAsyncThunk("me/getMe", async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await getMe(payload);
        if (response.status === 1) {
            // if any async operation need to be done on response data, do it here,
            // then full fill data
            let { response: { self, coverUrl, profileUrl }, message, status } = response
            let tempObj = { response: { ...self, coverUrl, profileUrl }, status, message }
            return fulfillWithValue(tempObj);
        }
        return rejectWithValue({ message: response?.message });
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const getUserProfileAction = createAsyncThunk("me/getUserProfile", async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await getUserProfile(payload);
        if (response.status === 1) {
            // if any async operation need to be done on response data, do it here,
            // then full fill data
            return fulfillWithValue(response);
        }
        return rejectWithValue({ message: response?.message });
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const blockUserProfileAction = createAsyncThunk("user/blockUser", async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
        let {folderId, blocked, ...rest } = payload
        const response = await blockUserProfile({...rest});
        if (response.status === 1) {
            // if any async operation need to be done on response data, do it here,
            // then full fill data
            // location.reload()
            dispatch(blockUserFromPost({id: payload.userId}))
            dispatch(BlockedUsersAction({id: payload.userId}))
            if (folderId) {
                dispatch(updateCountOnBlockedUser({ folderId, blocked }));
              }
            dispatch(closeModal())
            return fulfillWithValue(response);
        }
        return rejectWithValue({ message: response?.message });
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const updateMeAction = createAsyncThunk("me/updateMe", async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await updateMe(payload);
        if (response.status === 1) {
            // if any async operation need to be done on response data, do it here,
            // then full fill data
            let { response: { userProfileUpdated, coverUrl, profileUrl }, message, status } = response
            let tempObj = { response: { ...userProfileUpdated, coverUrl, profileUrl }, status, message }
            return fulfillWithValue(tempObj);
        }
        return rejectWithValue({ message: response?.message });
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const updateMeCreatorAction = createAsyncThunk("me/updateMeCreator", async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await updateMeCreator(payload);
        if (response.status === 1) {
            // if any async operation need to be done on response data, do it here,
            // then full fill data
            let { response: { userProfileUpdated, coverUrl, profileUrl }, message, status } = response
            let tempObj = { response: { ...userProfileUpdated, coverUrl, profileUrl }, status, message }
            return fulfillWithValue(tempObj);
        }
        return rejectWithValue({ message: response?.message });
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const deleteAccountAction = createAsyncThunk("me/deleteAccount", async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await deleteAccount(payload);
        if (response.status === 1) {
            // if any async operation need to be done on response data, do it here,
            // then full fill data
            return fulfillWithValue({ response });
        }
        return rejectWithValue({ message: response?.message });
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const changePassword = createAsyncThunk("me/changePassword", async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await updateAccount(payload);
        if (response.status === 1) {
            // if any async operation need to be done on response data, do it here,
            // then full fill data
            return fulfillWithValue({ response });
        }
        return rejectWithValue({ message: response?.message });
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const getSessions = createAsyncThunk("me/getSessions", async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await getAllSessions();
        if (response.status === 1) {
            // if any async operation need to be done on response data, do it here,
            // then full fill data
            return fulfillWithValue(response);
        }
        return rejectWithValue({ message: response?.message });
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const deleteSessionsAction = createAsyncThunk("me/deleteSessions", async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await deleteSessions(payload);
        if (response.status === 1) {
            // if any async operation need to be done on response data, do it here,
            // then full fill data
            return fulfillWithValue(response);
        }
        return rejectWithValue({ message: response?.message });
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const connectAccountAction = createAsyncThunk("me/connectAccount", async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await connectAccount();
        if (response.status === 1) {
            // if any async operation need to be done on response data, do it here,
            // then full fill data
            return fulfillWithValue(response);
        }
        return rejectWithValue({ message: response?.message });
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const GetCountryCodeForWebAction = createAsyncThunk("me/getCountryCode", async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await getCountryCodeForWeb();
        if (response.status === 1) {
            // if any async operation need to be done on response data, do it here,
            // then full fill data
            return fulfillWithValue(response);
        }
        return rejectWithValue({ message: response?.message });
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const GetStateForWebAction = createAsyncThunk("me/getState", async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await getStateForWeb(payload);
        if (response.status === 1) {
            // if any async operation need to be done on response data, do it here,
            // then full fill data
            return fulfillWithValue(response);
        }
        return rejectWithValue({ message: response?.message });
    } catch (error) {
        return rejectWithValue(error);
    }
})
