import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import { closeModal } from "../../../../../store/slices/common/modal";
import { useDispatch } from "react-redux";
import { blockuserSuggestionAction } from "../../../../../store/middlewares/web/suggestions/suggestions";
import { blockUserProfileAction } from "../../../../../store/middlewares/web/profile/myProfile/me";

function ModalBlock({ id, fromSugggestion = false, folderId = "" }) {
  //Block User
  const dispatch = useDispatch();

  const reasonsArray = [
    {
      id: 1,
      reason: "Block user from accessing your profile.",
    },
    {
      id: 2,
      reason:
        "Restrict, user will not be able to send you direct messages or reply to your posts.",
    },
  ];

  const [selectedReason, setSelectedReason] = useState(0);

  const onClose = () => {
    dispatch(closeModal());
  };

  const handleSubmit = () => {
    let selectedReasonValue = reasonsArray.find(
      (reason) => reason.id === selectedReason
    )?.reason;
    if (fromSugggestion) {
      dispatch(
        blockuserSuggestionAction({ userId: id, reason: selectedReasonValue, folderId, blocked: true })
      );
    } else {
      dispatch(
        blockUserProfileAction({ userId: id, reason: selectedReasonValue, folderId, blocked: true })
      );
    }
  };

  const handleChange = (e) => {
    setSelectedReason(Number(e.target.value));
  };

  return (
    <>
      <FormControl>
        <RadioGroup
          aria-label="options"
          name="options"
          onChange={(e) => handleChange(e)}
          checked={selectedReason}
        >
          {reasonsArray?.map((reason) => {
            return (
              <div
                key={reason.id}
                className="site-notification pl-2 pr-4 py-1 border-radius-10 gap-3 w-full"
              >
                <FormControlLabel
                  className="w-full"
                  value="option1"
                  control={
                    <Radio
                      checked={selectedReason === reason.id}
                      value={reason.id}
                    />
                  }
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <span>{reason.reason}</span>
                    </Box>
                  }
                />
              </div>
            );
          })}
        </RadioGroup>
      </FormControl>
      <div className="flex justify-end mt-6 gap-3">
        <Button
          variant="contained"
          className="m-auto white-btn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className="m-auto px-12"
          disabled={selectedReason === 0}
          onClick={handleSubmit}
        >
          Confirm
        </Button>
      </div>
    </>
  );
}

export default ModalBlock;
