import axiosClient from "../../../../utils/api";

// eslint-disable-next-line no-undef
const URI = process.env.REACT_APP_BASE_URL + "/admin/country";

export const getAllCountryCode = async (payload) => {
  const URL = `${URI}/getCode`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

export const addCountryCode = async (payload) => {
  const URL = `${URI}/addCode`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

export const updateCountryCode = async (payload) => {
  const URL = `${URI}/updateCode`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

export const deleteCountryCode = async (payload) => {
  const URL = `${URI}/deleteCode`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    let { data } = response;
    return { id: payload.id, ...data };
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};


