import axiosClient from "../../../../utils/api";

export const getSubscriptionsPlan = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: "/subscribtion/getPlan",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during get Subsrciption Service:", error);
    throw error;
  }
};

export const addSubscriptionsPlan = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: "/subscribtion/planAdd",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error occurred during Add Subscription Plan Service:",
      error
    );
    throw error;
  }
};

export const subscribePlan = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: "/subscribtion/subscribe",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Subscribe To Plan Service:", error);
    throw error;
  }
};
