import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import React, { useEffect } from "react";
import { Delete } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../../../../../store/middlewares/auth/session/auth";
import {
  deleteSessionsAction,
  getSessions,
} from "../../../../../../store/middlewares/web/profile/myProfile/me";
import { closeModal } from "../../../../../../store/slices/common/modal";

const ModalLoginSessions = () => {
  const { sessions, sessionsLoading } = useSelector((state) => state?.me);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSessions());
  }, [dispatch]);

  const onClose = () => {
    dispatch(closeModal());
  };
  return (
    <>
      <Box className="flex flex-col gap-x-2.5">
        {sessionsLoading && <CircularProgress />}
        {!sessionsLoading && (
          <>
            {sessions?.map((session, index) => {
              let { browser, version, os, ipAddress, isActive } = session;
              return (
                // show all sessions
                <FormControl
                  variant="outlined"
                  key={`${browser} ${version}, ${os} - ${ipAddress} - ${index}`}
                  className={`w-full mt-3 ${isActive ? "border-success" : ""}`}
                >
                  <OutlinedInput
                    defaultValue={`${browser} ${version}, ${os} - ${ipAddress}`}
                    id={`${browser} ${version}, ${os} - ${ipAddress}`}
                    readOnly={true}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            // Single Session Delete
                            dispatch(
                              deleteSessionsAction({ data: session.id })
                            );
                            if (
                              session.token ===
                              JSON.parse(localStorage.getItem("user-details"))
                                .token
                            ) {
                              dispatch(logoutAction());
                            }
                          }}
                        >
                          <Delete color="error" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              );
            })}
          </>
        )}
      </Box>

      <Box className="flex justify-end mt-6 gap-3">
        <Button
          variant="contained"
          className="m-auto white-btn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className="m-auto px-12"
          onClick={() => {
            // Delete All sessions
            let tempString = sessions
              .map((session) => {
                return session.id;
              })
              .join(",");
            dispatch(deleteSessionsAction({ data: tempString }));
            dispatch(logoutAction());
          }}
        >
          Close All Sessions
        </Button>
      </Box>
    </>
  );
};

export default ModalLoginSessions;
