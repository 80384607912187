import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCms } from "../../../services/auth/cms/cms.service";

export const getAuthCmsAction = createAsyncThunk(
  "auth/getCms",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getCms(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
