import { Button } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
// import { instagram as instagramSchema } from "../../../../../../utils/validation/ChangeInstagramForm";
import { UpdateAccountAction } from "../../../../../../store/middlewares/web/profile/myProfile/account";
import { Input } from "../../../../../atoms";
import { closeModal } from "../../../../../../store/slices/common/modal";
import { setSocialLinksAction } from "../../../../../../store/middlewares/web/profile/myProfile/sociallinks";

const ModalChangeInstagram = () => {
  const { me } = useSelector((state) => state?.me);
  const dispatch = useDispatch();

  const [instagram, setInstagram] = useState(
    me?.socialLinks.find((link) => link.type === "instagram")?.url || ""
  );

  useEffect(() => {
    setInstagram(
      me?.socialLinks.find((link) => link.type === "instagram")?.url || ""
    ); // Set Instagram handle of the current logged-in user after updating
  }, [me]);

  const schema = Yup.object({
    instagram: Yup.string()
      .url("Invalid URL")
      .required("Instagram handle is required"),
  });

  const handleClose = () => {
    formik.resetForm(); // Reset form on Modal Close
    dispatch(closeModal());
  };

  const handleSubmit = (values) => {
    dispatch(
      setSocialLinksAction({ url: values.instagram, type: "instagram" })
    ); // API Call to update Instagram handle
    // handleClose();
  };

  // Form initialization and set default value
  const formik = useFormik({
    initialValues: { instagram },
    enableReinitialize: true,
    validationSchema: schema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        name="instagram"
        value={formik.values.instagram}
        onChange={formik.handleChange}
        placeholder="Enter Instagram Handle"
        onBlur={formik.handleBlur}
        id="instagram"
        error={formik.errors.instagram && formik.touched.instagram}
        errorMsg={formik.errors.instagram}
      />

      <div className="flex justify-end mt-6 gap-3">
        <Button
          variant="contained"
          className="m-auto white-btn"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button type="submit" variant="contained" className="m-auto px-12">
          Save
        </Button>
      </div>
    </form>
  );
};
export default ModalChangeInstagram;
