import { Box, CircularProgress } from "@mui/material";
import React from "react";

const CustomLoader = () => {
  return (
    <Box className="card mt-5 py-10">
      <div className="card-body flex items-center justify-center">
        <CircularProgress />
      </div>
    </Box>
  );
};

export default CustomLoader;
