import React from "react";
import { FormControlLabel, Switch } from "@mui/material";
import ShowActivity from "./../../../../../../assets/images/show-activity.svg";

const ModalProfilePrivacy = () => {
  return (
    <div className="gray-border border-radius-10 p-3">
      <p className="font-20 font-medium text-darkblue mb-3">Profile</p>
      <div className="lightgray-400 p-3 flex justify-between border-radius-10 gap-3">
        <div className="flex items-start gap-4 w-full">
          <img src={ShowActivity} alt="ShowActivity" />
          <div>
            <p className="font-18 font-medium text-darkblue ">
              Show Activity Status
            </p>
          </div>
        </div>
        <FormControlLabel
          className="black-border"
          control={
            <Switch
              // checked={checked}
              // onChange={handleChange}
              name="checked"
              color="primary"
            />
          }
        />
      </div>
    </div>
  );
};

export default ModalProfilePrivacy;
