import { createSlice } from "@reduxjs/toolkit";
import {
  AddDocumentTypeAction,
  EditDocumentTypeAction,
  GetAllDocumentTypeAction,
  GetAllDocumentTypeForWebAction,
  RemoveDocumentTypeAction,
} from "../../../middlewares/admin/documentType/documentType";

export const documentTypeSlice = createSlice({
  name: "documentType",
  initialState: {
    docTypeLoading: false,
    docTypes: [],
  },
  extraReducers(builder) {
    builder
      .addCase(GetAllDocumentTypeForWebAction.pending, (state) => {
        state.docTypeLoading = true;
      })
      .addCase(GetAllDocumentTypeForWebAction.fulfilled, (state, action) => {
        state.docTypeLoading = false;
        state.docTypes = action.payload.response;
      })
      .addCase(GetAllDocumentTypeForWebAction.rejected, (state) => {
        state.docTypeLoading = false;
        state.docTypes = [];
      })
      .addCase(GetAllDocumentTypeAction.pending, (state) => {
        state.docTypeLoading = true;
      })
      .addCase(GetAllDocumentTypeAction.fulfilled, (state, action) => {
        state.docTypeLoading = false;
        state.docTypes = action.payload.response;
      })
      .addCase(GetAllDocumentTypeAction.rejected, (state) => {
        state.docTypeLoading = false;
        state.docTypes = [];
      })
      .addCase(EditDocumentTypeAction.fulfilled, (state, action) => {
        let tempDocTypes = state.docTypes.map((doc) => {
          if (doc.id === action.payload.response.id) {
            return { ...doc, ...action.payload.response };
          }
          return { ...doc };
        });
        state.docTypes = tempDocTypes;
      })
      .addCase(AddDocumentTypeAction.fulfilled, (state, action) => {
        let { response } = action.payload;
        let tempArray = state.docTypes;
        tempArray.push(response);
        state.docTypes = tempArray;
      })
      .addCase(RemoveDocumentTypeAction.fulfilled, (state, action) => {
        let tempUsers = state.docTypes.filter(
          (code) => code.id !== action.payload.id
        );
        state.docTypes = tempUsers;
      });
  },
});

export default documentTypeSlice.reducer;
