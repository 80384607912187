import { Button, Dialog, DialogContent, DialogTitle, FormControl, MenuItem, Select } from '@mui/material'
import React from 'react'
import CancelSubscription from './../../../../../../assets/images/cancel-subscription.png';

const SubscriptionDialog = ({ openDialog2, handleCloseDialog2, toggleDrawer }) => {
    return (
        <Dialog
            open={openDialog2}
            onClose={handleCloseDialog2}
            className="send-tips subscription-modal"
        >
            <DialogTitle>
                <div className="flex justify-between items-center borderBottom pb-3">
                    <h3>SUBSCRIPTION</h3>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="relative mb-4">
                    <img src={CancelSubscription} alt="CancelSubscription" className="h-215px m-auto" />
                    <p className="font-18 font-medium text-darkblue">Are you sure you want to cancel your subscription?</p>
                </div>
                <FormControl className="w-full">
                    <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                    >
                        <MenuItem value="0">
                            No Specific Reason
                        </MenuItem>
                        <MenuItem value={1}>Low Posting Frequency</MenuItem>
                        <MenuItem value={2}>Private Messages Not Replied</MenuItem>
                        <MenuItem value={3}>No longer Want to be Subscribe</MenuItem>
                        <MenuItem value={4}>Subscription Price too High</MenuItem>
                        <MenuItem value={5}>Wil Re-subscribe Later</MenuItem>
                    </Select>
                </FormControl>

                <div className="flex justify-end mt-6 gap-3">
                    <Button
                        variant="contained"
                        className="m-auto white-btn"
                        onClick={handleCloseDialog2}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={toggleDrawer(true)} className="m-auto px-12">
                        Unsubscribe
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default SubscriptionDialog