import React from "react";
import { capitalizeAndInsertSpace } from "../../../../utils/capitalizeAndInserSpace";
import { notificationKeyFilterByType } from "../../../../utils/notificationKeyFilterByType";
import { capitalizeString } from "../../../../utils/capitalizeString";

function NotificationSetting({ type, notification }) {
  return (
    <div className="p-3">
      {notification &&
        Object.keys(notification)
          .filter((key) => notificationKeyFilterByType(key, type))
          .map((key) => {
            return (
              <div className="grid grid-cols-2 mb-2" key={key}>
                <p className="capitalize text-[#000]">
                  {capitalizeAndInsertSpace(key)} :
                </p>
                <p className="font-semibold text-[#000] text-right">
                  {" "}
                  {typeof notification[`${key}`] === "boolean"
                    ? notification[`${key}`]
                      ? "On"
                      : "Off"
                    : type === "email"
                    ? capitalizeString(notification[`${key}`])
                    : notification[`${key}`]}
                </p>
              </div>
            );
          })}
    </div>
  );
}

export default NotificationSetting;
