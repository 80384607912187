import React from "react";
import ReactApexChart from "react-apexcharts";
import useChartColors from "../../../../hooks/useChartColors";

const BasicLine = ({ chartId, chartTitle="", itemName = "Users" }) => {
    const chartColors = useChartColors(chartId);

    const series = [{
        name: itemName,
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
    }];

    var options = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            },
            margin: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: chartTitle,
            align: 'left'
        },
        colors: chartColors,
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series || []}
                data-chart-colors='["bg-custom-500"]'
                id={chartId}
                className="apex-charts"
                type='line'
                height={350}
            />
        </React.Fragment>
    );
};

export default BasicLine