import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
// import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import configStore from "./store";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./assets/styles/global.scss";
import "./assets/styles/responsive.scss";
import "./assets/styles/global2.scss";
import "./assets/scss/themes.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ErrorBoundary } from "react-error-boundary";

const ErrorFallback = lazy(() => import("./newComponents/fallbackUI/Error"));
const Loader = lazy(() => import("./newComponents/fallbackUI/Loading"));
const store = configStore();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // eslint-disable-next-line no-undef
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <React.StrictMode>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Router>
          <Suspense fallback={<Loader />}>
            <Provider store={store}>
              <App />
            </Provider>
          </Suspense>
        </Router>
      </ErrorBoundary>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
