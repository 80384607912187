import { AccessTime } from '@mui/icons-material';
import { Button, FormHelperText, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const OtpFields = ({ handleSubmit, inputRefs, error = false, errorMsg = "", hasReset = false }) => {
    const initialTime = 60;

    const [timeLeft, setTimeLeft] = useState(initialTime);

    const handleChange = (e, index) => {
        const value = e.target.value.replace(/\D/g, '');

        e.target.value = value;

        if (value.length === 1 && index < inputRefs.current.length - 1) {
            //focus on the next one when a digit is entered
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !e.target.value && index > 0) {
            // focus on the previous one when a digit is removed
            inputRefs.current[index - 1].focus();
        }
    };

    useEffect(() => {
        if (timeLeft > 0) {
            const timerId = setInterval(() => {
                setTimeLeft(prevTime => prevTime - 1);
            }, 1000);
            return () => clearInterval(timerId);
        }
    }, [timeLeft]);


    // --------------- resend countdown timer ---------------
    const handleResendOTP = () => {
        if (timeLeft === 0) {
            setTimeLeft(initialTime);
        }
    };



    return (
        <form onSubmit={handleSubmit}>
            <div className="flex gap-3 otp-text">
                {[...Array(6)].map((_, index) => (
                    <TextField
                        key={index}
                        fullWidth
                        inputRef={el => inputRefs.current[index] = el}
                        placeholder="0"
                        variant="outlined"
                        error={error}
                        name={`otp-${index}`}
                        inputProps={{ maxLength: 1, type: 'text', pattern: '[0-9]*' }}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        sx={{ textAlign: "center !important" }}
                    />
                ))}
            </div>
            {error && <FormHelperText error={true}>{errorMsg}</FormHelperText>}
            {hasReset && (<div className="flex justify-between mb-4 mt-2">
                <div className="flex">
                    <AccessTime className="mr-1" />
                    <span>{`00:${timeLeft < 10 ? `0${timeLeft}` : timeLeft} Sec`}</span>
                    {/* <span>00:30 Sec</span> */}
                </div>
                {/* <Link href="#">Resend OTP</Link> */}
                <Link className={`${timeLeft === 0 ? "text-[#2799F6] cursor-pointer" : " otp-color cursor-default"}`} onClick={handleResendOTP}>
                    Resend OTP
                </Link>
            </div>)}
            <Button variant="contained" className='w-full' sx={{ marginTop: "10px" }} type="submit">Submit</Button>
        </form>
    )
}

export default OtpFields
