import { Button, InputAdornment } from "@mui/material";
import React from "react";
import {
  closeModal,
  // openModal,
  
} from "../../../../../store/slices/common/modal";
import { useDispatch } from "react-redux";
import { FolderName } from "../../../../../utils/validation/AddFolderForm";
import { useFormik } from "formik";
import { Input } from "../../../../atoms";
import * as Yup from "yup";
import { addUserListFolderAction } from "../../../../../store/middlewares/web/folders/folders";
// import SaveToListTitle from "../../userProfile/ModalSaveToList/title";
// import { ModalSaveToList } from "../../..";

const ModalAddCollection = ({ fromAdd = false }) => {
  const dispatch = useDispatch();

  const schema = Yup.object({
    folderName: FolderName, // validation
  });

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleSubmit = (values) => {
    dispatch(addUserListFolderAction({ fromAdd, ...values })); // API Call to Add Folder
    formik.resetForm();

    // if (fromAdd) {
    //   dispatch(
    //     openModal({
    //       title: <SaveToListTitle />,
    //       content: <ModalSaveToList />,
    //       hasCloseBtn: true,
    //     })
    //   );
    // }
  };

  const formik = useFormik({
    initialValues: { folderName: "" },
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: handleSubmit,
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Input
          placeholder="Enter Folder name"
          value={formik.values.folderName}
          onChange={formik.handleChange}
          name="folderName"
          onBlur={formik.handleBlur}
          id="folderName"
          inputProps={{ maxLength: 64 }}
          // onKeyDown={(e) => handleKeyDown(e)}
          error={formik.errors.folderName && formik.touched.folderName}
          errorMsg={formik.errors.folderName}
          endAdornment={
            <InputAdornment position="end" className="text-black">
              {formik.values.folderName.length}/64
            </InputAdornment>
          }
        />
        <div className="flex justify-end mt-6 gap-3">
          <Button
            variant="contained"
            className="m-auto white-btn"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button variant="contained" type="submit" className="m-auto px-12">
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ModalAddCollection;
