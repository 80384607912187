import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateAccount, activate2StepAuth, verifyTOTPFirstTime } from "./../../../../services/web/profile/account.services";
import { closeModal } from "../../../../slices/common/modal";

export const UpdateAccountAction = createAsyncThunk("account/updateAccount", async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    let { coverUrl, profileUrl, ...rest } = payload
    try {
        const res = await updateAccount(rest);
        if (res.status === 1) {
            // if any async operation need to be done on response data, do it here,
            // then full fill data
            const { response: { updatedAccount }, status, message } = res;
            let tempData = { ...updatedAccount, coverUrl, profileUrl }
            dispatch(closeModal()) 
            return fulfillWithValue({ response: tempData, status, message });
        }
        return rejectWithValue({ message: res?.message });
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const Activate2Step = createAsyncThunk("auth/activateTwoStep", async (payload, { rejectWithValue,  fulfillWithValue }) => {
    try {
        const response = await activate2StepAuth();
        if (response.status === 1) {
            // if any async operation need to be done on response data, do it here,
            // then full fill data
            return fulfillWithValue(response);
        }
        return rejectWithValue({ message: response?.message });
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const VerifyTOTPFirstTimeAction = createAsyncThunk("auth/verifyTOTP", async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await verifyTOTPFirstTime(payload);
        if (response.status === 1) {
            // if any async operation need to be done on response data, do it here,
            // then full fill data
            return fulfillWithValue(response);
        }
        return rejectWithValue({ message: response?.message });
    } catch (error) {
        return rejectWithValue(error);
    }
})


