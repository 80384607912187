import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { Box, Tab, } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import IconButton from "@mui/material/IconButton";
import PostsTab from "../../../../newComponents/organisms/creator/userList/PostsTab";
import UsersTab from "../../../../newComponents/organisms/creator/userList/UsersTab";

import ArrowLeft from "./../../../../assets/images/arrow-left.svg";;
import SearchIcon from "./../../../../assets/images/search-normal.svg";
import MessagesIcon from './../../../../assets/images/sidebar/messages.svg';
import PlusCircleIcon from "./../../../../assets/images/add-circle.svg";

export default function CollectionsCreator() {
    const drawerWidth = 330;
    const [value, setValue] = React.useState("0");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box
            component="main"
            className="flex"
            sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
            <Grid container spacing={2} className="mb-4 collectionsFans">
                <Grid item xs={12} md={12} lg={12}>
                    <div className="bg-white rounded-2xl p-3 heading">
                        <div className="flex flex-wrap items-center justify-between">
                            <div className="flex items-center gap-3">
                                <IconButton color="primary" aria-label="add to shopping cart">
                                    <img src={ArrowLeft} alt="ArrowLeft" height={26} width={26} className="img-dark-filter" />
                                </IconButton>
                                <h3 className="font-18 font-semibold text-darkblue uppercase">
                                    FOLLOWING
                                </h3>
                            </div>
                            <div className="flex flex-wrap gap-2">
                                <div className="post-tab mr-2">
                                    <TabContext value={value}>
                                        <Box>
                                            <TabList
                                                onChange={handleChange}
                                                aria-label="simple tabs example"
                                            >
                                                <Tab label="Users" value="0" />
                                                <Tab label="Posts" value="1" />
                                            </TabList>
                                        </Box>
                                    </TabContext>
                                </div>
                                <IconButton color="primary">
                                    <img className="img-dark-filter"
                                        src={MessagesIcon}
                                        alt="MessagesIcon"
                                        height={26}
                                        width={26}
                                    />
                                </IconButton>
                                <IconButton color="primary">
                                    <img className="img-dark-filter"
                                        src={SearchIcon}
                                        alt="SearchIcon"
                                        height={26}
                                        width={26}
                                    />
                                </IconButton>
                                <IconButton color="primary">
                                    <img className="img-dark-filter"
                                        src={PlusCircleIcon}
                                        alt="PlusCircleIcon"
                                        height={26}
                                        width={26}
                                    />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    <TabContext value={value}>
                        <TabPanel value="0" label='User' className="p-0 w-full">
                            <UsersTab />
                        </TabPanel>
                        <TabPanel value="1" label='Post' className="p-0 w-full">
                            <PostsTab />
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
        </Box>
    );
}

