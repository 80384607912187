import { GroupRemove, PeopleAlt, PersonOff } from "@mui/icons-material";
import React from "react";
import CountUp from "react-countup";
import { SimplePie } from "../../../../newComponents/atoms";

const DashboardSection = ({ title, activeCount, banCount, deletedCount }) => {
  return (
    <div className="card">
      <div className="bg-slate-200 card-body rounded-t-md">
        <h6 className="text-15">{title}</h6>
      </div>
      <div className="card-body">
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-2 items-start">
          <div className="flex flex-col gap-6 justify-between">
            <div className="p-6 text-center flex items-center justify-between bg-green-100 border border-green-200 rounded-md">
              <div className="flex items-center justify-center size-12 my-auto rounded-md bg-green-50 text-15">
                <PeopleAlt className="text-green-500 fill-green-200 " />
              </div>
              <div className="flex flex-col items-end">
                <h4 className="mb-1 text-green-500 text-4xl">
                  <CountUp className="counter-value" end={activeCount} />
                </h4>
                <p className="mb-0 text-green-500">{`ACTIVE ${title}`}</p>
              </div>
            </div>
            <div className="p-6 text-center flex items-center justify-between bg-red-100 border border-red-200 rounded-md">
              <div className="flex items-center justify-center size-12  rounded-md bg-red-50 text-15 ">
                <GroupRemove className="text-red-500 fill-red-200 " />
              </div>
              <div className="flex flex-col items-end">
                <h4 className="mb-1 text-red-500 text-4xl">
                  <CountUp className="counter-value" end={deletedCount} />
                </h4>
                <p className="mb-0 text-red-500">{`DELETED ${title}`}</p>
              </div>
            </div>
            <div className="p-6 text-center flex items-center justify-between bg-orange-100 border border-orange-200 rounded-md">
              <div className="flex items-center justify-center size-12 rounded-md bg-orange-50 text-15 d">
                <PersonOff className="text-orange-500 fill-orange-200" />
              </div>
              <div className="flex flex-col items-end">
                <h4 className="mb-1 text-orange-500 text-4xl">
                  <CountUp className="counter-value" end={banCount} />
                </h4>
                <p className="mb-0 text-orange-500">{`BANNED ${title}`}</p>
              </div>
            </div>
          </div>
          <div>
            <SimplePie
              chartId="simplePie"
              dataSeries={[
                activeCount ? activeCount : 0,
                banCount ? banCount : 0,
                deletedCount ? deletedCount : 0,
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSection;
