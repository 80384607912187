import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import SaveForLaterImg from "./../../../../../assets/images/saveforlater.png";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../../../../store/slices/common/modal";
import ModalAddCollection from "../../userLists/ModalAddCollection";
import { getUserListFolderAction } from "../../../../../store/middlewares/web/folders/folders";
import { upsertUserToUserListAction } from "../../../../../store/middlewares/admin/users/users";

function ModelSaveToList({ userId, refresh }) {
  //Save Post To A List
  const dispatch = useDispatch();
  const [checked, setChecked] = useState("");

  const { folders, foldersLoading } = useSelector((state) => state.folders);

  useEffect(() => {
    dispatch(getUserListFolderAction());
  }, [dispatch]);

  const handleChange = (e) => {
    setChecked(e.target.value);
  };

  const handleAddToUserList = () => {
    dispatch(upsertUserToUserListAction({ userId, folderId: checked, refresh }));
  };
  return (
    <>
      {foldersLoading && (
        <Box className="bg-white rounded-2xl p-4 flex items-start justify-center">
          <CircularProgress />
        </Box>
      )}
      {folders?.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormControl component="fieldset" className="w-full ">
            {/* Select Folder */}
            <RadioGroup
              aria-label="options"
              name="options"
              onChange={(e) => handleChange(e)}
              checked={checked}
            >
              {folders?.map((folder) => {
                return (
                  <div
                    className="site-notification flex items-center justify-center pl-2 pr-4 py-2 border-radius-10 gap-3 w-full"
                    key={folder.id}
                  >
                    <FormControlLabel
                      className="w-full"
                      value={folder.id}
                      control={
                        <Radio
                          sx={{ paddingTop: "0 !important" }}
                          value={folder.id}
                          checked={folder.id === checked}
                          onClick={(e) => handleChange(e)}
                        />
                      } //Radio Button For Each Language
                      label={<span>{folder.folderName}</span>}
                    />
                  </div>
                );
              })}
            </RadioGroup>
          </FormControl>
        </div>
      )}
      {folders?.length === 0 && (
        <div className="flex flex-col justify-center items-center light-gray700-bg p-4 rounded-2xl">
          <img src={SaveForLaterImg} alt="SaveForLaterImg" width={198} />
          <p className="font-20">Nothing found</p>
        </div>
      )}
      <div className="flex justify-end mt-6 gap-3">
        <Button
          variant="outlined"
          className="m-auto px-12"
          onClick={() => {
            dispatch(
              openModal({
                title: "CREATE NEW LIST",
                content: <ModalAddCollection fromAdd={true} />,
                hasCloseBtn: false,
              })
            );
          }}
        >
          New List <AddCircleOutlineIcon className="ml-2" />
        </Button>
        <Button
          variant="contained"
          className="m-auto white-btn"
          onClick={() => dispatch(closeModal())}
        >
          Cancel
        </Button>
        <Button
          onClick={handleAddToUserList}
          disabled={!checked}
          variant="contained"
          className="m-auto px-12"
        >
          Save
        </Button>
      </div>
    </>
  );
}

export default ModelSaveToList;
