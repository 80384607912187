import React from "react";
import BookMarksIcon from "../assets/SVG/SideBar/BookMarksIcon";
import MyProfileIcon from "../assets/SVG/SideBar/MyProfileIcon";
import UserListsIcon from "../assets/SVG/SideBar/UserListsIcon";
import YourCardsIcon from "../assets/SVG/SideBar/YourCardsIcon";
import BankIcon from "../assets/SVG/SideBar/BankIcon";
import HelpIcon from "../assets/SVG/SideBar/HelpIcon";
import DarkMode from "../assets/SVG/SideBar/DarkMode";
import SettingsIcon from "../assets/SVG/SideBar/SettingsIcon";
import EnglishIcon from "../assets/SVG/SideBar/EnglishIcon";

export const personalSectionItems = [
  {
    icon: <MyProfileIcon />,
    label: "My Profile",
    hasPage: true,
    slug: "my-profile",
  },
  {
    icon: <UserListsIcon />,
    label: "User Lists",
    hasPage: true,
    slug: "user-lists",
  },
  {
    icon: <BookMarksIcon />,
    label: "Bookmarks",
    hasPage: true,
    slug: "bookmarks",
  },
];

export const walletSectionItems = [
  {
    icon: <YourCardsIcon />,
    label: "Your Cards",
    helperText: "To Subscribe",
  },
  {
    icon: <BankIcon />,
    label: "Bank",
    helperText: "To Earn",
  },
  // {
  //     "icon": <HelpIcon />,
  //     "label": "Help and Support",
  //     "helperText": "Help and Support"
  // },
];

export const toolsSectionItems = [
  {
    icon: <HelpIcon />,
    label: "Help And Support",
    slug: "help-and-support",
    helperText: "Help and Support",
  },
  {
    icon: <DarkMode />,
    label: "Dark Mode",
    slug: "dark-mode",
    helperText: "Dark Mode",
  },
  {
    icon: <SettingsIcon />,
    slug: "settings",
    helperText: "Settings",
    label: "Settings",
  },
  {
    icon: <EnglishIcon />,
    slug: "english",
    label: "English",
    helperText: "English",
  },
];
