import CryptoJS from "crypto-js";

// Function to encrypt and store token
export const storeKey = () => {
  // const secretKey =
  //   "97bafbf9bbc7e723d3b12bfd86392e95f7a30530356f238f8835ce4efcba6eb3";
  // localStorage.setItem("secretKey", secretKey); // Use a secure key
};

export const encryptAndStoreToken = (token) => {
  // eslint-disable-next-line no-undef
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  const encryptedToken = CryptoJS.AES.encrypt(token, secretKey).toString();
  return encryptedToken;
};

export const getDecryptedToken = (encryptedToken) => {
  // eslint-disable-next-line no-undef
  const secretKey = process.env.REACT_APP_SECRET_KEY; // Same key used for encryption

  if (encryptedToken) {
    const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
    const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedToken;
  }
  return null; // Handle case where token is not available
};
