import React from "react";
import TableContainer from "../tablecomponet/TableContainer";

const ProductsStatistics = ({ rows, columns, title = "Users" }) => {
  return (
    <React.Fragment>
      <div className="order-11 col-span-12 2xl:order-1 card 2xl:col-span-12">
        <div className="card-body">
          <div className="grid items-center grid-cols-1 gap-3 mb-5 xl:grid-cols-12">
            <div className="xl:col-span-3">
              <h6 className="text-15">{title}</h6>
            </div>
          </div>
          <TableContainer
            isPagination={false}
            columns={columns || []}
            data={rows || []}
            customPageSize={10}
            divclassName="-mx-5 overflow-x-auto"
            tableclassName="w-full whitespace-nowrap"
            theadclassName="text-left bg-slate-100 text-slate-500 "
            thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-y border-slate-200"
            tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 h-[88px] max-w-[200px] overflow-hidden"
            PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductsStatistics;
