const formatDateForPost = (inputDateStr) => {
     let inputDate = new Date(inputDateStr);

    // Get the local date and time components
    let optionsDate = { day: '2-digit', month: 'short' };
    let optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true };
    
    // Format the date and time
    let formattedDate = inputDate.toLocaleDateString('en-US', optionsDate).toUpperCase();
    let formattedTime = inputDate.toLocaleTimeString('en-US', optionsTime);
    
    // Combine the formatted date and time
    let result = `${formattedDate}, ${formattedTime}`;

    return result
}

export default formatDateForPost