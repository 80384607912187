import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getLanguagesForWeb,
  toggleDarkMode,
  updateLanguagesForWeb,
} from "../../../../services/web/profile/profiledisplay.service";

export const getAllLanguagesForWebAction = createAsyncThunk(
  "display/getlanguages",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getLanguagesForWeb();
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateLanguageForUser = createAsyncThunk(
  "profile/updatelanguages",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await updateLanguagesForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        const { response, message } = res;
        let tempoObj = { languageId: response };
        return fulfillWithValue({ response: tempoObj, message });
      }
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const toggleDarkModeAction = createAsyncThunk(
  "display/toggleDarkMode",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await toggleDarkMode(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        const { message } = res;
        let tempObj = { isDarkMode: !payload.mode };
        return fulfillWithValue({ response: tempObj, message });
      }
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
