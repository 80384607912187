import React, { useState } from 'react'
import { Masonry, TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Grid, Avatar, AvatarGroup, Button, Divider, FormControl, FormControlLabel, InputAdornment, Menu, MenuItem, OutlinedInput, Radio, Tab } from '@mui/material';
import TipDialog from '../../../../molecules/web/creatorSide/UserList/Dialoage/TipDialog';
import SubscriptionDialog from '../../../../molecules/web/creatorSide/UserList/Dialoage/SubscriptionDialog';
import BillingDrawer from '../../../../molecules/web/creatorSide/UserList/Drawer/BillingDrawer';

import SearchIcon from "./../../../../../assets/images/search-normal.svg";
import ScreenMirroringIcon from "./../../../../../assets/images/screenmirroring.svg";
import ActiveUserIcon from "./../../../../../assets/images/active-user.svg";
import TimerIcon from "./../../../../../assets/images/timer.svg";
import BlockedUserIcon from "./../../../../../assets/images/blockuserIcon.svg";
import listmenublack from './../../../../../assets/images/listmenublack.svg';
import SettingsIcon from './../../../../../assets/images/setting-5.svg';
import CustomerImg1 from "./../../../../../assets/images/collections/customer1.png";
import CustomerImg2 from "./../../../../../assets/images/collections/customer2.png";
import CustomerImg3 from "./../../../../../assets/images/collections/customer3.png";
import starIcon from "./../../../../../assets/images/star.svg";
import ShareIcon from "./../../../../../assets/images/share-black.svg";
import dollarIcon from "./../../../../../assets/images/dollar-square-blue.svg";
import More from "./../../../../../assets/images/more-square.svg";
import GreenVerifyIcon from "./../../../../../assets/images/green-verify.svg";
import CollectionPostBg from "./../../../../../assets/images/collections/collectionimg4.png";
import CollectionPostBg2 from "./../../../../../assets/images/collections/collectionimg5.png";
import CollectionPostBg3 from "./../../../../../assets/images/collections/collectionimg6.png";
import CollectionPostBg4 from "./../../../../../assets/images/collections/collectionimg7.png";
import ColeUser from "./../../../../../assets/images/cole-user.png";
import Verified from "./../../../../../assets/images/verified.svg";
import SaveForLaterImg from "./../../../../../assets/images/saveforlater.png";

const UsersTab = () => {
    const [age, setAge] = React.useState('');

    // For tablist
    const [selectedTab, setSelectedTab] = useState('all');
    const handleChange = (event, newValue) => {
        setChecked(event.target.checked);
        setSelectedTab(newValue);
    };

    // For menu 
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isOpen = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    // For menu 
    const [anchorEl1, setAnchorEl1] = useState(null);
    const isOpen1 = Boolean(anchorEl1);
    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    // For menu 
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const isOpen2 = Boolean(anchorEl2);
    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    // For drawer 
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setIsDrawerOpen(open);
    };


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const [checked, setChecked] = React.useState(true);

    // For Tips and Subcription dialog
    const [openDialog1, setOpenDialog1] = useState(false);
    const [openDialog2, setOpenDialog2] = useState(false);
    const handleOpenDialog1 = () => {
        setOpenDialog1(true);
    };
    const handleCloseDialog1 = () => {
        setOpenDialog1(false);
    };
    const handleOpenDialog2 = () => {
        setOpenDialog2(true);
    };
    const handleCloseDialog2 = () => {
        setOpenDialog2(false);
    };

    return (
        <div className="h-queue fans-profile">
            <div className="post-tab">
                <TabContext value={selectedTab}>
                    <div className="bg-white rounded-2xl p-3 mt-5 w-full mb-3">
                        <div className="mb-4 recent-txtbox">
                            <FormControl className="w-full" variant="outlined">
                                <OutlinedInput
                                    placeholder="Recent"
                                    id="outlined-adornment-weight"
                                    endAdornment={
                                        <InputAdornment position="end" className="flex gap-3">
                                            <img src={SearchIcon} alt="SearchIcon" className="img-dark-filter" />
                                            <img src={SettingsIcon} alt="SettingsIcon" className="img-dark-filter" onClick={handleClick} />
                                            <img src={listmenublack} alt="List Menu" className="pr-4 img-dark-filter" onClick={handleClick1} />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <Menu
                                anchorEl={anchorEl}
                                id="more-detail"
                                open={isOpen}
                                className="radiobtn-menu"
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: "visible",
                                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.08))",
                                        mt: 1.5,
                                        "& .MuiAvatar-root": {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        "&::before": {
                                            content: '""',
                                            display: "block",
                                            position: "absolute",
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: "background.paper",
                                            transform: "translateY(-50%) rotate(45deg)",
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: "right", vertical: "top" }}
                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                            >
                                <p className="text-darkblue font-medium px-5 py-2">
                                    Subscription
                                </p>
                                <MenuItem onClick={handleClose}>
                                    <FormControlLabel
                                        value="All"
                                        control={<Radio />}
                                        label="All"
                                    />
                                </MenuItem>
                                <MenuItem>
                                    <FormControlLabel
                                        value="Paid"
                                        control={<Radio />}
                                        label="Paid"
                                    />
                                </MenuItem>
                                <MenuItem>
                                    <FormControlLabel
                                        value="Free"
                                        control={<Radio />}
                                        label="Free"
                                    />
                                </MenuItem>
                                <div className="mx-2">
                                    <Divider className="w-full my-1" />
                                </div>
                                <p className="text-darkblue font-medium px-5 py-2">
                                    Online Status
                                </p>
                                <MenuItem>
                                    <FormControlLabel
                                        value="All"
                                        control={<Radio />}
                                        label="All"
                                    />
                                </MenuItem>
                                <MenuItem>
                                    <FormControlLabel
                                        value="Online"
                                        control={<Radio />}
                                        label="Online"
                                    />
                                </MenuItem>
                                <MenuItem>
                                    <FormControlLabel
                                        value="Offline"
                                        control={<Radio />}
                                        label="Offline"
                                    />
                                </MenuItem>
                            </Menu>

                            <Menu
                                anchorEl={anchorEl1}
                                id="video-menu"
                                open={isOpen1}
                                onClose={handleClose1}
                                onClick={handleClose1}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: "visible",
                                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.08))",
                                        mt: 1.5,
                                        "& .MuiAvatar-root": {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        "&::before": {
                                            content: '""',
                                            display: "block",
                                            position: "absolute",
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: "background.paper",
                                            transform: "translateY(-50%) rotate(45deg)",
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{
                                    horizontal: "right",
                                    vertical: "top",
                                }}
                                anchorOrigin={{
                                    horizontal: "right",
                                    vertical: "bottom",
                                }}
                            >
                                <MenuItem onClick={handleClose}>
                                    <FormControlLabel
                                        value="Recent"
                                        control={<Radio />}
                                        label="Recent"
                                    />
                                </MenuItem>
                                <MenuItem>
                                    <FormControlLabel
                                        value="Current Subscription"
                                        control={<Radio />}
                                        label="Current Subscription"
                                    />
                                </MenuItem>
                                <MenuItem>
                                    <FormControlLabel
                                        value="Started"
                                        control={<Radio />}
                                        label="Started"
                                    />
                                </MenuItem>
                                <div className="mx-2">
                                    <Divider className="w-full my-1" />
                                </div>
                                <MenuItem>
                                    <FormControlLabel
                                        value="Ascending"
                                        control={<Radio />}
                                        label="Ascending"
                                    />
                                </MenuItem>
                                <MenuItem>
                                    <FormControlLabel
                                        value="Descending"
                                        control={<Radio />}
                                        label="Descending"
                                    />
                                </MenuItem>
                            </Menu>
                        </div>

                        <Box className="mb-4">
                            <TabList
                                onChange={handleChange}
                                aria-label="simple tabs example"
                                className="flex-wrap"
                            >
                                <Tab
                                    icon={
                                        <img
                                            src={ScreenMirroringIcon}
                                            alt="ScreenMirroringIcon"
                                        />
                                    }
                                    label="All"
                                    value="all"
                                />
                                <Tab
                                    icon={<img src={ActiveUserIcon} alt="ActiveUserIcon" />}
                                    label="Active (05)"
                                    value="active"
                                />
                                <Tab
                                    icon={
                                        <img src={TimerIcon} alt="TimerIcon" width={24} />
                                    }
                                    label="Expired (0)"
                                    value="expired"
                                />
                                <Tab
                                    icon={
                                        <img src={BlockedUserIcon} alt="BlockedUserIcon" />
                                    }
                                    label="Attention Required (1)"
                                    value="attention"
                                />
                            </TabList>
                        </Box>

                        <TabPanel value="all" className="p-0 w-full">
                            <Grid
                                container
                                spacing={1}
                                className="h-full mb-4 fans-message"
                            >
                                <Grid item xs={12} md={12} lg={8}>
                                    <div className="light-gray-bg lg:mr-4">
                                        <Grid
                                            container
                                            spacing={1}
                                            className="h-full mb-4 fans-message"
                                        >
                                            <Masonry
                                                columns={{ xs: 1, sm: 1, md: 1, lg: 2 }}
                                                spacing={2}
                                            >
                                                <div className="bg-white p-3 rounded-2xl">
                                                    <div className="flex justify-end flex-wrap gap-3 mb-2">
                                                        <div className="flex gap-2">
                                                            <img className="img-dark-filter"
                                                                src={ShareIcon}
                                                                alt="ShareIcon"
                                                                width={20}
                                                                height={20}
                                                            />
                                                            <img src={More} alt="More" width={20} className="img-dark-filter cursor-pointer" onClick={handleClick1} />
                                                        </div>
                                                    </div>
                                                    <Menu
                                                        anchorEl={anchorEl2}
                                                        id="video-menu"
                                                        open={isOpen2}
                                                        onClose={handleClose2}
                                                        onClick={handleClose2}
                                                        PaperProps={{
                                                            elevation: 0,
                                                            sx: {
                                                                overflow: "visible",
                                                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.08))",
                                                                mt: 1.5,
                                                                "& .MuiAvatar-root": {
                                                                    width: 32,
                                                                    height: 32,
                                                                    ml: -0.5,
                                                                    mr: 1,
                                                                },
                                                                "&::before": {
                                                                    content: '""',
                                                                    display: "block",
                                                                    position: "absolute",
                                                                    top: 0,
                                                                    right: 14,
                                                                    width: 10,
                                                                    height: 10,
                                                                    bgcolor: "background.paper",
                                                                    transform: "translateY(-50%) rotate(45deg)",
                                                                    zIndex: 0,
                                                                },
                                                            },
                                                        }}
                                                        transformOrigin={{
                                                            horizontal: "right",
                                                            vertical: "top",
                                                        }}
                                                        anchorOrigin={{
                                                            horizontal: "right",
                                                            vertical: "bottom",
                                                        }}
                                                    >
                                                        <MenuItem onClick={handleClose2}>
                                                            <div className="relative varified mr-3">
                                                                <img
                                                                    src={ColeUser}
                                                                    alt="ColeUser"
                                                                    height={38}
                                                                    width={38}
                                                                    className="hw-38px"
                                                                />
                                                                <div className="verified-bg">
                                                                    <img
                                                                        src={Verified}
                                                                        alt="Verified"
                                                                        width={16}
                                                                        height={16}
                                                                    />
                                                                </div>
                                                            </div>
                                                            @colejanson
                                                        </MenuItem>
                                                        <div className="px-4">
                                                            <Divider />
                                                        </div>
                                                        <MenuItem onClick={handleClose}>
                                                            Copy Link to Profile
                                                        </MenuItem>
                                                        <MenuItem onClick={handleClose}>
                                                            Add to / Remove from lists
                                                        </MenuItem>
                                                        <MenuItem onClick={handleClose}>Block</MenuItem>
                                                        <MenuItem onClick={handleClose}>Report</MenuItem>
                                                    </Menu>
                                                    <div className="relative">
                                                        <img
                                                            src={CollectionPostBg}
                                                            alt="CollectionPostBg"
                                                            className="h-100px w-full"
                                                        />
                                                        <img
                                                            src={CollectionPostBg}
                                                            alt="CollectionPostBg"
                                                            className="profile-user rounded-full h-77px w-77px"
                                                            width={144}
                                                            height={144}
                                                        />
                                                    </div>
                                                    <div className="relative flex flex-xs-nowrap gap-10 md:pl-24 md:mt-0 mt-10">
                                                        <div className="flex flex-md-wrap flex-wrap justify-between gap-2 w-full mt-2 ml-2">
                                                            <div>
                                                                <p className="font-18 font-semibold text-darkblue flex items-center gap-3">
                                                                    Yrsa Clicks{" "}
                                                                    <img
                                                                        src={GreenVerifyIcon}
                                                                        alt="Green Verify Icon"
                                                                    />
                                                                </p>
                                                                <p>@yrsaclicks</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-wrap gap-3 profile-post-btns mt-3">
                                                        <Button
                                                            variant="outlined"
                                                            className="light-blue-icon-btn"
                                                            onClick={handleOpenDialog1}
                                                        >
                                                            <img
                                                                src={dollarIcon}
                                                                alt="dollarIcon"
                                                                width={24}
                                                            />
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            className="light-blue-icon-btn"
                                                        >
                                                            <img
                                                                src={starIcon}
                                                                alt="starIcon"
                                                                width={24}
                                                            />
                                                        </Button>
                                                    </div>
                                                    <div className="flex flex-col gap-3 light-blue-bg mt-4 border-radius-10">
                                                        <div className="p-4">
                                                            <Button onClick={handleOpenDialog2}
                                                                variant="outlined"
                                                                className="m-auto px-12 w-full min-h-40px light-blue-icon-outline"
                                                            >
                                                                Subscribe for Free
                                                            </Button>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="bg-white p-3 rounded-2xl">
                                                    <div className="flex justify-end flex-wrap gap-3 mb-2">
                                                        <div className="flex gap-2">
                                                            <img className="img-dark-filter"
                                                                src={ShareIcon}
                                                                alt="ShareIcon"
                                                                width={20}
                                                                height={20}
                                                            />
                                                            <img src={More} alt="More" width={20} className="img-dark-filter" />
                                                        </div>
                                                    </div>
                                                    <div className="relative">
                                                        <img
                                                            src={CollectionPostBg2}
                                                            alt="CollectionPostBg2"
                                                            className="h-100px w-full"
                                                        />
                                                        <img
                                                            src={CollectionPostBg2}
                                                            alt="CollectionPostBg2"
                                                            className="profile-user rounded-full h-77px w-77px"
                                                            width={144}
                                                            height={144}
                                                        />
                                                    </div>
                                                    <div className="relative flex flex-xs-nowrap gap-10 md:pl-24 md:mt-0 mt-10">
                                                        <div className="flex flex-md-wrap flex-wrap justify-between gap-2 w-full mt-2 ml-2">
                                                            <div>
                                                                <p className="font-18 font-semibold text-darkblue flex items-center gap-3">
                                                                    Yrsa Clicks{" "}
                                                                    <img
                                                                        src={GreenVerifyIcon}
                                                                        alt="Green Verify Icon"
                                                                    />
                                                                </p>
                                                                <p>@yrsaclicks</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-wrap gap-3 profile-post-btns mt-3">
                                                        <Button
                                                            variant="outlined"
                                                            className="light-blue-icon-btn"
                                                        >
                                                            <img
                                                                src={dollarIcon}
                                                                alt="dollarIcon"
                                                                width={24}
                                                            />
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            className="light-blue-icon-btn"
                                                        >
                                                            <img
                                                                src={starIcon}
                                                                alt="starIcon"
                                                                width={24}
                                                            />
                                                        </Button>
                                                    </div>

                                                    <div className="flex flex-col gap-3 light-blue-bg mt-4 border-radius-10">
                                                        <div className="p-4">
                                                            <Button
                                                                variant="outlined"
                                                                className="m-auto px-12 w-full min-h-40px light-blue-icon-outline"
                                                            >
                                                                Subscribe for Free
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="bg-white p-3 rounded-2xl">
                                                    <div className="flex justify-end flex-wrap gap-3 mb-2">
                                                        <div className="flex gap-2">
                                                            <img className="img-dark-filter"
                                                                src={ShareIcon}
                                                                alt="ShareIcon"
                                                                width={20}
                                                                height={20}
                                                            />
                                                            <img src={More} alt="More" width={20} className="img-dark-filter" />
                                                        </div>
                                                    </div>
                                                    <div className="relative">
                                                        <img
                                                            src={CollectionPostBg3}
                                                            alt="CollectionPostBg3"
                                                            className="h-100px w-full"
                                                        />
                                                        <img
                                                            src={CollectionPostBg3}
                                                            alt="CollectionPostBg3"
                                                            className="profile-user rounded-full h-77px w-77px"
                                                            width={144}
                                                            height={144}
                                                        />
                                                    </div>
                                                    <div className="relative flex flex-xs-nowrap gap-10 md:pl-24 md:mt-0 mt-10">
                                                        <div className="flex flex-md-wrap flex-wrap justify-between gap-2 w-full mt-2 ml-2">
                                                            <div>
                                                                <p className="font-18 font-semibold text-darkblue flex items-center gap-3">
                                                                    Yrsa Clicks{" "}
                                                                    <img
                                                                        src={GreenVerifyIcon}
                                                                        alt="Green Verify Icon"
                                                                    />
                                                                </p>
                                                                <p>@yrsaclicks</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-wrap gap-3 profile-post-btns mt-3">
                                                        <Button
                                                            variant="outlined"
                                                            className="light-blue-icon-btn"
                                                        >
                                                            <img
                                                                src={dollarIcon}
                                                                alt="dollarIcon"
                                                                width={24}
                                                            />
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            className="light-blue-icon-btn"
                                                        >
                                                            <img
                                                                src={starIcon}
                                                                alt="starIcon"
                                                                width={24}
                                                            />
                                                        </Button>
                                                    </div>

                                                    <div className="flex flex-col gap-3 light-blue-bg mt-4 border-radius-10">
                                                        <div className="p-4">
                                                            <Button
                                                                variant="outlined"
                                                                className="m-auto px-12 w-full min-h-40px light-blue-icon-outline"
                                                            >
                                                                Subscribe for Free
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="bg-white p-3 rounded-2xl">
                                                    <div className="flex justify-end flex-wrap gap-3 mb-2">
                                                        <div className="flex gap-2">
                                                            <img className="img-dark-filter"
                                                                src={ShareIcon}
                                                                alt="ShareIcon"
                                                                width={20}
                                                                height={20}
                                                            />
                                                            <img src={More} alt="More" width={20} className="img-dark-filter" />
                                                        </div>
                                                    </div>
                                                    <div className="relative">
                                                        <img
                                                            src={CollectionPostBg4}
                                                            alt="CollectionPostBg4"
                                                            className="h-100px w-full"
                                                        />
                                                        <img
                                                            src={CollectionPostBg4}
                                                            alt="CollectionPostBg4"
                                                            className="profile-user rounded-full h-77px w-77px"
                                                            width={144}
                                                            height={144}
                                                        />
                                                    </div>
                                                    <div className="relative flex flex-xs-nowrap gap-10 md:pl-24 md:mt-0 mt-10">
                                                        <div className="flex flex-md-wrap flex-wrap justify-between gap-2 w-full mt-2 ml-2">
                                                            <div>
                                                                <p className="font-18 font-semibold text-darkblue flex items-center gap-3">
                                                                    Yrsa Clicks{" "}
                                                                    <img
                                                                        src={GreenVerifyIcon}
                                                                        alt="Green Verify Icon"
                                                                    />
                                                                </p>
                                                                <p>@yrsaclicks</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-wrap gap-3 profile-post-btns mt-3">
                                                        <Button
                                                            variant="outlined"
                                                            className="light-blue-icon-btn">
                                                            <img
                                                                src={dollarIcon}
                                                                alt="dollarIcon"
                                                                width={24}
                                                            />
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            className="light-blue-icon-btn"
                                                        >
                                                            <img
                                                                src={starIcon}
                                                                alt="starIcon"
                                                                width={24}
                                                            />
                                                        </Button>
                                                    </div>

                                                    <div className="flex flex-col gap-3 light-blue-bg mt-4 border-radius-10">
                                                        <div className="p-4">
                                                            <Button
                                                                variant="outlined"
                                                                className="m-auto px-12 w-full min-h-40px light-blue-icon-outline"
                                                            >
                                                                Subscribe for Free
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Masonry>
                                            <Grid item xs={12} md={6} lg={6}></Grid>
                                        </Grid>
                                    </div>

                                    {/* <div>
                                        <Link
                                            href="#"
                                            className="font-medium font-16 cursor-pointer text-current	"
                                            onClick={handleOpenDialog1}
                                        >
                                            Send Tip
                                        </Link>
                                    </div> */}

                                </Grid>
                                <Grid item xs={12} md={12} lg={4}>
                                    <div className="light-gray-bg p-4 subscription">
                                        <div className="flex justify-between flex-wrap gap-3 border-b pb-3">
                                            <p className="font-18 font-medium">
                                                Customer Order
                                            </p>
                                            <img className="img-dark-filter cursor-pointer" onClick={handleClick}
                                                src={listmenublack}
                                                alt="listmenublack"
                                                width={20}
                                            />
                                        </div>

                                        <Menu
                                            anchorEl={anchorEl}
                                            id="more-detail"
                                            open={isOpen}
                                            className="radiobtn-menu"
                                            onClose={handleClose}
                                            onClick={handleClose}
                                            PaperProps={{
                                                elevation: 0,
                                                sx: {
                                                    overflow: "visible",
                                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.08))",
                                                    mt: 1.5,
                                                    "& .MuiAvatar-root": {
                                                        width: 32,
                                                        height: 32,
                                                        ml: -0.5,
                                                        mr: 1,
                                                    },
                                                    "&::before": {
                                                        content: '""',
                                                        display: "block",
                                                        position: "absolute",
                                                        top: 0,
                                                        right: 14,
                                                        width: 10,
                                                        height: 10,
                                                        bgcolor: "background.paper",
                                                        transform: "translateY(-50%) rotate(45deg)",
                                                        zIndex: 0,
                                                    },
                                                },
                                            }}
                                            transformOrigin={{ horizontal: "right", vertical: "top" }}
                                            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                                        >

                                            <MenuItem onClick={handleClose}>
                                                <FormControlLabel
                                                    value="Recent"
                                                    control={<Radio />}
                                                    label="Recent"
                                                />
                                            </MenuItem>
                                            <MenuItem>
                                                <FormControlLabel
                                                    value="Name"
                                                    control={<Radio />}
                                                    label="Name"
                                                />
                                            </MenuItem>
                                            <MenuItem>
                                                <FormControlLabel
                                                    value="People"
                                                    control={<Radio />}
                                                    label="People"
                                                />
                                            </MenuItem>
                                            <MenuItem>
                                                <FormControlLabel
                                                    value="Custom"
                                                    control={<Radio />}
                                                    label="Custom"
                                                />
                                            </MenuItem>
                                            <div className="mx-2">
                                                <Divider className="w-full my-1" />
                                            </div>
                                            <MenuItem>
                                                <FormControlLabel
                                                    value="Ascending"
                                                    control={<Radio />}
                                                    label="Ascending"
                                                />
                                            </MenuItem>
                                            <MenuItem>
                                                <FormControlLabel
                                                    value="Descending"
                                                    control={<Radio />}
                                                    label="Descending"
                                                />
                                            </MenuItem>

                                            <div className="mx-2">
                                                <Divider className="w-full my-1" />
                                            </div>
                                            <MenuItem>
                                                <p className="pl-3 disable">Customize Order</p>
                                            </MenuItem>
                                        </Menu>

                                        <div className="customer-box active mt-5">
                                            <div className="flex justify-between gap-4 flex-wrap">
                                                <div>
                                                    <p className="mb-3 text-darkblue font-medium">
                                                        Following
                                                    </p>
                                                    <AvatarGroup>
                                                        <Avatar
                                                            alt="CustomerImg1"
                                                            src={CustomerImg1}
                                                            className="avatar-img"
                                                        />
                                                        <Avatar
                                                            alt="CustomerImg2"
                                                            src={CustomerImg2}
                                                            className="avatar-img"
                                                        />
                                                        <Avatar
                                                            alt="CustomerImg3"
                                                            src={CustomerImg3}
                                                            className="avatar-img"
                                                        />
                                                    </AvatarGroup>
                                                </div>
                                                <p className="font-14 font-medium mb-0">
                                                    5 Users <span className="mx-2">|</span> 2.1K
                                                    Posts
                                                </p>
                                            </div>
                                        </div>

                                        <div className="customer-box mt-5">
                                            <div className="flex justify-between gap-4 flex-wrap">
                                                <div>
                                                    <p className="mb-3 text-darkblue font-medium">
                                                        Fans
                                                    </p>
                                                    <AvatarGroup>
                                                        <Avatar
                                                            alt="CustomerImg1"
                                                            src={CustomerImg1}
                                                            className="avatar-img"
                                                        />
                                                        <Avatar
                                                            alt="CustomerImg2"
                                                            src={CustomerImg2}
                                                            className="avatar-img"
                                                        />
                                                        <Avatar
                                                            alt="CustomerImg3"
                                                            src={CustomerImg3}
                                                            className="avatar-img"
                                                        />
                                                    </AvatarGroup>
                                                </div>
                                                <p className="font-14 font-medium mb-0">
                                                    23 Users <span className="mx-2">|</span> 232 Posts
                                                </p>
                                            </div>
                                        </div>

                                        <div className="customer-box mt-5">
                                            <div className="flex justify-between gap-4 flex-wrap">
                                                <div>
                                                    <p className="text-darkblue font-medium">
                                                        Muted
                                                    </p>
                                                </div>
                                                <p className="font-14 font-medium mb-0">Empty</p>
                                            </div>
                                        </div>


                                        <div className="customer-box mt-5">
                                            <div className="flex justify-between gap-4 flex-wrap">
                                                <div>
                                                    <p className="mb-3 text-darkblue font-medium">
                                                        Renew Off
                                                    </p>
                                                    <AvatarGroup>
                                                        <Avatar
                                                            alt="CustomerImg1"
                                                            src={CustomerImg1}
                                                            className="avatar-img"
                                                        />
                                                        <Avatar
                                                            alt="CustomerImg2"
                                                            src={CustomerImg2}
                                                            className="avatar-img"
                                                        />
                                                        <Avatar
                                                            alt="CustomerImg3"
                                                            src={CustomerImg3}
                                                            className="avatar-img"
                                                        />
                                                    </AvatarGroup>
                                                </div>
                                                <p className="font-14 font-medium mb-0">
                                                    5 Users <span className="mx-2">|</span> 2.1K Posts
                                                </p>
                                            </div>
                                        </div>

                                        <div className="customer-box mt-5">
                                            <div className="flex justify-between gap-4 flex-wrap">
                                                <div>
                                                    <p className="mb-3 text-darkblue font-medium">
                                                        Renew On
                                                    </p>
                                                    <AvatarGroup>
                                                        <Avatar
                                                            alt="CustomerImg1"
                                                            src={CustomerImg1}
                                                            className="avatar-img"
                                                        />
                                                        <Avatar
                                                            alt="CustomerImg2"
                                                            src={CustomerImg2}
                                                            className="avatar-img"
                                                        />
                                                        <Avatar
                                                            alt="CustomerImg3"
                                                            src={CustomerImg3}
                                                            className="avatar-img"
                                                        />
                                                    </AvatarGroup>
                                                </div>
                                                <p className="font-14 font-medium mb-0">
                                                    22 Users <span className="mx-2">|</span> 2 Posts
                                                </p>
                                            </div>
                                        </div>

                                        <div className="customer-box mt-5">
                                            <div className="flex justify-between gap-4 flex-wrap">
                                                <div>
                                                    <p className="text-darkblue font-medium">
                                                        Recent(Last 24 hours)
                                                    </p>
                                                </div>
                                                <p className="font-14 font-medium mb-0">Empty</p>
                                            </div>
                                        </div>

                                        <div className="customer-box mt-5">
                                            <div className="flex justify-between gap-4 flex-wrap">
                                                <div>
                                                    <p className="text-darkblue font-medium">
                                                        Friends
                                                    </p>
                                                </div>
                                                <p className="font-14 font-medium mb-0">Empty</p>
                                            </div>
                                        </div>

                                        <div className="customer-box mt-5">
                                            <div className="flex justify-between gap-4 flex-wrap">
                                                <div>
                                                    <p className="text-darkblue font-medium">
                                                        Tagged
                                                    </p>
                                                </div>
                                                <p className="font-14 font-medium mb-0">Empty</p>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="active" className="p-0 w-full">
                            <Grid container spacing={2} className="fans-profile">
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="mb-4"
                                ></Grid>
                                <div className="flex flex-col justify-center items-center p-4 h-calc-200">
                                    <img
                                        src={SaveForLaterImg}
                                        alt="SaveForLaterImg"
                                        width={246}
                                    />
                                    <p className="font-20 mt-3">Nothing found</p>
                                </div>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="expired" className="p-0 w-full">
                            <Grid container spacing={2} className="fans-profile">
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="mb-4"
                                ></Grid>
                                <div className="flex flex-col justify-center items-center p-4 h-calc-200">
                                    <img
                                        src={SaveForLaterImg}
                                        alt="SaveForLaterImg"
                                        width={246}
                                    />
                                    <p className="font-20 mt-3">Nothing found</p>
                                </div>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="attention" className="p-0 w-full">
                            <Grid container spacing={2} className="fans-profile">
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="mb-4"
                                ></Grid>
                                <div className="flex flex-col justify-center items-center p-4 h-calc-200">
                                    <img
                                        src={SaveForLaterImg}
                                        alt="SaveForLaterImg"
                                        width={246}
                                    />
                                    <p className="font-20 mt-3">Nothing found</p>
                                </div>
                            </Grid>
                        </TabPanel>
                    </div>
                </TabContext>
            </div>
            <TipDialog openDialog1={openDialog1} handleCloseDialog1={handleCloseDialog1} toggleDrawer={toggleDrawer} />
            <SubscriptionDialog openDialog2={openDialog2} handleCloseDialog2={handleCloseDialog2} toggleDrawer={toggleDrawer} />
            <BillingDrawer toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} />
        </div>
    )
}

export default UsersTab