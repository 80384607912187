import NoArticleUser1 from "./../assets/images/noarticleUser1.png";
import NoArticleUser2 from "./../assets/images/noarticleUser2.png";
import NoArticleUser3 from "./../assets/images/noarticleUser3.png";
import NoArticleUser4 from "./../assets/images/noarticleUser4.png";
import NoArticleUser5 from "./../assets/images/noarticleUser5.png";
import Thumnailuser from "./../assets/images/thumbnail-user1.png";
import BodyEmogiIcon from "./../assets/images/emogi-body.png";

const highlightsArray = [
    {
        "userImgSrc" :NoArticleUser1,
        "thumbnailSrc" : Thumnailuser, 
        "description" : "LEO",
    },
    {
        "userImgSrc" :NoArticleUser2,
        "thumbnailSrc" : "", 
        "description" : "TikToks",
    },
    {
        "userImgSrc" :NoArticleUser3,
        "thumbnailSrc" : BodyEmogiIcon, 
        "description" : "",
    },
    {
        "userImgSrc" :NoArticleUser4,
        "thumbnailSrc" : BodyEmogiIcon, 
        "description" : "",
    },
    {
        "userImgSrc" :NoArticleUser5,
        "thumbnailSrc" : "", 
        "description" : "TikToks",
    },
    {
        "userImgSrc" :NoArticleUser3,
        "thumbnailSrc" : "", 
        "description" : "TikToks",
    },
    {
        "userImgSrc" :NoArticleUser3,
        "thumbnailSrc" : Thumnailuser, 
        "description" : "LEO",
    },

]

export default highlightsArray