import { Button, FormHelperText, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import GoogleIcon from "./../../../../../../assets/images/google.svg";
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { connectAccountAction } from '../../../../../../store/middlewares/web/profile/myProfile/me';
import axiosClient from '../../../../../../utils/api';

const ModalGoogleAccount = () => {

    const [user, setUser] = useState(null)
    const [error, setError] = useState('')
    const dispatch = useDispatch()
    const { connectAccountMessage, connectAccountError, connectAccountSuccess } = useSelector((state) => state.me)

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setUser(codeResponse)
        },
        onError: (error) => {
            setError(error)
        }
    });

    useEffect(() => {
        if (user) {
            axiosClient().get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, { //get data of user whose email is used
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                    Accept: 'application/json'
                }
            })
                .then((res) => {
                    // api call to connect Account
                    dispatch(connectAccountAction({ token: user.access_token, type: "google", email: res.data.email, }));
                })
                .catch((err) => setError(err));
        }
    }, [user, dispatch])


    return (
        //title = GOOGLE ACCOUNT
        <>
            <Button variant="outlined" disabled={connectAccountSuccess} className="w-full" onClick={() => login()} >
                <img
                    src={GoogleIcon}
                    alt="google"
                    width={27}
                    className="mr-3"
                />
                Connect Google Account
            </Button>
            {typeof error === "string" && <FormHelperText error={true}>{error}</FormHelperText>}
            {/* show error */}
            {connectAccountError && <FormHelperText error={true}>{connectAccountMessage}</FormHelperText>}
            {/* show success manage */}
            {connectAccountSuccess && <FormHelperText sx={{ color: "green" }}>{connectAccountMessage}</FormHelperText>}
        </>

    )
}

export default ModalGoogleAccount
