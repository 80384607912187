import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material';
import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ExpandMore } from '@mui/icons-material';

const NoArticleSliderSections = ({ title, subTitle, sliderItems, isSpotify = false }) => {
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        navs: false,
        speed: 500,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className="bg-white rounded-2xl p-4 mt-3">
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    {title}
                </AccordionSummary>
                <AccordionDetails>
                    {subTitle && <p className="text-darkblue font-16 font-medium">{subTitle}</p>}
                    {!isSpotify ?
                        <Slider className="streams pt-2" {...settings}>
                            {sliderItems?.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <div className="lighrgraybg m-0">
                                            <img
                                                src={item?.imgSrc}
                                                alt="NoArticleUser10"
                                                className="w-full"
                                            />
                                            <p className="text-darkblue font-18 font-medium my-2">
                                                {item?.title}
                                            </p>
                                            <Button
                                                variant="contained"
                                                className="m-auto px-12 text-white"
                                                sx={{
                                                    borderRadius: "10px !important",
                                                    minHeight: "37px !important"
                                                }}
                                            >
                                                <img
                                                    src={item?.iconSrc}
                                                    alt="LinkWhiteIcon"
                                                    className="mr-2"
                                                />
                                                {item?.site}
                                            </Button>
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider> :
                        <Slider className="streams pt-2" {...settings}>
                            {sliderItems?.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <div className="relative">
                                            <img
                                                src={item?.imgSrc}
                                                alt="NoArticleUser11"
                                                className="w-full"
                                            />
                                            <p className="spotify-text">{item?.title}</p>
                                        </div>
                                        <div className="text-darkblue font-18 font-medium mt-2 flex items-center gap-2">
                                            <img src={item?.iconSrc} alt="WifiGreenIcon" /> {item?.name}
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider>
                    }
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default NoArticleSliderSections
