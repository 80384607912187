import React from "react";
import BotLink from "./../../../../../../assets/images/bot-link.svg";

const ModalTelegramBot = () => {
  return (
    //tilte = TELEGRAM BOT maybe change
    <div className="lightgray-400 p-3 flex justify-between border-radius-10 gap-3">
      <div>
        <p className="font-18 font-medium text-darkblue ">Get Bot Link</p>
        <p>Please do not share this link with anyone</p>
      </div>
      <img src={BotLink} alt="BotLink" />
    </div>
  );
};

export default ModalTelegramBot;
