import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSuggestions } from "../../../services/web/suggestions/suggestions";
import { blockUserProfile } from "../../../services/web/profile/me.services";
import { closeModal } from "../../../slices/common/modal";
import { updateCountOnBlockedUser } from "../../../slices/web/folders/folders.slice";

export const getSuggestionsAction = createAsyncThunk(
  "suggestions/getSuggestions",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getSuggestions(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const blockuserSuggestionAction = createAsyncThunk(
  "user/blockSuggestion",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { folderId, blocked, ...rest } = payload;
      const response = await blockUserProfile({ ...rest });
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        if (folderId) {
          dispatch(updateCountOnBlockedUser({ folderId, blocked }));
        }
        dispatch(closeModal());
        return fulfillWithValue({ ...response, userId: payload.userId });
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
