import React from "react";

//import images
import youonlylarge from "./../../../../assets/images/you Only icon.png";
import youonlysmall from "./../../../../assets/images/youonlysmall.png";

import { NavLink } from "react-router-dom";

import VerticalLayout from "./Index";
import SimpleBar from "simplebar-react";
import HorizontalLayout from "./../HorizontalLayout";

const SidebarAdmin = ({ layoutType, layoutSidebarSizeType }) => {
  return (
    <React.Fragment>
      <div
        className={`app-menu w-vertical-menu bg-vertical-menu border-r border-vertical-menu-border fixed bottom-0 top-0 z-[1003] transition-all duration-75 ease-linear group-data-[sidebar-size=md]:w-vertical-menu-md group-data-[sidebar-size=sm]:w-vertical-menu-sm group-data-[sidebar-size=sm]:pt-header md:block print:hidden group-data-[sidebar-size=sm]:absolute hidden`}
      >
        <div
          className="flex items-center justify-center px-5 text-center h-header 
       group-data-[sidebar-size=sm]:fixed 
       group-data-[sidebar-size=sm]:top-0 
       group-data-[sidebar-size=sm]:bg-vertical-menu 
       group-data-[sidebar-size=sm]:z-10 group-data-[sidebar-size=sm]:w-[calc(theme('spacing.vertical-menu-sm')_-_1px)] 
       "
        >
          <NavLink to="#" className="">
            <span className="hidden group-data-[sidebar-size=sm]:block">
              <img src={youonlysmall} alt="" className="h-6 mx-auto" />
            </span>
            <span className="group-data-[sidebar-size=sm]:hidden">
              <img src={youonlylarge} alt="" className="h-6 mx-auto" />
            </span>
          </NavLink>
          <NavLink to="#" className="hidden">
            <span className="hidden group-data-[sidebar-size=sm]:block">
              <img src={youonlysmall} alt="" className="h-6 mx-auto" />
            </span>
            <span className="group-data-[sidebar-size=sm]:hidden">
              <img src={youonlylarge} alt="" className="h-6 mx-auto" />
            </span>
          </NavLink>
          <button
            type="button"
            className="hidden p-0 float-end"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>

        {layoutType === "vertical" && layoutSidebarSizeType !== "sm" ? (
          <SimpleBar
            id="scrollbar"
            className="group-data-[sidebar-size=md]:max-h-[calc(100vh_-_theme('spacing.header')_*_1.2)] group-data-[sidebar-size=lg]:max-h-[calc(100vh_-_theme('spacing.header')_*_1.2)]"
          >
            <div>
              <ul className="" id="navbar-nav">
                <VerticalLayout />
              </ul>
            </div>
          </SimpleBar>
        ) : (
          <div
            id="scrollbar"
            className="group-data-[sidebar-size=md]:max-h-[calc(100vh_-_theme('spacing.header')_*_1.2)] group-data-[sidebar-size=lg]:max-h-[calc(100vh_-_theme('spacing.header')_*_1.2)]"
          >
            <div>
              <ul className="" id="navbar-nav">
                <HorizontalLayout />
              </ul>
            </div>
          </div>
        )}
      </div>
      <div
        id="sidebar-overlay"
        className="absolute inset-0 z-[1002] bg-slate-500/30 hidden"
      ></div>
    </React.Fragment>
  );
};

export default SidebarAdmin;
