import { createSlice } from "@reduxjs/toolkit";
import {
  addSubscriptionsPlanAction,
  getSubscriptionsPlanAction,
} from "../../../middlewares/web/subscriptions/subscriptions";

export const plansSlice = createSlice({
  name: "plans",
  initialState: {
    plansLoading: false,
    plans: [],
  },
  reducers: {
    setPlans: ( state ) => {
      state.plansLoading = false,
      state.plans = []
    }
  },
  extraReducers(builder) {
    builder.addCase(addSubscriptionsPlanAction.fulfilled, (state, action) => {
      let { plans } = action.payload.response
      if(plans.length === 1) {
        state.plans = [ ...plans ]
      } else {
        state.plans = [...state.plans, ...plans];
      }
    }),
      builder.addCase(getSubscriptionsPlanAction.pending, (state) => {
        state.plansLoading = true;
        state.plans = [];
      }),
      builder.addCase(getSubscriptionsPlanAction.fulfilled, (state, action) => {
        state.plansLoading = false;
        state.plans = action.payload.response.plans;
      }),
      builder.addCase(getSubscriptionsPlanAction.rejected, (state) => {
        state.plansLoading = false;
        state.plans = [];
      });
  },
});
export const { setPlans } = plansSlice.actions;

export default plansSlice.reducer;
