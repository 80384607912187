import { openToast } from "../../../slices/common/toast/index.js";
import {
  getAllSettings,
  getGeneralSettings,
  updateGeneralSettings,
  updateSettings,
} from "./../../../services/admin/settings/settings.js";
import { getAllSettingsForWeb } from "./../../../services/web/settings/settings.js";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const GetAllSettingsAction = createAsyncThunk(
  "settings/getSettings",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getAllSettings(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const GetGeneralSettingsAction = createAsyncThunk(
  "settings/getGeneralSettings",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getGeneralSettings();
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const GetAllSettingsForWebAction = createAsyncThunk(
  "settings/getSettingsForWeb",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getAllSettingsForWeb();
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const UpdateSettingsAction = createAsyncThunk(
  "settings/updateSettings",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await updateSettings(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const UpdateGeneralSettingsAction = createAsyncThunk(
  "settings/updateGeneralSettings",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await updateGeneralSettings(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);
