import { Box, Grid, Drawer, FormControl, MenuItem, Select, TextField, OutlinedInput, InputAdornment, FormControlLabel, Checkbox, Button } from '@mui/material'
import React from 'react'
import DatePicker from './../../../../../../pages/web/creatorSide/common/Datepicker';

import IndiaIcon from "./../../../../../../assets/images/language/Hindi.svg";
import VisaIcon from "./../../../../../../assets/images/visa.svg";
import MasterIcon from "./../../../../../../assets/images/master.svg";
import MaestroIcon from "./../../../../../../assets/images/maestro.svg";
import DinearsClubIcon from "./../../../../../../assets/images/dinears-club.svg";
import JCBIcon from "./../../../../../../assets/images/jcb.svg";
import InfoCircleBlue from "./../../../../../../assets/images/info-circle-blue.svg";
const BillingDrawer = ({ toggleDrawer, isDrawerOpen }) => {
    return (
        <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
            <Box
                className="w-750px payment-fans"
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
            >
                <div className="light-blue-bg border-radius-10">
                    <p className="text-primary uppercase font-20 font-semibold">
                        add card
                    </p>
                </div>

                <div className="bg-white rounded-2xl p-3 mt-4">
                    <div className="post-tab gray-border p-4 rounded-2xl">
                        <h5 className="font-20 font-semibold text-darkblue mb-3">
                            Billing Details
                        </h5>
                        <p className="mb-4">
                            We are fully compliant with Payment Card Industry Data Security
                            Standards.
                        </p>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className="sm:mr-3 mb-4">
                                    <FormControl fullWidth>
                                        <Select
                                            placeholder="Your country"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            className="flex items-center gap-3"
                                        // onChange={handleChange}
                                        >
                                            <MenuItem
                                                value={10}
                                                className="flex items-center gap-3"
                                            >
                                                <img src={IndiaIcon} alt="IndiaIcon" /> India
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className="mb-4">
                                    <FormControl fullWidth>
                                        <Select
                                            placeholder="Your state / province"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            className="flex items-center gap-3"
                                        // onChange={handleChange}
                                        >
                                            <MenuItem
                                                value={10}
                                                className="flex items-center gap-3"
                                            >
                                                Gujarat
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className="sm:mr-3 mb-4">
                                    <TextField
                                        className="w-full"
                                        variant="outlined"
                                        placeholder="Your city"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className="mb-4">
                                    <TextField
                                        className="w-full"
                                        variant="outlined"
                                        placeholder="Your ZIP / postal code"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div>
                                    <TextField
                                        className="w-full"
                                        variant="outlined"
                                        placeholder="Your address"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="post-tab gray-border p-4 pb-0 rounded-2xl mt-4">
                        <h5 className="font-20 font-semibold text-darkblue mb-5">
                            Card Details
                        </h5>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className="sm:mr-3 mb-4">
                                    <TextField
                                        className="w-full"
                                        variant="outlined"
                                        placeholder="Your email"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className="mb-4">
                                    <TextField
                                        className="w-full"
                                        variant="outlined"
                                        placeholder="Card holder name"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className="mb-4">
                                    <FormControl className="w-full" variant="outlined">
                                        <OutlinedInput
                                            placeholder="Card number"
                                            id="outlined-adornment-weight"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <img src={MasterIcon} alt="MasterIcon" />{" "}
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className="sm:mr-3 mb-4">
                                    <DatePicker className='w-full' />
                                    {/* <TextField
                      className="w-full"
                      variant="outlined"
                      placeholder="MM / YY"
                    /> */}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className="mb-4">
                                    <TextField
                                        className="w-full"
                                        variant="outlined"
                                        placeholder="CVC"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <div>
                        <FormControlLabel
                            className="font-medium font-18 text-darkblue"
                            control={<Checkbox defaultChecked />}
                            label="Tick here to confirm that you are at least 18 years old and the age of majority in your place of residence"
                        />
                    </div>

                    <div className="flex flex-wrap gap-3 justify-end mt-5">
                        <Button
                            variant="outlined"
                            className="px-8 min-h-47px mb-2"
                        // onClick={handleOpenDialog1}
                        >
                            Verify
                        </Button>
                        <Button variant="contained" className="px-8 min-h-47px mb-2">
                            Submit
                        </Button>
                    </div>

                    <div className="light-blue-bg flex items-start gap-3 mt-8">
                        <img src={InfoCircleBlue} alt="InfoCircleBlue" />
                        <span>
                            YouOnly will make a one-time charge of $0.10 when adding your
                            payment card. The charges on your credit card statement will
                            appear as "YouOnly".
                        </span>
                    </div>

                    <div className="flex flex-wrap justify-center gap-3 mt-8 mb-4">
                        <Button variant="contained" className="payment-icons">
                            <img src={VisaIcon} alt="VisaIcon" className="img-dark-filter" />
                        </Button>
                        <Button variant="contained" className="payment-icons">
                            <img src={MasterIcon} alt="MasterIcon" />
                        </Button>
                        <Button variant="contained" className="payment-icons">
                            <img src={MaestroIcon} alt="MaestroIcon" />
                        </Button>
                        <Button variant="contained" className="payment-icons">
                            <img src={DinearsClubIcon} alt="DinearsClubIcon" />
                        </Button>
                        <Button variant="contained" className="payment-icons">
                            <img src={JCBIcon} alt="JCBIcon" className="img-dark-filter" />
                        </Button>
                    </div>
                    <p className="text-secondary font-14 md:w-3/4 m-auto text-center">
                        Fenix International Limited, 9th Floor, 107 Cheapside, London,
                        EC2V 6DN Fenix Internet LLC, 1000 N.West Street, Suite 1200,
                        Wilmington, Delaware, 19801 USA
                    </p>
                </div>
            </Box>
        </Drawer>
    )
}

export default BillingDrawer