import { Button } from '@mui/material'
import NotifyUser2 from './../../../../../assets/images/notification/notifyuser2.png';
import TagBlueIcon from './../../../../../assets/images/tag-blue.svg';
import verifyIcon from './../../../../../assets/images/green-verify.svg';
import React from 'react'

const TagNotifications = () => {
    //Notification In Tags Tab
    return (
        <>
            <div className="light-gray-box flex justify-between gap-2">
                <div className="flex flex-sm-wrap items-start gap-6">
                    <div className="relative">
                        <img
                            src={NotifyUser2}
                            alt="NotifyUser2"
                            className="notify-user"
                        />
                        <img
                            src={TagBlueIcon}
                            alt="TagBlueIcon"
                            className="notify-user-status"
                        />
                    </div>
                    <div>
                        <div className="flex flex-wrap items-center gap-2 title-width">
                            <p className="text-darkblue font-20 font-semibold">
                                Maria Lubin
                            </p>
                            <img src={verifyIcon} alt="verifyIcon" />
                            <span className="line">|</span>
                            <p>@bluntbarble</p>
                        </div>
                        <p className="text-darkblue font-medium mt-2">
                            Hunnies, the content i’ve got coming is
                            next level... Seriously stepping it up and
                            you’re not going to want to miss it ✌️✌️✌️
                        </p>
                        <div className="mt-2 flex gap-3">
                            <Button
                                variant="outlined"
                                className="light-blue-btn"
                            >
                                Leo
                            </Button>
                            <Button
                                variant="outlined"
                                className="light-blue-btn"
                            >
                                Simply Soma
                            </Button>
                            <Button
                                variant="outlined"
                                className="light-blue-btn"
                            >
                                Leo
                            </Button>
                        </div>
                    </div>
                </div>
                <p className="font-medium text-nowrap date-position	">
                    March 7
                </p>
            </div>
        </>
    )
}

export default TagNotifications
