import { Divider, FormControlLabel, IconButton, Menu, MenuItem, Radio, RadioGroup } from '@mui/material'
import React, { useState } from 'react'
import ShareBlackIcon from "./../../../../../assets/images/share-black.png";
import MoreSquare from "./../../../../../assets/images/more-square.svg";
import starBlackIcon from "./../../../../../assets/images/star-black.svg";

const NoArticleFilterMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget); //set Anchor for Menu
    };

    const handleClose = () => {
        setAnchorEl(null); //remove Anchor for Menu
    };
    return (
        <div>
            <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? "more-detail" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
            >
                <img src={starBlackIcon} alt="starIcon" width={24} />
            </IconButton>
            <IconButton
                onClick={(e) => { handleClick(e, 2) }}
                size="small"
                aria-controls={open ? "more-detail" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
            >
                <img src={ShareBlackIcon} alt="ShareIcon" width={24} />
            </IconButton>
            <IconButton
                onClick={(e) => { handleClick(e, 2) }}
                size="small"
                aria-controls={open ? "more-detail" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
            >
                <img src={MoreSquare} alt="MoreSquare" width={24} />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                id="more-detail"
                open={open}
                className="radiobtn-menu"
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: "200px",
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.08))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&::before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem onClick={handleClose} sx={{
                    flexDirection: "column",
                    alignItems: "flex-start"
                }}>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel
                            value="female"
                            control={<Radio />}
                            label="All Time"
                        />
                        <FormControlLabel
                            value="male"
                            control={<Radio />}
                            label="Go to Date"
                        />
                    </RadioGroup>
                    <Divider className="w-full" />
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel
                            value="female"
                            control={<Radio />}
                            label="Latest Posts"
                        />
                        <FormControlLabel
                            value="male"
                            control={<Radio />}
                            label="Most Liked"
                        />
                        <FormControlLabel
                            value="male"
                            control={<Radio />}
                            label="Highest Tips"
                        />
                    </RadioGroup>
                    <Divider className="w-full" />
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel
                            value="female"
                            control={<Radio />}
                            label="Ascending"
                        />
                        <FormControlLabel
                            value="male"
                            control={<Radio />}
                            label="Descending"
                        />
                    </RadioGroup>
                </MenuItem>
            </Menu>
        </div>
    )
}

export default NoArticleFilterMenu
